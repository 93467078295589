import React, { AnchorHTMLAttributes, FC, ReactNode } from 'react';
import { gql } from '@apollo/client';
import { LinkFieldFragment } from '../generated/graphql';
import Link from './Link';

export const fragment = gql`
    fragment LinkField on linkField_Link {
        customText
        target
        url
        type
        element {
            title
            uri
        }
    }
`;

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
    link: LinkFieldFragment;
}

const LinkField: FC<React.PropsWithChildren<Props>> = ({ link, title, children, ...rest }: Props) => {
    const { type, url, element, customText } = link;
    let text: ReactNode = children ?? customText ?? url;

    switch (type) {
        case 'entry':
            text = children ?? customText ?? element?.title;
            return element?.uri ? (
                <Link href={`/${element.uri}`}>
                    <a target={link.target ?? undefined} {...rest}>
                        {text}
                    </a>
                </Link>
            ) : null;
        default:
            return url ? (
                <a href={url} target={link.target ?? undefined} {...rest} rel="noopener">
                    {text}
                </a>
            ) : null;
    }
};

export default LinkField;
