"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = require("react");
var _formik = require("formik");
var _TenantContext = require("../../context/TenantContext");
var _getAccommodationTypeOptions = require("../../utils/getAccommodationTypeOptions");
var _reactIntl = require("react-intl");
var _getDefaultAccommodationType = _interopRequireDefault(require("../../utils/getDefaultAccommodationType"));
const _excluded = ["type", "trip", "arrivalDate", "departureDate", "flexibleMonth", "amountOfNights"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const getHumanReadableBookingType = type => {
  switch (type) {
    case 'flexible-random':
      return 'Flexibel (willekeurig)';
    case 'flexible-weekends':
      return 'Flexibel (weekends)';
    case 'static':
      return 'Datum';
    case 'coming-month':
      return 'Komende maand';
    default:
      return 'Onbekend';
  }
};

// pushes changes in the searchbarvalues to the datalayer every time the form gets submitted
const useAnalyticsSearchBarValues = values => {
  const {
    isSubmitting
  } = (0, _formik.useFormikContext)();
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const intl = (0, _reactIntl.useIntl)();
  const accommodationTypeOptions = (0, _getAccommodationTypeOptions.getAccommodationTypeOptions)(intl, brandConfig);
  (0, _react.useEffect)(() => {
    if (isSubmitting) {
      window.dataLayer = window.dataLayer ?? [];
      const _values$booking = values.booking,
        {
          type,
          trip,
          arrivalDate,
          departureDate,
          flexibleMonth,
          amountOfNights
        } = _values$booking,
        booking = (0, _objectWithoutProperties2.default)(_values$booking, _excluded);
      const {
        accommodationType
      } = values;
      const isFlexible = type?.startsWith('flexible');
      window.dataLayer.push(_objectSpread(_objectSpread({
        event: isFlexible ? 'flexiblesearch' : 'normalsearch',
        searchType: getHumanReadableBookingType(type)
      }, booking), {}, {
        departureDate: !isFlexible ? departureDate : null,
        arrivalDate: !isFlexible ? arrivalDate : null,
        flexibleMonth: isFlexible ? flexibleMonth : null,
        amountOfNights: isFlexible ? amountOfNights : null,
        accommodationType: accommodationTypeOptions.find(({
          value
        }) => value === accommodationType ?? (0, _getDefaultAccommodationType.default)(brandConfig))?.label
      }));
    }
  }, [values, isSubmitting, brandConfig, accommodationTypeOptions]);
};
var _default = exports.default = useAnalyticsSearchBarValues;