"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.MAX_DAYS = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _UnreachableCaseError = require("@oberoninternal/travelbase-ds/entities/UnreachableCaseError");
var _validInterval = _interopRequireDefault(require("@oberoninternal/travelbase-ds/utils/validInterval"));
var _addMonths = _interopRequireDefault(require("date-fns/addMonths"));
var _differenceInCalendarDays = _interopRequireDefault(require("date-fns/differenceInCalendarDays"));
var _isAfter = _interopRequireDefault(require("date-fns/isAfter"));
var _isSameDay = _interopRequireDefault(require("date-fns/isSameDay"));
var _react = require("react");
var _isDate = _interopRequireDefault(require("../utils/isDate"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const getDefaultInitialState = (isInline = false) => ({
  selecting: false,
  period: null,
  inView: isInline ? [new Date()] : [new Date(), (0, _addMonths.default)(new Date(), 1)],
  open: false,
  autoHide: true,
  isInline
});
const MAX_DAYS = exports.MAX_DAYS = 21;
function periodReducer(state = getDefaultInitialState(), action) {
  const {
    period,
    selecting,
    inView,
    singleDate,
    optionalSingleDate,
    isInline
  } = state;
  switch (action.type) {
    case 'click':
      {
        const {
          day
        } = action;
        const selectingState = _objectSpread(_objectSpread({}, state), {}, {
          period: {
            start: day,
            end: undefined
          },
          selecting: true,
          focus: 'end'
        });
        if (singleDate || (0, _isDate.default)(period)) {
          return _objectSpread(_objectSpread({}, state), {}, {
            period: day,
            selecting: false,
            focus: undefined,
            open: false
          });
        }
        if (!period) {
          return selectingState;
        }
        if (period.start && (0, _isSameDay.default)(period.start, day) && optionalSingleDate && selecting || period.end && (0, _isSameDay.default)(period.end, day) && state.focus === 'end' && optionalSingleDate && !selecting) {
          return _objectSpread(_objectSpread({}, state), {}, {
            period: {
              end: day,
              start: day
            },
            focus: 'start',
            selecting: false
          });
        }
        if (period.start && (0, _isSameDay.default)(period.start, day)) {
          return _objectSpread(_objectSpread({}, state), {}, {
            period: undefined,
            focus: 'start'
          });
        }
        if (period.end && !selecting && state.focus !== 'start' && (0, _isSameDay.default)(period.end, day)) {
          return state;
        }
        if ((0, _validInterval.default)(period)) {
          if (state.focus === 'start') {
            // when the period is about to be invalid (when the focus is end and the day is before the start)
            // we adapt to this by going to the "selecting" state
            if ((0, _isAfter.default)(day, period.end) || (0, _differenceInCalendarDays.default)(period.end, day) > MAX_DAYS) {
              return selectingState;
            }
            if ((0, _isSameDay.default)(day, period.end)) {
              return selectingState;
            }
            return _objectSpread(_objectSpread({}, state), {}, {
              // selecting: true,
              period: _objectSpread(_objectSpread({}, period), {}, {
                start: day
              }),
              focus: 'end'
            });
          }
          if (state.focus === 'end' && !selecting) {
            if ((0, _isAfter.default)(period.start, day)) {
              return selectingState;
            }
            return _objectSpread(_objectSpread({}, state), {}, {
              period: _objectSpread(_objectSpread({}, state.period), {}, {
                end: day
              }),
              open: !state.autoHide
            });
          }
        }
        return _objectSpread(_objectSpread({}, state), {}, {
          period: _objectSpread(_objectSpread({}, period), {}, {
            end: day
          }),
          open: !state.autoHide,
          selecting: false
        });
      }
    case 'hover':
      {
        const {
          day
        } = action;
        if (selecting && !(0, _isDate.default)(period) && period?.start) {
          const [start, end] = [period.start, day];
          return _objectSpread(_objectSpread({}, state), {}, {
            period: {
              start,
              end
            }
          });
        }
        return state;
      }
    case 'set':
      return _objectSpread(_objectSpread({}, state), action.state);
    case 'clear':
      return getDefaultInitialState();
    case 'reset':
      return _objectSpread(_objectSpread({}, getDefaultInitialState()), action.state);
    case 'next':
      {
        if (isInline) {
          const [end] = inView;
          return _objectSpread(_objectSpread({}, state), {}, {
            inView: [(0, _addMonths.default)(end, 1)]
          });
        }
        const [, end] = inView;
        return _objectSpread(_objectSpread({}, state), {}, {
          inView: [end, (0, _addMonths.default)(end, 1)]
        });
      }
    case 'previous':
      {
        if (isInline) {
          const [start] = inView;
          return _objectSpread(_objectSpread({}, state), {}, {
            inView: [(0, _addMonths.default)(start, -1)]
          });
        }
        const [start] = inView;
        return _objectSpread(_objectSpread({}, state), {}, {
          inView: [(0, _addMonths.default)(start, -1), start]
        });
      }
    case 'setView':
      {
        if (isInline) {
          return _objectSpread(_objectSpread({}, state), {}, {
            inView: [(0, _addMonths.default)(action.month, 1)]
          });
        }
        return _objectSpread(_objectSpread({}, state), {}, {
          inView: [action.month, (0, _addMonths.default)(action.month, 1)]
        });
      }
    case 'sesame':
      {
        let open = true;
        switch (action.variant) {
          case 'close':
            open = false;
            break;
          case 'toggle':
            open = !state.open;
            break;
          default:
            break;
        }
        return _objectSpread(_objectSpread({}, state), {}, {
          open
        });
      }
    default:
      throw new _UnreachableCaseError.UnreachableCaseError(action);
  }
}
const useDatepicker = (customInitialState, isInline) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialState = (0, _react.useMemo)(() => _objectSpread(_objectSpread({}, getDefaultInitialState(isInline)), customInitialState), [customInitialState, isInline]);
  const {
    0: state,
    1: dispatch
  } = (0, _react.useReducer)(periodReducer, initialState);
  const set = (0, _react.useCallback)(newState => {
    dispatch({
      state: newState,
      type: 'set'
    });
  }, []);
  const reset = (0, _react.useCallback)(() => {
    dispatch({
      state: initialState,
      type: 'reset'
    });
  }, [initialState]);
  const clear = (0, _react.useCallback)(() => {
    dispatch({
      type: 'clear'
    });
  }, []);
  const onClick = (0, _react.useCallback)(({
    day
  }) => dispatch({
    type: 'click',
    day
  }), []);
  const onHover = (0, _react.useCallback)(({
    day
  }) => dispatch({
    type: 'hover',
    day
  }), []);
  const onNextClick = (0, _react.useCallback)(() => {
    dispatch({
      type: 'next'
    });
  }, []);
  const onPreviousClick = (0, _react.useCallback)(() => {
    dispatch({
      type: 'previous'
    });
  }, []);
  const setView = (0, _react.useCallback)(month => {
    dispatch({
      type: 'setView',
      month
    });
  }, []);
  const sesame = (0, _react.useCallback)(variant => dispatch({
    type: 'sesame',
    variant
  }), []);
  return [state, {
    onClick,
    onHover,
    reset,
    set,
    clear,
    onNextClick,
    onPreviousClick,
    setView,
    sesame
  }];
};
var _default = exports.default = useDatepicker;