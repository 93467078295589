import React, { SVGAttributes } from 'react';

const IDeal = (props: SVGAttributes<SVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 25" {...props}>
        <defs />
        <defs>
            <path id="Ideal-a" d="M0 0h28.829v25H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g>
                <mask id="Ideal-b" fill="#fff">
                    <use xlinkHref="#Ideal-a" />
                </mask>
                <path
                    fill="#FFF"
                    d="M2.059.686C1.298.686.686 1.297.686 2.06V22.94c0 .762.612 1.373 1.373 1.373h12.806c8.559 0 13.277-4.204 13.277-11.84C28.142 2.22 19.82.686 14.865.686H2.059zM14.865 25H2.059A2.06 2.06 0 010 22.94V2.06A2.06 2.06 0 012.059 0h12.806c12.152 0 13.964 7.818 13.964 12.473C28.829 20.55 23.863 25 14.865 25z"
                    mask="url(#Ideal-b)"
                />
            </g>
            <path
                fill="#FFF"
                d="M19.272 11.09l-.568 1.598h1.127l-.548-1.598zM11.39 11.219a.983.983 0 00-.386-.29 1.588 1.588 0 00-.59-.097h-.687v2.745h.848v-.01c.14 0 .268-.021.407-.065a.796.796 0 00.344-.225c.096-.106.182-.236.246-.407a1.68 1.68 0 00.097-.611c0-.215-.022-.418-.065-.59a1.184 1.184 0 00-.214-.45"
            />
            <path
                fill="#FFF"
                d="M22.008 14.371v-4.354h.954v3.55h2.124v.804h-3.078zm-1.598 0l-.333-.965h-1.63l-.343.965h-.965l1.64-4.354h.999l1.629 4.354h-.997zm-3.861-.01h-3.303v-4.355h3.249V10.821H14.2v.933h2.113v.741H14.2v1.061h2.349v.804zm-4.043-1.33a2.055 2.055 0 01-.365.697 1.773 1.773 0 01-.612.461 2.162 2.162 0 01-.868.171H8.784v-4.354h1.855v.011c.279 0 .536.043.784.128.246.086.45.226.632.397.172.183.31.408.418.665.097.268.15.58.15.944 0 .322-.042.612-.117.88zm3.27-10.328H9.225c-.762 0-1.373.622-1.373 1.372v18.212h7.926c7.196 0 10.317-4.065 10.317-9.814 0-5.502-3.121-9.77-10.317-9.77zM4.269 10.189a2.006 2.006 0 110 4.011 2.006 2.006 0 010-4.011M5.78 22.286a3.032 3.032 0 01-3.034-3.035v-2.37a1.522 1.522 0 113.046 0v5.405H5.78z"
            />
        </g>
    </svg>
);

export default IDeal;
