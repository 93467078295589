"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
const SvgButton = _styledComponents.default.button.withConfig({
  displayName: "SvgButton",
  componentId: "sc-1h2ml44-0"
})(["display:flex;padding:0;> svg{margin:auto;}"]);
var _default = exports.default = SvgButton;