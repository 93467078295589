import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import SearchBoxModal from '@oberoninternal/travelbase-website/dist/components/SearchBoxModal';
import { SearchBarValues } from '@oberoninternal/travelbase-website/dist/components/SearchBox';

interface Props {
    open: boolean;
    onClose: () => void;
}

const SearchBoxInner: FC<React.PropsWithChildren<Props>> = ({ open, onClose }) => {
    const { submitForm, setValues, values } = useFormikContext<SearchBarValues>() ?? {};

    return (
        <SearchBoxModal open={open} onClose={onClose} submitForm={submitForm} setValues={setValues} values={values} />
    );
};

export default SearchBoxInner;
