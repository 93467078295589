"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.SectionDivider = exports.CheckoutDivider = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styledSystem = require("styled-system");
var _react = _interopRequireDefault(require("react"));
var __jsx = _react.default.createElement;
const Divider = _styledComponents.default.hr.withConfig({
  displayName: "Divider",
  componentId: "sc-1o1j602-0"
})(["width:100%;height:1px;border:none;background-color:", ";", " ", ""], ({
  theme
}) => theme.colors.neutral['10'], _styledSystem.space, _styledSystem.layout);
const SectionDivider = exports.SectionDivider = (0, _styledComponents.default)(Divider).withConfig({
  displayName: "Divider__SectionDivider",
  componentId: "sc-1o1j602-1"
})(["max-width:140.8rem;width:calc(100% - var(--wrapperPadding) * 2);margin:0 auto;"]);
const CheckoutDivider = props => __jsx(Divider, (0, _extends2.default)({
  my: [6, null, 8]
}, props));
exports.CheckoutDivider = CheckoutDivider;
var _default = exports.default = Divider;