"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SelectInput = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/form/SelectInput"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _grid = require("@rebass/grid");
var _formik = require("formik");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _customizationClassNames = require("../constants/customizationClassNames");
var _Dropdown = _interopRequireWildcard(require("./designsystem/Dropdown"));
var _Fieldset = _interopRequireDefault(require("./designsystem/Fieldset"));
var _SearchForm = require("./SearchForm");
var _TripGroupInputField = _interopRequireDefault(require("./TripGroupInputField"));
var _SearchDateField = _interopRequireDefault(require("./SearchDateField"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const SearchForm = ({
  variant,
  accommodationTypeOptions,
  searchModalOpen = false,
  active = _SearchForm.interactables,
  hideFlexibleOptions
}) => {
  const isSearchPage = variant === 'searchpage';
  const isHero = variant === 'hero';
  const isAccommodationSearch = variant === 'accommodationSearch';
  const isHomePage = variant === 'home';
  const showLabel = isHomePage || searchModalOpen;
  const isFull = isSearchPage || isHero || isAccommodationSearch;
  const {
    values
  } = (0, _formik.useFormikContext)();
  const sesame = (0, _useSesame.default)(false, {
    closeOnClickOutside: true
  });
  return __jsx(_react.default.Fragment, null, active.includes('tripgroup') && __jsx(_Fieldset.default, {
    style: {
      maxWidth: isHomePage ? undefined : '34rem'
    },
    label: showLabel && __jsx(_reactIntl.FormattedMessage, {
      id: "P0UVuU",
      defaultMessage: [{
        "type": 0,
        "value": "Reisgezelschap"
      }]
    }),
    id: "tripGroup",
    width: isFull ? undefined : 1,
    flex: isFull ? 1 : undefined,
    dataCy: "searchFormTripGroup",
    className: _customizationClassNames.CLASS_SEARCH_BOX_FIELDSET
  }, __jsx(StyledTripGroupInputField, {
    name: "booking",
    variantStyle: variant,
    surfaceElementVariant: "searchbar",
    className: _customizationClassNames.CLASS_SEARCH_BOX_TRIP_GROUP_FIELD
  })), active.includes('datepicker') && __jsx(_Fieldset.default, {
    label: showLabel && __jsx(_reactIntl.FormattedMessage, {
      id: "ANwe4i",
      defaultMessage: [{
        "type": 0,
        "value": "Reisperiode"
      }]
    }),
    dataCy: "searchFormDates",
    width: isFull ? [1, null, '33%', '30rem'] : 1,
    className: _customizationClassNames.CLASS_SEARCH_BOX_FIELDSET
  }, __jsx(_SearchDateField.default, {
    hideFlexibleOptions: hideFlexibleOptions,
    isHomepage: isHomePage
  })), active.includes('accommodationType') && __jsx(_Fieldset.default, {
    label: (isHomePage || searchModalOpen) && __jsx(_reactIntl.FormattedMessage, {
      id: "pllnR0",
      defaultMessage: [{
        "type": 0,
        "value": "Type accommodatie"
      }]
    }),
    id: "accommdationType",
    width: isFull ? undefined : 1,
    flex: isFull ? 1 : undefined,
    dataCy: "searchFormAccommdationType",
    className: _customizationClassNames.CLASS_SEARCH_BOX_FIELDSET,
    style: {
      maxWidth: isHomePage ? undefined : '24rem'
    }
  }, __jsx(StyledDropdown, {
    ref: sesame.ref,
    surfaceElement: __jsx(_react.default.Fragment, null, __jsx(_grid.Flex, {
      flexWrap: "wrap"
    }, __jsx(_grid.Box, null, __jsx(StyledBody, {
      variant: "tiny"
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "pllnR0",
      defaultMessage: [{
        "type": 0,
        "value": "Type accommodatie"
      }]
    }))), __jsx(_grid.Box, {
      width: 1
    }, __jsx(_grid.Box, null, __jsx(StyledDropdownDescription, {
      variant: "small",
      isPlaceholder: false
    }, accommodationTypeOptions.find(typeOption => typeOption.value === values.accommodationType)?.label))))),
    borderRadius: 0,
    open: sesame.open,
    onToggle: sesame.onToggle,
    scrollIntoView: true
  }, __jsx(StyledSelectInput, {
    hideChoices: true,
    options: accommodationTypeOptions,
    onMenuClose: () => {
      sesame.onClose();
    },
    name: "accommodationType",
    thinShadow: true,
    menuPlacement: "bottom",
    menuIsOpen: sesame.open,
    color: "primary",
    placeholder: __jsx(_reactIntl.FormattedMessage, {
      id: "SVfyOI",
      defaultMessage: [{
        "type": 0,
        "value": "Type verblijf"
      }]
    }),
    components: {
      Control: () => null
    }
  }))));
};
const StyledSelectInput = (0, _styledComponents.default)(_SelectInput.default).withConfig({
  displayName: "SearchDropdownForm__StyledSelectInput",
  componentId: "sc-153jd2p-0"
})(["& div{position:relative;margin-top:0;}"]);
const StyledTripGroupInputField = (0, _styledComponents.default)(_TripGroupInputField.default).withConfig({
  displayName: "SearchDropdownForm__StyledTripGroupInputField",
  componentId: "sc-153jd2p-1"
})(["--buttonContainerRadius:calc(", " + ", "*0.5);", ";& > button{padding-left:2rem;}"], ({
  theme
}) => theme.radius.button, ({
  theme
}) => theme.radius.button, ({
  variantStyle,
  theme
}) => variantStyle === 'home' ? (0, _styledComponents.css)(["--borderRadius:", ";"], theme.radius.textInput) : (0, _styledComponents.css)(["--borderRadius:var(--buttonContainerRadius) 0 0 var(--buttonContainerRadius);"]));
const StyledDropdown = (0, _styledComponents.default)(_Dropdown.default).withConfig({
  displayName: "SearchDropdownForm__StyledDropdown",
  componentId: "sc-153jd2p-2"
})(["--borderRadius:0;padding-left:3.2rem;"]);
const StyledBody = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "SearchDropdownForm__StyledBody",
  componentId: "sc-153jd2p-3"
})(["color:", ";font-weight:300 !important;line-height:1.15;white-space:nowrap;"], ({
  theme
}) => theme.colors.neutral['60']);
const StyledDropdownDescription = (0, _styledComponents.default)(_Dropdown.DropdownDescription).withConfig({
  displayName: "SearchDropdownForm__StyledDropdownDescription",
  componentId: "sc-153jd2p-4"
})(["", ";"], ({
  isPlaceholder,
  theme
}) => isPlaceholder && `color: ${theme.colors.neutral['40']}`);
var _default = exports.default = SearchForm;