"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _yup = _interopRequireWildcard(require("yup"));
var Yup = _yup;
var _addYears = _interopRequireDefault(require("date-fns/addYears"));
var _isAfter = _interopRequireDefault(require("date-fns/isAfter"));
var _set = _interopRequireDefault(require("date-fns/set"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const method = (age, message) => Yup.object().test({
  message,
  test(value) {
    const {
      createError,
      path
    } = this;
    const minDate = (0, _addYears.default)(new Date(), -age);
    if (value.year && value.month && value.year) {
      const parsedDate = (0, _set.default)(new Date(0), {
        year: +value.year,
        month: +value.month - 1,
        // months are zero indexed...
        date: +value.day
      });
      if ((0, _isAfter.default)(parsedDate, minDate)) {
        createError({
          path,
          message
        });
        return false;
      }
    }
    return true;
  }
});
Yup.addMethod(Yup.object, 'minimumAge', method);