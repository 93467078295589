"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.StyledTextInput = exports.InputWrapper = exports.DatepickerStartInput = exports.DatepickerEndInput = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _TextInput = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/form/TextInput"));
var _createBorderCss = _interopRequireDefault(require("@oberoninternal/travelbase-ds/utils/createBorderCss"));
var _react = _interopRequireDefault(require("react"));
var _Calendar = _interopRequireDefault(require("../../svg/Calendar.svg"));
var _Context = require("./Context");
var _isDate = _interopRequireDefault(require("../../../utils/isDate"));
var _reactIntl = require("react-intl");
var _addMonths = _interopRequireDefault(require("date-fns/addMonths"));
const _excluded = ["open", "period", "inView", "selecting"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const DatepickerInputs = ({
  hideCalendarIcon,
  hideInput
}) => {
  const _useDatepickerContext = (0, _Context.useDatepickerContext)(),
    {
      datepicker: [{
        open,
        period,
        inView,
        selecting
      }, {
        sesame,
        set
      }],
      singleDate
    } = _useDatepickerContext,
    state = (0, _objectWithoutProperties2.default)(_useDatepickerContext.datepicker, _excluded);
  const {
    formatDate,
    formatMessage
  } = (0, _reactIntl.useIntl)();
  const formatToValue = date => date ? formatDate(date, {
    format: 'DATERANGEPICKER'
  }) : '';
  const placeholderMessage = formatMessage({
    "id": "b4kjaB",
    "defaultMessage": [{
      "type": 0,
      "value": "Selecteer"
    }]
  });
  const start = (0, _isDate.default)(period) ? period : period?.start;
  const onClickInput = focus => {
    set({
      inView: start ? [new Date(start), (0, _addMonths.default)(start, 1)] : inView,
      open: true,
      focus
    });
  };
  const onClickStart = () => onClickInput('start');
  const onClickEnd = () => onClickInput(!(0, _isDate.default)(period) && period?.start ? 'end' : 'start');
  return __jsx(InputWrapper, {
    hideCalendarIcon: hideCalendarIcon,
    singleDate: singleDate,
    hasFocus: open && state.focus === 'start',
    "data-cy": "datePicker"
  }, __jsx(DatepickerStartInput, {
    iconClickHandler: () => {
      sesame('toggle');
    },
    onClick: onClickStart,
    hasFocus: open && state.focus === 'start',
    placeholder: placeholderMessage,
    value: (0, _isDate.default)(period) ? formatToValue(period) : formatToValue(period?.start),
    readOnly: true,
    hideInput: hideInput,
    "data-cy": "datePickerStart"
  }, !hideCalendarIcon && !hideInput && __jsx(_Calendar.default, null)), !singleDate && __jsx(DatepickerEndInput, {
    hasFocus: open && state.focus === 'end',
    onClick: onClickEnd,
    placeholder: placeholderMessage,
    value: !selecting && !(0, _isDate.default)(period) ? formatToValue(period?.end) : '',
    readOnly: true,
    hideInput: hideInput,
    "data-cy": "datePickerEnd"
  }));
};
var _default = exports.default = DatepickerInputs;
const InputWrapper = exports.InputWrapper = _styledComponents.default.div.withConfig({
  displayName: "Inputs__InputWrapper",
  componentId: "sc-8b8029-0"
})(["width:100%;display:flex;> div:first-of-type{:hover,:focus-within{z-index:1;}", ";flex:unset;width:", ";", ";}> div:last-of-type:first-of-type > input{border-radius:0.8rem;}"], ({
  hasFocus
}) => hasFocus && 'z-index: 1', ({
  singleDate
}) => singleDate ? '100%' : 'calc(50% + 1.2rem)', ({
  hideCalendarIcon
}) => !hideCalendarIcon && (0, _styledComponents.css)(["input{padding-left:4.2rem !important;}"]));
const StyledTextInput = exports.StyledTextInput = (0, _styledComponents.default)(_TextInput.default).withConfig({
  displayName: "Inputs__StyledTextInput",
  componentId: "sc-8b8029-1"
})(["&&{z-index:1;", ";:hover{--border-color:", ";}}color:currentColor;height:4.8rem;", ";"], ({
  hasFocus,
  theme
}) => hasFocus && `--border-color: ${theme.colors.secondary['20']}`, ({
  theme,
  hasFocus
}) => hasFocus ? theme.colors.secondary['30'] : theme.colors.neutral['30'], (0, _createBorderCss.default)('1px'));
const DatepickerStartInput = exports.DatepickerStartInput = (0, _styledComponents.default)(StyledTextInput).withConfig({
  displayName: "Inputs__DatepickerStartInput",
  componentId: "sc-8b8029-2"
})(["border-radius:", " 0 0 ", ";padding-right:", ";", ""], ({
  theme
}) => theme.radius.textInput, ({
  theme
}) => theme.radius.textInput, ({
  theme
}) => theme.spacing['30_Small'], ({
  hideInput
}) => hideInput && `display: none;`);
const DatepickerEndInput = exports.DatepickerEndInput = (0, _styledComponents.default)(StyledTextInput).withConfig({
  displayName: "Inputs__DatepickerEndInput",
  componentId: "sc-8b8029-3"
})(["border-radius:0 ", " ", " 0;", ""], ({
  theme
}) => theme.radius.textInput, ({
  theme
}) => theme.radius.textInput, ({
  hideInput
}) => hideInput && `display: none;`);