"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _router = require("next/router");
var _react = require("react");
/**
 *
 * @param event router event type
 * @param handler handler to execute when the event happens. NOTE: make sure this function is referentially equal to avoid running the effect on every render, unless ofcourse it's on purpose.
 */
const useRouterEvent = (event, handler) => {
  const router = (0, _router.useRouter)();
  (0, _react.useEffect)(() => {
    router.events.on(event, handler);
    return () => router.events.off(event, handler);
  }, [event, handler, router.events]);
};
var _default = exports.default = useRouterEvent;