"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _format = _interopRequireDefault(require("date-fns/format"));
var _nl = _interopRequireDefault(require("date-fns/locale/nl"));
const shape = 'yyyy-MM-dd';
const parseToDateString = date => (0, _format.default)(date, shape, {
  locale: _nl.default
});
var _default = exports.default = parseToDateString;