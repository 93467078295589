"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getPageSlugByLocale = require("../utils/getPageSlugByLocale");
var _TenantContext = require("../context/TenantContext");
var _react = require("react");
var _router = require("next/router");
const useGetPageSlugByLocale = () => {
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const {
    locale: routerLocale
  } = (0, _router.useRouter)();
  const getPageSlugByLocale = (0, _react.useCallback)((pageType, locale = routerLocale) => {
    switch (pageType) {
      case 'COMPANY':
        return (0, _getPageSlugByLocale.getCompanyPageSlugByLocale)(locale, brandConfig);
      case 'ACCOMMODATION':
        return (0, _getPageSlugByLocale.getAccommodationPageSlugByLocale)(locale, brandConfig);
      case 'ACTIVITY':
      default:
        return (0, _getPageSlugByLocale.getActivityPageSlugByLocale)(locale, brandConfig);
    }
  }, [brandConfig, routerLocale]);
  return {
    getPageSlugByLocale
  };
};
var _default = exports.default = useGetPageSlugByLocale;