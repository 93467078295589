"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _UnreachableCaseError = require("@oberoninternal/travelbase-ds/entities/UnreachableCaseError");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const getGradientCss = variant => {
  switch (variant) {
    case 'neutral':
      return (0, _styledComponents.css)(["--linearOne:rgba(255,255,255,0);--linearTwo:rgb(244,244,244,0.4);"]);
    case 'secondary':
      return (0, _styledComponents.css)(["--linearOne:rgba(255,255,255,0);--linearTwo:rgba(255,230,221,0.3);"]);
    default:
      throw new _UnreachableCaseError.UnreachableCaseError(variant);
  }
};
const GradientBackground = _styledComponents.default.div.withConfig({
  displayName: "GradientBackground",
  componentId: "o8exym-0"
})(["margin:0;padding:0;height:100%;", ";:before{position:absolute;top:0;left:0;content:'';height:100%;background:linear-gradient(0deg,var(--linearOne) 0%,var(--linearTwo) 100%);width:100%;z-index:-1;}"], ({
  variant = 'neutral'
}) => getGradientCss(variant));
var _default = exports.default = GradientBackground;