const contactLinks: Record<string, string> = {
    nl: 'over-texel/over-ons/contact',
    en: 'about-texel/about-us/contact',
    de: 'ueber-texel/ueber-uns/kontakt',
};

const getContactLink = (locale: string) =>
    contactLinks[locale] ||
    (() => {
        throw new Error(`getContactLink: unknown locale ${locale}`);
    })();

export const isContactLink = (link: string): boolean => Object.values(contactLinks).includes(link);

export default getContactLink;
