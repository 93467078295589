import React from 'react';

const FlagDE = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="flag">
        <defs>
            <circle id="FlagDE-path-1" cx="12" cy="12" r="12" />
        </defs>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group">
                <mask id="FlagDE-mask-2" fill="#fff">
                    <use href="#FlagDE-path-1" />
                </mask>
                <use id="Oval" fill="#D8D8D8" href="#path-1" />
                <g id="Group-4" mask="url(#FlagDE-mask-2)">
                    <path id="Rectangle" fill="#000" d="M0 0h24v7.944H0z" />
                    <path id="Rectangle" fill="#EC0017" d="M0 7.944h24v8.113H0z" />
                    <path id="Rectangle" fill="#FFDA00" d="M0 16.056h24V24H0z" />
                </g>
            </g>
        </g>
    </svg>
);

export default FlagDE;
