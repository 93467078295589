"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _ErrorTemplate = _interopRequireDefault(require("../ErrorTemplate"));
var __jsx = _react.default.createElement;
const NotFound = () => __jsx(_ErrorTemplate.default, {
  title: __jsx(_reactIntl.FormattedMessage, {
    id: "okhbQU",
    defaultMessage: [{
      "type": 0,
      "value": "Oeps! Pagina niet gevonden"
    }]
  }),
  description: __jsx(_reactIntl.FormattedMessage, {
    id: "BOFJSh",
    defaultMessage: [{
      "type": 0,
      "value": "We kunnen de pagina die je zoekt helaas niet vinden..."
    }]
  })
});
var _default = exports.default = NotFound;