import React, { SVGAttributes } from 'react';

const UserIcon = (props: SVGAttributes<SVGElement>) => (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M17.9359 19V17C17.9359 15.9391 17.5145 14.9217 16.7643 14.1716C16.0142 13.4214 14.9968 13 13.9359 13H5.93591C4.87505 13 3.85763 13.4214 3.10749 14.1716C2.35734 14.9217 1.93591 15.9391 1.93591 17V19M13.9359 5C13.9359 7.20914 12.1451 9 9.93591 9C7.72677 9 5.93591 7.20914 5.93591 5C5.93591 2.79086 7.72677 1 9.93591 1C12.1451 1 13.9359 2.79086 13.9359 5Z"
            stroke="#393939"
            strokeWidth="2"
            strokeLinecap="square"
        />
    </svg>
);

export default UserIcon;
