import React, { SVGAttributes } from 'react';

const Facebook = (props: SVGAttributes<SVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 29" {...props}>
        <defs />
        <path
            fill="currentColor"
            fillRule="nonzero"
            d="M4.445 28.117v-12.3H0v-5.09h4.445V6.85C4.445 2.44 7.058 0 11.055 0c1.914 0 3.918.344 3.918.344v4.332h-2.208c-2.173 0-2.852 1.356-2.852 2.75v3.302h4.853l-.775 5.09H9.913v12.299H4.445z"
        />
    </svg>
);
export default Facebook;
