"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _reactIntl = require("react-intl");
var _grid = require("@rebass/grid");
var _Stepper = require("@oberoninternal/travelbase-ds/components/form/Stepper");
var _Carousel = _interopRequireDefault(require("./designsystem/Carousel"));
var _MonthSelect = _interopRequireDefault(require("./MonthSelect"));
var _react = _interopRequireWildcard(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _formik = require("formik");
var _parse = _interopRequireDefault(require("date-fns/parse"));
var _endOfMonth = _interopRequireDefault(require("date-fns/endOfMonth"));
var _differenceInDays = _interopRequireDefault(require("date-fns/differenceInDays"));
var _startOfMonth = _interopRequireDefault(require("date-fns/startOfMonth"));
var _Switch = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/form/Switch"));
var _Label = require("@oberoninternal/travelbase-ds/components/primitive/Label");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const FlexibleDateContent = () => {
  const {
    values,
    setFieldValue
  } = (0, _formik.useFormikContext)();
  const parsedFlexibleMonth = (0, _react.useMemo)(() => values.booking.flexibleMonth && (0, _parse.default)(values.booking.flexibleMonth, 'MM-yyyy', new Date()), [values]);
  const maxDays = (0, _react.useMemo)(() => parsedFlexibleMonth ? (0, _differenceInDays.default)((0, _endOfMonth.default)(parsedFlexibleMonth), (0, _startOfMonth.default)(parsedFlexibleMonth)) : 31, [parsedFlexibleMonth]);
  return __jsx(_react.default.Fragment, null, __jsx(FieldWrapper, {
    py: 16,
    width: ['100%', '32.5rem']
  }, __jsx(StyledTitle, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "JfYkFu",
    defaultMessage: [{
      "type": 0,
      "value": "Hoeveel nachten wil je blijven?"
    }]
  })), __jsx(_grid.Flex, {
    alignItems: "center",
    justifyContent: "center"
  }, __jsx(_Stepper.StepperField, {
    validate: () => {
      // override validator because error message is ugly
    },
    minimum: 1,
    maximum: maxDays,
    name: "booking.amountOfNights"
  }))), __jsx(FieldWrapper, {
    pt: 16
  }, __jsx(StyledTitle, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "mR2EF5",
    defaultMessage: [{
      "type": 0,
      "value": "In welke maand wil je zoeken?"
    }]
  })), __jsx(_grid.Box, {
    pl: [4],
    pt: 20,
    width: "100%"
  }, __jsx(CarouselWrapper, null, __jsx(_Carousel.default, {
    buttonSize: "small",
    continuous: true,
    arrowsOnObject: true
  }, __jsx(_MonthSelect.default, null)))), __jsx(_grid.Flex, {
    alignItems: "center",
    justifyContent: "center"
  }, __jsx(_Switch.default, {
    checked: values.booking.type === 'flexible-weekends',
    onChange: e => setFieldValue('booking.type', e.currentTarget.checked ? 'flexible-weekends' : 'flexible-random'),
    id: "flexible-weekends-switch"
  }), __jsx(_Label.Label, {
    htmlFor: "flexible-weekends-switch",
    variant: "small",
    style: {
      marginLeft: '0.8rem'
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "zxy3um",
    defaultMessage: [{
      "type": 0,
      "value": "Alleen in het weekend zoeken"
    }]
  })))));
};
var _default = exports.default = FlexibleDateContent;
const FieldWrapper = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "FlexibleDateContent__FieldWrapper",
  componentId: "sc-1hmtww7-0"
})(["margin:0 auto;"]);
const CarouselWrapper = _styledComponents.default.div.withConfig({
  displayName: "FlexibleDateContent__CarouselWrapper",
  componentId: "sc-1hmtww7-1"
})(["position:relative;"]);
const StyledTitle = (0, _styledComponents.default)(_Title.default).withConfig({
  displayName: "FlexibleDateContent__StyledTitle",
  componentId: "sc-1hmtww7-2"
})(["text-align:center;"]);