"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const setItemWithExpiry = storage => (keyName, value, expiryInDays = 7) => {
  storage.setItem(keyName, value);
  storage.setItem(`${keyName}_expiry`, ((+new Date()).valueOf() + 1000 * 60 * 60 * 24 * expiryInDays).toString());
};
const getItemWithExpiry = storage => keyName => {
  const expiry = storage.getItem(`${keyName}_expiry`);

  // non-existent or expired?
  if (expiry === null || Number(expiry) < new Date().valueOf()) {
    return null;
  }
  return storage.getItem(keyName);
};
const removeItemWithExpiry = storage => keyName => {
  storage.removeItem(keyName);
  storage.removeItem(`${keyName}_expiry`);
};
const variantStorageMap = {
  local: 'localStorage',
  session: 'sessionStorage'
};
const onBrowser = typeof window !== 'undefined';
const getStorageWithExpiry = variant => {
  try {
    const type = variantStorageMap[variant];
    if (!onBrowser || typeof window?.[type] === 'undefined') {
      return null;
    }
    const storage = window[type];
    return {
      setItem: setItemWithExpiry(storage),
      getItem: getItemWithExpiry(storage),
      removeItem: removeItemWithExpiry(storage)
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Unable to use ${variantStorageMap[variant]}`);
  }
  return null;
};
var _default = exports.default = getStorageWithExpiry;