"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _link = _interopRequireDefault(require("next/link"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _ContentWrapper = _interopRequireDefault(require("../ContentWrapper"));
var _Cell = _interopRequireDefault(require("../designsystem/Cell"));
var _Grid = _interopRequireDefault(require("../designsystem/Grid"));
var _Heading = _interopRequireDefault(require("../designsystem/Heading"));
var _Page = _interopRequireDefault(require("../Page"));
var _CheckoutPage = require("../CheckoutPage");
var __jsx = _react.default.createElement;
const CheckoutEmpty = () => __jsx(_react.default.Fragment, null, __jsx(_Page.default, null, __jsx(_CheckoutPage.CheckoutPageGradient, null), __jsx(_ContentWrapper.default, {
  variant: "checkout"
}, __jsx(_Grid.default, null, __jsx(_Cell.default, {
  gridColumn: ['1 / -1', null, null, '1 / 7', null, '1 / 8'],
  style: {
    display: 'flex',
    flexDirection: 'column'
  }
}, __jsx(_Heading.default, {
  variant: "large"
}, __jsx(_Title.default, {
  variant: "large"
}, __jsx(_reactIntl.FormattedMessage, {
  id: "R0aU/6",
  defaultMessage: [{
    "type": 0,
    "value": "Geen boeking gevonden!"
  }]
}))), __jsx(_Body.default, null, __jsx(_reactIntl.FormattedMessage, {
  id: "r7kK82",
  defaultMessage: [{
    "type": 0,
    "value": "Er is geen boeking gevonden in uw winkelwagen. Ga naar de homepage en selecteer een accommodatie die u wilt boeken!"
  }]
})), __jsx(_link.default, {
  href: "/",
  legacyBehavior: true
}, __jsx(_Button.default, {
  as: "a",
  style: {
    marginTop: '5.6rem',
    alignSelf: 'flex-start',
    cursor: 'pointer'
  }
}, __jsx(_reactIntl.FormattedMessage, {
  id: "5a+zJe",
  defaultMessage: [{
    "type": 0,
    "value": "Terug naar de homepagina"
  }]
})))), __jsx(_Cell.default, {
  className: "gt-m",
  order: [1, null, null, 2],
  gridColumn: ['1 / -1', null, null, '8 / -1', null, '10 / -1']
}, __jsx(_Body.default, {
  variant: "small"
}, __jsx(_reactIntl.FormattedMessage, {
  id: "DtMqYU",
  defaultMessage: [{
    "type": 0,
    "value": "Geen accommodaties of activiteiten."
  }]
})))))));
var _default = exports.default = CheckoutEmpty;