"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _validInterval = _interopRequireDefault(require("@oberoninternal/travelbase-ds/utils/validInterval"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _getTripGroupText = _interopRequireDefault(require("../utils/getTripGroupText"));
var _trip = require("../utils/trip");
var _messages = _interopRequireDefault(require("../constants/messages"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _dateFns = require("date-fns");
var _parse = _interopRequireDefault(require("date-fns/parse"));
const _excluded = ["arrivalDate", "departureDate", "type"];
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const BookingText = ({
  booking,
  accommodationType,
  onlyShowPersons = false
}) => {
  const _ref = booking ?? {},
    {
      arrivalDate,
      departureDate,
      type
    } = _ref,
    amounts = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const amountGuests = (amounts.amountAdults ?? 0) + (amounts.amountBabies ?? 0) + (amounts.amountChildren ?? 0) + (amounts.amountYouths ?? 0);
  const intl = (0, _reactIntl.useIntl)();
  const {
    formatList,
    formatMessage
  } = (0, _reactIntl.useIntl)();
  const guestsText = onlyShowPersons ? formatList([formatMessage(_messages.default.guests, {
    amountGuests
  })]) : (0, _getTripGroupText.default)(intl, _objectSpread({}, amounts));
  const period = (0, _trip.convertToPeriod)(arrivalDate, departureDate);
  const parsedFlexibleMonth = booking.flexibleMonth && (0, _parse.default)(booking.flexibleMonth, 'MM-yyyy', new Date());
  return __jsx(StyledText, null, guestsText, type !== 'static' && parsedFlexibleMonth && __jsx(_react.default.Fragment, null, ', ', __jsx(_reactIntl.FormattedMessage, {
    id: "fiVw0j",
    defaultMessage: [{
      "type": 2,
      "style": null,
      "value": "amountOfNights"
    }, {
      "type": 0,
      "value": " "
    }, {
      "type": 6,
      "pluralType": "cardinal",
      "value": "amountOfNights",
      "offset": 0,
      "options": {
        "one": {
          "value": [{
            "type": 0,
            "value": "nacht"
          }]
        },
        "other": {
          "value": [{
            "type": 0,
            "value": "nachten"
          }]
        }
      }
    }, {
      "type": 0,
      "value": " in "
    }, {
      "type": 1,
      "value": "month"
    }],
    values: {
      amountOfNights: booking.amountOfNights,
      month: intl.formatDate(parsedFlexibleMonth, _objectSpread({
        month: 'long'
      }, !(0, _dateFns.isSameYear)(new Date(), parsedFlexibleMonth) ? {
        year: 'numeric'
      } : {}))
    }
  })), type === 'static' && (0, _validInterval.default)(period) && __jsx(_react.default.Fragment, null, ', ', __jsx(_react.default.Fragment, null, __jsx(_reactIntl.FormattedDate, {
    value: period.start,
    day: "numeric",
    month: "short"
  }), ' — ', __jsx(_reactIntl.FormattedDate, {
    value: period.end,
    day: "numeric",
    month: "short"
  }))), __jsx("span", {
    className: "gt-m"
  }, accommodationType && `, ${accommodationType}`));
};
const StyledText = _styledComponents.default.span.withConfig({
  displayName: "BookingText__StyledText",
  componentId: "sc-18dv31l-0"
})(["line-height:1.4;font-weight:", ";overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;"], ({
  theme
}) => theme.fontWeights.bold);
var _default = exports.default = BookingText;