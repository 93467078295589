"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatPhoneNumber = exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var __jsx = _react.default.createElement;
const formatPhoneNumber = (phoneNumber, locale, includeZero = true) => {
  const countryCodeRegexp = /([+]31)0?/;
  const dutchPhoneNumber = phoneNumber.startsWith('+31') ? phoneNumber.replace(countryCodeRegexp, '+31 (0)') : `+31 (0) ${phoneNumber.startsWith('0') ? phoneNumber.substring(1, phoneNumber.length) : phoneNumber}`;
  if (locale !== 'nl') {
    const formattedPhoneNumber = dutchPhoneNumber.replace('+31 (0)', '0031');
    return formattedPhoneNumber;
  }
  if (!includeZero) {
    // hrefs (tel:) shoudn't include the 0.
    return dutchPhoneNumber.replace('+31 (0)', '+31');
  }
  return dutchPhoneNumber;
};
exports.formatPhoneNumber = formatPhoneNumber;
const TranslatedPhoneNumber = ({
  phoneNumber
}) => {
  const {
    locale
  } = (0, _reactIntl.useIntl)();
  return __jsx(_react.default.Fragment, null, formatPhoneNumber(phoneNumber, locale));
};
var _default = exports.default = TranslatedPhoneNumber;