"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSearchValidationSchema = exports.getInitialSearchBoxValues = exports.default = void 0;
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _createBorderCss = _interopRequireDefault(require("@oberoninternal/travelbase-ds/utils/createBorderCss"));
var _grid = require("@rebass/grid");
var _formik = require("formik");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var Yup = _interopRequireWildcard(require("yup"));
var _customizationClassNames = require("../constants/customizationClassNames");
var _TenantContext = require("../context/TenantContext");
var _graphql = require("../generated/graphql");
var _getAccommodationTypeOptions = require("../utils/getAccommodationTypeOptions");
var _getDefaultAccommodationType = _interopRequireDefault(require("../utils/getDefaultAccommodationType"));
var _BookingText = _interopRequireDefault(require("./BookingText"));
var _Inputs = require("./designsystem/datepicker/Inputs");
var _Fieldset = require("./designsystem/Fieldset");
var _SearchBoxModal = _interopRequireDefault(require("./SearchBoxModal"));
var _SearchDropdownForm = _interopRequireDefault(require("./SearchDropdownForm"));
var _SearchForm = _interopRequireDefault(require("./SearchForm"));
var _Calendar = _interopRequireDefault(require("./svg/Calendar.svg"));
var _TriangleIcon = _interopRequireDefault(require("./svg/TriangleIcon.svg"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _trip = require("../utils/trip");
var _useAnalyticsSearchBarValues = _interopRequireDefault(require("../hooks/analytics/useAnalyticsSearchBarValues"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const getInitialSearchBoxValues = (brandConfig, values) => ({
  accommodationType: values?.accommodationType ? values?.accommodationType : (0, _getDefaultAccommodationType.default)(brandConfig),
  booking: (0, _trip.withDefaultBookingValues)(values?.booking ?? {}, brandConfig),
  brand: values?.brand ? values?.brand : 'all'
});
exports.getInitialSearchBoxValues = getInitialSearchBoxValues;
const getSearchValidationSchema = () => Yup.object().shape({
  booking: Yup.object().shape({
    arrivalDate: Yup.string(),
    departureDate: Yup.string()
  })
});
exports.getSearchValidationSchema = getSearchValidationSchema;
const SearchBox = ({
  variant,
  title,
  isSubmitting,
  hideCalendarIcon,
  hideSearchIcon = false,
  active,
  hideHelper,
  hideFlexibleOptions,
  homeBarLabel = false
}) => {
  const intl = (0, _reactIntl.useIntl)();
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const accommodationTypeOptions = (0, _getAccommodationTypeOptions.getAccommodationTypeOptions)(intl, brandConfig);
  const {
    data: unitFilterPropertiesData
  } = (0, _graphql.useUnitFilterPropertiesQuery)({
    ssr: true
  });
  const islandOptions = unitFilterPropertiesData?.unitFilterProperties?.find(property => property.handle === '_brand');
  const {
    open: searchModalOpen,
    onOpen,
    onClose
  } = (0, _useSesame.default)(false);
  const {
    submitForm,
    setValues,
    values
  } = (0, _formik.useFormikContext)();
  const translatedAccommodationType = accommodationTypeOptions?.find(accommodationTypeOption => accommodationTypeOption.value === values.accommodationType)?.label;
  (0, _useAnalyticsSearchBarValues.default)(values);
  return __jsx(_react.default.Fragment, null, variant !== 'home' && __jsx(MobileSearchBox, {
    onClick: onOpen,
    variant: variant,
    className: _customizationClassNames.CLASS_SEARCH_BOX_CONTAINER_MOBILE
  }, !hideCalendarIcon && __jsx(_Calendar.default, null), variant === 'homeBar' && homeBarLabel ? __jsx(StyledBody, null, homeBarLabel) : variant === 'homeBar' ? __jsx(StyledBody, null, __jsx(_reactIntl.FormattedMessage, {
    id: "mOqzWm",
    defaultMessage: [{
      "type": 0,
      "value": "Zoek en boek"
    }]
  })) : __jsx(_BookingText.default, {
    accommodationType: translatedAccommodationType,
    booking: values.booking
  }), values.booking.arrivalDate && values.booking.departureDate ? __jsx(StyledDatePickerEditText, null, __jsx(_reactIntl.FormattedMessage, {
    id: "/rVWo/",
    defaultMessage: [{
      "type": 0,
      "value": "Bewerken"
    }]
  })) : __jsx(_react.default.Fragment, null, variant !== 'homeBar' && __jsx(StyledDatePickerText, null, __jsx(_reactIntl.FormattedMessage, {
    id: "ELaDA0",
    defaultMessage: [{
      "type": 0,
      "value": "Kies datum"
    }]
  }))), variant === 'homeBar' && __jsx(MobileContainer, {
    variant: variant,
    "data-cy": "searchBoxContainer",
    className: _customizationClassNames.CLASS_SEARCH_BOX_CONTAINER
  }, __jsx(StyledButtonContainer, {
    className: _customizationClassNames.CLASS_SEARCH_BOX_BUTTON_CONTAINER
  }, __jsx(StyledSearchButton, null, __jsx(StyledTriangleIcon, null)))), !hideSearchIcon && variant !== 'homeBar' && __jsx(StyledTriangleIcon, null)), __jsx(_SearchBoxModal.default, {
    onClose: onClose,
    open: searchModalOpen,
    setValues: setValues,
    submitForm: submitForm,
    values: values
  }), __jsx(Container, {
    variant: variant,
    "data-cy": "searchBoxContainer",
    className: _customizationClassNames.CLASS_SEARCH_BOX_CONTAINER
  }, title && __jsx(StyledTitle, {
    variant: "large"
  }, title), variant === 'home' && __jsx(_SearchForm.default, {
    active: active,
    brandOptions: islandOptions?.filterProperties?.map(option => ({
      value: option.handle,
      label: option.name
    })),
    variant: variant,
    accommodationTypeOptions: accommodationTypeOptions,
    hideCalendarIcon: hideCalendarIcon,
    hideHelper: hideHelper,
    hideFlexibleOptions: hideFlexibleOptions
  }), variant !== 'home' && __jsx(_react.default.Fragment, null, __jsx(_SearchDropdownForm.default, {
    active: active,
    variant: variant,
    accommodationTypeOptions: accommodationTypeOptions,
    hideFlexibleOptions: hideFlexibleOptions
  }), __jsx(StyledButtonContainer, {
    className: _customizationClassNames.CLASS_SEARCH_BOX_BUTTON_CONTAINER
  }, __jsx(StyledSearchButton, {
    onClick: submitForm
  }, __jsx(StyledTriangleIcon, null), __jsx(StyledSpan, null, __jsx(_reactIntl.FormattedMessage, {
    id: "qCcfnn",
    defaultMessage: [{
      "type": 0,
      "value": "Zoek"
    }]
  }))))), variant === 'home' && __jsx(SearchButton, {
    size: "large",
    type: "submit",
    submitting: isSubmitting,
    "data-cy": "searchButton",
    className: _customizationClassNames.CLASS_SEARCH_BOX_BUTTON
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "SFVO9u",
    defaultMessage: [{
      "type": 0,
      "value": "Zoeken"
    }]
  }))));
};
var _default = exports.default = SearchBox;
const leftBorderRadius = (0, _styledComponents.css)(["border-top-left-radius:var(--buttonContainerRadius) !important;border-bottom-left-radius:var(--buttonContainerRadius) !important;"]);
const rightBorderRadius = (0, _styledComponents.css)(["border-top-right-radius:var(--buttonContainerRadius) !important;border-bottom-right-radius:var(--buttonContainerRadius) !important;"]);
const StyledSearchButton = (0, _styledComponents.default)(_Button.default).withConfig({
  displayName: "SearchBox__StyledSearchButton",
  componentId: "sc-13bba9x-0"
})(["border-top-left-radius:inherit;border-bottom-left-radius:inherit;height:100%;"]);
const StyledButtonContainer = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "SearchBox__StyledButtonContainer",
  componentId: "sc-13bba9x-1"
})(["height:100%;", " ", " --border-color:", ";"], (0, _createBorderCss.default)('1px'), rightBorderRadius, ({
  theme
}) => theme.colors.neutral['20']);
const StyledTriangleIcon = (0, _styledComponents.default)(_TriangleIcon.default).withConfig({
  displayName: "SearchBox__StyledTriangleIcon",
  componentId: "sc-13bba9x-2"
})(["transform:rotateY(0deg) rotate(90deg);"]);
const StyledBody = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "SearchBox__StyledBody",
  componentId: "sc-13bba9x-3"
})(["font-weight:bold;"]);
const StyledDatePickerText = _styledComponents.default.span.withConfig({
  displayName: "SearchBox__StyledDatePickerText",
  componentId: "sc-13bba9x-4"
})(["width:12rem;padding-left:", ";border-left:1px solid ", ";margin-left:5px;"], ({
  theme
}) => theme.spacing['30_Small'], ({
  theme
}) => theme.colors.neutral['30']);
const StyledDatePickerEditText = _styledComponents.default.span.withConfig({
  displayName: "SearchBox__StyledDatePickerEditText",
  componentId: "sc-13bba9x-5"
})(["width:10rem;padding-left:", ";border-left:1px solid ", ";margin-left:5px;"], ({
  theme
}) => theme.spacing['30_Small'], ({
  theme
}) => theme.colors.neutral['30']);
const StyledTitle = (0, _styledComponents.default)(_Title.default).withConfig({
  displayName: "SearchBox__StyledTitle",
  componentId: "sc-13bba9x-6"
})(["", ";margin-top:", ";margin-bottom:", ";"], ({
  theme
}) => theme.colorAssignments.searchBox?.color && `color: ${(0, _theme.getAssignmentColor)(theme, theme.colorAssignments.searchBox.color)}`, ({
  theme
}) => theme.spacing['60_Large'], ({
  theme
}) => theme.spacing['40_Standard']);
const MobileSearchBox = (0, _styledComponents.default)(_grid.Flex).attrs({
  justifyContent: 'space-between',
  alignItems: 'center'
}).withConfig({
  displayName: "SearchBox__MobileSearchBox",
  componentId: "sc-13bba9x-7"
})(["width:100%;@media screen and (min-width:", "){width:", ";max-width:36rem;}padding-left:1.6rem;height:4.8rem;border:1px solid ", ";background:", ";box-shadow:0px 0.2rem 0.4rem 0px rgba(59,118,160,0.02),0px 0.4rem 1.2rem 0px rgba(59,118,160,0.04);border-radius:", ";cursor:pointer;@media screen and (min-width:", "){font-size:1.6rem;}@media screen and (min-width:", "){display:none;}"], ({
  theme
}) => theme.mediaQueries.s, ({
  variant
}) => variant === 'searchMap' ? '36rem' : '100%', ({
  theme
}) => theme.colors.neutral['20'], ({
  theme
}) => theme.colors.neutral[0], ({
  theme
}) => theme.radius.button, ({
  theme
}) => theme.mediaQueries.xs, ({
  variant,
  theme
}) => variant === 'hero' || variant === 'accommodationSearch' || variant === 'searchpage' || variant === 'homeBar' ? theme.mediaQueries.s : '1440px');
const getMaxWidth = variant => {
  if (variant === 'hero') {
    return '109rem';
  }
  if (variant === 'accommodationSearch') {
    return '72rem';
  }
  return '100rem';
};
const StyledSpan = _styledComponents.default.span.withConfig({
  displayName: "SearchBox__StyledSpan",
  componentId: "sc-13bba9x-8"
})(["@media screen and (max-width:", "){display:none;}"], ({
  theme
}) => theme.mediaQueries.m);
const MobileContainer = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "SearchBox__MobileContainer",
  componentId: "sc-13bba9x-9"
})(["--buttonContainerRadius:calc(", " + ", "*0.5);flex-direction:column;display:", ";height:100%;@media screen and (min-width:", "){max-width:", ";}", ";"], ({
  theme
}) => theme.radius.button, ({
  theme
}) => theme.radius.button, ({
  variant
}) => variant !== 'home' ? 'flex' : 'flex', ({
  theme
}) => theme.mediaQueries.s, ({
  variant
}) => getMaxWidth(variant), ({
  variant
}) => variant === 'home' ? (0, _styledComponents.css)(["padding:2.4rem;@media screen and (min-width:", "){width:43.2rem;background:", ";box-shadow:0px 16px 80px 0px rgba(0,0,0,0.1);border-radius:1.2rem;}@media screen and (min-width:", "){right:10.4rem;}"], ({
  theme
}) => theme.mediaQueries.xs, ({
  theme
}) => theme.colorAssignments.searchBox?.backgroundColor ? (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.searchBox.backgroundColor) : theme.colors.neutral['0'], ({
  theme
}) => theme.mediaQueries.s) : (0, _styledComponents.css)(["> *{z-index:2;:last-of-type{", "}}>:last-child{", "{", "}.", "{> div:first-of-type{", "}}}> div{margin-bottom:0;}"], rightBorderRadius, _Inputs.DatepickerEndInput, rightBorderRadius, _customizationClassNames.CLASS_SEARCH_BOX_ACCOMMODATION_TYPE_FIELD, rightBorderRadius));
const Container = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "SearchBox__Container",
  componentId: "sc-13bba9x-10"
})(["--buttonContainerRadius:calc(", " + ", "*0.5);flex-direction:column;display:", ";min-height:0;@media screen and (min-width:", "){flex-direction:", ";", ";display:flex;> ", " + ", "{margin-left:1px;}}@media screen and (min-width:", "){max-width:", ";}", ";"], ({
  theme
}) => theme.radius.button, ({
  theme
}) => theme.radius.button, ({
  variant
}) => variant !== 'home' ? 'none' : 'flex', ({
  variant,
  theme
}) => variant === 'hero' || variant === 'accommodationSearch' || variant === 'searchpage' || variant === 'homeBar' ? theme.mediaQueries.s : '1440px', ({
  variant
}) => variant === 'home' ? 'column' : 'row', ({
  variant
}) => variant !== 'home' && `
            height: 4.8rem;
        `, _Fieldset.FormBox, _Fieldset.FormBox, ({
  theme
}) => theme.mediaQueries.l, ({
  variant
}) => getMaxWidth(variant), ({
  variant
}) => variant === 'home' ? (0, _styledComponents.css)(["padding:3.2rem 2.4rem;@media screen and (min-width:", "){width:43.2rem;background:", ";box-shadow:0px 16px 80px 0px rgba(0,0,0,0.1);border-radius:1.2rem;}height:100%;@media screen and (min-width:", "){right:10.4rem;}"], ({
  theme
}) => theme.mediaQueries.xs, ({
  theme
}) => theme.colorAssignments.searchBox?.backgroundColor ? (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.searchBox.backgroundColor) : theme.colors.neutral['0'], ({
  theme
}) => theme.mediaQueries.s) : (0, _styledComponents.css)(["> *{box-shadow:0 16px 24px 0 rgba(59,118,160,0.03),0 24px 80px 0 rgba(59,118,160,0.05);z-index:1;:hover{z-index:2;}:first-of-type{", "}:last-of-type{", "}}>:last-child{", "{", "}.", "{> div:first-of-type{", "}}}@media screen and (min-width:", "){flex:1;}> div{margin-bottom:0;}"], leftBorderRadius, rightBorderRadius, _Inputs.DatepickerEndInput, rightBorderRadius, _customizationClassNames.CLASS_SEARCH_BOX_ACCOMMODATION_TYPE_FIELD, rightBorderRadius, ({
  theme
}) => theme.mediaQueries.s));
const SearchButton = (0, _styledComponents.default)(_Button.default).withConfig({
  displayName: "SearchBox__SearchButton",
  componentId: "sc-13bba9x-11"
})(["margin-top:", ";border-radius:", ";@media screen and (min-width:", "){margin-top:", ";}"], ({
  theme
}) => theme.spacing['40_Standard'], ({
  theme
}) => theme.radius.button, ({
  theme
}) => theme.mediaQueries.xs, ({
  theme
}) => theme.spacing['50_Semi']);