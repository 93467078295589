"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _reactToastify = require("react-toastify");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _react = _interopRequireDefault(require("react"));
const _excluded = ["className"];
var __jsx = _react.default.createElement;
const Toasts = _ref => {
  let {
      className
    } = _ref,
    rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return __jsx("div", {
    className: className
  }, __jsx(_reactToastify.ToastContainer, (0, _extends2.default)({
    position: "bottom-right",
    draggable: false,
    autoClose: false,
    closeButton: false,
    hideProgressBar: true,
    closeOnClick: false
  }, rest)));
};
var _default = exports.default = (0, _styledComponents.default)(Toasts).withConfig({
  displayName: "Toasts",
  componentId: "sc-340p95-0"
})([".Toastify__toast-container{color:inherit;width:unset;border-radius:2px;}.Toastify__toast{font-family:inherit;padding:0;cursor:inherit;color:inherit !important;box-shadow:0 20px 40px 0 rgba(16,36,48,0.06);}.Toastify__close-button{position:absolute;right:1rem;top:1rem;}"]);