"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireWildcard(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _getTripGroupText = _interopRequireDefault(require("../utils/getTripGroupText"));
var _formik = require("formik");
var _reactIntl = require("react-intl");
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _TripGroupInputField = _interopRequireDefault(require("./TripGroupInputField"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _grid = require("@rebass/grid");
var _Stack = _interopRequireDefault(require("./Stack"));
var _SearchDateField = _interopRequireWildcard(require("./SearchDateField"));
var _InlineDropdownField = _interopRequireDefault(require("./InlineDropdownField"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const SearchFormMobile = ({
  accommodationTypeOptions
}) => {
  const intl = (0, _reactIntl.useIntl)();
  const {
    0: expanded,
    1: setExpanded
  } = (0, _react.useState)(null);
  const {
    values: {
      booking,
      accommodationType
    }
  } = (0, _formik.useFormikContext)();
  const tripGroupLabel = (0, _react.useMemo)(() => (0, _getTripGroupText.default)(intl, booking), [booking, intl]);
  const searchDateLabel = (0, _react.useMemo)(() => (0, _SearchDateField.getSearchDateLabel)(booking, intl.formatDate), [booking, intl.formatDate]);
  return __jsx(_Stack.default, {
    spacing: 4
  }, __jsx(Field, null, expanded !== 'tripgroup' && __jsx(_grid.Box, {
    p: 4,
    onClick: () => setExpanded('tripgroup')
  }, __jsx(FieldCollapsed, null, __jsx(FieldLabel, null, __jsx(_reactIntl.FormattedMessage, {
    id: "P0UVuU",
    defaultMessage: [{
      "type": 0,
      "value": "Reisgezelschap"
    }]
  })), __jsx(FieldValue, null, tripGroupLabel ?? __jsx(_reactIntl.FormattedMessage, {
    id: "P0UVuU",
    defaultMessage: [{
      "type": 0,
      "value": "Reisgezelschap"
    }]
  })))), expanded === 'tripgroup' && __jsx(_react.default.Fragment, null, __jsx(_grid.Box, {
    p: 4,
    onClick: () => setExpanded(null)
  }, __jsx(_Title.default, {
    variant: "large"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "P0UVuU",
    defaultMessage: [{
      "type": 0,
      "value": "Reisgezelschap"
    }]
  }))), __jsx(_grid.Box, {
    p: 4,
    pt: 0
  }, __jsx(_TripGroupInputField.default, {
    name: "booking",
    variant: "inputs"
  })))), __jsx(Field, null, expanded !== 'datepicker' && __jsx(_grid.Box, {
    p: 4,
    onClick: () => setExpanded('datepicker')
  }, __jsx(FieldCollapsed, null, __jsx(FieldLabel, null, __jsx(_reactIntl.FormattedMessage, {
    id: "ANwe4i",
    defaultMessage: [{
      "type": 0,
      "value": "Reisperiode"
    }]
  })), __jsx(FieldValue, null, searchDateLabel))), expanded === 'datepicker' && __jsx(_react.default.Fragment, null, __jsx(_grid.Box, {
    pb: 4
  }, __jsx(_SearchDateField.default, {
    hideFlexibleOptions: false,
    isHomepage: false,
    variant: "inline"
  })))), __jsx(Field, null, expanded !== 'accommodationType' && __jsx(_grid.Box, {
    p: 4,
    onClick: () => setExpanded('accommodationType')
  }, __jsx(FieldCollapsed, null, __jsx(FieldLabel, null, __jsx(_reactIntl.FormattedMessage, {
    id: "pllnR0",
    defaultMessage: [{
      "type": 0,
      "value": "Type accommodatie"
    }]
  })), __jsx(FieldValue, null, accommodationTypeOptions.find(opt => opt.value === accommodationType)?.label ?? __jsx(_reactIntl.FormattedMessage, {
    id: "6wcscs",
    defaultMessage: [{
      "type": 0,
      "value": "Accommodatie type"
    }]
  })))), expanded === 'accommodationType' && __jsx(_react.default.Fragment, null, __jsx(_grid.Box, {
    p: 4,
    onClick: () => setExpanded(null)
  }, __jsx(_Title.default, {
    variant: "large"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "pllnR0",
    defaultMessage: [{
      "type": 0,
      "value": "Type accommodatie"
    }]
  }))), __jsx(_grid.Box, {
    p: 4,
    pt: 0
  }, __jsx(_InlineDropdownField.default, {
    options: accommodationTypeOptions,
    name: "accommodationType",
    onChange: () => setExpanded(null)
  })))));
};
var _default = exports.default = SearchFormMobile;
const Field = _styledComponents.default.div.withConfig({
  displayName: "SearchFormMobile__Field",
  componentId: "sc-1umvq7g-0"
})(["background:", ";border:1px solid #a8a8a8;box-shadow:0px 24px 14px rgba(0,0,0,0.01),0px 11px 11px rgba(0,0,0,0.02),0px 3px 6px rgba(0,0,0,0.03),0px 0px 0px rgba(0,0,0,0.03);border-radius:8px;color:", ";.toggleSwitchContainer{border-bottom:1px solid #a8a8a8;}"], ({
  theme
}) => theme.colors.neutral[0], ({
  theme
}) => theme.colors.neutral[70]);
const FieldLabel = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "SearchFormMobile__FieldLabel",
  componentId: "sc-1umvq7g-1"
})([""]);
const FieldValue = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "SearchFormMobile__FieldValue",
  componentId: "sc-1umvq7g-2"
})(["font-weight:600;"]);
const FieldCollapsed = _styledComponents.default.div.withConfig({
  displayName: "SearchFormMobile__FieldCollapsed",
  componentId: "sc-1umvq7g-3"
})(["display:flex;justify-content:space-between;"]);