"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCompanyPageSlugByLocale = exports.getActivityPageSlugByLocale = exports.getAccommodationPageSlugByLocale = void 0;
const getActivityPageSlugByLocale = (locale, brandConfig) => {
  if (brandConfig?.customSlugs?.activity?.[locale]) {
    return brandConfig?.customSlugs?.activity[locale];
  }
  switch (locale) {
    case 'nl':
      return 'activiteit';
    case 'de':
      return 'aktivitat';
    case 'en':
    default:
      return 'activity';
  }
};
exports.getActivityPageSlugByLocale = getActivityPageSlugByLocale;
const getCompanyPageSlugByLocale = (locale, brandConfig) => {
  if (brandConfig?.customSlugs?.company?.[locale]) {
    return brandConfig?.customSlugs?.company[locale];
  }
  switch (locale) {
    case 'nl':
      return 'onderneming';
    case 'de':
      return 'firma';
    case 'en':
    default:
      return 'company';
  }
};
exports.getCompanyPageSlugByLocale = getCompanyPageSlugByLocale;
const getAccommodationPageSlugByLocale = (locale, brandConfig) => {
  if (brandConfig?.customSlugs?.accommodation?.[locale]) {
    return brandConfig?.customSlugs?.accommodation[locale];
  }
  switch (locale) {
    case 'nl':
      return 'accommodatie';
    case 'de':
      return 'unterkunft';
    case 'en':
    default:
      return 'accommodation';
  }
};
exports.getAccommodationPageSlugByLocale = getAccommodationPageSlugByLocale;