import React, { SVGAttributes } from 'react';

const Mail = (props: SVGAttributes<SVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0ZM18 9.867L12.312 13.5448L12.2261 13.5904C12.0779 13.6535 11.9082 13.6516 11.7613 13.5848L11.688 13.5448L6 9.867L6.00006 14.8C6.00006 15.4627 6.53732 16 7.20006 16H16.8C17.4627 16 18 15.4627 18 14.8V9.867ZM16.8 8H7.20006C6.73805 8 6.33703 8.26109 6.13648 8.64377L12 12.4342L17.8636 8.64377C17.663 8.26109 17.262 8 16.8 8Z"
            fill="currentColor"
        />
    </svg>
);
export default Mail;
