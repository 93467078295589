"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deskModeQuery = exports.default = void 0;
var _client = require("@apollo/client");
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _LogoText = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/LogoText"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var _ModalHeader = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/ModalHeader"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _useQueryParams = require("use-query-params");
var _customizationClassNames = require("../constants/customizationClassNames");
var _storage = require("../constants/storage");
var _graphql = require("../generated/graphql");
var _useQueryParams2 = _interopRequireDefault(require("../hooks/useQueryParams"));
var _cart = require("../utils/cart");
var _getStorageWithExpiry = _interopRequireDefault(require("../utils/getStorageWithExpiry"));
var _Stack = _interopRequireDefault(require("./Stack"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const deskModeQuery = exports.deskModeQuery = (0, _client.gql)`
    query DeskMode($id: ID!) {
        cart(id: $id) {
            id
            order {
                isDeskOrder
            }
        }
    }
`;
const DeskMode = () => {
  const {
    onClose,
    open,
    onOpen
  } = (0, _useSesame.default)();
  const [{
    cartId: cartIdParam
  }, setParams] = (0, _useQueryParams2.default)({
    cartId: _useQueryParams.StringParam
  });
  const {
    0: cartId,
    1: setCartIdState
  } = (0, _react.useState)(null);
  const intl = (0, _reactIntl.useIntl)();
  const {
    0: deskMode,
    1: setDeskMode
  } = (0, _react.useState)(false);
  const storageUtils = (0, _react.useMemo)(() => (0, _getStorageWithExpiry.default)('local'), []);
  const lsDeskMode = storageUtils?.getItem(_storage.DESKMODE_KEY) === 'true';
  const ref = (0, _react.useRef)(null);
  (0, _react.useEffect)(() => {
    setCartIdState(cartIdParam ?? (0, _cart.getCartId)());
  }, [cartIdParam]);
  const [fetch, {
    data,
    called
  }] = (0, _graphql.useDeskModeLazyQuery)({
    errorPolicy: 'ignore'
  });
  const isDeskOrder = data?.cart?.order.isDeskOrder;
  (0, _react.useEffect)(() => {
    if (cartId && !called) {
      fetch({
        variables: {
          id: cartId
        }
      });
    }
  }, [called, cartId, fetch, lsDeskMode]);
  (0, _react.useEffect)(() => {
    if (!storageUtils) {
      return;
    }
    if (isDeskOrder) {
      if (cartId) {
        (0, _cart.setCartId)(cartId);
      }
      storageUtils.setItem(_storage.DESKMODE_KEY, 'true');
    }
    if (!isDeskOrder) {
      storageUtils.removeItem(_storage.DESKMODE_KEY);
    }
    setDeskMode(!!isDeskOrder);
  }, [storageUtils, isDeskOrder, cartId]);
  const onCancelDeskMode = (0, _react.useCallback)(() => {
    if (window.confirm(intl.formatMessage({
      "id": "FYaNhe",
      "defaultMessage": [{
        "type": 0,
        "value": "Weet je zeker dat je de desk modus wilt afsluiten?"
      }]
    }))) {
      setParams({
        cartId: undefined
      });
      storageUtils?.removeItem(_storage.DESKMODE_KEY);
      (0, _cart.clearCartId)();
      onClose();
      setDeskMode(false);
    }
  }, [intl, onClose, setParams, storageUtils]);
  if (!deskMode) {
    return null;
  }
  return __jsx(_react.default.Fragment, null, __jsx(DeskModeButton, {
    className: _customizationClassNames.CLASS_DESKMODE_BUTTON,
    ref: ref,
    open: open,
    onClick: onOpen
  }, __jsx(_LogoText.default, {
    width: "14.4rem"
  })), __jsx(_Modal.default, {
    variant: "small",
    open: open,
    onClose: onClose
  }, __jsx(_Stack.default, {
    spacing: 4
  }, __jsx(_ModalHeader.default, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "O5Yogj",
    defaultMessage: [{
      "type": 0,
      "value": "Desk modus"
    }]
  })), __jsx(_Body.default, null, __jsx(_reactIntl.FormattedMessage, {
    id: "xYXjzz",
    defaultMessage: [{
      "type": 0,
      "value": "Je bevindt je nu in de desk modus. In deze modus is het mogelijk om een desk boeking te maken voor een gast"
    }]
  })), __jsx(_Button.default, {
    variant: "outline",
    onClick: onCancelDeskMode
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "C03ddx",
    defaultMessage: [{
      "type": 0,
      "value": "Afsluiten"
    }]
  })))));
};
var _default = exports.default = DeskMode;
const DeskModeButton = _styledComponents.default.button.withConfig({
  displayName: "DeskMode__DeskModeButton",
  componentId: "b9yoym-0"
})(["position:fixed;top:3.2rem;right:0;z-index:", ";box-shadow:0px 1.6rem 2.4rem 0 rgba(59,118,160,0.03),0 2.4rem 8rem 0 rgba(59,118,160,0.05);border-radius:8px 0 0 8px;outline:none;padding:0.8rem;background:white;--border-style:1px solid ", ";border-left:var(--border-style);border-top:var(--border-style);border-bottom:var(--border-style);transition:200ms transform ease-in-out;transform:translateX(", ");:active,:focus{border-color:", ";}@media screen and (min-width:", "){:hover{transform:translateX(0);}}"], ({
  theme
}) => theme.zIndices.sticky, ({
  theme
}) => theme.colors.neutral['20'], ({
  open
}) => !open ? '12.9rem' : 0, ({
  theme
}) => theme.colors.neutral['30'], ({
  theme
}) => theme.mediaQueries.s);