"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withDefaultBookingValues = exports.validBooking = exports.pickBookingFromParams = exports.getTripsFromPeriod = exports.getTripDuration = exports.getDayTrips = exports.getDateStringsFromTrip = exports.filterTripsWithInterval = exports.convertToPeriod = exports.convertToDateStrings = exports.convertBookingToParamsInput = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _validInterval = _interopRequireDefault(require("@oberoninternal/travelbase-ds/utils/validInterval"));
var _differenceInCalendarDays = _interopRequireDefault(require("date-fns/differenceInCalendarDays"));
var _isSameDay = _interopRequireDefault(require("date-fns/isSameDay"));
var _addDays = _interopRequireDefault(require("date-fns/addDays"));
var _parseToDateString = _interopRequireDefault(require("../constants/parseToDateString"));
var _Booking = require("../entities/Booking");
var _lodash = _interopRequireDefault(require("lodash.pick"));
var _isWithinInterval = _interopRequireDefault(require("date-fns/isWithinInterval"));
var _removeUndefinedEntries = _interopRequireDefault(require("./removeUndefinedEntries"));
var _dateFns = require("date-fns");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const byDuration = (a, b) => a.duration - b.duration;
const getDayTrips = (day, trips) => trips.filter(({
  date
}) => (0, _isSameDay.default)(new Date(date), day)).sort(byDuration);
exports.getDayTrips = getDayTrips;
const convertToDateStrings = period => {
  if (!(0, _validInterval.default)(period)) {
    return {
      departureDate: undefined,
      arrivalDate: undefined
    };
  }
  return {
    departureDate: (0, _parseToDateString.default)(period.end),
    arrivalDate: (0, _parseToDateString.default)(period.start)
  };
};
exports.convertToDateStrings = convertToDateStrings;
const getDateStringsFromTrip = trip => ({
  arrivalDate: (0, _parseToDateString.default)(new Date(trip.date)),
  departureDate: (0, _parseToDateString.default)((0, _addDays.default)(new Date(trip.date), trip.duration))
});
exports.getDateStringsFromTrip = getDateStringsFromTrip;
const filterTripsWithInterval = (trips, interval, exclusive = false) => trips.filter(trip => {
  const within = (0, _isWithinInterval.default)(new Date(trip.date), interval);
  return exclusive ? !within : within;
});
exports.filterTripsWithInterval = filterTripsWithInterval;
const getTripsFromPeriod = (period, trips, skipSort) => {
  const potentialDayTrips = getDayTrips(period.start, trips);
  const filteredTrips = potentialDayTrips.filter(({
    duration
  }) => (0, _differenceInCalendarDays.default)(period.end, period.start) === duration);
  if (skipSort) {
    return filteredTrips;
  }
  return filteredTrips.sort((a, b) => a.price - b.price);
};
exports.getTripsFromPeriod = getTripsFromPeriod;
const getTripDuration = (arrivalDate, departureDate) => (0, _differenceInCalendarDays.default)(new Date(departureDate), new Date(arrivalDate));
exports.getTripDuration = getTripDuration;
const convertToPeriod = (arrivalDate, departureDate) => {
  if (!arrivalDate || !departureDate) {
    return undefined;
  }
  return {
    start: new Date(arrivalDate),
    end: new Date(departureDate)
  };
};
exports.convertToPeriod = convertToPeriod;
const validBooking = booking => {
  const {
    arrivalDate,
    departureDate,
    trip
  } = booking ?? {};
  return !!(arrivalDate && departureDate && trip);
};
exports.validBooking = validBooking;
const defaultBookingValues = {
  type: 'static',
  flexibleMonth: (0, _dateFns.format)((0, _dateFns.startOfMonth)(new Date()), 'MM-yyyy'),
  amountOfNights: 1,
  amountAdults: 2,
  amountBabies: 0,
  amountChildren: 0,
  amountPets: 0,
  amountYouths: 0,
  arrivalDate: '',
  departureDate: ''
};

/**
 * In case certain booking values aren't set they'll be overwritten with the `defaultBookingValues` above
 */
const withDefaultBookingValues = (booking, brandConfig) => {
  const defaults = _objectSpread({}, defaultBookingValues);
  if (brandConfig?.searchBoxGroup?.defaultOccupancy) {
    defaults.amountAdults = brandConfig.searchBoxGroup.defaultOccupancy;
  }
  return _objectSpread(_objectSpread({}, defaults), (0, _removeUndefinedEntries.default)(booking));
};
exports.withDefaultBookingValues = withDefaultBookingValues;
const pickBookingFromParams = params => (0, _lodash.default)(withDefaultBookingValues(params), _Booking.bookingCartKeys);
exports.pickBookingFromParams = pickBookingFromParams;
const convertBookingToParamsInput = (input, extensions) => {
  const {
    amountAdults,
    amountBabies,
    amountChildren,
    amountPets,
    amountYouths,
    arrivalDate,
    departureDate
  } = withDefaultBookingValues(input);
  const inputParams = _objectSpread({
    pets: amountPets,
    persons: amountAdults + amountChildren + amountYouths,
    babies: amountBabies,
    date: arrivalDate,
    duration: getTripDuration(arrivalDate, departureDate)
  }, extensions);
  return inputParams;
};
exports.convertBookingToParamsInput = convertBookingToParamsInput;