import React, { SVGAttributes } from 'react';

const CrossIcon = (props: SVGAttributes<SVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" {...props}>
        <defs />
        <g
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        >
            <path d="M1.65.761l14.219 14.082M16.219 1L1.999 15.081" />
        </g>
    </svg>
);

export default CrossIcon;
