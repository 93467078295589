"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _formik = require("formik");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _AutoSave = _interopRequireDefault(require("./AutoSave"));
var _Prompt = _interopRequireDefault(require("./Prompt"));
var _ErrorFocus = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/form/ErrorFocus"));
const _excluded = ["children", "handleAutoSave", "skipPrompt"];
var __jsx = _react.default.createElement;
const promptMessage = (0, _reactIntl.defineMessages)({
  message: {
    "id": "l1S69E",
    "defaultMessage": [{
      "type": 0,
      "value": "Je hebt de gegevens nog niet opgeslagen, weet je het zeker?"
    }]
  }
});
const createHandler = handler => async (values, helpers) => {
  try {
    await handler(values, helpers);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

/* Formed requires either a `handleSubmit`, `handleAutosave` or both. */
const Formed = _ref => {
  let {
      children,
      handleAutoSave,
      skipPrompt
    } = _ref,
    rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const {
    formatMessage
  } = (0, _reactIntl.useIntl)();
  return __jsx(_formik.Formik, (0, _extends2.default)({}, rest, {
    onSubmit: 'handleSubmit' in rest ? createHandler(rest.handleSubmit) : () => {}
  }), bag => __jsx(_formik.Form, null, __jsx(_ErrorFocus.default, null), !skipPrompt && __jsx(_Prompt.default, {
    message: formatMessage(promptMessage.message),
    when: bag.dirty && !bag.isSubmitting
  }), children(bag), handleAutoSave && __jsx(_AutoSave.default, {
    // we use useFormikContext in Autosave so this shouldn't be necessary... https://github.com/formium/formik/pull/2774
    enableReinitialize: rest.enableReinitialize,
    onSave: createHandler(handleAutoSave)
  })));
};
var _default = exports.default = Formed;