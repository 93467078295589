import React, { Dispatch, FC, SetStateAction } from 'react';
import styled from 'styled-components';
import { MenuItemPageFieldsFragment, MenuItemPageRecursiveFragment } from '../generated/graphql';
import Link from './Link';
import { EntryIds, MenuItem } from './MainNavigation';
import { FormattedMessage } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import CaretRight from './icons/CaretRight';
import NavButton from './NavButton';

interface Props {
    level: number;
    selectedPageIds: EntryIds;
    setSelectedPageIds: Dispatch<SetStateAction<EntryIds>>;
    closeMenu: () => void;
    item: MenuItemPageRecursiveFragment & MenuItemPageFieldsFragment;
}

const Submenu: FC<React.PropsWithChildren<Props>> = props => {
    const { setSelectedPageIds, selectedPageIds, level, item, closeMenu } = props;
    const { id } = item;
    if (!id) {
        return null;
    }

    return (
        <DropdownMenu data-scroll-lock-scrollable>
            <BackItem
                onClick={() => {
                    const newItems = [...selectedPageIds];
                    newItems.pop();
                    setSelectedPageIds(newItems);
                }}
            >
                <NavButton>
                    <BackCaret />
                </NavButton>

                <div>
                    <FormattedMessage defaultMessage="Terug" />
                </div>
            </BackItem>
            <DropdownMenuList>
                {item.children?.map(child => {
                    const hasChildren = (child?.children?.length ?? 0) > 0;
                    return (
                        child?.__typename === 'pages_page_Entry' && (
                            <MenuItem key={child.id}>
                                <Link href={`/${child.uri}`}>
                                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                    <a
                                        onClick={() => {
                                            closeMenu();
                                        }}
                                    >
                                        {child?.settingsMenuTitle ?? child?.title}
                                    </a>
                                </Link>

                                {hasChildren && (
                                    <NavButton
                                        onClick={() => {
                                            if (hasChildren && child.id) {
                                                setSelectedPageIds(items => [...items, child.id ?? '']);
                                            }
                                        }}
                                    >
                                        <StyledCaret />
                                    </NavButton>
                                )}

                                <TransitionGroup>
                                    {selectedPageIds[level + 1] === child.id && (
                                        <CSSTransition timeout={500}>
                                            <Submenu {...props} level={level + 1} item={child} />
                                        </CSSTransition>
                                    )}
                                </TransitionGroup>
                            </MenuItem>
                        )
                    );
                })}
            </DropdownMenuList>
        </DropdownMenu>
    );
};

export default Submenu;

const DropdownMenu = styled.div`
    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        height: calc(100vh - 7.2rem);
        overflow: auto;
        width: 100%;
        transition: transform 0.5s ease-in-out;
        background: ${({ theme }) => theme.colors.neutral['0']};
        z-index: 9;
        padding-top: 0.8rem;
        padding-bottom: 6.4rem;
        box-shadow: 0 0.4rem 8rem rgba(0, 0, 0, 0.1);
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: none;
    }

    &.enter {
        transform: translateX(100vw);
    }
    &.enter-active {
        transform: translateX(0);
    }
    &.exit {
        transform: translateX(0);
    }
    &.exit-active {
        transform: translateX(100vw);
    }
`;

const DropdownMenuList = styled.ul`
    padding: 2.2rem 2.2rem 6.4rem;
    background: ${({ theme }) => theme.colors.neutral['0']};
`;

const BackItem = styled.span`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 1.6rem;

    div {
        padding: 1.6rem 0;
        font-weight: 700;
    }

    button {
        margin-right: 0.8rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: none;
    }
`;

const StyledCaret = styled(CaretRight)`
    width: 1.6rem;
    height: 1.6rem;
`;

const BackCaret = styled(StyledCaret as FC<React.PropsWithChildren<unknown>>)`
    transform: rotate(180deg);
    padding-left: 0;
`;
