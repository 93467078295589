"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _formik = require("formik");
var _react = _interopRequireWildcard(require("react"));
var _parseToDateString = _interopRequireDefault(require("../constants/parseToDateString"));
var _Datepicker = _interopRequireDefault(require("./designsystem/Datepicker"));
var _trip = require("../utils/trip");
var _isDate = _interopRequireDefault(require("../utils/isDate"));
const _excluded = ["startName", "endName", "hideInput", "name"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const performOnChange = (helpers, value) => {
  helpers.setValue(value ? (0, _parseToDateString.default)(value) : '');
  setTimeout(() => helpers.setTouched(true));
};
const getError = meta => meta.touched && meta.error ? meta.error : undefined;
const DatepickerField = _ref => {
  let {
      startName,
      endName,
      hideInput,
      name
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  if (!name && !startName || startName && !endName) {
    throw new Error('Please provide at least either a name or a startName and endName.');
  }
  const [{
    value: start
  }, startMeta, startHelpers] = (0, _formik.useField)(name ?? startName);
  const [{
    value: end
  }, endMeta, endHelpers] = (0, _formik.useField)(name ?? endName);
  const {
    enableReinitialize
  } = (0, _formik.useFormikContext)();
  const value = (0, _isDate.default)(start) ? new Date(start) : (0, _trip.convertToPeriod)(start, end);
  const initialValue = (0, _trip.convertToPeriod)(startMeta.initialValue, endMeta.initialValue);
  const onChange = (0, _react.useCallback)(val => {
    if ((0, _isDate.default)(val)) {
      performOnChange(startHelpers, val);
    } else {
      performOnChange(startHelpers, val?.start);
      performOnChange(endHelpers, val?.end);
    }
  }, [endHelpers, startHelpers]);
  return __jsx(_Datepicker.default
  // when the initial value changes, we want to reinitialize the datepicker
  , (0, _extends2.default)({
    key: startMeta.initialValue && endMeta.initialValue && enableReinitialize ? `${startMeta.initialValue}-${endMeta.initialValue}` : undefined,
    hideInput: hideInput,
    defaultValue: initialValue,
    value: value,
    onChange: onChange,
    error: getError(startMeta) ?? getError(endMeta),
    singleDate: !!name
  }, props));
};
var _default = exports.default = DatepickerField;