import React, { FC, useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { NotificationFragment } from '../generated/graphql';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import Page from '@oberoninternal/travelbase-website/dist/components/Page';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import LinkField from './LinkField';
import styled from 'styled-components';
import HtmlMapper from 'react-html-map';
import tagMap from '../constants/tagMap';
import { Box } from '@rebass/grid';
import CrossIcon from './icons/CrossIcon';
import cssCalcBetweenViewport from '../utils/cssCalcBetweenViewport';

interface Props {
    notification: NotificationFragment;
}

export const fragment = gql`
    fragment Notification on notification_GlobalSet {
        button {
            ...LinkField
            text
        }
        header
        active
        enabled
        description
    }
`;

const Notification: FC<React.PropsWithChildren<Props>> = ({ notification }) => {
    const { button, header, enabled, description, active } = notification;
    const [closed, setClosed] = useState<boolean>(false);
    const showButton = button?.element?.uri ?? button?.url ?? false;

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const notificationClosed = localStorage.getItem('notification');
            if (notificationClosed === JSON.stringify(notification)) {
                setClosed(!closed);
            } else {
                localStorage.removeItem('notification');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!enabled || !active || closed) {
        return null;
    }

    return (
        <Page>
            <NotificationWrapper>
                <StyledBox pt={4} pb={4}>
                    <Text>
                        <Title variant="tiny">{header}</Title>
                        <HtmlMapper html={description ?? ''}>{tagMap}</HtmlMapper>
                    </Text>
                    <ButtonWrapper>
                        {button && showButton && (
                            <LinkButton variant="outline" rounded size="large">
                                <LinkField link={button}>{button.text}</LinkField>
                            </LinkButton>
                        )}
                        <CloseButton
                            onClick={() => {
                                localStorage.setItem('notification', JSON.stringify(notification));
                                setClosed(true);
                            }}
                        />
                    </ButtonWrapper>
                </StyledBox>
            </NotificationWrapper>
        </Page>
    );
};

export default Notification;

const NotificationWrapper = styled.div`
    padding: 0 ${cssCalcBetweenViewport(20, 40)} 0 2rem;
    position: relative;
    z-index: 11;
    background-color: ${({ theme }) => theme.colors.secondary['10']};
`;

const StyledBox = styled(Box)`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    div {
        margin-top: 0;
    }

    p {
        margin-top: 0.2rem;
        font-size: 1.4rem;
        line-height: 2rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        flex-direction: row;
        align-items: center;
    }
`;

const Text = styled.div`
    flex-grow: 1;
    padding-right: 4rem;
`;

const LinkButton = styled(Button)`
    background-color: transparent;
    --border-color: ${({ theme }) => theme.colors.primary['80']};
    margin-top: 1.6rem;
    padding: 0 1rem;
    height: 3.2rem;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        margin-top: 0;
        height: 4rem;
        padding: 0 2.4rem;
    }

    a {
        color: ${({ theme }) => theme.colors.primary['80']};
        text-decoration: none;
    }

    &:hover {
        background-color: transparent;
        --border-color: ${({ theme }) => theme.colors.primary['80']};
    }
`;

const CloseButton = styled(CrossIcon)`
    width: 2.4rem;
    height: 2.4rem;
    margin-left: 2.4rem;
    margin-right: 0.4rem;
    cursor: pointer;
    display: block;
    padding: 0.4rem;

    position: absolute;
    top: 3rem;
    right: ${cssCalcBetweenViewport(20, 40)};
    color: ${({ theme }) => theme.colors.primary['80']};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        position: static;
        margin-right: 1.6rem;
    }

    &:hover {
        color: ${({ theme }) => theme.colors.primary['60']};
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
