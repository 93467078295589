"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _router = require("next/router");
var _react = require("react");
var _storage = require("../constants/storage");
var _getStorageWithExpiry = _interopRequireDefault(require("../utils/getStorageWithExpiry"));
const handleRouteChange = () => {
  window.dataLayer = window.dataLayer ?? [];
  const storage = (0, _getStorageWithExpiry.default)('local');
  const isLoggedin = !!storage?.getItem(_storage.CART_KEY);
  const isGuest = !!storage?.getItem(_storage.IS_GUEST);
  // eslint-disable-next-line no-nested-ternary
  const visitorType = isLoggedin ? isGuest ? 'guest' : 'known' : 'unknown';
  window.dataLayer.push({
    event: 'pageview',
    visitor_type: visitorType,
    visitor_party: storage?.getItem(_storage.VISITOR_PARTY) ?? 'unknown'
  });
};
const usePageView = () => {
  const {
    asPath
  } = (0, _router.useRouter)();
  const previous = (0, _react.useRef)(null);
  (0, _react.useEffect)(() => {
    if (asPath !== previous.current) {
      handleRouteChange();
    }
    previous.current = asPath;
  }, [asPath]);
};
var _default = exports.default = usePageView;