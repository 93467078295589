"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.interactables = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _SelectInput = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/form/SelectInput"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
var _useThemeContext = require("@oberoninternal/travelbase-ds/hooks/useThemeContext");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _customizationClassNames = require("../constants/customizationClassNames");
var _TenantContext = require("../context/TenantContext");
var _DatepickerField = _interopRequireDefault(require("./DatepickerField"));
var _Fieldset = _interopRequireDefault(require("./designsystem/Fieldset"));
var _StyledCaret = _interopRequireDefault(require("./designsystem/StyledCaret"));
var _TripGroupInputField = _interopRequireDefault(require("./TripGroupInputField"));
var _SearchDateField = _interopRequireDefault(require("./SearchDateField"));
var _devicesize = require("@oberoninternal/travelbase-ds/context/devicesize");
var _SearchDateFieldMobile = _interopRequireDefault(require("./SearchDateFieldMobile"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const interactables = exports.interactables = ['tripgroup', 'datepicker', 'accommodationType'];
const SearchForm = ({
  variant,
  accommodationTypeOptions,
  brandOptions,
  searchModalOpen = false,
  active = interactables,
  hideCalendarIcon,
  hideHelper,
  hideFlexibleOptions
}) => {
  const isSearchPage = variant === 'searchpage';
  const isHero = variant === 'hero';
  const isAccommodationSearch = variant === 'accommodationSearch';
  const isHomePage = variant === 'home';
  const showLabel = isHomePage || searchModalOpen;
  const isHomeBar = variant === 'homeBar';
  const isFull = isSearchPage || isHero || isAccommodationSearch || isHomeBar;
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const intl = (0, _reactIntl.useIntl)();
  const theme = (0, _useThemeContext.useThemeContext)();
  const deviceSize = (0, _devicesize.useDeviceSize)();
  return __jsx(_react.default.Fragment, null, active.includes('tripgroup') && __jsx(_Fieldset.default, {
    style: {
      maxWidth: isHomePage ? undefined : '34rem'
    },
    label: showLabel && __jsx(_reactIntl.FormattedMessage, {
      id: "P0UVuU",
      defaultMessage: [{
        "type": 0,
        "value": "Reisgezelschap"
      }]
    }),
    id: "tripGroup",
    width: isFull ? undefined : 1,
    flex: isFull ? 1 : undefined,
    dataCy: "searchFormTripGroup",
    className: _customizationClassNames.CLASS_SEARCH_BOX_FIELDSET,
    overwriteLabelColor: theme.colorAssignments.searchBox?.color && (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.searchBox.color)
  }, __jsx(StyledTripGroupInputField, {
    name: "booking",
    surfaceElementVariant: variant === 'home' ? 'input' : 'searchbar',
    className: _customizationClassNames.CLASS_SEARCH_BOX_TRIP_GROUP_FIELD
  })), active.includes('datepicker') && __jsx(_Fieldset.default, {
    label: showLabel && __jsx(_reactIntl.FormattedMessage, {
      id: "ANwe4i",
      defaultMessage: [{
        "type": 0,
        "value": "Reisperiode"
      }]
    }),
    dataCy: "searchFormDates",
    width: isFull ? [1, null, '33%', '30rem'] : 1,
    className: _customizationClassNames.CLASS_SEARCH_BOX_FIELDSET
  }, deviceSize === 'mobile' ? __jsx(_DatepickerField.default, {
    startName: "booking.arrivalDate",
    endName: "booking.departureDate",
    hideInput: true,
    hasFlexibleDates: !hideFlexibleOptions,
    singleDate: false,
    hideCalendarIcon: hideCalendarIcon,
    hideHelper: hideHelper
  }, __jsx(_SearchDateFieldMobile.default, null)) : __jsx(_SearchDateField.default, {
    isHomepage: isHomePage,
    hideFlexibleOptions: hideFlexibleOptions
  })), active.includes('accommodationType') && __jsx(_Fieldset.default, {
    label: (isHomePage || searchModalOpen) && __jsx(_reactIntl.FormattedMessage, {
      id: "pllnR0",
      defaultMessage: [{
        "type": 0,
        "value": "Type accommodatie"
      }]
    }),
    id: "accommdationType",
    width: isFull ? undefined : 1,
    flex: isFull ? 1 : undefined,
    dataCy: "searchFormAccommdationType",
    className: _customizationClassNames.CLASS_SEARCH_BOX_FIELDSET,
    style: {
      maxWidth: isHomePage ? undefined : '24rem'
    },
    overwriteLabelColor: theme.colorAssignments.searchBox?.color && (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.searchBox.color)
  }, __jsx(_SelectInput.default, {
    hideChoices: true,
    ToggleComponent: _StyledCaret.default,
    options: accommodationTypeOptions,
    name: "accommodationType",
    thinShadow: true,
    placeholder: __jsx(_reactIntl.FormattedMessage, {
      id: "SVfyOI",
      defaultMessage: [{
        "type": 0,
        "value": "Type verblijf"
      }]
    }),
    className: _customizationClassNames.CLASS_SEARCH_BOX_ACCOMMODATION_TYPE_FIELD,
    styles: {
      control: styles => _objectSpread({}, styles)
    }
  })), brandConfig.searchBox?.hasBrandSearch && isHomePage && __jsx(_Fieldset.default, {
    label: (isHomePage || searchModalOpen) && __jsx(_reactIntl.FormattedMessage, {
      id: "AOn5tH",
      defaultMessage: [{
        "type": 0,
        "value": "Eiland"
      }]
    }),
    id: "brand",
    width: isFull ? undefined : 1,
    flex: isFull ? 1 : undefined,
    dataCy: "searchFormIsland",
    overwriteLabelColor: theme.colorAssignments.searchBox?.color && (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.searchBox.color),
    className: _customizationClassNames.CLASS_SEARCH_BOX_FIELDSET,
    style: {
      maxWidth: isHomePage ? undefined : '24rem'
    }
  }, __jsx(_SelectInput.default, {
    hideChoices: true,
    ToggleComponent: _StyledCaret.default,
    options: [{
      value: 'all',
      label: intl.formatMessage({
        "id": "tSiF+D",
        "defaultMessage": [{
          "type": 0,
          "value": "Alle eilanden"
        }]
      })
    }, ...(brandOptions ?? [])],
    name: "brand",
    thinShadow: true,
    placeholder: __jsx(_reactIntl.FormattedMessage, {
      id: "AOn5tH",
      defaultMessage: [{
        "type": 0,
        "value": "Eiland"
      }]
    }),
    className: _customizationClassNames.CLASS_SEARCH_BOX_ACCOMMODATION_TYPE_FIELD,
    styles: {
      control: styles => _objectSpread({}, styles)
    }
  })));
};
const StyledTripGroupInputField = (0, _styledComponents.default)(_TripGroupInputField.default).withConfig({
  displayName: "SearchForm__StyledTripGroupInputField",
  componentId: "sc-1087w45-0"
})(["z-index:", ";--buttonContainerRadius:calc(", " + ", "*0.5);", ";"], ({
  theme
}) => theme.zIndices.dropdown, ({
  theme
}) => theme.radius.button, ({
  theme
}) => theme.radius.button, ({
  theme
}) => (0, _styledComponents.css)(["--borderRadius:", ";"], theme.radius.textInput));
var _default = exports.default = SearchForm;