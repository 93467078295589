"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.CheckoutPageSummaryBar = exports.CheckoutPageGradient = exports.CheckoutPageContentCell = exports.CheckoutPageContent = exports.CheckoutPageBackButton = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _TextButton = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/TextButton"));
var _Left = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/Left"));
var _grid = require("@rebass/grid");
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _CheckoutSteps = _interopRequireDefault(require("./CheckoutSteps"));
var _ContentWrapper = _interopRequireDefault(require("./ContentWrapper"));
var _Cell = _interopRequireDefault(require("./designsystem/Cell"));
var _GradientBackground = _interopRequireDefault(require("./designsystem/GradientBackground"));
var _Grid = require("./designsystem/Grid");
var _Page = _interopRequireDefault(require("./Page"));
var _SummaryBar = _interopRequireDefault(require("./SummaryBar"));
var _head = _interopRequireDefault(require("next/head"));
var _TenantContext = require("../context/TenantContext");
const _excluded = ["children", "paymentType", "stepsLoading", "onlyTicketsInCart"],
  _excluded2 = ["children"];
var __jsx = _react.default.createElement;
const CheckoutPage = ({
  children,
  title
}) => {
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  return __jsx(_react.default.Fragment, null, __jsx(_head.default, null, title && __jsx("title", null, `${title} - ${brandConfig.seo.siteName}`), __jsx("meta", {
    name: "robots",
    content: "noindex"
  })), __jsx(_Page.default, null, __jsx(CheckoutPageGradient, null), __jsx(_ContentWrapper.default, {
    className: "checkout-content-wrapper",
    variant: "checkout"
  }, __jsx(CheckoutGrid, null, children))));
};
var _default = exports.default = CheckoutPage;
const CheckoutPageContent = _ref => {
  let {
      children,
      paymentType,
      stepsLoading,
      onlyTicketsInCart
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return __jsx(CheckoutPageContentCell, props, __jsx(_CheckoutSteps.default, {
    paymentType: paymentType,
    loading: stepsLoading,
    onlyTicketsInCart: onlyTicketsInCart
  }), children);
};
exports.CheckoutPageContent = CheckoutPageContent;
const CheckoutPageContentCell = props => __jsx(_Cell.default, (0, _extends2.default)({
  flex: 1,
  order: [2, null, null, 1],
  gridColumn: ['1 / -1', null, null, '1 / 7', null, '1 / 8']
}, props));
exports.CheckoutPageContentCell = CheckoutPageContentCell;
const CheckoutPageSummaryBar = props => __jsx(_Cell.default, {
  order: [1, null, null, 2],
  gridColumn: ['1 / -1', null, null, '8 / -1', null, '10 / -1']
}, __jsx(_SummaryBar.default, props));
exports.CheckoutPageSummaryBar = CheckoutPageSummaryBar;
const CheckoutPageBackButton = _ref2 => {
  let {
      children
    } = _ref2,
    props = (0, _objectWithoutProperties2.default)(_ref2, _excluded2);
  return __jsx(_grid.Box, {
    pb: 6
  }, __jsx(_TextButton.default, props, __jsx(_Left.default, null), __jsx("span", null, children)));
};
exports.CheckoutPageBackButton = CheckoutPageBackButton;
const CheckoutPageGradient = exports.CheckoutPageGradient = (0, _styledComponents.default)(_GradientBackground.default).attrs({
  variant: 'secondary'
}).withConfig({
  displayName: "CheckoutPage__CheckoutPageGradient",
  componentId: "sc-1grgtd1-0"
})(["visibility:hidden;@media screen and (min-width:", "){visibility:visible;:before{left:unset;right:0;width:calc(100vw / 8 * 3);}}@media screen and (min-width:", "){:before{width:calc((100vw - 152rem) / 2 + 50.5rem);}}"], ({
  theme
}) => theme.mediaQueries.m, ({
  theme
}) => theme.mediaQueries.xl);
const CheckoutGrid = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "CheckoutPage__CheckoutGrid",
  componentId: "sc-1grgtd1-1"
})(["grid-template-columns:repeat(4,1fr);--gutter:calc((100vw - var(--wrapperPadding) * 2) * ", ");display:grid;column-gap:var(--gutter);width:100%;@media (min-width:", "){grid-template-columns:repeat(6,1fr);--gutter:calc((100vw - var(--wrapperPadding) * 2) * ", ");}@media (min-width:", "){grid-template-columns:repeat(10,1fr);--gutter:calc((100vw - var(--wrapperPadding) * 2) * ", ");}@media (min-width:", "){grid-template-columns:repeat(12,1fr);--gutter:3.2rem;}"], (0, _Grid.getPercentageForColumns)(4, 64, 20).gutter, ({
  theme
}) => theme.mediaQueries.s, (0, _Grid.getPercentageForColumns)(6, 72, 24).gutter, ({
  theme
}) => theme.mediaQueries.m, (0, _Grid.getPercentageForColumns)(10, 80, 28).gutter, ({
  theme
}) => theme.mediaQueries.xl);