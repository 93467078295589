"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStaticProps = exports.default = void 0;
var _NotFound = _interopRequireDefault(require("../components/pages/NotFound"));
var _default = exports.default = _NotFound.default;
const getStaticProps = async () => ({
  props: {}
});
exports.getStaticProps = getStaticProps;