import React, { SVGAttributes } from 'react';

const SearchIcon = (props: SVGAttributes<SVGElement>) => (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g
            transform="translate(1.6 1.1)"
            stroke="currentcolor"
            strokeWidth="2"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle transform="rotate(-23.025 9.13 9.13)" cx="9.13" cy="9.13" r="6.522" />
            <path d="M15.795 15.795l3.478 3.479" />
        </g>
    </svg>
);

export default SearchIcon;
