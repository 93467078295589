import React, { SVGAttributes } from 'react';

const CaretRight = (props: SVGAttributes<SVGElement>) => (
    <svg width="10" height="18" viewBox="0 0 10 18" {...props}>
        <path
            d="M1 1l3.75 3.75.644.644.806.806L9 9l-8 8"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default CaretRight;
