import React from 'react';

const Youtube = ({ ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 24" {...rest}>
        <defs />
        <path
            fill="currentColor"
            fillRule="nonzero"
            d="M17.083 0S6.398 0 3.716.697C2.28 1.098 1.098 2.28.696 3.737 0 6.42 0 11.973 0 11.973s0 5.575.697 8.214a4.35 4.35 0 003.02 3.02c2.702.718 13.366.718 13.366.718s10.706 0 13.388-.697c1.457-.401 2.618-1.541 2.998-3.02.718-2.66.718-8.214.718-8.214s.021-5.575-.718-8.257A4.22 4.22 0 0030.471.74C27.789 0 17.083 0 17.083 0zm-3.4 6.842l8.89 5.13-8.89 5.111V6.842z"
        />
    </svg>
);

export default Youtube;
