"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _grid = require("@rebass/grid");
var _reactIntl = require("react-intl");
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _graphql = require("../generated/graphql");
var _surchargeCalculationTexts = _interopRequireDefault(require("../constants/surchargeCalculationTexts"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var __jsx = _react.default.createElement;
const Pricing = ({
  main,
  label,
  price,
  category
}) => __jsx(_grid.Flex, {
  justifyContent: "space-between",
  alignItems: "center"
}, __jsx(StyledBody, {
  style: {
    textTransform: typeof label === 'string' ? 'unset' : 'capitalize',
    fontWeight: main ? 600 : undefined
  },
  variant: main ? 'regular' : 'small'
}, label), __jsx(_Body.default, {
  variant: main ? 'regular' : 'small',
  style: {
    fontWeight: main ? 600 : undefined
  }
}, price === 0 && category === _graphql.PriceLineCategoryEnum.NotInTotal ? _surchargeCalculationTexts.default.SUBSEQUENT : __jsx(_reactIntl.FormattedNumber, {
  value: price,
  format: "EUR"
})));
const StyledBody = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "SummaryBarPricing__StyledBody",
  componentId: "sc-1nk2ale-0"
})([":first-letter{text-transform:uppercase;}"]);
var _default = exports.default = Pricing;