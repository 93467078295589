"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isBooking = exports.fragment = exports.default = exports.MobileHeader = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var _ModalHeader = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/ModalHeader"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _UnreachableCaseError = require("@oberoninternal/travelbase-ds/entities/UnreachableCaseError");
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _grid = require("@rebass/grid");
var _client = require("@apollo/client");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _reactLoadingSkeleton = _interopRequireDefault(require("react-loading-skeleton"));
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _TenantContext = require("../context/TenantContext");
var _graphql = require("../generated/graphql");
var _computePayToOwnerAmount = _interopRequireDefault(require("../utils/computePayToOwnerAmount"));
var _fp = require("../utils/fp");
var _TextButton = _interopRequireDefault(require("./designsystem/TextButton"));
var _UnitThumbnail = _interopRequireDefault(require("./designsystem/UnitThumbnail"));
var _Divider = _interopRequireDefault(require("./Divider"));
var _Loading = _interopRequireDefault(require("./Loading"));
var _Stack = _interopRequireDefault(require("./Stack"));
var _StickyBar = require("./StickyBar");
var _SummaryBarItemBooking = _interopRequireDefault(require("./SummaryBarItemBooking"));
var _SummaryBarItemTicket = _interopRequireDefault(require("./SummaryBarItemTicket"));
var _SummaryBarPricing = _interopRequireDefault(require("./SummaryBarPricing"));
var _SummaryBarItemUpsell = _interopRequireDefault(require("./SummaryBarItemUpsell"));
const _excluded = ["updating", "loading", "showOnMobile", "hidePrices", "showPartialPaymentAmount", "exactLink", "isClickable", "totalPriceOnly"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const fragment = exports.fragment = (0, _client.gql)`
    fragment OrderSummary on Order {
        orderItems {
            ...OrderSummaryItem
        }
        paymentOptions {
            partialPaymentAmount
        }
        ...OrderMeta
    }
`;
const orderItemsOrder = ['Booking', 'Ticket', 'UpsellPurchase'];
const isBooking = item => item.__typename === 'Booking';
exports.isBooking = isBooking;
const isTicket = item => item.__typename === 'Ticket';
const isUpsell = item => item.__typename === 'UpsellPurchase';
const SummaryBar = _ref => {
  let {
      updating,
      loading,
      showOnMobile = false,
      hidePrices = false,
      showPartialPaymentAmount = false,
      exactLink,
      isClickable = true,
      totalPriceOnly
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const {
    open,
    onClose,
    onOpen
  } = (0, _useSesame.default)();
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const {
    formatMessage
  } = (0, _reactIntl.useIntl)();
  const {
    mobileHeaderPlacement = 'top'
  } = brandConfig.checkout ?? {};
  let data;
  let totalPrice = 0;
  if (props.data?.__typename === 'Booking' || props.data?.__typename === 'Ticket' || props.data?.__typename === 'UpsellPurchase') {
    data = {
      orderItems: [props.data]
    };
    totalPrice = props.data.totalPrice;
  }
  if (props.data?.__typename === 'Order') {
    data = props.data;
    totalPrice = props.data.totalPrice;
  }
  const {
    orderItems = []
  } = data ?? {};
  const unrelatedPrices = orderItems.reduce((acc, item) => [...acc, ...item.priceLines.filter(line => line.category === _graphql.PriceLineCategoryEnum.NotInTotal)], []);
  const bookings = orderItems.filter(isBooking);
  const tickets = orderItems.filter(isTicket);
  const upsell = orderItems.filter(isUpsell);
  const mainBooking = bookings.find(booking => booking.__typename === 'Booking');
  const isRequest = mainBooking?.requiresApproval;
  const {
    paymentPrice = 0,
    deposit = 0,
    totalPrice: bookingTotalPrice = 0,
    handleDepositPayment
  } = mainBooking ?? {};
  const payToOwnerAmount = mainBooking ? (0, _computePayToOwnerAmount.default)(bookingTotalPrice, handleDepositPayment, deposit, paymentPrice) : 0;
  const {
    partialPaymentAmount
  } = data?.paymentOptions ?? {};
  const content = __jsx(Container, null, loading && __jsx(_grid.Box, null, __jsx(_UnitThumbnail.default, {
    loading: true
  }), __jsx(_Loading.default, {
    mt: 4,
    spacing: 3
  })), __jsx(_Stack.default, {
    spacing: 6
  }, !loading && [...orderItems].sort((itemA, itemB) => orderItemsOrder.indexOf(itemA?.__typename ?? '') - orderItemsOrder.indexOf(itemB?.__typename ?? '')).map((item, i) => {
    if (!item.__typename) {
      return null;
    }
    switch (item.__typename) {
      case 'Booking':
        return __jsx("div", null, i !== 0 && __jsx(_Divider.default, null), __jsx(_SummaryBarItemBooking.default, {
          key: item.rentalUnit?.id ?? i,
          item: item,
          updating: updating,
          hidePrices: hidePrices,
          isClickable: isClickable,
          exactLink: exactLink
        }));
      case 'Ticket':
        return __jsx("div", null, i !== 0 && __jsx(_Divider.default, null), __jsx(_SummaryBarItemTicket.default, {
          key: item.timeslot?.id ?? i,
          updating: updating,
          item: item,
          hidePrices: hidePrices
        }));
      case 'UpsellPurchase':
        return __jsx("div", null, i !== 0 && __jsx(_Divider.default, null), __jsx(_SummaryBarItemUpsell.default, {
          key: item?.offer?.id ?? i,
          item: item,
          updating: updating
        }));
      default:
        throw new _UnreachableCaseError.UnreachableCaseError(item.__typename);
    }
  })), !loading && !updating && !hidePrices && __jsx(_react.default.Fragment, null, __jsx(_Divider.default, {
    mt: 5,
    mb: 4
  }), __jsx(_Stack.default, {
    spacing: 3
  }, __jsx(_SummaryBarPricing.default, {
    label: formatMessage({
      "id": "+i11sY",
      "defaultMessage": [{
        "type": 0,
        "value": "totaal"
      }]
    }),
    price: totalPrice,
    main: true
  }), showPartialPaymentAmount && !!partialPaymentAmount && partialPaymentAmount !== totalPrice && __jsx(_SummaryBarPricing.default, {
    label: formatMessage({
      "id": "9knKo5",
      "defaultMessage": [{
        "type": 0,
        "value": "Je betaalt nu"
      }]
    }),
    price: partialPaymentAmount,
    main: true
  }), unrelatedPrices?.map(line => __jsx(_SummaryBarPricing.default, {
    key: line.label,
    label: line.label,
    price: line.totalPrice,
    category: line.category
  })), (!!payToOwnerAmount || !!deposit) && !isRequest && !totalPriceOnly && __jsx(_Stack.default, {
    style: {
      marginTop: '3.2rem'
    },
    spacing: 3
  }, !!payToOwnerAmount && payToOwnerAmount !== totalPrice && __jsx(_Body.default, {
    style: {
      fontWeight: 300
    },
    variant: "tiny"
  }, __jsx(_reactIntl.FormattedMessage, {
    values: {
      price: __jsx("span", {
        style: {
          fontWeight: 600
        }
      }, __jsx(_reactIntl.FormattedNumber, {
        value: payToOwnerAmount,
        format: "EUR"
      }))
    },
    id: "A51/SN",
    defaultMessage: [{
      "type": 0,
      "value": "Van het totaal betaal je "
    }, {
      "type": 1,
      "value": "price"
    }, {
      "type": 0,
      "value": " rechtstreeks aan de verhuurder."
    }]
  })), !!deposit && __jsx(_Body.default, {
    style: {
      fontWeight: 300
    },
    variant: "tiny"
  }, handleDepositPayment ? __jsx(_reactIntl.FormattedMessage, {
    values: {
      price: __jsx("span", {
        style: {
          fontWeight: 600
        }
      }, __jsx(_reactIntl.FormattedNumber, {
        value: deposit,
        format: "EUR"
      }))
    },
    id: "DboE6N",
    defaultMessage: [{
      "type": 0,
      "value": "De borgsom van "
    }, {
      "type": 1,
      "value": "price"
    }, {
      "type": 0,
      "value": " betaal je via ons aan en verreken je met de verhuurder bij vertrek"
    }]
  }) : __jsx(_reactIntl.FormattedMessage, {
    values: {
      price: __jsx("span", {
        style: {
          fontWeight: 600
        }
      }, __jsx(_reactIntl.FormattedNumber, {
        value: deposit,
        format: "EUR"
      }))
    },
    id: "051h/j",
    defaultMessage: [{
      "type": 0,
      "value": "De borgsom van "
    }, {
      "type": 1,
      "value": "price"
    }, {
      "type": 0,
      "value": " betaal je rechtstreeks aan de verhuurder"
    }]
  }))))), (loading || updating) && __jsx(_react.default.Fragment, null, __jsx(_Divider.default, {
    mt: 5,
    mb: 4
  }), __jsx(_grid.Flex, {
    width: 1,
    justifyContent: "space-between"
  }, __jsx(_grid.Box, {
    width: "12rem"
  }, __jsx(_reactLoadingSkeleton.default, {
    height: "2rem"
  })), __jsx(_grid.Box, {
    width: "6rem"
  }, __jsx(_reactLoadingSkeleton.default, {
    height: "2rem"
  })))));
  return __jsx(_react.default.Fragment, null, !showOnMobile ? __jsx(_react.default.Fragment, null, __jsx(MobileHeader, {
    placement: mobileHeaderPlacement,
    className: "lt-m checkout-mobile-header"
  }, (loading || updating) && __jsx(_grid.Flex, {
    justifyContent: "space-between",
    alignItems: "center"
  }, __jsx(_grid.Box, {
    width: "12rem"
  }, __jsx(_reactLoadingSkeleton.default, {
    height: "2rem"
  })), __jsx(_grid.Box, {
    width: "6rem"
  }, __jsx(_reactLoadingSkeleton.default, {
    height: "2rem"
  }))), !loading && __jsx(_react.default.Fragment, null, !updating && __jsx(_SummaryBarPricing.default, {
    label: __jsx(_reactIntl.FormattedMessage, {
      id: "+i11sY",
      defaultMessage: [{
        "type": 0,
        "value": "totaal"
      }]
    }),
    price: totalPrice,
    main: true
  }), __jsx(_grid.Flex, {
    justifyContent: "space-between",
    alignItems: "center"
  }, __jsx(_Body.default, null, __jsx(_reactIntl.FormattedList, {
    value: [bookings.length ? __jsx(_reactIntl.FormattedMessage, {
      key: "accommodation",
      values: {
        amount: bookings.length
      },
      id: "+ygd5Q",
      defaultMessage: [{
        "type": 6,
        "pluralType": "cardinal",
        "value": "amount",
        "offset": 0,
        "options": {
          "one": {
            "value": [{
              "type": 7
            }, {
              "type": 0,
              "value": " accommodatie"
            }]
          },
          "other": {
            "value": [{
              "type": 7
            }, {
              "type": 0,
              "value": " accommodaties"
            }]
          }
        }
      }]
    }) : null, tickets.length ? __jsx(_reactIntl.FormattedMessage, {
      key: "ticket",
      values: {
        amount: tickets.length
      },
      id: "qKV0jt",
      defaultMessage: [{
        "type": 6,
        "pluralType": "cardinal",
        "value": "amount",
        "offset": 0,
        "options": {
          "one": {
            "value": [{
              "type": 7
            }, {
              "type": 0,
              "value": " activiteit"
            }]
          },
          "other": {
            "value": [{
              "type": 7
            }, {
              "type": 0,
              "value": " activiteiten"
            }]
          }
        }
      }]
    }) : null, upsell.length ? __jsx(_reactIntl.FormattedMessage, {
      key: "upsell",
      values: {
        amount: upsell.length
      },
      id: "GLmVy6",
      defaultMessage: [{
        "type": 6,
        "pluralType": "cardinal",
        "value": "amount",
        "offset": 0,
        "options": {
          "one": {
            "value": [{
              "type": 7
            }, {
              "type": 0,
              "value": " speciale aanbieding"
            }]
          },
          "other": {
            "value": [{
              "type": 7
            }, {
              "type": 0,
              "value": " speciale aanbiedingen"
            }]
          }
        }
      }]
    }) : null].filter(_fp.truthy)
  })), __jsx(_TextButton.default, {
    onClick: onOpen
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "F2P5bG",
    defaultMessage: [{
      "type": 0,
      "value": "uw boeking"
    }]
  }))))), __jsx(_Modal.default, {
    onClose: onClose,
    open: open
  }, __jsx(_ModalHeader.default, {
    style: {
      textTransform: 'capitalize',
      marginBottom: '1.6rem'
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "F2P5bG",
    defaultMessage: [{
      "type": 0,
      "value": "uw boeking"
    }]
  })), content)) : __jsx("div", {
    className: "lt-m"
  }, content), __jsx(StickyWrapper, {
    className: "gt-m"
  }, content));
};
var _default = exports.default = SummaryBar;
const StickyWrapper = _styledComponents.default.div.withConfig({
  displayName: "SummaryBar__StickyWrapper",
  componentId: "sc-15pn8gr-0"
})(["top:var(--vertical-padding);position:sticky;"]);
const Container = _styledComponents.default.div.withConfig({
  displayName: "SummaryBar__Container",
  componentId: "sc-15pn8gr-1"
})(["width:100%;"]);
const MobileHeader = exports.MobileHeader = _styledComponents.default.div.withConfig({
  displayName: "SummaryBar__MobileHeader",
  componentId: "sc-15pn8gr-2"
})(["box-shadow:0 1.6rem 2.4rem 0 rgba(59,118,160,0.03),0 2.4rem 8rem 0 rgba(59,118,160,0.05);position:fixed;background:", ";left:0;right:0;height:7rem;padding:0 var(--wrapperPadding);display:flex;flex-direction:column;justify-content:center;z-index:", ";", ";"], ({
  theme
}) => theme.colors.neutral[0], ({
  theme
}) => theme.zIndices.sticky, ({
  placement
}) => {
  switch (placement) {
    case 'top':
      return (0, _styledComponents.css)(["top:0;border-bottom:1px solid ", ";"], ({
        theme
      }) => theme.colors.neutral['20']);
    case 'bottom':
      return (0, _styledComponents.css)(["bottom:0;border-top:1px solid ", ";", ""], ({
        theme
      }) => theme.colors.neutral['20'], _StickyBar.iosStickyBarSafeSpace);
    default:
      return '';
  }
});