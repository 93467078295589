import { createGlobalStyle } from 'styled-components';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';
import cssCalcBetweenViewport from './utils/cssCalcBetweenViewport';

const GlobalStyle = createGlobalStyle`
.ext-search-box__container{
    & * {
        --buttonContainerRadius: 2rem !important;

    }
}
    body {
        --logoOffsetLeft: 15.3rem;
        --paddingLeft: calc(var(--logoOffsetLeft) + ${cssCalcBetweenViewport(20, 40)});
        --paddingRight: calc(var(--logoOffsetLeft) + ${cssCalcBetweenViewport(20, 40)});

        display: block !important;
        color: ${({ theme }) => theme.colors.primary[80]};

        p, h1, h2, h3, h4, h5, li {
            a {
                color: ${({ theme }) => theme.colors.primary[60]};
                font-weight: 600;
                text-decoration: underline !important;

                :hover {
                    color: ${({ theme }) => theme.colors.primary[60]};
                }
            }
        }
    }
    // very hacky content padding overrides, needed to prevent our glorious (way too big) logo from overlapping the content
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) and (max-width: ${({ theme }) =>
    theme.mediaQueries.xxxl}) {
        ${ContentWrapper as never}:not(.checkout-content-wrapper) {
            padding-left: var(--paddingLeft);
            padding-right: var(--paddingRight);
            max-width: none;
        }
    }

    body.is-checkout {
        padding-bottom: 7rem;
    }

    /* This code is subject to LICENSE in root of this repository */

    /* Used to detect in JavaScript if apps have loaded styles or not. */
    :root {
        --reach-dialog: 1;
    }

    [data-reach-dialog-overlay] {
        background: hsla(0, 0%, 0%, 0.33);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
    }

    [data-reach-dialog-content] {
        width: 50vw;
        margin: 10vh auto;
        background: white;
        padding: 2rem;
        outline: none;
    }

    // This is used to hide the reCAPTCHA Badge
    .grecaptcha-badge { visibility: hidden; }

    // This is used to adjust positioning of ThuisWinkel badge
    .tww_sidebar_container.tww_sidebar_right.tww_sidebar_bottom.tww_sidebar_widget { bottom: 90px; }
`;
export default GlobalStyle;
