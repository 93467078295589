"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VISITOR_PARTY = exports.SEARCHBAR_KEY = exports.REFRESH_TOKEN = exports.IS_GUEST = exports.DESKMODE_KEY = exports.DATE_STAMP = exports.CART_KEY = exports.ACCESS_TOKEN = void 0;
// Declare any keys that the app needs to have control over in the local- or sessionstorage
const SEARCHBAR_KEY = exports.SEARCHBAR_KEY = 'searchBar';
const DESKMODE_KEY = exports.DESKMODE_KEY = 'deskmode';
const CART_KEY = exports.CART_KEY = 'cartId';
const ACCESS_TOKEN = exports.ACCESS_TOKEN = 'access_token';
const IS_GUEST = exports.IS_GUEST = 'is_guest';
const VISITOR_PARTY = exports.VISITOR_PARTY = 'visitor_party';
const REFRESH_TOKEN = exports.REFRESH_TOKEN = 'refresh_token';
const DATE_STAMP = exports.DATE_STAMP = 'dateStamp';