"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _formik = require("formik");
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _Stack = _interopRequireDefault(require("./Stack"));
var _react = _interopRequireDefault(require("react"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const InlineSelectField = ({
  name,
  options,
  onChange
}) => {
  const [{
    value
  },, {
    setValue,
    setTouched
  }] = (0, _formik.useField)(name);
  return __jsx(_Stack.default, {
    spacing: 4
  }, options.map(option => __jsx(Item, {
    key: option.value,
    isSelected: value === option.value,
    onClick: () => {
      setValue(option.value);
      setTouched(true);
      onChange?.(option);
    }
  }, option.label)));
};
var _default = exports.default = InlineSelectField;
const Item = _styledComponents.default.div.withConfig({
  displayName: "InlineDropdownField__Item",
  componentId: "sc-1a5slja-0"
})(["appearance:none;margin:0;padding:1.4rem 1.6rem;font-weight:500;font-size:14px;line-height:20px;border-radius:0.8rem;", ";"], ({
  isSelected
}) => isSelected && (0, _styledComponents.css)(["background:", ";color:", ";"], ({
  theme
}) => theme.colors.primary['80'], ({
  theme
}) => theme.colors.neutral['0']));