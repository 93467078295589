"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _createPlaceholder = _interopRequireDefault(require("./createPlaceholder"));
const _excluded = ["loading"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const isImage = img => 'transform' in img;
const createImgProps = (fallback, webp, sizes, props) => {
  const _ref = props ?? {},
    {
      loading
    } = _ref,
    rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const fallbackType = fallback.type ?? 'image/jpeg';
  const jpegTransform = isImage(fallback) ? fallback.transform : fallback;
  const webpTransform = isImage(webp) ? webp.transform : webp;
  return {
    fallback: _objectSpread({
      src: jpegTransform?.src
    }, rest),
    loading,
    sources: [{
      srcSet: webpTransform?.srcSet,
      type: 'image/webp',
      sizes
    }, {
      srcSet: jpegTransform?.srcSet,
      type: fallbackType,
      sizes
    }],
    placeholder: (0, _createPlaceholder.default)(webpTransform?.placeholder)
  };
};
var _default = exports.default = createImgProps;