import {
    getInitialSearchBoxValues,
    getSearchValidationSchema,
} from '@oberoninternal/travelbase-website/dist/components/SearchBox';
import React, { FC } from 'react';
import { useRouter } from 'next/router';
import Formed from '@oberoninternal/travelbase-website/dist/components/Formed';
import { OptionType } from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import { withDefaultBookingValues } from '@oberoninternal/travelbase-website/dist/utils/trip';
import { Booking } from '@oberoninternal/travelbase-website/dist/entities/Booking';
import SearchBoxInner from './SearchBoxInner';
import { useTenantContext } from '@oberoninternal/travelbase-website/dist/context/TenantContext';

interface Props {
    open: boolean;
    onClose: () => void;
}

interface Values {
    accommodationType: OptionType['value'];
    booking: Booking;
}

const SearchBox: FC<React.PropsWithChildren<Props>> = ({ onClose, open }) => {
    const { push } = useRouter();
    const validationSchema = getSearchValidationSchema();
    const { brandConfig } = useTenantContext();

    return (
        <Formed<Values>
            handleSubmit={async ({ accommodationType, booking }) => {
                await push({
                    pathname: `/search`,
                    query: {
                        ...withDefaultBookingValues(booking),
                        ...(accommodationType && { accommodationType }),
                    },
                });
            }}
            initialValues={getInitialSearchBoxValues(brandConfig)}
            validationSchema={validationSchema}
            skipPrompt
        >
            {() => <SearchBoxInner open={open} onClose={onClose} />}
        </Formed>
    );
};

export default SearchBox;
