import React from 'react';

const FlagNL = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" className="flag">
        <defs>
            <circle id="FlagNL-a" cx="12" cy="12" r="12" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <mask id="FlagNL-b" fill="#fff">
                <use href="#FlagNL-a" />
            </mask>
            <g mask="url(#FlagNL-b)">
                <path fill="#DC463E" d="M0 0h24v7.944H0z" />
                <path fill="#FFF" d="M0 7.944h24v8.113H0z" />
                <path fill="#12277F" d="M0 16.056h24V24H0z" />
            </g>
        </g>
    </svg>
);

export default FlagNL;
