import { useCallback, useEffect, useState } from 'react';

const useIsTop = () => {
    const [isTop, setIsTop] = useState<boolean>(true);

    const handleScroll = useCallback(() => {
        setIsTop((document.documentElement ?? document.body).scrollTop <= 0);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return isTop;
};

export default useIsTop;
