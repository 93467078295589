const subscribeToNewsletter = (email: string, locale: string) => {
    window._sqzl = window._sqzl || [];

    window._sqzl.push({
        event: 'EmailOptInWebsite',
        custom_frequentie: 'week',
        custom_taal: locale,
        email,
        // this indicates opt in, which only happens after the user confirms their email.
        // therefore this value should never be 'yes'.
        newsletter: 'unknown',
        marketing: 'no',
        service: 'no',
    });
};

export default subscribeToNewsletter;
