"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * safari doesn't support dateTime's with a space in between the date and time for example.
 */
const parseDateTime = dateTime => typeof dateTime === 'string' ? new Date(dateTime.replace(/-/g, '/')) : dateTime;
var _default = exports.default = parseDateTime;