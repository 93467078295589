"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ACCESS_TOKEN", {
  enumerable: true,
  get: function () {
    return _storage.ACCESS_TOKEN;
  }
});
Object.defineProperty(exports, "REFRESH_TOKEN", {
  enumerable: true,
  get: function () {
    return _storage.REFRESH_TOKEN;
  }
});
exports.refresh = exports.logout = exports.login = void 0;
var _storage = require("../constants/storage");
var _getStorageWithExpiry = _interopRequireDefault(require("./getStorageWithExpiry"));
const login = async authCode => {
  const formData = new FormData();
  formData.append('grant_type', 'authorization_code');
  formData.append('client_id', 'frontend');
  formData.append('scope', 'internal');
  formData.append('code', authCode);
  const storageUtils = (0, _getStorageWithExpiry.default)('local');
  try {
    const result = await fetch(`${process.env.NEXT_PUBLIC_CORE_API_URL}/api/public/oauth/token/`, {
      method: 'POST',
      // https://github.com/microsoft/TypeScript/issues/30584
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      body: formData
    });
    if (result.ok) {
      const data = await result.json();
      storageUtils?.setItem(_storage.ACCESS_TOKEN, data.access_token);
      storageUtils?.setItem(_storage.REFRESH_TOKEN, data.refresh_token);
      return true;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return false;
};
exports.login = login;
const logout = () => {
  const storageUtils = (0, _getStorageWithExpiry.default)('local');
  storageUtils?.removeItem(_storage.ACCESS_TOKEN);
  storageUtils?.removeItem(_storage.REFRESH_TOKEN);
};
exports.logout = logout;
const refresh = async () => {
  const storageUtils = (0, _getStorageWithExpiry.default)('local');
  const token = storageUtils?.getItem(_storage.REFRESH_TOKEN);
  if (!token) {
    return false;
  }
  const formData = new FormData();
  formData.append('grant_type', _storage.REFRESH_TOKEN);
  formData.append('client_id', 'frontend');
  formData.append(_storage.REFRESH_TOKEN, token);
  try {
    const result = await fetch(`${process.env.NEXT_PUBLIC_CORE_API_URL}/api/public/oauth/token/`, {
      method: 'POST',
      // https://github.com/microsoft/TypeScript/issues/30584
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      body: formData
    });
    if (result.ok) {
      const data = await result.json();
      storageUtils?.setItem(_storage.ACCESS_TOKEN, data.access_token);
      storageUtils?.setItem(_storage.REFRESH_TOKEN, data.refresh_token);
      return true;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  // remove current tokens
  logout();
  return false;
};
exports.refresh = refresh;