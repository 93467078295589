"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.iosStickyBarSafeSpace = exports.default = exports.StickyBarThumnail = exports.StickyBarTextButton = exports.StickyBarSvgButton = exports.StickyBarPriceContainer = exports.StickyBarPrice = exports.StickyBarOriginalPrice = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Button = require("@oberoninternal/travelbase-ds/components/action/Button");
var _TextButton = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/TextButton"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
var _portal = _interopRequireDefault(require("@reach/portal"));
var _grid = require("@rebass/grid");
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _customizationClassNames = require("../constants/customizationClassNames");
var _SvgButton = _interopRequireDefault(require("./designsystem/SvgButton"));
var _UnitThumbnail = _interopRequireDefault(require("./designsystem/UnitThumbnail"));
const _excluded = ["className", "children", "wrapInPortal", "hasRelativePosition"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const StickyBar = _ref => {
  let {
      className,
      children,
      wrapInPortal,
      hasRelativePosition
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const content = __jsx(Sticky, (0, _extends2.default)({
    hasRelativePosition: hasRelativePosition,
    inPortal: wrapInPortal,
    className: `${className ?? ''} ${_customizationClassNames.CLASS_STICKY_BAR}`
  }, props), __jsx(Container, null, children));
  if (wrapInPortal) {
    return __jsx(_portal.default, null, content);
  }
  return content;
};
var _default = exports.default = StickyBar;
const iosStickyBarSafeSpace = exports.iosStickyBarSafeSpace = (0, _styledComponents.css)(["@supports (margin-bottom:env(safe-area-inset-bottom)){margin-bottom:env(safe-area-inset-bottom);::after{position:absolute;content:'';width:100%;top:100%;height:env(safe-area-inset-bottom);background:white;left:0;}}"]);
const StickyBarTextButton = exports.StickyBarTextButton = (0, _styledComponents.default)(_TextButton.default).withConfig({
  displayName: "StickyBar__StickyBarTextButton",
  componentId: "sc-1yag542-0"
})(["line-height:1.4em;text-align:left;padding:0;font-weight:600;margin-left:0rem !important;> span >:first-child +:last-child{margin-left:0;}:hover{> span{::after{opacity:0;}}}@media screen and (min-width:", "px){margin-left:0.8rem !important;> span{::after{opacity:0.4;transform-origin:100% 50%;transform:scale3d(0,1,1);transition:transform 0.3s,opacity 0.3s;}::before{position:absolute;content:'';left:0;bottom:0;right:0;opacity:0.4;border-bottom:1px solid ", ";", ";}}:hover{> span{::after{opacity:1;transform-origin:0% 50%;transform:scale3d(1,1,1);}}}}"], ({
  theme
}) => theme.mediaQueriesValues.s, ({
  theme
}) => (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.textAction), ({
  size
}) => size === 'tiny' ? 'padding-bottom: 2px;' : '');
const StickyBarPriceContainer = exports.StickyBarPriceContainer = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "StickyBar__StickyBarPriceContainer",
  componentId: "sc-1yag542-1"
})(["@media screen and (min-width:", "){text-align:right;}"], ({
  theme
}) => theme.mediaQueries.s);
const StickyBarThumnail = exports.StickyBarThumnail = (0, _styledComponents.default)(_UnitThumbnail.default).withConfig({
  displayName: "StickyBar__StickyBarThumnail",
  componentId: "sc-1yag542-2"
})(["border-right:1px solid ", ";padding-right:", ";max-width:30.2rem;margin-right:", ";"], ({
  theme
}) => theme.colors.neutral['20'], ({
  theme
}) => theme.spacing['40_Standard'], ({
  theme
}) => theme.spacing['50_Semi']);
const StickyBarOriginalPrice = exports.StickyBarOriginalPrice = _styledComponents.default.span.withConfig({
  displayName: "StickyBar__StickyBarOriginalPrice",
  componentId: "sc-1yag542-3"
})(["", ";text-decoration:line-through;color:", ";"], ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.body.regular), ({
  theme
}) => theme.colors.neutral['40']);
const StickyBarPrice = exports.StickyBarPrice = _styledComponents.default.span.withConfig({
  displayName: "StickyBar__StickyBarPrice",
  componentId: "sc-1yag542-4"
})(["&&{", ";font-weight:", ";}"], ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.body.huge), ({
  theme
}) => theme.fontWeights.bold);
const Container = (0, _styledComponents.default)(_grid.Flex).attrs({
  alignItems: 'center',
  py: 3,
  px: '2.4rem'
}).withConfig({
  displayName: "StickyBar__Container",
  componentId: "sc-1yag542-5"
})(["height:var(--booking-bar-height);width:100%;", " > div{height:4.8rem;}@media screen and (max-width:", "px){", "{padding-left:", ";padding-right:", ";}}"], iosStickyBarSafeSpace, ({
  theme
}) => theme.mediaQueriesValues.m - 1, _Button.StyledButton, ({
  theme
}) => theme.spacing['40_Standard'], ({
  theme
}) => theme.spacing['40_Standard']);
const Sticky = _styledComponents.default.div.withConfig({
  displayName: "StickyBar__Sticky",
  componentId: "sc-1yag542-6"
})(["position:", ";margin-top:", ";bottom:0;left:0;transition:transform 200ms ease-in-out;right:0;background:", ";border:", ";box-shadow:", ";z-index:", ";"], ({
  hasRelativePosition
}) => hasRelativePosition ? 'relative' : 'fixed', ({
  hasRelativePosition
}) => hasRelativePosition && '1.5rem', ({
  theme
}) => theme.colors.neutral['0'], ({
  hasRelativePosition,
  theme
}) => !hasRelativePosition && `1px solid ${theme.colors.neutral['20']}`, ({
  hasRelativePosition
}) => !hasRelativePosition && ' 0 0 2.4rem 0 rgba(59, 118, 160, 0.03), 0 0 8rem 0 rgba(59, 118, 160, 0.05);', ({
  theme,
  inPortal
}) => inPortal ? theme.zIndices.modal + 1 : theme.zIndices.sticky);
const StickyBarSvgButton = exports.StickyBarSvgButton = (0, _styledComponents.default)(_SvgButton.default).withConfig({
  displayName: "StickyBar__StickyBarSvgButton",
  componentId: "sc-1yag542-7"
})(["margin:-3px 0 0 4px;display:inline-flex;"]);