"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.CarouselChild = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _RoundButton = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/RoundButton"));
var _ArrowLeft = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/ArrowLeft"));
var _ArrowRight = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/ArrowRight"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _grid = require("@rebass/grid");
var _react = _interopRequireWildcard(require("react"));
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _ContentWrapper = _interopRequireDefault(require("../ContentWrapper"));
var _reactIntersectionObserver = require("react-intersection-observer");
const _excluded = ["title", "children", "continuous", "arrowsOnObject", "buttonSize"],
  _excluded2 = ["children"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const Carousel = _ref => {
  let {
      title,
      children,
      continuous = false,
      arrowsOnObject,
      buttonSize
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const WrapperComponent = continuous ? _react.Fragment : _ContentWrapper.default;
  const viewport = (0, _react.useRef)(null);
  const content = (0, _react.useRef)(null);
  const {
    0: prevNextVisible,
    1: setPrevNextVisible
  } = (0, _react.useState)(true);
  const scrollTo = (0, _react.useCallback)(scrollLeft => {
    const left = scrollLeft ?? viewport.current?.scrollLeft;
    viewport.current?.scrollTo({
      left,
      behavior: 'smooth'
    });
  }, [viewport]);
  const {
    0: prevEnabled,
    1: setPrevEnabled
  } = (0, _react.useState)(false);
  const {
    0: nextEnabled,
    1: setNextEnabled
  } = (0, _react.useState)(false);
  const calculateEnabled = (0, _react.useCallback)(() => {
    const vpElement = viewport.current;
    if (vpElement) {
      setNextEnabled(Math.round(vpElement.scrollLeft + vpElement.clientWidth) !== vpElement.scrollWidth);
      setPrevEnabled(vpElement.scrollLeft > 0);
      setPrevNextVisible(vpElement.scrollWidth !== vpElement.clientWidth);
    }
  }, [viewport]);
  const count = (0, _react.useMemo)(() => _react.Children.count(children), [children]);
  (0, _react.useEffect)(() => {
    calculateEnabled();
  }, [viewport, calculateEnabled, count]);
  return __jsx(_reactIntersectionObserver.InView, {
    onChange: () => {
      calculateEnabled();
    }
  }, __jsx(Container, props, __jsx(HeaderWrapper, {
    hasTitle: !!title
  }, title && __jsx(_Title.default, {
    variant: "large"
  }, title), prevNextVisible ? __jsx("div", {
    style: {
      flexShrink: 0
    }
  }, __jsx(LeftButton, {
    variant: "outline",
    size: buttonSize ?? 'large',
    disabled: !prevEnabled,
    arrowsOnObject: arrowsOnObject,
    className: arrowsOnObject ? 'gt-s' : undefined,
    onClick: () => {
      const firstItem = content.current?.firstElementChild;
      if (viewport?.current && firstItem) {
        scrollTo(viewport.current.scrollLeft - firstItem.scrollWidth);
        calculateEnabled();
      }
    }
  }, __jsx(StyledArrowLeft, {
    disabled: !prevEnabled
  })), __jsx(RightButton, {
    variant: "outline",
    size: buttonSize ?? 'large',
    arrowsOnObject: arrowsOnObject,
    className: arrowsOnObject ? 'gt-s' : undefined,
    disabled: !nextEnabled,
    onClick: () => {
      const firstItem = content.current?.firstElementChild;
      if (viewport?.current && firstItem) {
        scrollTo(viewport.current.scrollLeft + firstItem.scrollWidth);
        calculateEnabled();
      }
    }
  }, __jsx(StyledArrowRight, {
    disabled: !nextEnabled
  }))) : null), __jsx(WrapperComponent, null, __jsx(ScrollContainer, {
    ref: viewport,
    onScroll: () => calculateEnabled()
  }, __jsx(ScrollWrapper, {
    ref: content
  }, children)))));
};
const Container = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "Carousel__Container",
  componentId: "sc-9ibf4h-0"
})(["display:flex;flex-direction:column;"]);
const HeaderWrapper = _styledComponents.default.div.withConfig({
  displayName: "Carousel__HeaderWrapper",
  componentId: "sc-9ibf4h-1"
})(["display:flex;justify-content:", ";align-items:center;width:100%;margin-bottom:3.2rem;"], ({
  hasTitle
}) => hasTitle ? 'space-between' : 'flex-end');
const ScrollContainer = _styledComponents.default.div.withConfig({
  displayName: "Carousel__ScrollContainer",
  componentId: "sc-9ibf4h-2"
})(["display:flex;margin:0;margin-bottom:", ";overflow-x:auto;scroll-snap-type:x mandatory;scrollbar-width:none;-ms-overflow-style:none;overscroll-behavior-x:none;will-change:transform;-webkit-overflow-scrolling:touch;&::-webkit-scrollbar{display:none;}"], ({
  theme
}) => theme.spacing['50_Semi']);
const ScrollWrapper = _styledComponents.default.div.withConfig({
  displayName: "Carousel__ScrollWrapper",
  componentId: "sc-9ibf4h-3"
})(["display:flex;& > *:first-child{box-sizing:content-box;}"]);
const LeftButton = (0, _styledComponents.default)(_RoundButton.default).withConfig({
  displayName: "Carousel__LeftButton",
  componentId: "sc-9ibf4h-4"
})(["margin-right:0.8rem;opacity:", ";", " ", ""], ({
  disabled
}) => disabled ? '0.4' : '1', ({
  arrowsOnObject
}) => arrowsOnObject && (0, _styledComponents.css)(["position:absolute;z-index:12;top:50%;margin-top:-3.4rem;left:2rem;&&{background:white !important;}"]), ({
  arrowsOnObject,
  disabled
}) => arrowsOnObject && disabled && `
            display: none
    `);
const RightButton = (0, _styledComponents.default)(LeftButton).withConfig({
  displayName: "Carousel__RightButton",
  componentId: "sc-9ibf4h-5"
})(["margin-right:0;left:unset;", ""], ({
  arrowsOnObject
}) => arrowsOnObject && (0, _styledComponents.css)(["right:2rem;"]));
const StyledArrowLeft = (0, _styledComponents.default)(_ArrowLeft.default).withConfig({
  displayName: "Carousel__StyledArrowLeft",
  componentId: "sc-9ibf4h-6"
})(["opacity:", ";"], ({
  disabled
}) => disabled ? '0.4' : '1');
const StyledArrowRight = (0, _styledComponents.default)(_ArrowRight.default).withConfig({
  displayName: "Carousel__StyledArrowRight",
  componentId: "sc-9ibf4h-7"
})(["opacity:", ";"], ({
  disabled
}) => disabled ? '0.4' : '1');
const ChildContainer = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "Carousel__ChildContainer",
  componentId: "sc-9ibf4h-8"
})(["scroll-snap-align:start;& + &{margin-left:3.2rem;}&:last-child{margin-right:3.2rem;}"]);
const ChildWrapper = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "Carousel__ChildWrapper",
  componentId: "sc-9ibf4h-9"
})(["width:32rem;@media (min-width:", "){width:44.8rem;}"], ({
  theme
}) => theme.mediaQueries.m);
const CarouselChild = _ref2 => {
  let {
      children
    } = _ref2,
    props = (0, _objectWithoutProperties2.default)(_ref2, _excluded2);
  return __jsx(ChildContainer, props, __jsx(ChildWrapper, null, children));
};
exports.CarouselChild = CarouselChild;
var _default = exports.default = Carousel;