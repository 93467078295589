"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPercentageForColumns = exports.default = void 0;
var _grid = require("@rebass/grid");
var _styledComponents = _interopRequireDefault(require("styled-components"));
const getPercentageForColumns = (cols, colWidth, gutterWidth) => {
  const w = colWidth * cols + gutterWidth * (cols - 1);
  return {
    column: colWidth / w,
    gutter: gutterWidth / w
  };
};
exports.getPercentageForColumns = getPercentageForColumns;
const Grid = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "Grid",
  componentId: "sc-5f74ua-0"
})(["--column:calc((100vw - var(--wrapperPadding) * 2) * ", ");--gutter:calc((100vw - var(--wrapperPadding) * 2) * ", ");display:grid;grid-template-columns:repeat(auto-fill,var(--column));column-gap:var(--gutter);width:100%;@media (min-width:", "){--column:calc((100vw - var(--wrapperPadding) * 2) * ", ");--gutter:calc((100vw - var(--wrapperPadding) * 2) * ", ");}@media (min-width:", "){--column:calc((100vw - var(--wrapperPadding) * 2) * ", ");--gutter:calc((100vw - var(--wrapperPadding) * 2) * ", ");}@media (min-width:", "){--column:8.8rem;--gutter:3.2rem;}"], getPercentageForColumns(4, 64, 20).column, getPercentageForColumns(4, 64, 20).gutter, ({
  theme
}) => theme.mediaQueries.s, getPercentageForColumns(6, 72, 24).column, getPercentageForColumns(6, 72, 24).gutter, ({
  theme
}) => theme.mediaQueries.m, getPercentageForColumns(10, 80, 28).column, getPercentageForColumns(10, 80, 28).gutter, ({
  theme
}) => theme.mediaQueries.xl);
var _default = exports.default = Grid;