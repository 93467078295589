"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _grid = require("@rebass/grid");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var __jsx = _react.default.createElement;
const SummaryBarItemPriceLine = ({
  priceLine
}) => __jsx(_grid.Flex, {
  key: priceLine.label,
  justifyContent: "space-between"
}, __jsx(StyledBody, null, priceLine.label), Number(priceLine.quantity) > 1 && __jsx(StyledBody, null, priceLine.quantity, ' x'), __jsx(StyledBody, null, __jsx(_reactIntl.FormattedNumber, {
  value: priceLine.totalPrice,
  format: "EUR"
})));
var _default = exports.default = SummaryBarItemPriceLine;
const StyledBody = (0, _styledComponents.default)(_Body.default).attrs({
  variant: 'small'
}).withConfig({
  displayName: "SummaryBarItemPriceLine__StyledBody",
  componentId: "sc-3nw3vo-0"
})(["flex:1;text-align:center;:first-of-type{text-align:start;}:last-of-type{text-align:end;}"]);