import { TagMap } from 'react-html-map';
import Link from '../components/Link';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import React, { AnchorHTMLAttributes, ComponentProps, FC } from 'react';

export const TitleWrapper: FC<React.PropsWithChildren<ComponentProps<typeof Title>>> = ({ children, ...rest }) => (
    <Title {...rest}>{children}</Title>
);

const HtmlLink: FC<React.PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>> = ({ href, children, ...rest }) =>
    href?.startsWith('/') ? (
        // assume internal link
        <Link href={`/${href}`}>
            <a {...rest}>{children}</a>
        </Link>
    ) : (
        <a href={href} {...rest}>
            {children}
        </a>
    );

const tagMap: TagMap = {
    a: HtmlLink,
    p: props => <Body {...props} />,
    h1: props => <TitleWrapper variant="large" {...props} />,
    h2: props => <TitleWrapper variant="regular" {...props} />,
    h3: props => <TitleWrapper variant="small" {...props} />,
    h4: props => <TitleWrapper variant="micro" {...props} />,
    h5: props => <TitleWrapper variant="tiny" {...props} />,
    strong: props => <strong style={{ fontWeight: 600 }} {...props} />,
    em: props => <em {...props} />,
    ul: props => <ul {...props} />,
    ol: props => <ol {...props} />,
    li: props => <li style={{ listStyle: 'unset' }} {...props} />,
    br: () => <br />,
    span: null,
};
export default tagMap;
