"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _router = require("next/router");
var _react = _interopRequireDefault(require("react"));
var _reactErrorBoundary = require("react-error-boundary");
var _sentry = _interopRequireDefault(require("../utils/sentry"));
var _ErrorFallback = _interopRequireDefault(require("./ErrorFallback"));
var __jsx = _react.default.createElement;
const {
  captureException
} = (0, _sentry.default)();
const ErrorBoundary = ({
  children
}) => {
  const router = (0, _router.useRouter)();
  return __jsx(_reactErrorBoundary.ErrorBoundary, {
    resetKeys: [router.pathname],
    onError: captureException,
    FallbackComponent: _ErrorFallback.default
  }, children);
};
var _default = exports.default = ErrorBoundary;