"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unitTypeMessages = exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _Bedroom = _interopRequireDefault(require("../components/svg/Bedroom.svg"));
var _House = _interopRequireDefault(require("../components/svg/House.svg"));
var _RV = _interopRequireDefault(require("../components/svg/RV.svg"));
var _Tent = _interopRequireDefault(require("../components/svg/Tent.svg"));
var _Boat = _interopRequireDefault(require("../components/svg/Boat.svg"));
var _graphql = require("../generated/graphql");
var __jsx = _react.default.createElement;
const unitTypeMessages = exports.unitTypeMessages = (0, _reactIntl.defineMessages)({
  HOME: {
    "id": "9WmLj4",
    "defaultMessage": [{
      "type": 0,
      "value": "Vakantiehuis"
    }]
  },
  CAMPING_PITCH: {
    "id": "XjJcVS",
    "defaultMessage": [{
      "type": 0,
      "value": "Camping"
    }]
  },
  ROOM: {
    "id": "m6RIgQ",
    "defaultMessage": [{
      "type": 0,
      "value": "Kamer"
    }]
  },
  TENT: {
    "id": "13BMit",
    "defaultMessage": [{
      "type": 0,
      "value": "Tent"
    }]
  },
  BED: {
    "id": "f9yG77",
    "defaultMessage": [{
      "type": 0,
      "value": "Bed"
    }]
  },
  CAMPER_SPOT: {
    "id": "aSulw+",
    "defaultMessage": [{
      "type": 0,
      "value": "Camper"
    }]
  },
  BOAT: {
    "id": "1B5OHs",
    "defaultMessage": [{
      "type": 0,
      "value": "Boot"
    }]
  }
});

// TODO: kinda needless. just make a Record<RentalUnitTypeEnum, ReactNode> for the icons and use the messages object above directly.
const getUnitTypeConsts = intl => {
  const {
    formatMessage
  } = intl;
  return {
    HOME: {
      translation: formatMessage(unitTypeMessages[_graphql.RentalUnitTypeEnum.Home]),
      icon: __jsx(_House.default, null)
    },
    CAMPING_PITCH: {
      translation: formatMessage(unitTypeMessages[_graphql.RentalUnitTypeEnum.CampingPitch]),
      icon: __jsx(_Tent.default, null)
    },
    ROOM: {
      translation: formatMessage(unitTypeMessages[_graphql.RentalUnitTypeEnum.Room]),
      icon: __jsx(_Bedroom.default, null)
    },
    TENT: {
      translation: formatMessage(unitTypeMessages[_graphql.RentalUnitTypeEnum.Tent]),
      icon: __jsx(_Tent.default, null)
    },
    BED: {
      translation: formatMessage(unitTypeMessages[_graphql.RentalUnitTypeEnum.Bed]),
      icon: __jsx(_Bedroom.default, null)
    },
    CAMPER_SPOT: {
      translation: formatMessage(unitTypeMessages[_graphql.RentalUnitTypeEnum.CamperSpot]),
      icon: __jsx(_RV.default, null)
    },
    BOAT: {
      translation: formatMessage(unitTypeMessages[_graphql.RentalUnitTypeEnum.Boat]),
      icon: __jsx(_Boat.default, {
        width: "22px",
        height: "22px"
      })
    }
  };
};
var _default = exports.default = getUnitTypeConsts;