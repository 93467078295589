import React, { FC, SVGAttributes } from 'react';

const Phone: FC<React.PropsWithChildren<SVGAttributes<SVGElement>>> = props => (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0ZM9.008 6H6.656C6.47467 6 6.32 6.064 6.192 6.192C6.064 6.32 6 6.47467 6 6.656C6 8.192 6.29867 9.664 6.896 11.072C7.472 12.4267 8.28267 13.6267 9.328 14.672C10.3733 15.7173 11.5733 16.528 12.928 17.104C14.336 17.7013 15.808 18 17.344 18C17.5253 18 17.68 17.936 17.808 17.808C17.9104 17.7056 17.9718 17.5861 17.9923 17.4496L18 17.344V14.992C18 14.8107 17.936 14.656 17.808 14.528C17.68 14.4 17.5253 14.336 17.344 14.336C16.4693 14.336 15.68 14.2133 14.976 13.968C14.8587 13.9147 14.736 13.904 14.608 13.936C14.512 13.96 14.428 14.002 14.356 14.062L14.288 14.128L12.816 15.6C11.8773 15.1093 11.0293 14.4853 10.272 13.728C9.60933 13.0653 9.04875 12.3333 8.59025 11.5317L8.4 11.184L9.872 9.712C9.968 9.62667 10.0267 9.52 10.048 9.392C10.0693 9.264 10.064 9.14133 10.032 9.024C9.78667 8.32 9.664 7.53067 9.664 6.656C9.664 6.47467 9.6 6.32 9.472 6.192C9.3696 6.0896 9.25013 6.02816 9.1136 6.00768L9.008 6Z"
            fill="#223260"
        />
    </svg>
);

export default Phone;
