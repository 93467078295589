"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("react");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const defaultOptions = {
  behavior: 'smooth',
  block: 'nearest'
};
const useScrollIntoView = (ref, scrollTrigger = true, scrollBackTrigger = false, options = defaultOptions) => {
  const scrollIntoViewOptions = _objectSpread(_objectSpread({}, defaultOptions), options);
  const currentTop = (0, _react.useRef)(0);
  (0, _react.useEffect)(() => {
    if (scrollTrigger) {
      currentTop.current = window.pageYOffset;
      ref.current?.scrollIntoView(scrollIntoViewOptions);
    }
    if (scrollBackTrigger) {
      window.scrollTo({
        behavior: scrollIntoViewOptions.behavior,
        top: currentTop.current
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollBackTrigger, scrollTrigger]);
};
var _default = exports.default = useScrollIntoView;