"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setCartId = exports.getCartIdFromSession = exports.getCartIdFromLocal = exports.getCartId = exports.clearCartId = void 0;
var _storage = require("../constants/storage");
var _getStorageWithExpiry = _interopRequireDefault(require("./getStorageWithExpiry"));
/* eslint-disable import/prefer-default-export */

const getCartIdFromLocal = () => (0, _getStorageWithExpiry.default)('local')?.getItem(_storage.CART_KEY);
exports.getCartIdFromLocal = getCartIdFromLocal;
const getCartIdFromSession = () => (0, _getStorageWithExpiry.default)('session')?.getItem(_storage.CART_KEY);
exports.getCartIdFromSession = getCartIdFromSession;
const getCartId = disableStorageFallback => {
  const sessionCartId = getCartIdFromSession();
  const localeCartId = getCartIdFromLocal();
  if (disableStorageFallback) {
    return sessionCartId;
  }
  return sessionCartId ?? localeCartId;
};
exports.getCartId = getCartId;
const setCartId = cartId => {
  (0, _getStorageWithExpiry.default)('session')?.setItem(_storage.CART_KEY, cartId);
  (0, _getStorageWithExpiry.default)('local')?.setItem(_storage.CART_KEY, cartId);
};
exports.setCartId = setCartId;
const clearCartId = () => {
  const storageUtils = (0, _getStorageWithExpiry.default)('session');
  storageUtils?.removeItem(_storage.CART_KEY);
  storageUtils?.removeItem('deskCart'); // is this still being used?
  (0, _getStorageWithExpiry.default)('local')?.removeItem(_storage.CART_KEY);
};
exports.clearCartId = clearCartId;