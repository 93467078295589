"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAccommodationTypeOptions = void 0;
var _accommodationMessages = _interopRequireDefault(require("../constants/accommodationMessages"));
// eslint-disable-next-line import/prefer-default-export
const getAccommodationTypeOptions = (intl, brandConfig) => {
  const {
    formatMessage
  } = intl;
  return brandConfig.accommodationTypes.map(({
    message,
    value
  }) => ({
    label: formatMessage(_accommodationMessages.default[message]),
    value
  }));
};
exports.getAccommodationTypeOptions = getAccommodationTypeOptions;