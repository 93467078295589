import { Locale } from '@oberoninternal/travelbase-website/dist/entities/Locale';

// TODO: move this to travelbase website repo
const getAgendaPageSlugByLocale = (locale: Locale): string => {
    switch (locale) {
        case 'nl':
            return 'agenda';
        case 'en':
            return 'whats-on';
        case 'de':
        default:
            return 'veranstaltungsuebersicht';
    }
};

export default getAgendaPageSlugByLocale;
