"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _formik = require("formik");
var _lodash = _interopRequireDefault(require("lodash.isequal"));
var _react = _interopRequireWildcard(require("react"));
var _usePrevious = _interopRequireDefault(require("../hooks/usePrevious"));
const _excluded = ["values", "dirty"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const AutoSave = ({
  onSave,
  enableReinitialize
}) => {
  const _useFormikContext = (0, _formik.useFormikContext)(),
    {
      values,
      dirty
    } = _useFormikContext,
    rest = (0, _objectWithoutProperties2.default)(_useFormikContext, _excluded);
  const previousValues = (0, _usePrevious.default)(values);
  (0, _react.useEffect)(() => {
    if ((enableReinitialize && dirty || !enableReinitialize) && previousValues && Object.keys(previousValues).length && !(0, _lodash.default)(previousValues, values)) {
      onSave(values, rest);
    }
  }, [dirty, enableReinitialize, onSave, previousValues, rest, values]);
  return __jsx(_react.default.Fragment, null);
};
var _default = exports.default = AutoSave;