import React from 'react';

const StoreIcon = () => (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.45833 13.0833C6.49954 13.0833 6.53982 13.0956 6.57408 13.1184C6.60834 13.1413 6.63504 13.1739 6.65081 13.2119C6.66658 13.25 6.6707 13.2919 6.66266 13.3323C6.65463 13.3727 6.63478 13.4098 6.60565 13.439C6.57651 13.4681 6.53939 13.488 6.49898 13.496C6.45856 13.504 6.41668 13.4999 6.37861 13.4841C6.34054 13.4684 6.308 13.4417 6.28511 13.4074C6.26222 13.3731 6.25 13.3329 6.25 13.2917C6.25 13.2364 6.27195 13.1834 6.31102 13.1444C6.35009 13.1053 6.40308 13.0833 6.45833 13.0833Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.5416 13.0833C13.5828 13.0833 13.6231 13.0956 13.6573 13.1184C13.6916 13.1413 13.7183 13.1739 13.7341 13.2119C13.7498 13.25 13.754 13.2919 13.7459 13.3323C13.7379 13.3727 13.718 13.4098 13.6889 13.439C13.6598 13.4681 13.6226 13.488 13.5822 13.496C13.5418 13.504 13.4999 13.4999 13.4619 13.4841C13.4238 13.4684 13.3913 13.4417 13.3684 13.4074C13.3455 13.3731 13.3333 13.3329 13.3333 13.2917C13.3333 13.2364 13.3552 13.1834 13.3943 13.1444C13.4333 13.1053 13.4863 13.0833 13.5416 13.0833Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.50078 12.6C6.63923 12.6 6.77457 12.6411 6.88968 12.718C7.00479 12.7949 7.09452 12.9042 7.1475 13.0321C7.20048 13.16 7.21434 13.3008 7.18733 13.4366C7.16032 13.5723 7.09365 13.6971 6.99576 13.795C6.89786 13.8929 6.77313 13.9595 6.63734 13.9865C6.50156 14.0136 6.36081 13.9997 6.2329 13.9467C6.10499 13.8937 5.99567 13.804 5.91875 13.6889C5.84183 13.5738 5.80078 13.4384 5.80078 13.3C5.80078 13.1143 5.87453 12.9363 6.00581 12.805C6.13708 12.6737 6.31513 12.6 6.50078 12.6Z"
            fill="currentColor"
        />
        <path
            d="M13.5516 12.6C13.69 12.6 13.8253 12.6411 13.9405 12.718C14.0556 12.7949 14.1453 12.9042 14.1983 13.0321C14.2513 13.16 14.2651 13.3008 14.2381 13.4366C14.2111 13.5723 14.1444 13.6971 14.0465 13.795C13.9486 13.8929 13.8239 13.9595 13.6881 13.9865C13.5523 14.0136 13.4116 13.9997 13.2837 13.9467C13.1558 13.8937 13.0465 13.804 12.9695 13.6889C12.8926 13.5738 12.8516 13.4384 12.8516 13.3C12.8516 13.1143 12.9253 12.9363 13.0566 12.805C13.1879 12.6737 13.3659 12.6 13.5516 12.6Z"
            fill="currentColor"
        />
        <path
            d="M13.1184 15.5833C12.7758 16.0964 12.312 16.517 11.7679 16.8079C11.2239 17.0988 10.6165 17.251 9.99961 17.251C9.38269 17.251 8.77531 17.0988 8.23129 16.8079C7.68727 16.517 7.22342 16.0964 6.88086 15.5833"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.25 8.91667V5.16667C6.25 4.1721 6.64509 3.21828 7.34835 2.51502C8.05161 1.81175 9.00544 1.41667 10 1.41667C10.9946 1.41667 11.9484 1.81175 12.6516 2.51502C13.3549 3.21828 13.75 4.1721 13.75 5.16667V8.91667"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17.8883 18.0833L17.1383 7.25C17.1231 7.02405 17.0226 6.81231 16.8573 6.65761C16.6919 6.50291 16.4739 6.41679 16.2475 6.41666H3.74998C3.52352 6.41679 3.30556 6.50291 3.14018 6.65761C2.97481 6.81231 2.87435 7.02405 2.85914 7.25L2.10914 18.0833C2.09025 18.3611 2.08081 18.6389 2.08081 18.9167C2.08081 19.3587 2.25641 19.7826 2.56897 20.0952C2.88153 20.4077 3.30545 20.5833 3.74748 20.5833H16.2475C16.6895 20.5833 17.1134 20.4077 17.426 20.0952C17.7385 19.7826 17.9141 19.3587 17.9141 18.9167C17.9158 18.6389 17.9072 18.3611 17.8883 18.0833Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default StoreIcon;
