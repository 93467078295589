"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  useQuery: true,
  useMutation: true
};
exports.useQuery = exports.useMutation = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var Apollo = _interopRequireWildcard(require("@apollo/client"));
Object.keys(Apollo).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === Apollo[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return Apollo[key];
    }
  });
});
var _omitDeepLodash = _interopRequireDefault(require("omit-deep-lodash"));
var _react = require("react");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * This file is mostly a re-export of the apollo hooks, with the minor difference that it throws
 * an error if any of the hooks have errors in their results. It also removes the __typename property
 * from mutation variables for developer convenience
 *
 */

const useQuery = (query, options) => {
  const opts = (0, _react.useMemo)(() => typeof window === 'undefined' ? options : _objectSpread({
    ssr: false
  }, options), [options]);
  const result = Apollo.useQuery(query, opts);
  if (result.error) {
    throw result.error;
  }

  // https://github.com/apollographql/apollo-client/issues/7038
  result.data = !result.loading ? result.data ?? result.previousData : result.previousData;
  return result;
};
exports.useQuery = useQuery;
const useMutation = (query, options) => {
  const [mutationFunction, result] = Apollo.useMutation(query, options);

  // we patch the real mutate func with our own, so we can extract the variables from it, if it gets called.
  const patched = funcOptions => mutationFunction(_objectSpread(_objectSpread({}, funcOptions), {}, {
    variables: (0, _omitDeepLodash.default)(funcOptions?.variables, ['__typename'])
  }));
  if (result.error && options?.errorPolicy !== 'ignore') {
    result.error.name = 'MutationError';
    throw result.error;
  }
  return [patched, result];
};
exports.useMutation = useMutation;