"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.FormBox = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _grid = require("@rebass/grid");
var _Label = require("@oberoninternal/travelbase-ds/components/primitive/Label");
var _customizationClassNames = require("../../constants/customizationClassNames");
const _excluded = ["children", "label", "secondLabel", "id", "variant", "dataCy", "overwriteLabelColor"];
var __jsx = _react.default.createElement;
const Fieldset = _ref => {
  let {
      children,
      label,
      secondLabel,
      id,
      variant,
      dataCy,
      overwriteLabelColor
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return __jsx(FormBox, (0, _extends2.default)({
    "data-cy": dataCy
  }, props), (label || secondLabel) && __jsx(SuperScript, {
    className: _customizationClassNames.CLASS_FIELDSET_LABEL,
    overwriteLabelColor: overwriteLabelColor
  }, label && __jsx(_Label.Label, {
    htmlFor: id,
    variant: variant
  }, label), secondLabel && __jsx(_Label.Label, {
    htmlFor: id,
    variant: variant
  }, secondLabel)), children);
};
var _default = exports.default = Fieldset;
const SuperScript = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "Fieldset__SuperScript",
  componentId: "dlpdw1-0"
})(["margin-bottom:", ";position:relative;color:", ";", "{font-weight:600;", ";@media screen and (max-width:", "){font-size:14px;}:nth-of-type(2){position:absolute;left:50%;@media screen and (min-width:", "){left:50%;}}}"], ({
  theme
}) => theme.spacing['30_Small'], ({
  theme
}) => theme.colors.neutral['90'], _Label.Label, ({
  overwriteLabelColor
}) => overwriteLabelColor && `color: ${overwriteLabelColor}`, ({
  theme
}) => theme.mediaQueries.m, ({
  theme
}) => theme.mediaQueries.m);
const FormBox = exports.FormBox = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "Fieldset__FormBox",
  componentId: "dlpdw1-1"
})(["margin-bottom:2rem;&:focus-within{z-index:10;}@media screen and (max-width:", "){margin-left:0;}"], ({
  theme
}) => theme.mediaQueries.s);