"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fragment = exports.default = void 0;
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _grid = require("@rebass/grid");
var _client = require("@apollo/client");
var _router = require("next/router");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _getUnitTypeConsts = _interopRequireDefault(require("../constants/getUnitTypeConsts"));
var _graphql = require("../generated/graphql");
var _createPlaceholder = _interopRequireDefault(require("../utils/createPlaceholder"));
var _getTripGroupText = _interopRequireDefault(require("../utils/getTripGroupText"));
var _Stack = _interopRequireDefault(require("./Stack"));
var _SummaryBarItem = _interopRequireWildcard(require("./SummaryBarItem"));
var _useGetPageSlugByLocale = _interopRequireDefault(require("../hooks/useGetPageSlugByLocale"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const fragment = exports.fragment = (0, _client.gql)`
    fragment OrderSummaryItemBooking on Booking {
        rentalUnit {
            id
            code
            name
            slug
            maxOccupancy
            minOccupancy
            petsAllowed
            accommodation {
                id
                hasPublicPage
                name
                checkInEndTime
                checkInStartTime
                checkOutTime
                type
            }
            type
            jpegThumbnail: listImage {
                transform(config: TILE, format: JPEG) {
                    ...Transform
                }
            }
            webpThumbnail: listImage {
                transform(config: TILE, format: WEBP) {
                    ...Transform
                }
            }
        }
    }
`;
const SummaryBarItemBooking = ({
  item,
  exactLink = false,
  updating,
  hidePrices,
  isClickable = true
}) => {
  const intl = (0, _reactIntl.useIntl)();
  const {
    getPageSlugByLocale
  } = (0, _useGetPageSlugByLocale.default)();
  const accommodationSlug = getPageSlugByLocale('ACCOMMODATION');
  const {
    push
  } = (0, _router.useRouter)();
  const {
    rentalUnit,
    amountAdults,
    amountBabies,
    amountChildren,
    amountYouths,
    amountPets,
    arrivalDate,
    departureDate
  } = item;
  if (!rentalUnit) {
    return null;
  }
  const {
    name,
    code,
    slug,
    accommodation: {
      hasPublicPage,
      name: accommodationName,
      checkInEndTime,
      checkInStartTime,
      checkOutTime
    },
    type,
    jpegThumbnail,
    webpThumbnail
  } = rentalUnit;
  const unitType = (0, _getUnitTypeConsts.default)(intl)[type].translation;
  const guestsText = (0, _getTripGroupText.default)(intl, {
    amountAdults,
    amountBabies,
    amountChildren,
    amountYouths,
    amountPets
  }, true);
  return __jsx(_SummaryBarItem.default, {
    updating: updating,
    item: item,
    thumbnail: __jsx(_SummaryBarItem.SummaryBarItemThumbnail, {
      isClickable: isClickable,
      onClick: () => {
        if (isClickable) {
          push('/[accommodation]/[slug]', {
            pathname: `/${accommodationSlug}/${slug}`,
            query: exactLink ? {
              arrivalDate,
              departureDate,
              amountAdults,
              amountBabies,
              amountChildren,
              amountYouths,
              amountPets
            } : undefined
          });
        }
      },
      title: name,
      thumbnail: {
        sources: [{
          srcSet: webpThumbnail.transform?.srcSet,
          type: 'image/webp'
        }, {
          srcSet: jpegThumbnail.transform?.srcSet,
          type: 'image/jpeg'
        }],
        fallback: {
          src: jpegThumbnail.transform?.src
        },
        placeholder: (0, _createPlaceholder.default)(webpThumbnail.transform?.placeholder)
      },
      strapline: hasPublicPage && accommodationName || undefined,
      subTitle: __jsx(_SummaryBarItem.SummaryBarItemBody, {
        style: {
          textTransform: 'capitalize'
        },
        title: unitType
      }, unitType)
    })
  }, __jsx(_SummaryBarItem.SummaryBarItemField, {
    title: __jsx(_reactIntl.FormattedMessage, {
      id: "B0YRgl",
      defaultMessage: [{
        "type": 0,
        "value": "# Accommodatie"
      }]
    })
  }, __jsx(_Body.default, {
    variant: "small"
  }, code)), __jsx(_SummaryBarItem.SummaryBarItemField, {
    title: __jsx(_reactIntl.FormattedMessage, {
      id: "P0UVuU",
      defaultMessage: [{
        "type": 0,
        "value": "Reisgezelschap"
      }]
    })
  }, __jsx(_Body.default, {
    variant: "small"
  }, guestsText)), __jsx(_SummaryBarItem.SummaryBarItemField, {
    title: __jsx(_reactIntl.FormattedMessage, {
      id: "VT/CvC",
      defaultMessage: [{
        "type": 0,
        "value": "Aankomst"
      }]
    })
  }, __jsx(_Body.default, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedDate, {
    value: new Date(arrivalDate),
    format: "DATERANGEPICKER"
  }), ' ', checkInStartTime === checkInEndTime ? checkInStartTime : __jsx(_react.default.Fragment, null, __jsx(_reactIntl.FormattedMessage, {
    id: "vkLXHc",
    defaultMessage: [{
      "type": 0,
      "value": "tussen"
    }]
  }), " ", checkInStartTime, " ", ' - ', checkInEndTime), ' ', __jsx(_reactIntl.FormattedMessage, {
    id: "ViGBFO",
    defaultMessage: [{
      "type": 0,
      "value": "uur"
    }]
  }))), __jsx(_SummaryBarItem.SummaryBarItemField, {
    title: __jsx(_reactIntl.FormattedMessage, {
      id: "tMip3S",
      defaultMessage: [{
        "type": 0,
        "value": "Vertrek"
      }]
    })
  }, __jsx(_Body.default, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedDate, {
    value: new Date(departureDate),
    format: "DATERANGEPICKER"
  }), ' ', __jsx(_reactIntl.FormattedMessage, {
    id: "GKy00d",
    defaultMessage: [{
      "type": 0,
      "value": "voor"
    }]
  }), " ", `${checkOutTime}`, ' ')), !hidePrices && __jsx(_SummaryBarItem.SummaryBarItemField, {
    title: __jsx(_reactIntl.FormattedMessage, {
      id: "IRgtJJ",
      defaultMessage: [{
        "type": 0,
        "value": "Prijsopbouw"
      }]
    })
  }, __jsx(_Stack.default, {
    spacing: 3
  }, item.priceLines.filter(line => line.category !== _graphql.PriceLineCategoryEnum.NotInTotal).map(line => __jsx(_grid.Flex, {
    key: line.label,
    justifyContent: "space-between"
  }, __jsx(_Body.default, {
    variant: "small"
  }, line.label), __jsx(_Body.default, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedNumber, {
    value: line.totalPrice,
    format: "EUR"
  })))))));
};
var _default = exports.default = SummaryBarItemBooking;