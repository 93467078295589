"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _grid = require("@rebass/grid");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styledSystem = require("styled-system");
const cell = (0, _styledSystem.compose)(_styledSystem.gridColumn, _styledSystem.gridRow);
const Cell = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "Cell",
  componentId: "n86noy-0"
})(cell);
var _default = exports.default = Cell;