"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _add = _interopRequireDefault(require("date-fns/add"));
var _format = _interopRequireDefault(require("date-fns/format"));
var _RadioLabel = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/form/RadioLabel"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _grid = require("@rebass/grid");
var _getYear = _interopRequireDefault(require("date-fns/getYear"));
var _formik = require("formik");
var _reactIntl = require("react-intl");
var __jsx = _react.default.createElement;
const getTheNextMonths = () => {
  const now = new Date();
  const months = [{
    name: (0, _format.default)(new Date(), 'dd MMMM yyyy'),
    id: (0, _format.default)(new Date(), 'MM-yyyy'),
    date: now
  }];
  for (let i = 0; i <= 23; i += 1) {
    const date = (0, _add.default)(new Date(), {
      months: i + 1
    });
    months.push({
      name: (0, _format.default)(date, 'MMMM yyyy'),
      id: (0, _format.default)(date, 'MM-yyyy'),
      date
    });
  }
  return months;
};
const MonthSelect = () => {
  const months = getTheNextMonths();
  const {
    values,
    setFieldValue
  } = (0, _formik.useFormikContext)();
  const {
    formatDate
  } = (0, _reactIntl.useIntl)();
  return __jsx(_react.default.Fragment, null, months.map(month => {
    const monthName = formatDate(month.date, {
      month: 'long'
    });
    const yearName = (0, _getYear.default)(month.date);
    return __jsx(Month, {
      key: month.id
    }, __jsx(MonthRadio, {
      required: true,
      checked: values.booking.flexibleMonth === month.id,
      onChange: () => setFieldValue('booking.flexibleMonth', month.id),
      name: "month",
      id: month.id,
      variant: "primary"
    }, __jsx(MonthWrapper, {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    }, __jsx(MonthLabel, null, monthName), yearName)));
  }));
};
var _default = exports.default = MonthSelect;
const Month = _styledComponents.default.div.withConfig({
  displayName: "MonthSelect__Month",
  componentId: "sc-18i37v7-0"
})(["scroll-snap-align:start;position:relative;overflow:hidden;& + &{margin-left:1.6rem;}"]);
const MonthRadio = (0, _styledComponents.default)(_RadioLabel.default).withConfig({
  displayName: "MonthSelect__MonthRadio",
  componentId: "sc-18i37v7-1"
})(["label{border-radius:8px !important;padding:1.6rem 0.9rem !important;min-width:12rem;min-height:12rem;display:flex;align-items:center;justify-content:center;}"]);
const MonthLabel = _styledComponents.default.span.withConfig({
  displayName: "MonthSelect__MonthLabel",
  componentId: "sc-18i37v7-2"
})(["font-weight:bold;"]);
const MonthWrapper = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "MonthSelect__MonthWrapper",
  componentId: "sc-18i37v7-3"
})([""]);