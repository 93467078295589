import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

interface Props {
    open: boolean;
    openMenu: (open: boolean) => void;
}

const HamburgerMenu = ({ open, openMenu, ...rest }: Props) => {
    const { formatMessage } = useIntl();
    return (
        <Wrapper open={open} {...rest}>
            <button
                className={`hamburger hamburger--spin ${open ? 'is-active' : ''}`}
                onClick={() => {
                    openMenu(!open);
                }}
                aria-label={formatMessage({ defaultMessage: 'Menu' })}
                type="button"
            >
                <span className="hamburger-box">
                    <span className="hamburger-inner" />
                </span>
            </button>
        </Wrapper>
    );
};
export default HamburgerMenu;

const Wrapper = styled.div<{ open: boolean }>`
    cursor: pointer;
    padding-top: 0.9rem;
    width: 2.4rem;
    margin-right: -0.8rem;

    .hamburger {
        display: inline-block;
        cursor: pointer;
        transition-property: opacity, filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        background-color: transparent;
        border: 0;
        margin: 0;
        overflow: visible;

        &:hover,
        &.is-active:hover {
            opacity: 1;
        }

        .hamburger-box {
            width: 2.4rem;
            height: 2.4rem;
            display: inline-block;
            position: relative;
        }

        &.is-active .hamburger-inner,
        &.is-active .hamburger-inner::before,
        &.is-active .hamburger-inner::after {
            width: 1.8rem;
            background-color: ${({ theme }) => theme.colors.primary['80']};
        }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
        width: 1.8rem;
        height: 0.2rem;
        border-radius: 5rem;
        background-color: ${({ theme }) => theme.colors.primary['80']};
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    .hamburger-inner {
        top: 50%;
        margin-top: -2px;
        width: 1.3rem;
        display: flex;
        flex-direction: row-reverse;
        right: 0.4rem;
    }

    .hamburger-inner::before,
    .hamburger-inner::after {
        content: '';
        display: block;
    }
    .hamburger-inner::before {
        top: -7px;
    }
    .hamburger-inner::after {
        bottom: -7px;
    }

    /*
    * Spin
    */
    .hamburger--spin .hamburger-inner {
        transition-duration: 0.22s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    .hamburger--spin .hamburger-inner::before {
        transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    }
    .hamburger--spin .hamburger-inner::after {
        transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    .hamburger--spin.is-active .hamburger-inner {
        transform: rotate(225deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    .hamburger--spin.is-active .hamburger-inner::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    }
    .hamburger--spin.is-active .hamburger-inner::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
`;
