"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _validInterval = _interopRequireDefault(require("@oberoninternal/travelbase-ds/utils/validInterval"));
var _grid = require("@rebass/grid");
var _addDays = _interopRequireDefault(require("date-fns/addDays"));
var _addMonths = _interopRequireDefault(require("date-fns/addMonths"));
var _differenceInCalendarMonths = _interopRequireDefault(require("date-fns/differenceInCalendarMonths"));
var _react = _interopRequireWildcard(require("react"));
var _reactIntersectionObserver = _interopRequireDefault(require("react-intersection-observer"));
var _reactIntl = require("react-intl");
var _reactSpring = require("react-spring");
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _TenantContext = require("../../../context/TenantContext");
var _isDate = _interopRequireDefault(require("../../../utils/isDate"));
var _StickyBar = _interopRequireDefault(require("../../StickyBar"));
var _Context = require("./Context");
var _Month = _interopRequireDefault(require("./Month"));
var _ToggleSwitchWithText = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/form/ToggleSwitchWithText"));
var _FlexibleDateContent = _interopRequireDefault(require("../../FlexibleDateContent"));
var _formik = require("formik");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const getInitialMonthsAmount = period => {
  if ((0, _isDate.default)(period)) {
    return period.getMonth() + 1;
  }
  if (period?.start) {
    return new Date(period.start).getMonth() + 1;
  }
  return 3;
};
const getHelpMessage = (intl, period, singleDate) => {
  if (singleDate) {
    return intl.formatMessage({
      "id": "YIHi0r",
      "defaultMessage": [{
        "type": 0,
        "value": "Kies een datum"
      }]
    });
  }
  if (!period) {
    return intl.formatMessage({
      "id": "7PEkrd",
      "defaultMessage": [{
        "type": 0,
        "value": "Kies je aankomstdatum"
      }]
    });
  }
  if (!(0, _isDate.default)(period) && (0, _validInterval.default)(period)) {
    return intl.formatMessage({
      "id": "PCQh9C",
      "defaultMessage": [{
        "type": 0,
        "value": "Van "
      }, {
        "type": 1,
        "value": "startDate"
      }, {
        "type": 0,
        "value": " t/m "
      }, {
        "type": 1,
        "value": "endDate"
      }]
    }, {
      startDate: intl.formatDate(period.start, {
        format: 'WEEKDAYMONTH'
      }),
      endDate: intl.formatDate(period.end, {
        format: 'WEEKDAYMONTH'
      })
    });
  }
  return intl.formatMessage({
    "id": "/KeF4c",
    "defaultMessage": [{
      "type": 0,
      "value": "Kies je vertrekdatum"
    }]
  });
};
const DatepickerModal = ({
  hideHelper = false,
  helperChildren,
  hasFlexibleDates
}) => {
  const {
    datepicker: [{
      open,
      period,
      selecting
    }, {
      onClick,
      onHover,
      sesame
    }],
    computeDayProps,
    locale,
    singleDate = false
  } = (0, _Context.useDatepickerContext)();
  const {
    0: monthsAmount,
    1: setMonthsAmount
  } = (0, _react.useState)(() => getInitialMonthsAmount(period));
  const maximumReached = (0, _differenceInCalendarMonths.default)((0, _addMonths.default)(new Date(), monthsAmount), new Date()) <= 24;
  const containerRef = (0, _react.useRef)(null);
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const onChange = (0, _react.useCallback)((inView, index) => {
    if (inView && index === monthsAmount - 1 && maximumReached) {
      setMonthsAmount(prev => prev + 1);
    }
  }, [maximumReached, monthsAmount]);
  const intl = (0, _reactIntl.useIntl)();
  const helpMessage = hideHelper ? null : getHelpMessage(intl, period, singleDate);
  const {
    values,
    setFieldValue
  } = (0, _formik.useFormikContext)();
  const helperContent = __jsx(StyledStickyBar, {
    open: open,
    wrapInPortal: true
  }, __jsx(_grid.Flex, {
    justifyContent: "center",
    alignItems: "center",
    width: 1,
    flexDirection: "column"
  }, helpMessage && __jsx(_Body.default, {
    variant: "small"
  }, helpMessage), helperChildren && __jsx(_react.default.Fragment, null, helperChildren)));
  return __jsx(_react.default.Fragment, null, __jsx(StyledModal, {
    config: _objectSpread(_objectSpread({}, _reactSpring.config.gentle), {}, {
      duration: 200
    }),
    open: open,
    onClose: () => {
      sesame('close');
    }
  }, __jsx(Container, {
    hideScrollbar: brandConfig.searchBox?.hideScrollbar,
    ref: containerRef,
    style: {
      overflow: 'auto',
      height: '100%'
    }
  }, hasFlexibleDates ? __jsx(_react.default.Fragment, null, __jsx(ToggleSwitchWrapper, {
    px: [0, 0, 16]
  }, __jsx(_ToggleSwitchWithText.default, {
    name: "dateSwitchMobile",
    active: values.booking.type === 'static' ? 'static-mobile' : 'flexible-mobile',
    setActive: key => setFieldValue('booking.type', key === 'flexible-mobile' ? 'flexible-random' : 'static'),
    options: [{
      key: 'static-mobile',
      label: __jsx(_reactIntl.FormattedMessage, {
        id: "S7bCwP",
        defaultMessage: [{
          "type": 0,
          "value": "Datums kiezen"
        }]
      })
    }, {
      key: 'flexible-mobile',
      label: __jsx(_reactIntl.FormattedMessage, {
        id: "VNRBWz",
        defaultMessage: [{
          "type": 0,
          "value": "Flexibel zoeken"
        }]
      })
    }]
  })), __jsx(_react.default.Fragment, null, values?.booking?.type === 'static' && monthsAmount && new Array(monthsAmount).fill(null).map((_, i) => {
    const correctedPeriod = (0, _isDate.default)(period) ? {
      start: period,
      end: (0, _addDays.default)(period, 1)
    } : period;
    return __jsx(_reactIntersectionObserver.default, {
      key: i,
      threshold: 0.3,
      triggerOnce: true,
      onChange: inView => onChange(inView, i)
    }, ({
      ref
    }) => __jsx(_Month.default, {
      isSelecting: selecting,
      onDayMouseEnter: onHover,
      onDayClick: onClick,
      period: correctedPeriod,
      date: (0, _addMonths.default)(new Date(), i),
      computeDayProps: computeDayProps,
      ref: ref,
      locale: locale
    }));
  }), values?.booking?.type !== 'static' && __jsx(_FlexibleDateContent.default, null))) : __jsx(_react.default.Fragment, null, monthsAmount && new Array(monthsAmount).fill(null).map((_, i) => {
    const correctedPeriod = (0, _isDate.default)(period) ? {
      start: period,
      end: (0, _addDays.default)(period, 1)
    } : period;
    return __jsx(_reactIntersectionObserver.default, {
      key: i,
      threshold: 0.3,
      triggerOnce: true,
      onChange: inView => onChange(inView, i)
    }, ({
      ref
    }) => __jsx(_Month.default, {
      isSelecting: selecting,
      onDayMouseEnter: onHover,
      onDayClick: onClick,
      period: correctedPeriod,
      date: (0, _addMonths.default)(new Date(), i),
      computeDayProps: computeDayProps,
      ref: ref,
      locale: locale
    }));
  })))), !hideHelper && (hasFlexibleDates ? values?.booking?.type === 'static' && helperContent : helperContent));
};
var _default = exports.default = DatepickerModal;
const StyledStickyBar = (0, _styledComponents.default)(_StickyBar.default).withConfig({
  displayName: "DatepickerModal__StyledStickyBar",
  componentId: "sc-1x8lsr3-0"
})(["transform:translateY(", ");transition:transform 200ms ease-in-out;padding-top:2rem;padding-bottom:2rem;"], ({
  open
}) => open ? '0px' : '100%');
const StyledModal = (0, _styledComponents.default)(_Modal.default).withConfig({
  displayName: "DatepickerModal__StyledModal",
  componentId: "sc-1x8lsr3-1"
})(["[data-reach-dialog-content]{position:relative;overflow:unset;padding:1.6rem 0.8rem 0 0.8rem;}"]);
const Container = _styledComponents.default.div.withConfig({
  displayName: "DatepickerModal__Container",
  componentId: "sc-1x8lsr3-2"
})(["", ";@media screen and (max-width:", "px){flex:1;}"], ({
  hideScrollbar
}) => hideScrollbar && (0, _styledComponents.css)(["-webkit-overflow-scrolling:touch;&::-webkit-scrollbar{display:none;}scrollbar-width:0;-ms-overflow-style:none;"]), ({
  theme
}) => theme.mediaQueriesValues.s + 1);
const ToggleSwitchWrapper = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "DatepickerModal__ToggleSwitchWrapper",
  componentId: "sc-1x8lsr3-3"
})(["text-align:center;margin-top:4rem;margin-bottom:1.6rem;"]);