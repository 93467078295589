import React from 'react';

const Instagram = ({ ...rest }) => (
    <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <g fill="currentColor" fillRule="evenodd">
            <path d="M7.498.005C5.46.005 5.206.013 4.406.05c-.798.036-1.343.163-1.82.348a3.675 3.675 0 00-1.328.865 3.675 3.675 0 00-.864 1.328c-.186.477-.312 1.022-.349 1.82C.01 5.211 0 5.466 0 7.502c0 2.037.009 2.292.045 3.092.037.798.163 1.343.349 1.82.191.493.448.91.864 1.328.417.416.835.673 1.328.864.477.186 1.022.312 1.82.349.8.036 1.055.045 3.092.045 2.036 0 2.291-.009 3.09-.045.799-.037 1.344-.163 1.82-.349a3.676 3.676 0 001.329-.864c.417-.417.673-.835.865-1.328.185-.477.312-1.022.348-1.82.037-.8.045-1.055.045-3.092 0-2.036-.008-2.291-.045-3.09-.036-.799-.163-1.344-.348-1.82a3.676 3.676 0 00-.865-1.329 3.675 3.675 0 00-1.328-.865c-.477-.185-1.022-.312-1.82-.348-.8-.037-1.055-.045-3.091-.045zm0 1.35c2.002 0 2.239.008 3.03.044.73.034 1.127.156 1.392.259.35.136.6.298.862.56.262.263.424.513.56.862.103.265.225.662.259 1.393.036.79.043 1.027.043 3.03 0 2.001-.007 2.238-.043 3.03-.034.73-.156 1.127-.259 1.391-.136.35-.298.6-.56.862a2.323 2.323 0 01-.862.561c-.265.103-.662.225-1.393.258-.79.036-1.027.044-3.03.044-2.001 0-2.239-.008-3.03-.044-.73-.033-1.127-.155-1.391-.258-.35-.136-.6-.298-.862-.56a2.323 2.323 0 01-.561-.863c-.103-.264-.225-.66-.258-1.392-.036-.79-.044-1.028-.044-3.03s.008-2.239.044-3.03c.033-.73.155-1.127.258-1.392.136-.35.298-.6.56-.862.263-.262.513-.424.863-.56.264-.103.66-.225 1.392-.259.79-.036 1.028-.043 3.03-.043z" />
            <path d="M7.707 9.778a2.5 2.5 0 110-4.998 2.5 2.5 0 010 4.998zm-3.85-2.5a3.85 3.85 0 107.7 0 3.85 3.85 0 00-7.7 0zM11.614 4.37a.9.9 0 110-1.799.9.9 0 010 1.8z" />
        </g>
    </svg>
);

export default Instagram;
