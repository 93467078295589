/* eslint-disable */
/* tslint:disable */
import { disableFragmentWarnings } from '@apollo/client';
disableFragmentWarnings();
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: any;
    Number: any;
    QueryArgument: any;
};

/**
 *   An Accommodation is a representation of a location which can contain one or more RentalUnits.
 *  For example: a hotel would be represented by an Accommodation with each room type represented by a separate RentalUnit.
 */
export type Accommodation = {
    __typename?: 'Accommodation';
    /**   The address where this Accommodation is located. */
    address: Address;
    /**   The attributes assigned to this Accommodation, organized into categories. */
    attributeCategories: Array<AttributeCategory>;
    /**   Indicates the Travelbase brand this Accommodation belongs to (used on aggregate sites). */
    brand: Scalars['String'];
    /**
     *   The time at which check-in closes.
     *  Formatted as HH:MM.
     */
    checkInEndTime: Scalars['String'];
    /**
     *   The time at which check-in opens.
     *  Formatted as HH:MM.
     */
    checkInStartTime: Scalars['String'];
    /**
     *   The time before which guests have to check out.
     *  Formatted as HH:MM.
     */
    checkOutTime: Scalars['String'];
    collapseInSearch: Scalars['Boolean'];
    /**   The GPS coordinates of this Accommodation. */
    coordinates: LatLon;
    /**
     *   A general description of the Accommodation.
     *  This value is language dependent.
     */
    descriptionGeneral?: Maybe<Scalars['String']>;
    /**
     *   A description of the location this Accommodation is in.
     *  This value is language dependent.
     */
    descriptionLocation: Scalars['String'];
    /**   Indicates whether this Accommodation should have it's own page on the website, otherwise only the pages of the underlying RentalUnits exist. */
    hasPublicPage: Scalars['Boolean'];
    /**   Unique ID representing this Accommodation. */
    id: Scalars['ID'];
    /**   Image gallery for the Accommodation. */
    images: Array<AccommodationImage>;
    /**   The main (hero) image for the Accommodation. */
    mainImage?: Maybe<GenericImage>;
    /**   The highest max occupancy of all the rental units within the accommodation */
    maxOccupancy?: Maybe<Scalars['Int']>;
    /**   The name of the Accommodation. */
    name: Scalars['String'];
    /**   An avatar image for the Accommodation's owner. */
    ownerImage?: Maybe<GenericImage>;
    /**
     *   A short descriptive text about the Accommodation's owner.
     *  This value is language dependent.
     */
    ownerIntroduction?: Maybe<Scalars['String']>;
    /**   The name of the Accommodation's owner. */
    ownerName?: Maybe<Scalars['String']>;
    /**
     *   A text containing tips for visitors from the Accommodation's owner.
     *  This value is language dependent.
     */
    ownerTips?: Maybe<Scalars['String']>;
    /**   The name of the Place this Accommodation is located in. */
    place: Scalars['String'];
    /**   Distances to POI's. */
    pointsOfInterest: Array<PointOfInterest>;
    /**   Public email address for reservations. */
    publicReservationsEmail?: Maybe<Scalars['String']>;
    /**   Public phone number for reservations. */
    publicReservationsPhoneNumber?: Maybe<Scalars['String']>;
    /**   Public URL for reservations. */
    publicReservationsWebsiteUrl?: Maybe<Scalars['String']>;
    /**   List the RentalUnits within this Accommodation. */
    rentalUnits: Array<RentalUnit>;
    /**   Search the RentalUnits within this Accommodation. */
    searchRentalUnits: UnitSearchResult;
    /**   Unique slug to represent this Accommodation, most commonly in URLs. */
    slug: Scalars['String'];
    /**   The type of this Accommodation. */
    type: AccommodationTypeEnum;
};

/**
 *   An Accommodation is a representation of a location which can contain one or more RentalUnits.
 *  For example: a hotel would be represented by an Accommodation with each room type represented by a separate RentalUnit.
 */
export type AccommodationSearchRentalUnitsArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    params?: InputMaybe<UnitSearchParamsInput>;
};

/**   An image file available through a CDN URL. */
export type AccommodationImage = Image & {
    __typename?: 'AccommodationImage';
    /**   The category assigned to this Image. */
    category: AccommodationImageCategoryEnum;
    /**
     *   A version of the original image transformed to specific parameters.
     *  Note that the available transform options are dependent on the type of image.
     */
    transform?: Maybe<ImageTransform>;
};

/**   An image file available through a CDN URL. */
export type AccommodationImageTransformArgs = {
    config: ImageTransformConfigEnum;
    format: ImageTransformFormatEnum;
};

/**   Categories for AccommodationImages, describing what the image displays. */
export enum AccommodationImageCategoryEnum {
    Bathroom = 'BATHROOM',
    Bedroom = 'BEDROOM',
    Entrance = 'ENTRANCE',
    Facilities = 'FACILITIES',
    FloorPlan = 'FLOOR_PLAN',
    Front = 'FRONT',
    Kitchen = 'KITCHEN',
    LivingRoom = 'LIVING_ROOM',
    Other = 'OTHER',
    Outdoor = 'OUTDOOR',
    Surroundings = 'SURROUNDINGS',
    View = 'VIEW',
}

/**   Types of Accommodation. */
export enum AccommodationTypeEnum {
    ApartmentBlock = 'APARTMENT_BLOCK',
    BedAndBreakfast = 'BED_AND_BREAKFAST',
    BungalowPark = 'BUNGALOW_PARK',
    Camping = 'CAMPING',
    Hotel = 'HOTEL',
    Location = 'LOCATION',
}

export type Activity = {
    __typename?: 'Activity';
    bookingType: ActivityBookingTypesEnum;
    company?: Maybe<Company>;
    coordinates?: Maybe<LatLon>;
    /**   This value is language dependent. */
    description?: Maybe<Scalars['String']>;
    /**   This value is language dependent. */
    descriptionLocation?: Maybe<Scalars['String']>;
    /**   This value is language dependent. */
    descriptionParticulars?: Maybe<Scalars['String']>;
    externalBookingUrl?: Maybe<Scalars['String']>;
    externalInfoUrl?: Maybe<Scalars['String']>;
    highlighted: Scalars['Boolean'];
    id: Scalars['ID'];
    images: Array<ActivityImage>;
    listImage?: Maybe<GenericImage>;
    /**   The address where this Activity is located. */
    locationAddress?: Maybe<Address>;
    mainImage?: Maybe<GenericImage>;
    maxCapacity?: Maybe<Scalars['Int']>;
    minCapacity?: Maybe<Scalars['Int']>;
    /**   This value is language dependent. */
    name: Scalars['String'];
    place?: Maybe<Scalars['String']>;
    slug: Scalars['String'];
    targetAudiences: Array<TargetAudienceEnum>;
    themes: Array<ActivityTheme>;
    /** @deprecated Now handled by bookingType of Agreement */
    ticketSalesEnabled: Scalars['Boolean'];
    timeslots: Array<Timeslot>;
    videoUrl?: Maybe<Scalars['String']>;
};

export type ActivityTimeslotsArgs = {
    endDate: Scalars['String'];
    startDate: Scalars['String'];
};

/**   Activity booking types */
export enum ActivityBookingTypesEnum {
    /**  Activity is directly bookable */
    Direct = 'DIRECT',
    /**  Activity is externally bookable */
    External = 'EXTERNAL',
    /**  activity is not bookable */
    NotBookable = 'NOT_BOOKABLE',
}

/**   An image file available through a CDN URL. */
export type ActivityImage = Image & {
    __typename?: 'ActivityImage';
    /**
     *   A version of the original image transformed to specific parameters.
     *  Note that the available transform options are dependent on the type of image.
     */
    transform?: Maybe<ImageTransform>;
};

/**   An image file available through a CDN URL. */
export type ActivityImageTransformArgs = {
    config: ImageTransformConfigEnum;
    format: ImageTransformFormatEnum;
};

export type ActivityRateGroup = {
    __typename?: 'ActivityRateGroup';
    /** @deprecated Now handled by bookingType of Agreement */
    canBuyTickets: Scalars['Boolean'];
    rates: Array<Rate>;
    releaseHours?: Maybe<Scalars['Int']>;
};

/**   Activity search result ordering options. */
export enum ActivitySearchOrderEnum {
    /**
     *  Randomize order.
     * # The random seed used is the current date, so the order will be identical between pageloads and between users, but change daily.
     */
    Random = 'RANDOM',
    /**  Order by first available Timeslot. */
    TimeslotsAsc = 'TIMESLOTS_ASC',
}

export type ActivitySearchParamsInput = {
    companyId?: InputMaybe<Scalars['ID']>;
    /**   Formatted as yyyy-mm-dd, inclusive */
    endDate?: InputMaybe<Scalars['String']>;
    highlighted?: InputMaybe<Scalars['Boolean']>;
    placeId?: InputMaybe<Scalars['ID']>;
    /**   Formatted as yyyy-mm-dd */
    startDate?: InputMaybe<Scalars['String']>;
    targetAudiences?: InputMaybe<Array<TargetAudienceEnum>>;
    themeSlugs?: InputMaybe<Array<Scalars['String']>>;
};

export type ActivitySearchResult = {
    __typename?: 'ActivitySearchResult';
    hits: Array<Activity>;
    placeCounts: Array<GenericAggregationCount>;
    targetAudienceCount: Array<TargetAudienceAggregationCount>;
    themeCounts: Array<GenericAggregationCount>;
    totalHits: Scalars['Int'];
};

export type ActivityTheme = {
    __typename?: 'ActivityTheme';
    id: Scalars['ID'];
    /**   This value is language dependent. */
    name?: Maybe<Scalars['String']>;
    position: Scalars['Int'];
    slug: Scalars['String'];
};

export type AddFavoriteActivityInput = {
    activityId: Scalars['ID'];
};

export type AddFavoriteActivityPayload = {
    __typename?: 'AddFavoriteActivityPayload';
    favorite?: Maybe<FavoriteActivity>;
};

export type AddFavoriteCompanyInput = {
    companyId: Scalars['ID'];
};

export type AddFavoriteCompanyPayload = {
    __typename?: 'AddFavoriteCompanyPayload';
    favorite?: Maybe<FavoriteCompany>;
};

export type AddFavoriteRentalUnitInput = {
    rentalUnitId: Scalars['ID'];
};

export type AddFavoriteRentalUnitPayload = {
    __typename?: 'AddFavoriteRentalUnitPayload';
    favorite?: Maybe<FavoriteRentalUnit>;
};

export type Address = {
    __typename?: 'Address';
    city?: Maybe<Scalars['String']>;
    countryCode?: Maybe<Scalars['String']>;
    number?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
};

export type AddressInput = {
    city?: InputMaybe<Scalars['String']>;
    countryCode?: InputMaybe<Scalars['String']>;
    number?: InputMaybe<Scalars['String']>;
    postalCode?: InputMaybe<Scalars['String']>;
    street?: InputMaybe<Scalars['String']>;
};

export type Affiliate = {
    __typename?: 'Affiliate';
    handle: Scalars['String'];
    name: Scalars['String'];
};

export type AffiliateInfoInput = {
    handle: Scalars['String'];
    reference?: InputMaybe<Scalars['String']>;
};

export type AlterBookingInput = {
    bookingId: Scalars['ID'];
    /**
     *   Submitting `null` will leave the surcharges unchanged, submitting an empty array indicates none of the available optional surcharges are desired.
     *  Submitting something other than `null` will result in an error if `Booking.isSurchargesAlterable` is `false`.
     */
    optionalSurcharges?: InputMaybe<Array<BookingCartItemSurchargeInput>>;
    /**   Submitting something other than `null` will result in an error if `Booking.isTravelGroupAlterable` is `false`. */
    travelGroup?: InputMaybe<TravelGroupInput>;
};

export type AlterBookingPayload = {
    __typename?: 'AlterBookingPayload';
    booking?: Maybe<Booking>;
};

export type AssetCriteriaInput = {
    /** Narrows the query results to only elements that have been archived. */
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the assets’ files’ last-modified dates. */
    dateModified?: InputMaybe<Scalars['String']>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on whether the elements are enabled in the site they’re being queried in, per the `site` argument. */
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the assets’ filenames. */
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the folders the assets belong to, per the folders’ IDs. */
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the assets’ image heights. */
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Broadens the query results to include assets from any of the subfolders of the folder specified by `folderId`. */
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the assets’ file kinds. */
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the assets’ file sizes (in bytes). */
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ statuses. */
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that have been soft-deleted. */
    trashed?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the user the assets were uploaded by, per the user’s ID. */
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the volumes the assets belong to, per the volumes’ handles. */
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the volumes the assets belong to, per the volumes’ IDs. */
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the assets’ image widths. */
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** A list of transform handles to preload. */
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This is the interface implemented by all assets. */
export type AssetInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** Whether the element is archived or not. */
    archived?: Maybe<Scalars['Boolean']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the asset file was last modified. */
    dateModified?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** Whether the element is enabled or not. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The file extension for the asset file. */
    extension?: Maybe<Scalars['String']>;
    /** The filename of the asset file. */
    filename?: Maybe<Scalars['String']>;
    /** The focal point represented as an array with `x` and `y` keys, or null if it’s not an image. */
    focalPoint?: Maybe<Array<Maybe<Scalars['Float']>>>;
    /** The ID of the folder that the asset belongs to. */
    folderId?: Maybe<Scalars['Int']>;
    /** Whether a user-defined focal point is set on the asset. */
    hasFocalPoint?: Maybe<Scalars['Boolean']>;
    /** The height in pixels or null if it’s not an image. */
    height?: Maybe<Scalars['Int']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** An `<img>` tag based on this asset. */
    img?: Maybe<Scalars['String']>;
    /** The file kind. */
    kind?: Maybe<Scalars['String']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The file’s MIME type, if it can be determined. */
    mimeType?: Maybe<Scalars['String']>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<AssetInterface>;
    /** The asset’s path in the volume. */
    path?: Maybe<Scalars['String']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<AssetInterface>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The file size in bytes. */
    size?: Maybe<Scalars['String']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
    srcset?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted or not. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
    /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
    url?: Maybe<Scalars['String']>;
    /** The ID of the volume that the asset belongs to. */
    volumeId?: Maybe<Scalars['Int']>;
    /** The width in pixels or null if it’s not an image. */
    width?: Maybe<Scalars['Int']>;
};

/** This is the interface implemented by all assets. */
export type AssetInterface_CountArgs = {
    field: Scalars['String'];
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceHeightArgs = {
    format?: InputMaybe<Scalars['String']>;
    handle?: InputMaybe<Scalars['String']>;
    height?: InputMaybe<Scalars['Int']>;
    immediately?: InputMaybe<Scalars['Boolean']>;
    interlace?: InputMaybe<Scalars['String']>;
    mode?: InputMaybe<Scalars['String']>;
    position?: InputMaybe<Scalars['String']>;
    quality?: InputMaybe<Scalars['Int']>;
    transform?: InputMaybe<Scalars['String']>;
    width?: InputMaybe<Scalars['Int']>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceNextArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfacePrevArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceSrcsetArgs = {
    sizes: Array<Scalars['String']>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceUrlArgs = {
    format?: InputMaybe<Scalars['String']>;
    handle?: InputMaybe<Scalars['String']>;
    height?: InputMaybe<Scalars['Int']>;
    immediately?: InputMaybe<Scalars['Boolean']>;
    interlace?: InputMaybe<Scalars['String']>;
    mode?: InputMaybe<Scalars['String']>;
    position?: InputMaybe<Scalars['String']>;
    quality?: InputMaybe<Scalars['Int']>;
    transform?: InputMaybe<Scalars['String']>;
    width?: InputMaybe<Scalars['Int']>;
};

/** This is the interface implemented by all assets. */
export type AssetInterfaceWidthArgs = {
    format?: InputMaybe<Scalars['String']>;
    handle?: InputMaybe<Scalars['String']>;
    height?: InputMaybe<Scalars['Int']>;
    immediately?: InputMaybe<Scalars['Boolean']>;
    interlace?: InputMaybe<Scalars['String']>;
    mode?: InputMaybe<Scalars['String']>;
    position?: InputMaybe<Scalars['String']>;
    quality?: InputMaybe<Scalars['Int']>;
    transform?: InputMaybe<Scalars['String']>;
    width?: InputMaybe<Scalars['Int']>;
};

/**   An attribute which is assigned to either an Accommodation or a RentalUnit. */
export type Attribute = {
    __typename?: 'Attribute';
    /**   A URL-friendly handle unique to this Attribute, this should never change even if the description changes. */
    handle: Scalars['String'];
    /**   URL of an SVG icon representing this Attribute. */
    iconUrl?: Maybe<Scalars['String']>;
    /**   Unique ID representing this Attribute. */
    id: Scalars['ID'];
    /**
     *   The name of this Attribute.
     *  This value is language dependent.
     */
    name: Scalars['String'];
};

/**   A category for Attributes. */
export type AttributeCategory = {
    __typename?: 'AttributeCategory';
    /**   The Attributes in this category. */
    attributes: Array<Attribute>;
    /**   A URL-friendly handle unique to this AttributeCategory, this should never change even if the description changes. */
    handle: Scalars['String'];
    /**   Unique ID representing this AttributeCategory. */
    id: Scalars['ID'];
    /**
     *   The name of this AttributeCategory.
     *  This value is language dependent.
     */
    name: Scalars['String'];
};

export type Booking = OrderItem & {
    __typename?: 'Booking';
    amountAdults: Scalars['Int'];
    amountBabies: Scalars['Int'];
    amountChildren: Scalars['Int'];
    amountPets: Scalars['Int'];
    amountYouths: Scalars['Int'];
    /**   Formatted as yyyy-mm-dd */
    arrivalDate: Scalars['String'];
    customerComment?: Maybe<Scalars['String']>;
    /**   Formatted as yyyy-mm-dd */
    departureDate: Scalars['String'];
    deposit: Scalars['Float'];
    downloadVoucherUrl?: Maybe<Scalars['String']>;
    errors: Array<Scalars['String']>;
    /**   Formatted as yyyy-mm-dd HH:mm */
    flexCancellationUntil?: Maybe<Scalars['String']>;
    handleDepositPayment: Scalars['Boolean'];
    hasCancellationInsurance: Scalars['Boolean'];
    hasContentsInsurance: Scalars['Boolean'];
    id?: Maybe<Scalars['ID']>;
    /**   If true, some part of the Booking can be altered by the Customer (via the `alterBooking` Mutation). Use the `is...Alterable` fields to determine which parts specifically can be altered. */
    isAlterable: Scalars['Boolean'];
    isCancellable: Scalars['Boolean'];
    /**   If true, the optional surcharges for this Booking can be altered by the Customer (via the `alterBooking` Mutation). */
    isSurchargesAlterable: Scalars['Boolean'];
    /**   If true, the travel group for this Booking can be altered by the Customer (via the `alterBooking` Mutation). */
    isTravelGroupAlterable: Scalars['Boolean'];
    optionalSurcharges: Array<BookingCartItemSurcharge>;
    paymentPrice: Scalars['Float'];
    priceLines: Array<PriceLine>;
    rentalUnit?: Maybe<RentalUnit>;
    requiresApproval: Scalars['Boolean'];
    review?: Maybe<Review>;
    special?: Maybe<Special>;
    status: BookingStatus;
    totalPrice: Scalars['Float'];
};

export type BookingCartItemInput = {
    amountAdults: Scalars['Int'];
    amountBabies: Scalars['Int'];
    amountChildren: Scalars['Int'];
    amountPets: Scalars['Int'];
    amountYouths: Scalars['Int'];
    /**   Formatted as yyyy-mm-dd */
    arrivalDate: Scalars['String'];
    cancellationInsurance: Scalars['Boolean'];
    contentsInsurance: Scalars['Boolean'];
    /**   Formatted as yyyy-mm-dd */
    departureDate: Scalars['String'];
    optionalSurcharges: Array<BookingCartItemSurchargeInput>;
    rentalUnitId: Scalars['ID'];
    specialId?: InputMaybe<Scalars['ID']>;
};

export type BookingCartItemSurcharge = {
    __typename?: 'BookingCartItemSurcharge';
    amount: Scalars['Int'];
    rentalUnitSurchargeId: Scalars['ID'];
};

export type BookingCartItemSurchargeInput = {
    amount: Scalars['Int'];
    rentalUnitSurchargeId: Scalars['ID'];
};

export type BookingChangePreview = {
    __typename?: 'BookingChangePreview';
    bookingPreview: Booking;
    newOrderAmountDue: Scalars['Float'];
};

export enum BookingStatus {
    Accepted = 'ACCEPTED',
    CancelledExternal = 'CANCELLED_EXTERNAL',
    CancelledGuest = 'CANCELLED_GUEST',
    CancelledPartner = 'CANCELLED_PARTNER',
    Declined = 'DECLINED',
    Init = 'INIT',
    NoShow = 'NO_SHOW',
    Pending = 'PENDING',
    Waived = 'WAIVED',
}

/**   A bounding box of GPS coordinates. */
export type BoundingBoxInput = {
    bottom: Scalars['Float'];
    left: Scalars['Float'];
    right: Scalars['Float'];
    top: Scalars['Float'];
};

export type Bucket = {
    __typename?: 'Bucket';
    count?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
};

export type CancelBookingInput = {
    bookingId: Scalars['ID'];
};

export type CancelBookingPayload = {
    __typename?: 'CancelBookingPayload';
    booking?: Maybe<Booking>;
};

export type Cart = {
    __typename?: 'Cart';
    availableUpsellOffers: Array<UpsellOffer>;
    id: Scalars['ID'];
    order: Order;
    status: CartStatusEnum;
};

export enum CartStatusEnum {
    Closed = 'CLOSED',
    Open = 'OPEN',
    PaymentFailed = 'PAYMENT_FAILED',
    PaymentPending = 'PAYMENT_PENDING',
}

export type CategoryCriteriaInput = {
    /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `ancestorOf`. */
    ancestorDist?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that are ancestors of another element in its structure, provided by its ID. */
    ancestorOf?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that have been archived. */
    archived?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `descendantOf`. */
    descendantDist?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that are descendants of another element in its structure provided by its ID. */
    descendantOf?: InputMaybe<Scalars['Int']>;
    /** Whether to only return categories that the user has permission to edit. */
    editable?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on whether the elements are enabled in the site they’re being queried in, per the `site` argument. */
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the category groups the categories belong to per the group’s handles. */
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the category groups the categories belong to, per the groups’ IDs. */
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on whether the elements have any descendants in their structure. */
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on whether the elements are “leaves” in their structure (element with no descendants). */
    leaves?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ level within the structure. */
    level?: InputMaybe<Scalars['Int']>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only the entry that comes immediately after another element in its structure, provided by its ID. */
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Narrows the query results to only entries that are positioned after another element in its structure, provided by its ID. */
    positionedAfter?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only entries that are positioned before another element in its structure, provided by its ID. */
    positionedBefore?: InputMaybe<Scalars['Int']>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only the entry that comes immediately before another element in its structure, provided by its ID. */
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ statuses. */
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which structure data should be joined into the query. */
    structureId?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that have been soft-deleted. */
    trashed?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Explicitly determines whether the query should join in the structure data. */
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type CheckoutCartInput = {
    affiliateInfo?: InputMaybe<AffiliateInfoInput>;
    agreedToTerms: Scalars['Boolean'];
    bookings?: InputMaybe<Array<BookingCartItemInput>>;
    cartId: Scalars['ID'];
    customerInfo?: InputMaybe<CustomerInfoInput>;
    paymentOptions: PaymentOptionsInput;
    tickets?: InputMaybe<Array<TicketCartItemInput>>;
    upsellPurchases?: InputMaybe<Array<UpsellPurchaseCartItemInput>>;
};

export type CheckoutCartPayload = {
    __typename?: 'CheckoutCartPayload';
    cart?: Maybe<Cart>;
    redirectUrl?: Maybe<Scalars['String']>;
    success?: Maybe<Scalars['Boolean']>;
};

export type Company = {
    __typename?: 'Company';
    activities: Array<Activity>;
    address: Address;
    categories: Array<CompanyCategory>;
    companyRateGroups: Array<CompanyRateGroup>;
    coordinates?: Maybe<LatLon>;
    /**   This value is language dependent. */
    description?: Maybe<Scalars['String']>;
    /**   This value is language dependent. */
    descriptionParticulars?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    fullCircleImageUrl?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    images: Array<CompanyImage>;
    listImage?: Maybe<GenericImage>;
    logoImage?: Maybe<GenericImage>;
    mainImage?: Maybe<GenericImage>;
    name: Scalars['String'];
    normalOperatingHours?: Maybe<NormalOperatingHours>;
    phoneNumber?: Maybe<Scalars['String']>;
    place: Scalars['String'];
    showInCalendar: Scalars['Boolean'];
    slug: Scalars['String'];
    socialsFacebookUrl?: Maybe<Scalars['String']>;
    socialsInstagramUrl?: Maybe<Scalars['String']>;
    socialsTwitterUrl?: Maybe<Scalars['String']>;
    specialOperatingHours: Array<SpecialOperatingHours>;
    targetAudiences: Array<TargetAudienceEnum>;
    themes: Array<ActivityTheme>;
    videoUrl?: Maybe<Scalars['String']>;
    websiteUrl?: Maybe<Scalars['String']>;
};

export type CompanyCategory = {
    __typename?: 'CompanyCategory';
    children: Array<CompanyCategory>;
    id: Scalars['ID'];
    isRoot: Scalars['Boolean'];
    /**   This value is language dependent. */
    name?: Maybe<Scalars['String']>;
    position: Scalars['Int'];
    slug: Scalars['String'];
};

/**   An image file available through a CDN URL. */
export type CompanyImage = Image & {
    __typename?: 'CompanyImage';
    /**
     *   A version of the original image transformed to specific parameters.
     *  Note that the available transform options are dependent on the type of image.
     */
    transform?: Maybe<ImageTransform>;
};

/**   An image file available through a CDN URL. */
export type CompanyImageTransformArgs = {
    config: ImageTransformConfigEnum;
    format: ImageTransformFormatEnum;
};

export type CompanyRateGroup = {
    __typename?: 'CompanyRateGroup';
    canBuyTickets: Scalars['Boolean'];
    endDate?: Maybe<Scalars['String']>;
    rates: Array<Rate>;
    startDate?: Maybe<Scalars['String']>;
};

export type CompanySearchParamsInput = {
    categorySlugs?: InputMaybe<Array<Scalars['String']>>;
    /**   Formatted as yyyy-mm-dd, inclusive */
    endDate?: InputMaybe<Scalars['String']>;
    mainCategorySlugs?: InputMaybe<Array<Scalars['String']>>;
    placeId?: InputMaybe<Scalars['ID']>;
    showInCalendar?: InputMaybe<Scalars['Boolean']>;
    /**   Formatted as yyyy-mm-dd */
    startDate?: InputMaybe<Scalars['String']>;
    targetAudiences?: InputMaybe<Array<TargetAudienceEnum>>;
    themeSlugs?: InputMaybe<Array<Scalars['String']>>;
};

export type CompanySearchResult = {
    __typename?: 'CompanySearchResult';
    categoryCounts: Array<GenericAggregationCount>;
    hits: Array<Company>;
    mainCategoryCounts: Array<GenericAggregationCount>;
    placeCounts: Array<GenericAggregationCount>;
    targetAudienceCount: Array<TargetAudienceAggregationCount>;
    themeCounts: Array<GenericAggregationCount>;
    totalHits: Scalars['Int'];
};

export type ContactInfo = {
    __typename?: 'ContactInfo';
    contactName?: Maybe<Scalars['String']>;
    emails: Array<Scalars['String']>;
    phoneNumbers: Array<Scalars['String']>;
};

export type Customer = {
    __typename?: 'Customer';
    address?: Maybe<Address>;
    agreedToTermsAt: Scalars['String'];
    birthdate?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    favorites: Array<Favorite>;
    firstName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    lastName?: Maybe<Scalars['String']>;
    locale: Scalars['String'];
    orders: Array<Order>;
    phoneNumber?: Maybe<Scalars['String']>;
};

export type CustomerInfoInput = {
    address?: InputMaybe<AddressInput>;
    birthdate?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    newsletterOptin: Scalars['Boolean'];
    phoneNumber?: InputMaybe<Scalars['String']>;
};

export type DateCount = {
    __typename?: 'DateCount';
    /**   Formatted as yyyy-mm-dd */
    date: Scalars['String'];
    hits: Scalars['Int'];
};

export type DateHitCount = {
    __typename?: 'DateHitCount';
    /**   Formatted as yyyy-mm-dd */
    date: Scalars['String'];
    hits: Scalars['Int'];
};

export type Element = ElementInterface & {
    __typename?: 'Element';
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** Whether the element is archived or not. */
    archived?: Maybe<Scalars['Boolean']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** Whether the element is enabled or not. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted or not. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
};

export type Element_CountArgs = {
    field: Scalars['String'];
};

/** This is the interface implemented by all elements. */
export type ElementInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** Whether the element is archived or not. */
    archived?: Maybe<Scalars['Boolean']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** Whether the element is enabled or not. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted or not. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all elements. */
export type ElementInterface_CountArgs = {
    field: Scalars['String'];
};

export type EntryCriteriaInput = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only entries that were posted on or after a certain date. */
    after?: InputMaybe<Scalars['String']>;
    /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `ancestorOf`. */
    ancestorDist?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that are ancestors of another element in its structure, provided by its ID. */
    ancestorOf?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that have been archived. */
    archived?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the user group the entries’ authors belong to. */
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the user group the entries’ authors belong to, per the groups’ IDs. */
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the entries’ authors. */
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only entries that were posted before a certain date. */
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that are up to a certain distance away from the element in its structure specified by `descendantOf`. */
    descendantDist?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only elements that are descendants of another element in its structure provided by its ID. */
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** The drafts’ creator ID */
    draftCreator?: InputMaybe<Scalars['Int']>;
    /** The ID of the draft to return (from the `drafts` table) */
    draftId?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only drafts of a given element.  Set to `false` to fetch unpublished drafts. */
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    /** Whether draft elements should be returned. */
    drafts?: InputMaybe<Scalars['Boolean']>;
    /** Whether to only return entries that the user has permission to edit. */
    editable?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on whether the elements are enabled in the site they’re being queried in, per the `site` argument. */
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the entries’ expiry dates. */
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on whether the elements have any descendants in their structure. */
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on whether the elements are “leaves” in their structure (element with no descendants). */
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ level within the structure. */
    level?: InputMaybe<Scalars['Int']>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only the entry that comes immediately after another element in its structure, provided by its ID. */
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Narrows the query results to only entries that are positioned after another element in its structure, provided by its ID. */
    positionedAfter?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results to only entries that are positioned before another element in its structure, provided by its ID. */
    positionedBefore?: InputMaybe<Scalars['Int']>;
    /** Narrows the query results based on the entries’ post dates. */
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only the entry that comes immediately before another element in its structure, provided by its ID. */
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    /** Whether provisional drafts should be returned. */
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    /** The revisions’ creator ID */
    revisionCreator?: InputMaybe<Scalars['Int']>;
    /** The ID of the revision to return (from the `revisions` table) */
    revisionId?: InputMaybe<Scalars['Int']>;
    /** The source element ID that revisions should be returned for */
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    /** Whether revision elements should be returned. */
    revisions?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Narrows the query results based on the section handles the entries belong to. */
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the sections the entries belong to, per the sections’ IDs. */
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ statuses. */
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which structure data should be joined into the query. */
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that have been soft-deleted. */
    trashed?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the entries’ entry type handles. */
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the entries’ entry types, per the types’ IDs. */
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Explicitly determines whether the query should join in the structure data. */
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
    ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
    /** Whether the element is archived or not. */
    archived?: Maybe<Scalars['Boolean']>;
    /** Returns the entry’s canonical ID. */
    canonicalId?: Maybe<Scalars['Int']>;
    /** Returns the entry’s canonical UUID. */
    canonicalUid?: Maybe<Scalars['String']>;
    /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
    children?: Maybe<Array<Maybe<EntryInterface>>>;
    /** The current revision for the entry. */
    currentRevision?: Maybe<EntryInterface>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
    descendants?: Maybe<Array<Maybe<EntryInterface>>>;
    /** The creator of a given draft. */
    draftCreator?: Maybe<UserInterface>;
    /** The draft ID (from the `drafts` table). */
    draftId?: Maybe<Scalars['Int']>;
    /** The name of the draft. */
    draftName?: Maybe<Scalars['String']>;
    /** The notes for the draft. */
    draftNotes?: Maybe<Scalars['String']>;
    /** The drafts for the entry. */
    drafts?: Maybe<Array<Maybe<EntryInterface>>>;
    /** Whether the element is enabled or not. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The expiry date of the entry. */
    expiryDate?: Maybe<Scalars['DateTime']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Returns whether this is a draft. */
    isDraft?: Maybe<Scalars['Boolean']>;
    /** Returns whether this is a revision. */
    isRevision?: Maybe<Scalars['Boolean']>;
    /** Returns whether this is an unpublished draft. */
    isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
    /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
    isUnsavedDraft?: Maybe<Scalars['Boolean']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The element’s level within its structure */
    level?: Maybe<Scalars['Int']>;
    /** The element’s left position within its structure. */
    lft?: Maybe<Scalars['Int']>;
    /** The same element in other locales. */
    localized?: Maybe<Array<Maybe<EntryInterface>>>;
    /** Returns the next element relative to this one, from a given set of criteria. */
    next?: Maybe<EntryInterface>;
    /** The entry’s parent, if the section is a structure. */
    parent?: Maybe<EntryInterface>;
    /** The entry’s post date. */
    postDate?: Maybe<Scalars['DateTime']>;
    /** Returns the previous element relative to this one, from a given set of criteria. */
    prev?: Maybe<EntryInterface>;
    /** The creator of a given revision. */
    revisionCreator?: Maybe<UserInterface>;
    /** The revision ID (from the `revisions` table). */
    revisionId?: Maybe<Scalars['Int']>;
    /** The revision notes (from the `revisions` table). */
    revisionNotes?: Maybe<Scalars['String']>;
    /** The revisions for the entry. */
    revisions?: Maybe<Array<Maybe<EntryInterface>>>;
    /** The element’s right position within its structure. */
    rgt?: Maybe<Scalars['Int']>;
    /** The element’s structure’s root ID */
    root?: Maybe<Scalars['Int']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['String']>;
    /** The handle of the section that contains the entry. */
    sectionHandle?: Maybe<Scalars['String']>;
    /** The ID of the section that contains the entry. */
    sectionId?: Maybe<Scalars['Int']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /**
     * Returns the entry’s canonical ID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
     */
    sourceId?: Maybe<Scalars['Int']>;
    /**
     * Returns the entry’s canonical UUID.
     * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
     */
    sourceUid?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The element’s structure ID. */
    structureId?: Maybe<Scalars['Int']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted or not. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The handle of the entry type that contains the entry. */
    typeHandle?: Maybe<Scalars['String']>;
    /** The ID of the entry type that contains the entry. */
    typeId?: Maybe<Scalars['Int']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
    /** The element’s full URL */
    url?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterface_CountArgs = {
    field: Scalars['String'];
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfacePrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all entries. */
export type EntryInterfaceRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Ether_Map = {
    __typename?: 'Ether_Map';
    /** The full address. */
    address?: Maybe<Scalars['String']>;
    /** The distance to this location. */
    distance?: Maybe<Scalars['Float']>;
    /** The maps latitude. */
    lat?: Maybe<Scalars['Float']>;
    /** The maps longitude. */
    lng?: Maybe<Scalars['Float']>;
    /** The maps address parts. */
    parts?: Maybe<Ether_MapParts>;
    /** The maps zoom level. */
    zoom?: Maybe<Scalars['Int']>;
};

export type Ether_MapParts = {
    __typename?: 'Ether_MapParts';
    /** The street address. */
    address?: Maybe<Scalars['String']>;
    /** The city. */
    city?: Maybe<Scalars['String']>;
    /** The country. */
    country?: Maybe<Scalars['String']>;
    /** The county. */
    county?: Maybe<Scalars['String']>;
    /** The address name / number. */
    number?: Maybe<Scalars['String']>;
    /** The postal code. */
    postcode?: Maybe<Scalars['String']>;
    /** The state. */
    state?: Maybe<Scalars['String']>;
};

export type Ether_SeoKeyword = {
    __typename?: 'Ether_SEOKeyword';
    keyword?: Maybe<Scalars['String']>;
    rating?: Maybe<Scalars['String']>;
};

/** Robots and canonical data */
export type Ether_SeoAdvanced = {
    __typename?: 'Ether_SeoAdvanced';
    canonical?: Maybe<Scalars['String']>;
    robots?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Ether_SeoData = {
    __typename?: 'Ether_SeoData';
    advanced?: Maybe<Ether_SeoAdvanced>;
    description?: Maybe<Scalars['String']>;
    keywords?: Maybe<Array<Maybe<Ether_SeoKeyword>>>;
    social?: Maybe<Ether_SeoSocialNetworks>;
    title?: Maybe<Scalars['String']>;
};

/** Social data for an individual Social network */
export type Ether_SeoSocialData = {
    __typename?: 'Ether_SeoSocialData';
    description?: Maybe<Scalars['String']>;
    image?: Maybe<AssetInterface>;
    title?: Maybe<Scalars['String']>;
};

export type Ether_SeoSocialNetworks = {
    __typename?: 'Ether_SeoSocialNetworks';
    facebook?: Maybe<Ether_SeoSocialData>;
    twitter?: Maybe<Ether_SeoSocialData>;
};

export type Favorite = {
    id: Scalars['ID'];
    targetId: Scalars['ID'];
};

export type FavoriteActivity = Favorite & {
    __typename?: 'FavoriteActivity';
    activity?: Maybe<Activity>;
    id: Scalars['ID'];
    targetId: Scalars['ID'];
};

export type FavoriteCompany = Favorite & {
    __typename?: 'FavoriteCompany';
    company?: Maybe<Company>;
    id: Scalars['ID'];
    targetId: Scalars['ID'];
};

export type FavoriteRentalUnit = Favorite & {
    __typename?: 'FavoriteRentalUnit';
    id: Scalars['ID'];
    rentalUnit?: Maybe<RentalUnit>;
    targetId: Scalars['ID'];
};

export type FieldAttribute = {
    __typename?: 'FieldAttribute';
    label?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all fields. */
export type FieldInterface = {
    /** The width of the field’s column. */
    columnWidth?: Maybe<Scalars['String']>;
    /** The field’s container attributes. */
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s CSS classes. */
    cssClasses?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    /** The field’s error message. */
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The field’s input attributes. */
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    /** The field’s instructions position. */
    instructionsPosition?: Maybe<Scalars['String']>;
    /** The field’s label position. */
    labelPosition?: Maybe<Scalars['String']>;
    /** Whether the field should limit content. */
    limit?: Maybe<Scalars['Boolean']>;
    /** The field’s limit amount. */
    limitAmount?: Maybe<Scalars['Int']>;
    /** The field’s limit type. */
    limitType?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    /** The field’s placeholder. */
    placeholder?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type Field_Address = FieldInterface & {
    __typename?: 'Field_Address';
    address1Collapsed?: Maybe<Scalars['Boolean']>;
    address1DefaultValue?: Maybe<Scalars['String']>;
    address1Enabled?: Maybe<Scalars['Boolean']>;
    address1ErrorMessage?: Maybe<Scalars['String']>;
    address1Hidden?: Maybe<Scalars['Boolean']>;
    address1Label?: Maybe<Scalars['String']>;
    address1Placeholder?: Maybe<Scalars['String']>;
    address1PrePopulate?: Maybe<Scalars['String']>;
    address1Required?: Maybe<Scalars['Boolean']>;
    address2Collapsed?: Maybe<Scalars['Boolean']>;
    address2DefaultValue?: Maybe<Scalars['String']>;
    address2Enabled?: Maybe<Scalars['Boolean']>;
    address2ErrorMessage?: Maybe<Scalars['String']>;
    address2Hidden?: Maybe<Scalars['Boolean']>;
    address2Label?: Maybe<Scalars['String']>;
    address2Placeholder?: Maybe<Scalars['String']>;
    address2PrePopulate?: Maybe<Scalars['String']>;
    address2Required?: Maybe<Scalars['Boolean']>;
    address3Collapsed?: Maybe<Scalars['Boolean']>;
    address3DefaultValue?: Maybe<Scalars['String']>;
    address3Enabled?: Maybe<Scalars['Boolean']>;
    address3ErrorMessage?: Maybe<Scalars['String']>;
    address3Hidden?: Maybe<Scalars['Boolean']>;
    address3Label?: Maybe<Scalars['String']>;
    address3Placeholder?: Maybe<Scalars['String']>;
    address3PrePopulate?: Maybe<Scalars['String']>;
    address3Required?: Maybe<Scalars['Boolean']>;
    autocompleteCollapsed?: Maybe<Scalars['Boolean']>;
    autocompleteDefaultValue?: Maybe<Scalars['String']>;
    autocompleteEnabled?: Maybe<Scalars['Boolean']>;
    autocompleteErrorMessage?: Maybe<Scalars['String']>;
    autocompleteIntegration?: Maybe<Scalars['String']>;
    autocompleteLabel?: Maybe<Scalars['String']>;
    autocompletePlaceholder?: Maybe<Scalars['String']>;
    autocompletePrePopulate?: Maybe<Scalars['String']>;
    autocompleteRequired?: Maybe<Scalars['String']>;
    cityCollapsed?: Maybe<Scalars['Boolean']>;
    cityDefaultValue?: Maybe<Scalars['String']>;
    cityEnabled?: Maybe<Scalars['Boolean']>;
    cityErrorMessage?: Maybe<Scalars['String']>;
    cityHidden?: Maybe<Scalars['Boolean']>;
    cityLabel?: Maybe<Scalars['String']>;
    cityPlaceholder?: Maybe<Scalars['String']>;
    cityPrePopulate?: Maybe<Scalars['String']>;
    cityRequired?: Maybe<Scalars['Boolean']>;
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    countryCollapsed?: Maybe<Scalars['Boolean']>;
    countryDefaultValue?: Maybe<Scalars['String']>;
    countryEnabled?: Maybe<Scalars['Boolean']>;
    countryErrorMessage?: Maybe<Scalars['String']>;
    countryHidden?: Maybe<Scalars['Boolean']>;
    countryLabel?: Maybe<Scalars['String']>;
    countryPlaceholder?: Maybe<Scalars['String']>;
    countryPrePopulate?: Maybe<Scalars['String']>;
    countryRequired?: Maybe<Scalars['Boolean']>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    enableAutocomplete?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    stateCollapsed?: Maybe<Scalars['Boolean']>;
    stateDefaultValue?: Maybe<Scalars['String']>;
    stateEnabled?: Maybe<Scalars['Boolean']>;
    stateErrorMessage?: Maybe<Scalars['String']>;
    stateHidden?: Maybe<Scalars['Boolean']>;
    stateLabel?: Maybe<Scalars['String']>;
    statePlaceholder?: Maybe<Scalars['String']>;
    statePrePopulate?: Maybe<Scalars['String']>;
    stateRequired?: Maybe<Scalars['Boolean']>;
    subfieldLabelPosition?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    zipCollapsed?: Maybe<Scalars['Boolean']>;
    zipDefaultValue?: Maybe<Scalars['String']>;
    zipEnabled?: Maybe<Scalars['Boolean']>;
    zipErrorMessage?: Maybe<Scalars['String']>;
    zipHidden?: Maybe<Scalars['Boolean']>;
    zipLabel?: Maybe<Scalars['String']>;
    zipPlaceholder?: Maybe<Scalars['String']>;
    zipPrePopulate?: Maybe<Scalars['String']>;
    zipRequired?: Maybe<Scalars['Boolean']>;
};

export type Field_Agree = FieldInterface & {
    __typename?: 'Field_Agree';
    checkedValue?: Maybe<Scalars['String']>;
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultState?: Maybe<Scalars['Boolean']>;
    description?: Maybe<Scalars['String']>;
    descriptionHtml?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    uncheckedValue?: Maybe<Scalars['String']>;
};

export type Field_Categories = FieldInterface & {
    __typename?: 'Field_Categories';
    allowLimit?: Maybe<Scalars['String']>;
    allowMultipleSources?: Maybe<Scalars['String']>;
    allowSelfRelations?: Maybe<Scalars['String']>;
    branchLimit?: Maybe<Scalars['String']>;
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    displayType?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    labelSource?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    localizeRelations?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    orderBy?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    searchable?: Maybe<Scalars['String']>;
    selectionLabel?: Maybe<Scalars['String']>;
    showSiteMenu?: Maybe<Scalars['String']>;
    source?: Maybe<Scalars['String']>;
    sources?: Maybe<Scalars['String']>;
    targetSiteId?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    validateRelatedElements?: Maybe<Scalars['String']>;
    viewMode?: Maybe<Scalars['String']>;
};

export type Field_Checkboxes = FieldInterface & {
    __typename?: 'Field_Checkboxes';
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    layout?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    options?: Maybe<Array<Maybe<KeyValueType>>>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    toggleCheckbox?: Maybe<Scalars['String']>;
    toggleCheckboxLabel?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type Field_Date = FieldInterface & {
    __typename?: 'Field_Date';
    ampmLabel?: Maybe<Scalars['String']>;
    ampmPlaceholder?: Maybe<Scalars['String']>;
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    dateFormat?: Maybe<Scalars['String']>;
    dayLabel?: Maybe<Scalars['String']>;
    dayPlaceholder?: Maybe<Scalars['String']>;
    defaultDate?: Maybe<Scalars['DateTime']>;
    defaultOption?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    displayType?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    hourLabel?: Maybe<Scalars['String']>;
    hourPlaceholder?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    includeTime?: Maybe<Scalars['Boolean']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    minuteLabel?: Maybe<Scalars['String']>;
    minutePlaceholder?: Maybe<Scalars['String']>;
    monthLabel?: Maybe<Scalars['String']>;
    monthPlaceholder?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    secondLabel?: Maybe<Scalars['String']>;
    secondPlaceholder?: Maybe<Scalars['String']>;
    subfieldLabelPosition?: Maybe<Scalars['String']>;
    timeFormat?: Maybe<Scalars['String']>;
    timeLabel?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    yearLabel?: Maybe<Scalars['String']>;
    yearPlaceholder?: Maybe<Scalars['String']>;
};

export type Field_Dropdown = FieldInterface & {
    __typename?: 'Field_Dropdown';
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    multi?: Maybe<Scalars['String']>;
    multiple?: Maybe<Scalars['Boolean']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    optgroups?: Maybe<Scalars['String']>;
    options?: Maybe<Array<Maybe<KeyValueType>>>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type Field_Email = FieldInterface & {
    __typename?: 'Field_Email';
    blockedDomains?: Maybe<Array<Maybe<KeyValueType>>>;
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    validateDomain?: Maybe<Scalars['Boolean']>;
};

export type Field_Entries = FieldInterface & {
    __typename?: 'Field_Entries';
    allowSelfRelations?: Maybe<Scalars['String']>;
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    displayType?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    labelSource?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    localizeRelations?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    orderBy?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    searchable?: Maybe<Scalars['String']>;
    selectionLabel?: Maybe<Scalars['String']>;
    showSiteMenu?: Maybe<Scalars['String']>;
    source?: Maybe<Scalars['String']>;
    sources?: Maybe<Scalars['String']>;
    targetSiteId?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    validateRelatedElements?: Maybe<Scalars['String']>;
    viewMode?: Maybe<Scalars['String']>;
};

export type Field_FileUpload = FieldInterface & {
    __typename?: 'Field_FileUpload';
    allowSelfRelations?: Maybe<Scalars['String']>;
    allowUploads?: Maybe<Scalars['String']>;
    allowedKinds?: Maybe<Array<Maybe<Scalars['String']>>>;
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultUploadLocationSource?: Maybe<Scalars['String']>;
    defaultUploadLocationSubpath?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    displayType?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    labelSource?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitFiles?: Maybe<Scalars['String']>;
    limitType?: Maybe<Scalars['String']>;
    localizeRelations?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    orderBy?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    previewMode?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    restrictFiles?: Maybe<Scalars['String']>;
    searchable?: Maybe<Scalars['String']>;
    selectionLabel?: Maybe<Scalars['String']>;
    showSiteMenu?: Maybe<Scalars['String']>;
    showUnpermittedFiles?: Maybe<Scalars['String']>;
    showUnpermittedVolumes?: Maybe<Scalars['String']>;
    singleUploadLocationSource?: Maybe<Scalars['String']>;
    singleUploadLocationSubpath?: Maybe<Scalars['String']>;
    sizeLimit?: Maybe<Scalars['String']>;
    sizeMinLimit?: Maybe<Scalars['String']>;
    source?: Maybe<Scalars['String']>;
    sources?: Maybe<Scalars['String']>;
    targetSiteId?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    uploadLocationSource?: Maybe<Scalars['String']>;
    uploadLocationSubpath?: Maybe<Scalars['String']>;
    useSingleFolder?: Maybe<Scalars['String']>;
    validateRelatedElements?: Maybe<Scalars['String']>;
    viewMode?: Maybe<Scalars['String']>;
};

export type Field_Group = FieldInterface & {
    __typename?: 'Field_Group';
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    contentTable?: Maybe<Scalars['String']>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s nested fields. */
    fields?: Maybe<Array<Maybe<FieldInterface>>>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    /** The field’s nested rows. */
    nestedRows?: Maybe<Array<Maybe<RowInterface>>>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type Field_Heading = FieldInterface & {
    __typename?: 'Field_Heading';
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    headingSize?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type Field_Hidden = FieldInterface & {
    __typename?: 'Field_Hidden';
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultOption?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['Boolean']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    queryParameter?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type Field_Html = FieldInterface & {
    __typename?: 'Field_Html';
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    htmlContent?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type Field_MissingField = FieldInterface & {
    __typename?: 'Field_MissingField';
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    expectedType?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    settings?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type Field_MultiLineText = FieldInterface & {
    __typename?: 'Field_MultiLineText';
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    richTextButtons?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    useRichText?: Maybe<Scalars['Boolean']>;
};

export type Field_Name = FieldInterface & {
    __typename?: 'Field_Name';
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    firstNameCollapsed?: Maybe<Scalars['Boolean']>;
    firstNameDefaultValue?: Maybe<Scalars['String']>;
    firstNameEnabled?: Maybe<Scalars['Boolean']>;
    firstNameErrorMessage?: Maybe<Scalars['String']>;
    firstNameLabel?: Maybe<Scalars['String']>;
    firstNamePlaceholder?: Maybe<Scalars['String']>;
    firstNamePrePopulate?: Maybe<Scalars['String']>;
    firstNameRequired?: Maybe<Scalars['Boolean']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    lastNameCollapsed?: Maybe<Scalars['Boolean']>;
    lastNameDefaultValue?: Maybe<Scalars['String']>;
    lastNameEnabled?: Maybe<Scalars['Boolean']>;
    lastNameErrorMessage?: Maybe<Scalars['String']>;
    lastNameLabel?: Maybe<Scalars['String']>;
    lastNamePlaceholder?: Maybe<Scalars['String']>;
    lastNamePrePopulate?: Maybe<Scalars['String']>;
    lastNameRequired?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    middleNameCollapsed?: Maybe<Scalars['Boolean']>;
    middleNameDefaultValue?: Maybe<Scalars['String']>;
    middleNameEnabled?: Maybe<Scalars['Boolean']>;
    middleNameErrorMessage?: Maybe<Scalars['String']>;
    middleNameLabel?: Maybe<Scalars['String']>;
    middleNamePlaceholder?: Maybe<Scalars['String']>;
    middleNamePrePopulate?: Maybe<Scalars['String']>;
    middleNameRequired?: Maybe<Scalars['Boolean']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    prefixCollapsed?: Maybe<Scalars['Boolean']>;
    prefixDefaultValue?: Maybe<Scalars['String']>;
    prefixEnabled?: Maybe<Scalars['Boolean']>;
    prefixErrorMessage?: Maybe<Scalars['String']>;
    prefixLabel?: Maybe<Scalars['String']>;
    prefixPlaceholder?: Maybe<Scalars['String']>;
    prefixPrePopulate?: Maybe<Scalars['String']>;
    prefixRequired?: Maybe<Scalars['Boolean']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    subfieldLabelPosition?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    useMultipleFields?: Maybe<Scalars['Boolean']>;
};

export type Field_Newsletter = FieldInterface & {
    __typename?: 'Field_Newsletter';
    checkedValue?: Maybe<Scalars['String']>;
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultState?: Maybe<Scalars['Boolean']>;
    description?: Maybe<Scalars['String']>;
    descriptionHtml?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    uncheckedValue?: Maybe<Scalars['String']>;
};

export type Field_Number = FieldInterface & {
    __typename?: 'Field_Number';
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    decimals?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    max?: Maybe<Scalars['String']>;
    min?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type Field_Phone = FieldInterface & {
    __typename?: 'Field_Phone';
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    countryAllowed?: Maybe<Scalars['String']>;
    countryCollapsed?: Maybe<Scalars['Boolean']>;
    countryDefaultValue?: Maybe<Scalars['String']>;
    countryEnabled?: Maybe<Scalars['Boolean']>;
    countryShowDialCode?: Maybe<Scalars['Boolean']>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    subfieldLabelPosition?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type Field_Radio = FieldInterface & {
    __typename?: 'Field_Radio';
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    layout?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    options?: Maybe<Array<Maybe<KeyValueType>>>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type Field_Recipients = FieldInterface & {
    __typename?: 'Field_Recipients';
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    displayType?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    multiple?: Maybe<Scalars['Boolean']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    options?: Maybe<Array<Maybe<KeyValueType>>>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type Field_Repeater = FieldInterface & {
    __typename?: 'Field_Repeater';
    addLabel?: Maybe<Scalars['String']>;
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    contentTable?: Maybe<Scalars['String']>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    /** The field’s nested fields. */
    fields?: Maybe<Array<Maybe<FieldInterface>>>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    maxRows?: Maybe<Scalars['String']>;
    minRows?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    /** The field’s nested rows. */
    nestedRows?: Maybe<Array<Maybe<RowInterface>>>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type Field_Section = FieldInterface & {
    __typename?: 'Field_Section';
    borderColor?: Maybe<Scalars['String']>;
    borderStyle?: Maybe<Scalars['String']>;
    borderWidth?: Maybe<Scalars['String']>;
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type Field_SingleLineText = FieldInterface & {
    __typename?: 'Field_SingleLineText';
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type Field_Table = FieldInterface & {
    __typename?: 'Field_Table';
    addRowLabel?: Maybe<Scalars['String']>;
    columnType?: Maybe<Scalars['String']>;
    columnWidth?: Maybe<Scalars['String']>;
    columns?: Maybe<Array<Maybe<KeyValueType>>>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    defaults?: Maybe<Array<Maybe<KeyValueType>>>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    maxRows?: Maybe<Scalars['String']>;
    minRows?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    searchable?: Maybe<Scalars['String']>;
    static?: Maybe<Scalars['Boolean']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type Field_Tags = FieldInterface & {
    __typename?: 'Field_Tags';
    allowLimit?: Maybe<Scalars['String']>;
    allowMultipleSources?: Maybe<Scalars['String']>;
    allowSelfRelations?: Maybe<Scalars['String']>;
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    displayType?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    labelSource?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    localizeRelations?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    orderBy?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    searchable?: Maybe<Scalars['String']>;
    selectionLabel?: Maybe<Scalars['String']>;
    showSiteMenu?: Maybe<Scalars['String']>;
    source?: Maybe<Scalars['String']>;
    sources?: Maybe<Scalars['String']>;
    targetSiteId?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    validateRelatedElements?: Maybe<Scalars['String']>;
    viewMode?: Maybe<Scalars['String']>;
};

export type Field_Users = FieldInterface & {
    __typename?: 'Field_Users';
    allowSelfRelations?: Maybe<Scalars['String']>;
    columnWidth?: Maybe<Scalars['String']>;
    containerAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    cssClasses?: Maybe<Scalars['String']>;
    defaultValue?: Maybe<Scalars['String']>;
    /** The field’s display name. */
    displayName?: Maybe<Scalars['String']>;
    displayType?: Maybe<Scalars['String']>;
    errorMessage?: Maybe<Scalars['String']>;
    formId?: Maybe<Scalars['String']>;
    /** The field’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    includeInEmail?: Maybe<Scalars['String']>;
    inputAttributes?: Maybe<Array<Maybe<FieldAttribute>>>;
    /** The field’s instructions. */
    instructions?: Maybe<Scalars['String']>;
    instructionsPosition?: Maybe<Scalars['String']>;
    isNested?: Maybe<Scalars['String']>;
    labelPosition?: Maybe<Scalars['String']>;
    labelSource?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Boolean']>;
    limitAmount?: Maybe<Scalars['Int']>;
    limitType?: Maybe<Scalars['String']>;
    localizeRelations?: Maybe<Scalars['String']>;
    /** The field’s name. */
    name?: Maybe<Scalars['String']>;
    orderBy?: Maybe<Scalars['String']>;
    placeholder?: Maybe<Scalars['String']>;
    prePopulate?: Maybe<Scalars['String']>;
    /** Whether the field is required. */
    required?: Maybe<Scalars['Boolean']>;
    searchable?: Maybe<Scalars['String']>;
    selectionLabel?: Maybe<Scalars['String']>;
    showSiteMenu?: Maybe<Scalars['String']>;
    source?: Maybe<Scalars['String']>;
    sources?: Maybe<Scalars['String']>;
    targetSiteId?: Maybe<Scalars['String']>;
    /** The field’s type. */
    type?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    validateRelatedElements?: Maybe<Scalars['String']>;
    viewMode?: Maybe<Scalars['String']>;
};

export type Form = ElementInterface &
    FormInterface & {
        __typename?: 'Form';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        aanbieding?: Maybe<Scalars['String']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        bedrijfsnaam?: Maybe<Scalars['String']>;
        /** The form’s config as JSON. */
        configJson?: Maybe<Scalars['String']>;
        contactpersoon?: Maybe<Scalars['String']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        eMailadres?: Maybe<Scalars['String']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The form’s fields. */
        fields?: Maybe<Array<Maybe<FieldInterface>>>;
        /** The form’s handle. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        looptijd?: Maybe<Scalars['String']>;
        /** The form’s pages. */
        pages?: Maybe<Array<Maybe<PageInterface>>>;
        /** The form’s rows. */
        rows?: Maybe<Array<Maybe<RowInterface>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The form’s settings. */
        settings?: Maybe<FormSettingsType>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The form’s rendered HTML. */
        templateHtml?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        uploadBijpassendeFotos?: Maybe<Array<Maybe<AssetInterface>>>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type Form_CountArgs = {
    field: Scalars['String'];
};

export type FormTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FormUploadBijpassendeFotosArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FormIntegrationsType = {
    __typename?: 'FormIntegrationsType';
    enabled?: Maybe<Scalars['Boolean']>;
    handle?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    settings?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all forms. */
export type FormInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** Whether the element is archived or not. */
    archived?: Maybe<Scalars['Boolean']>;
    /** The form’s config as JSON. */
    configJson?: Maybe<Scalars['String']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** Whether the element is enabled or not. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The form’s fields. */
    fields?: Maybe<Array<Maybe<FieldInterface>>>;
    /** The form’s handle. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The form’s pages. */
    pages?: Maybe<Array<Maybe<PageInterface>>>;
    /** The form’s rows. */
    rows?: Maybe<Array<Maybe<RowInterface>>>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['String']>;
    /** The form’s settings. */
    settings?: Maybe<FormSettingsType>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The form’s rendered HTML. */
    templateHtml?: Maybe<Scalars['String']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted or not. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all forms. */
export type FormInterface_CountArgs = {
    field: Scalars['String'];
};

/** This is the interface implemented by all forms. */
export type FormInterfaceTemplateHtmlArgs = {
    options?: InputMaybe<Scalars['String']>;
    populateFormValues?: InputMaybe<Scalars['String']>;
};

export type FormSettingsType = {
    __typename?: 'FormSettingsType';
    /** The form’s default instructions position for fields. */
    defaultInstructionsPosition?: Maybe<Scalars['String']>;
    /** The form’s default label position for fields. */
    defaultLabelPosition?: Maybe<Scalars['String']>;
    /** Whether to show the form’s current page title. */
    displayCurrentPageTitle?: Maybe<Scalars['Boolean']>;
    /** Whether to show the form’s title. */
    displayFormTitle?: Maybe<Scalars['Boolean']>;
    /** Whether to show the form’s page progress. */
    displayPageProgress?: Maybe<Scalars['Boolean']>;
    /** Whether to show the form’s page tabs. */
    displayPageTabs?: Maybe<Scalars['Boolean']>;
    /** The form’s submit error message. */
    errorMessageHtml?: Maybe<Scalars['String']>;
    /** The form’s integrations. */
    integrations?: Maybe<Array<Maybe<FormIntegrationsType>>>;
    /** Whether to show the form’s loading indicator. */
    loadingIndicator?: Maybe<Scalars['Boolean']>;
    /** The form’s loading indicator text. */
    loadingIndicatorText?: Maybe<Scalars['String']>;
    /** The form’s progress bar position. */
    progressPosition?: Maybe<Scalars['String']>;
    /** The form’s submit action. */
    submitAction?: Maybe<Scalars['String']>;
    /** Whether to hide the form’s success message. */
    submitActionFormHide?: Maybe<Scalars['Boolean']>;
    /** The form’s submit success message. */
    submitActionMessageHtml?: Maybe<Scalars['String']>;
    /** The form’s submit success message timeout. */
    submitActionMessageTimeout?: Maybe<Scalars['Int']>;
    /** The form’s submit redirect option (if in new tab or same tab). */
    submitActionTab?: Maybe<Scalars['String']>;
    /** The form’s submit action URL. */
    submitActionUrl?: Maybe<Scalars['String']>;
    /** The form’s submit method. */
    submitMethod?: Maybe<Scalars['String']>;
    /** Whether to validate the form’s on focus. */
    validationOnFocus?: Maybe<Scalars['Boolean']>;
    /** Whether to validate the form’s on submit. */
    validationOnSubmit?: Maybe<Scalars['Boolean']>;
};

export type GenericAggregationCount = {
    __typename?: 'GenericAggregationCount';
    hits: Scalars['Int'];
    value: Scalars['String'];
};

/**   An image file available through a CDN URL. */
export type GenericImage = Image & {
    __typename?: 'GenericImage';
    /**
     *   A version of the original image transformed to specific parameters.
     *  Note that the available transform options are dependent on the type of image.
     */
    transform?: Maybe<ImageTransform>;
};

/**   An image file available through a CDN URL. */
export type GenericImageTransformArgs = {
    config: ImageTransformConfigEnum;
    format: ImageTransformFormatEnum;
};

/** This is the interface implemented by all global sets. */
export type GlobalSetInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** Whether the element is archived or not. */
    archived?: Maybe<Scalars['Boolean']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** Whether the element is enabled or not. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The handle of the global set. */
    handle?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The name of the global set. */
    name?: Maybe<Scalars['String']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted or not. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all global sets. */
export type GlobalSetInterface_CountArgs = {
    field: Scalars['String'];
};

/**   An image file available through a CDN URL. */
export type Image = {
    /**
     *   A version of the original image transformed to specific parameters.
     *  Note that the available transform options are dependent on the type of image.
     */
    transform?: Maybe<ImageTransform>;
};

/**   An image file available through a CDN URL. */
export type ImageTransformArgs = {
    config: ImageTransformConfigEnum;
    format: ImageTransformFormatEnum;
};

/**   A version of an image transformed according to the requested parameters. */
export type ImageTransform = {
    __typename?: 'ImageTransform';
    /**
     *   This is an extremely downscaled version of the original image which can be used as a placeholder while loading the full image.
     *  This is formatted as a string of base64 encoded WebP data, and is therefore only available on transforms with the `WEBP` format.
     */
    placeholder?: Maybe<Scalars['String']>;
    /**   The aspect ratio of this image. */
    ratio: Scalars['Float'];
    /**   The URL for the largest version of this transform. */
    src: Scalars['String'];
    /**   The different available sizes of this transform, formatted for use in an HTML `srcSet` attribute. */
    srcSet: Scalars['String'];
};

/**   Available image transform configurations. This determines the scaling method and the generated sizes. */
export enum ImageTransformConfigEnum {
    /**
     *  Used for displaying avatar images, generally inside a circular frame. Images are transformed and cropped to fill a 1:1 frame.
     * # Available on avatar image fields.
     */
    Avatar = 'AVATAR',
    /**
     *  Used for displaying images of varying aspect ratio in a gallery. Images are transformed to fit inside a 4:3 frame without cropping.
     * # Available on fields containing multiple images.
     */
    Gallery = 'GALLERY',
    /**
     *  Used for displaying large landscape images in a hero element. Images are transformed and cropped to fill a 4:3 frame.
     * # Available on fields called `mainImage`.
     */
    Hero = 'HERO',
    /**
     *  Used for displaying logos. Images are transformed to fit inside a 1:1 frame and extended with empty space to always produce a 1:1 image.
     * # Available on logo image fields.
     */
    Logo = 'LOGO',
    /**
     *  Used for displaying landscape images in a listing. Images are transformed and cropped to fill a 4:3 frame.
     * # Available on fields called `listImage`.
     */
    Tile = 'TILE',
}

/**
 *   Available image formats for transforms. Use WebP whenever possible for lowest image size.
 *  Note that not all formats are available for every transform.
 */
export enum ImageTransformFormatEnum {
    /**  JPEG is not available for transforms with the `LOGO` config. */
    Jpeg = 'JPEG',
    /**  PNG is only available for transforms with the `LOGO` config. */
    Png = 'PNG',
    /**  WebP is available for all transforms. */
    Webp = 'WEBP',
}

export type KeyValueType = {
    __typename?: 'KeyValueType';
    default?: Maybe<Scalars['Boolean']>;
    handle?: Maybe<Scalars['String']>;
    heading?: Maybe<Scalars['String']>;
    isDefault?: Maybe<Scalars['Boolean']>;
    isOptgroup?: Maybe<Scalars['Boolean']>;
    label?: Maybe<Scalars['String']>;
    optgroup?: Maybe<Scalars['Boolean']>;
    type?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
    width?: Maybe<Scalars['String']>;
};

/**   A set of GPS coordinates. */
export type LatLon = {
    __typename?: 'LatLon';
    /**   The latitude. */
    lat: Scalars['Float'];
    /**   The longitude. */
    lon: Scalars['Float'];
};

/**   A set of GPS coordinates. */
export type LatLonInput = {
    /**   The latitude. */
    lat: Scalars['Float'];
    /**   The longitude. */
    lon: Scalars['Float'];
};

export type LeaveReviewInput = {
    bookingId: Scalars['ID'];
    comment: Scalars['String'];
    ratingHospitality: Scalars['Int'];
    ratingLayout: Scalars['Int'];
    ratingLocation: Scalars['Int'];
    ratingMaintenance: Scalars['Int'];
    ratingValueForMoney: Scalars['Int'];
    tips?: InputMaybe<Scalars['String']>;
    title: Scalars['String'];
    travelGroupType: TravelGroupType;
};

export type LeaveReviewPayload = {
    __typename?: 'LeaveReviewPayload';
    review?: Maybe<Review>;
};

export type LeaveUnauthenticatedReviewInput = {
    bookingHash: Scalars['ID'];
    comment: Scalars['String'];
    ratingHospitality: Scalars['Int'];
    ratingLayout: Scalars['Int'];
    ratingLocation: Scalars['Int'];
    ratingMaintenance: Scalars['Int'];
    ratingValueForMoney: Scalars['Int'];
    tips?: InputMaybe<Scalars['String']>;
    title: Scalars['String'];
    travelGroupType: TravelGroupType;
};

/** This is the interface implemented by all matrix blocks. */
export type MatrixBlockInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** Whether the element is archived or not. */
    archived?: Maybe<Scalars['Boolean']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** Whether the element is enabled or not. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The ID of the field that owns the matrix block. */
    fieldId?: Maybe<Scalars['Int']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The ID of the element that owns the matrix block. */
    ownerId?: Maybe<Scalars['Int']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** The sort order of the matrix block within the owner element field. */
    sortOrder?: Maybe<Scalars['Int']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted or not. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The handle of the matrix block’s type. */
    typeHandle?: Maybe<Scalars['String']>;
    /** The ID of the matrix block’s type. */
    typeId?: Maybe<Scalars['Int']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all matrix blocks. */
export type MatrixBlockInterface_CountArgs = {
    field: Scalars['String'];
};

export type Mutation = {
    __typename?: 'Mutation';
    /**
     *   Add an Activity to a Customer's favorites.
     *  Requires an active login session.
     */
    addFavoriteActivity: AddFavoriteActivityPayload;
    /**
     *   Add a Company to a Customer's favorites.
     *  Requires an active login session.
     */
    addFavoriteCompany: AddFavoriteCompanyPayload;
    /**
     *   Add a RentalUnit to a Customer's favorites.
     *  Requires an active login session.
     */
    addFavoriteRentalUnit: AddFavoriteRentalUnitPayload;
    /**
     *   Alter an existing Booking.
     *  The Booking must belong to the currently logged in Customer and Booking.isAlterable must be TRUE.
     */
    alterBooking: AlterBookingPayload;
    /**
     *   Cancel an existing Booking.
     *  The Booking must belong to the currently logged in Customer and Booking.isCancellable must be TRUE.
     */
    cancelBooking: CancelBookingPayload;
    /**   Initiate final checkout for an existing Cart. */
    checkoutCart: CheckoutCartPayload;
    /**
     *   Leave a review for a Booking.
     *  The Booking must be accepted, in the past, linked to the currently logged in Customer, and not already have a Review.
     */
    leaveReview: LeaveReviewPayload;
    /**   Leave a review without an active login session by supplying the hash from a review request email. */
    leaveUnauthenticatedReview: LeaveReviewPayload;
    /**
     *   Initiate payment for an existing Order.
     *  The Order must belong to the currently logged in Customer.
     */
    payOrder: PayOrderPayload;
    ping?: Maybe<Scalars['String']>;
    /**   Register a new Customer. */
    register: RegisterPayload;
    /**
     *   Remove an item for a Customer's favorites.
     *  The Favorite must belong to the currently logged in Customer.
     */
    removeFavorite: RemoveFavoritePayload;
    /**
     *   Request a login email for a Customer.
     *  The email contains a link which supplies an `authCode` for the oAuth token endpoint.
     */
    sendAuthenticationEmail: SendAuthenticationEmailPayload;
    /**   Create or update a Cart with contents and/or details. */
    updateCart: UpdateCartPayload;
    /**
     *   Update the details of an existing Customer.
     *  This can only be performed on the currently logged in Customer.
     */
    updateCustomer: UpdateCustomerPayload;
};

export type MutationAddFavoriteActivityArgs = {
    input: AddFavoriteActivityInput;
};

export type MutationAddFavoriteCompanyArgs = {
    input: AddFavoriteCompanyInput;
};

export type MutationAddFavoriteRentalUnitArgs = {
    input: AddFavoriteRentalUnitInput;
};

export type MutationAlterBookingArgs = {
    input: AlterBookingInput;
};

export type MutationCancelBookingArgs = {
    input: CancelBookingInput;
};

export type MutationCheckoutCartArgs = {
    input: CheckoutCartInput;
};

export type MutationLeaveReviewArgs = {
    input: LeaveReviewInput;
};

export type MutationLeaveUnauthenticatedReviewArgs = {
    input: LeaveUnauthenticatedReviewInput;
};

export type MutationPayOrderArgs = {
    input: PayOrderInput;
};

export type MutationRegisterArgs = {
    input: RegisterInput;
};

export type MutationRemoveFavoriteArgs = {
    input: RemoveFavoriteInput;
};

export type MutationSendAuthenticationEmailArgs = {
    input: SendAuthenticationEmailInput;
};

export type MutationUpdateCartArgs = {
    input: UpdateCartInput;
};

export type MutationUpdateCustomerArgs = {
    input: UpdateCustomerInput;
};

export type NormalOperatingHours = {
    __typename?: 'NormalOperatingHours';
    friday: Array<TimeOpenClosed>;
    monday: Array<TimeOpenClosed>;
    saturday: Array<TimeOpenClosed>;
    sunday: Array<TimeOpenClosed>;
    thursday: Array<TimeOpenClosed>;
    tuesday: Array<TimeOpenClosed>;
    wednesday: Array<TimeOpenClosed>;
};

export type Order = {
    __typename?: 'Order';
    affiliate?: Maybe<Affiliate>;
    affiliateReference?: Maybe<Scalars['String']>;
    agreedToTerms?: Maybe<Scalars['Boolean']>;
    createdDate?: Maybe<Scalars['String']>;
    customer?: Maybe<Customer>;
    downloadOverviewUrl?: Maybe<Scalars['String']>;
    errors: Array<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    invoiceAddress?: Maybe<Address>;
    isDeskOrder: Scalars['Boolean'];
    newsletterOptin?: Maybe<Scalars['Boolean']>;
    number?: Maybe<Scalars['String']>;
    orderItems: Array<OrderItem>;
    paymentOptions?: Maybe<PaymentOptions>;
    payments: Array<Payment>;
    totalPrice: Scalars['Float'];
};

export type OrderItem = {
    errors: Array<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    paymentPrice: Scalars['Float'];
    priceLines: Array<PriceLine>;
    totalPrice: Scalars['Float'];
};

/** This is the interface implemented by all pages. */
export type PageInterface = {
    /** The page’s fields. */
    fields?: Maybe<Array<Maybe<FieldInterface>>>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The page’s name. */
    name?: Maybe<Scalars['String']>;
    /** The page’s rows. */
    rows?: Maybe<Array<Maybe<RowInterface>>>;
    /** The page’s settings. */
    settings?: Maybe<PageSettingsInterface>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all pages. */
export type PageSettingsInterface = {
    /** The page’s back button label. */
    backButtonLabel?: Maybe<Scalars['String']>;
    /** The page’s button positions. */
    buttonsPosition?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether to show the page’s back button. */
    showBackButton?: Maybe<Scalars['Boolean']>;
    /** The page’s submit button label. */
    submitButtonLabel?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type PageSettingsType = PageSettingsInterface & {
    __typename?: 'PageSettingsType';
    /** The page’s back button label. */
    backButtonLabel?: Maybe<Scalars['String']>;
    /** The page’s button positions. */
    buttonsPosition?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** Whether to show the page’s back button. */
    showBackButton?: Maybe<Scalars['Boolean']>;
    /** The page’s submit button label. */
    submitButtonLabel?: Maybe<Scalars['String']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type PageType = PageInterface & {
    __typename?: 'PageType';
    /** The page’s fields. */
    fields?: Maybe<Array<Maybe<FieldInterface>>>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The page’s name. */
    name?: Maybe<Scalars['String']>;
    /** The page’s rows. */
    rows?: Maybe<Array<Maybe<RowInterface>>>;
    /** The page’s settings. */
    settings?: Maybe<PageSettingsInterface>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type PayOrderInput = {
    orderId: Scalars['ID'];
    paymentOptions: PaymentOptionsInput;
};

export type PayOrderPayload = {
    __typename?: 'PayOrderPayload';
    order?: Maybe<Order>;
    redirectUrl?: Maybe<Scalars['String']>;
};

export type Payment = {
    __typename?: 'Payment';
    amount: Scalars['Float'];
    date: Scalars['String'];
    method: PaymentMethodEnum;
    status: PaymentStatusEnum;
};

export enum PaymentMethodEnum {
    ApplePay = 'APPLE_PAY',
    Bancontact = 'BANCONTACT',
    BankAccount = 'BANK_ACCOUNT',
    Cash = 'CASH',
    CreditCard = 'CREDIT_CARD',
    CreditNote = 'CREDIT_NOTE',
    DeskBooking = 'DESK_BOOKING',
    GiftCard = 'GIFT_CARD',
    Giropay = 'GIROPAY',
    Ideal = 'IDEAL',
    IngHomepay = 'ING_HOMEPAY',
    None = 'NONE',
    Other = 'OTHER',
    Paypal = 'PAYPAL',
    SepaDirectDebit = 'SEPA_DIRECT_DEBIT',
    SepaTransfer = 'SEPA_TRANSFER',
    Sofort = 'SOFORT',
}

export type PaymentOptions = {
    __typename?: 'PaymentOptions';
    /**   The methods available for this payment. */
    availableMethods: Array<PaymentMethodEnum>;
    /**   The date before which the totalAmountDue must be paid. Formatted as: yyyy-mm-dd. */
    fullPaymentDueDate?: Maybe<Scalars['String']>;
    /**   The minimum amount that needs to be paid at this point. */
    partialPaymentAmount: Scalars['Float'];
    /**   The total remaining amount due to be paid through Travelbase. */
    totalAmountDue: Scalars['Float'];
};

export type PaymentOptionsInput = {
    /**   Method "SEPA_DIRECT_DEBIT" only, this must be supplied */
    accountHolderName?: InputMaybe<Scalars['String']>;
    /**   Method "IDEAL" only, directs the user straight to the selected issuer's payment flow (not required) */
    iDealIssuer?: InputMaybe<Scalars['String']>;
    /**   Method "SEPA_DIRECT_DEBIT" only, this must be supplied and must be a valid IBAN */
    iban?: InputMaybe<Scalars['String']>;
    landingUrl: Scalars['String'];
    method: PaymentMethodEnum;
    payPartialAmount: Scalars['Boolean'];
};

export enum PaymentStatusEnum {
    Completed = 'COMPLETED',
    Failed = 'FAILED',
    Initializing = 'INITIALIZING',
    Pending = 'PENDING',
    Provisional = 'PROVISIONAL',
}

export type Place = {
    __typename?: 'Place';
    id: Scalars['ID'];
    name: Scalars['String'];
    position: Scalars['Int'];
};

/**   Description of a POI and the distance to it from the parent object. */
export type PointOfInterest = {
    __typename?: 'PointOfInterest';
    /**   The distance in meters from the parent object to the POI (as the crow flies). */
    distance: Scalars['Int'];
    /**
     *   Name of the POI.
     *  This value is language dependent.
     */
    name: Scalars['String'];
};

export type PriceLine = {
    __typename?: 'PriceLine';
    category?: Maybe<PriceLineCategoryEnum>;
    label: Scalars['String'];
    quantity?: Maybe<Scalars['String']>;
    totalPrice: Scalars['Float'];
    unitPrice?: Maybe<Scalars['Float']>;
};

export enum PriceLineCategoryEnum {
    Accommodation = 'ACCOMMODATION',
    AdminCosts = 'ADMIN_COSTS',
    Charges = 'CHARGES',
    Insurance = 'INSURANCE',
    NotInTotal = 'NOT_IN_TOTAL',
    Special = 'SPECIAL',
    Surcharges = 'SURCHARGES',
}

export type PropertyFilterCount = {
    __typename?: 'PropertyFilterCount';
    hits: Scalars['Int'];
    property: Scalars['String'];
};

/**   Properties to filter by in a search query. */
export type PropertyFilterInput = {
    /**
     *   The handles of the properties to filter by.
     *  Multiples handles are combined as "OR".
     *  Be aware that when treating filters as "OR", the corresponding FilterBuckets in the result are essentially useless as possible values will be excluded based on already active filters.
     */
    properties: Array<Scalars['String']>;
};

export type Query = {
    __typename?: 'Query';
    /**   Retrieve details for a specific Accommodation. */
    accommodation?: Maybe<Accommodation>;
    /**   Retrieve details for a specific set of Accommodations. */
    accommodationsBySlug: Array<Accommodation>;
    /**   Search within all available Activities. */
    activities: ActivitySearchResult;
    /**   Retrieve details for a specific set of Activities. */
    activitiesBySlug: Array<Activity>;
    /**   Retrieve details for a specific Activity. */
    activity?: Maybe<Activity>;
    /**   Retrieve details for all available ActivityThemes. */
    activityThemes: Array<ActivityTheme>;
    /** This query is used to query for a single asset. */
    asset?: Maybe<AssetInterface>;
    /** This query is used to return the number of assets. */
    assetCount: Scalars['Int'];
    /** This query is used to query for assets. */
    assets?: Maybe<Array<Maybe<AssetInterface>>>;
    /**
     *   Preview the effects of altering a specific Booking.
     *  The booking must belong to the currently logged in Customer.
     */
    bookingAlterationPreview?: Maybe<BookingChangePreview>;
    /**
     *   Preview the effects of cancelling a specific Booking.
     *  The booking must belong to the currently logged in Customer.
     */
    bookingCancellationPreview?: Maybe<BookingChangePreview>;
    /**   Retrieve details for a specific Cart. */
    cart?: Maybe<Cart>;
    /**   Search within all available Companies. */
    companies: CompanySearchResult;
    /**   Retrieve details for a specific set of Companies. */
    companiesBySlug: Array<Company>;
    /**   Retrieve details for a specific Company. */
    company?: Maybe<Company>;
    /**   Retrieve details for all available CompanyCategories. */
    companyCategories: Array<CompanyCategory>;
    /** This query is used to query for entries. */
    entries?: Maybe<Array<Maybe<EntryInterface>>>;
    /** This query is used to query for a single entry. */
    entry?: Maybe<EntryInterface>;
    /** This query is used to return the number of entries. */
    entryCount: Scalars['Int'];
    /** This query is used to query for a single form. */
    form?: Maybe<FormInterface>;
    /** This query is used to query for a single form. */
    formieForm?: Maybe<FormInterface>;
    /** This query is used to query for forms. */
    formieForms?: Maybe<Array<Maybe<FormInterface>>>;
    /** This query is used to query for a single submission. */
    formieSubmission?: Maybe<SubmissionInterface>;
    /** This query is used to query for submissions. */
    formieSubmissions?: Maybe<Array<Maybe<SubmissionInterface>>>;
    /** This query is used to query for forms. */
    forms?: Maybe<Array<Maybe<FormInterface>>>;
    /** Search within both CMS and Travelbase content. */
    globalSearch?: Maybe<SearchResult>;
    /** This query is used to query for a single global set. */
    globalSet?: Maybe<GlobalSetInterface>;
    /** This query is used to query for global sets. */
    globalSets?: Maybe<Array<Maybe<GlobalSetInterface>>>;
    /**   Retrieve details for a specific Order. */
    order?: Maybe<Order>;
    ping?: Maybe<Scalars['String']>;
    /**   Retrieve details for all available Places. */
    places: Array<Place>;
    /**   Retrieve details for a specific RentalUnit. */
    rentalUnit?: Maybe<RentalUnit>;
    /**   Retrieve details for a specific set of RentalUnits. */
    rentalUnits: Array<RentalUnit>;
    /**
     * This query is used to query for Retour redirects.
     * @deprecated This query is deprecated and will be removed in the future. You should use `retourResolveRedirect` instead.
     */
    retour?: Maybe<RetourInterface>;
    /** This query is used to query for all Retour redirects for a site. */
    retourRedirects?: Maybe<Array<Maybe<RetourInterface>>>;
    /** This query is used to query for Retour redirects. */
    retourResolveRedirect?: Maybe<RetourInterface>;
    /**
     * This query is used to search for keywords.
     * @deprecated Limited and contains bugs, use `globalSearch` instead.
     */
    search?: Maybe<Array<Maybe<SearchType>>>;
    /**   Search within all available RentalUnits. */
    searchRentalUnits: SearchRentalUnitsResult;
    /**
     *   Search for available Trips.
     *  Note that this will only ever return and/or count 1 Trip per RentalUnit, even if multiple Trips for the same RentalUnit match the search parameters.
     */
    searchTrips: SearchTripsResult;
    /**   Retrieve details for a specific Special. */
    special?: Maybe<Special>;
    /** This query is used to query for a single submission. */
    submission?: Maybe<SubmissionInterface>;
    /** This query is used to query for submissions. */
    submissions?: Maybe<Array<Maybe<SubmissionInterface>>>;
    /** This query is used to query for a single tag. */
    tag?: Maybe<TagInterface>;
    /** This query is used to return the number of tags. */
    tagCount: Scalars['Int'];
    /** This query is used to query for tags. */
    tags?: Maybe<Array<Maybe<TagInterface>>>;
    /** This query is used to get all the accommodations for the sitemap. */
    travelbaseObjectsSitemap?: Maybe<Array<Maybe<TravelbaseObjectsSitemapType>>>;
    /**   Retrieve all possible properties by which RentalUnits can be filtered. */
    unitFilterProperties: Array<UnitFilterPropertyCategory>;
    /** @deprecated Use searchRentalUnits or searchTrips instead */
    unitSearch: UnitSearchResult;
    /** @deprecated Use searchTrips.stats.dateCounts instead */
    unitSearchAlternativeDates: Array<DateHitCount>;
    /**   Returns the currently logged in Customer, if any. */
    viewer?: Maybe<Customer>;
};

export type QueryAccommodationArgs = {
    slug: Scalars['String'];
};

export type QueryAccommodationsBySlugArgs = {
    slugs: Array<Scalars['String']>;
};

export type QueryActivitiesArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order?: InputMaybe<ActivitySearchOrderEnum>;
    params: ActivitySearchParamsInput;
};

export type QueryActivitiesBySlugArgs = {
    slugs: Array<Scalars['String']>;
};

export type QueryActivityArgs = {
    slug: Scalars['String'];
};

export type QueryAssetArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryAssetCountArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryAssetsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryBookingAlterationPreviewArgs = {
    input: AlterBookingInput;
};

export type QueryBookingCancellationPreviewArgs = {
    input: CancelBookingInput;
};

export type QueryCartArgs = {
    id: Scalars['ID'];
};

export type QueryCompaniesArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    params: CompanySearchParamsInput;
    sortByDistanceTo?: InputMaybe<LatLonInput>;
};

export type QueryCompaniesBySlugArgs = {
    slugs: Array<Scalars['String']>;
};

export type QueryCompanyArgs = {
    slug: Scalars['String'];
};

export type QueryEntriesArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryEntryArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryEntryCountArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryFormArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    handle?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryFormieFormArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    handle?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryFormieFormsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    handle?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryFormieSubmissionArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    form?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryFormieSubmissionsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    form?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryFormsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    handle?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGlobalSearchArgs = {
    language: Scalars['String'];
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    query: Scalars['String'];
    type?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryGlobalSetArgs = {
    active?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactEmailText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactMessengerText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactMyTexelText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactPhoneText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactShopText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactWhatsappText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    handle?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGlobalSetsArgs = {
    active?: InputMaybe<Scalars['Boolean']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactEmailText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactMessengerText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactMyTexelText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactPhoneText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactShopText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    contactWhatsappText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    handle?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryOrderArgs = {
    id: Scalars['ID'];
};

export type QueryRentalUnitArgs = {
    slug: Scalars['String'];
};

export type QueryRentalUnitsArgs = {
    slugs: Array<Scalars['String']>;
};

export type QueryRetourArgs = {
    site?: InputMaybe<Scalars['String']>;
    siteId?: InputMaybe<Scalars['Int']>;
    uri?: InputMaybe<Scalars['String']>;
};

export type QueryRetourRedirectsArgs = {
    site?: InputMaybe<Scalars['String']>;
    siteId?: InputMaybe<Scalars['Int']>;
};

export type QueryRetourResolveRedirectArgs = {
    site?: InputMaybe<Scalars['String']>;
    siteId?: InputMaybe<Scalars['Int']>;
    uri?: InputMaybe<Scalars['String']>;
};

export type QuerySearchArgs = {
    language?: InputMaybe<Scalars['String']>;
    q?: InputMaybe<Scalars['String']>;
    size?: InputMaybe<Scalars['Int']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySearchRentalUnitsArgs = {
    babies?: InputMaybe<Scalars['Int']>;
    filters?: InputMaybe<Array<PropertyFilterInput>>;
    limit?: InputMaybe<Scalars['Int']>;
    mapArea?: InputMaybe<BoundingBoxInput>;
    offset?: InputMaybe<Scalars['Int']>;
    order?: InputMaybe<SearchRentalUnitsOrderEnum>;
    persons?: InputMaybe<Scalars['Int']>;
    pets?: InputMaybe<Scalars['Int']>;
};

export type QuerySearchTripsArgs = {
    babies: Scalars['Int'];
    date: Array<Scalars['String']>;
    duration: Array<Scalars['Int']>;
    filters?: InputMaybe<Array<PropertyFilterInput>>;
    mapArea?: InputMaybe<BoundingBoxInput>;
    persons: Scalars['Int'];
    pets: Scalars['Int'];
    price?: InputMaybe<RangeFilterInput>;
    specialId?: InputMaybe<Array<Scalars['ID']>>;
    tripType?: InputMaybe<Array<TripTypeEnum>>;
};

export type QuerySpecialArgs = {
    id: Scalars['ID'];
};

export type QuerySubmissionArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    form?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QuerySubmissionsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    form?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryTagArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryTagCountArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryTravelbaseObjectsSitemapArgs = {
    language?: InputMaybe<Scalars['String']>;
    size?: InputMaybe<Scalars['Int']>;
};

export type QueryUnitSearchArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    mapArea?: InputMaybe<BoundingBoxInput>;
    offset?: InputMaybe<Scalars['Int']>;
    order?: InputMaybe<UnitSearchOrderEnum>;
    params: UnitSearchParamsInput;
};

export type QueryUnitSearchAlternativeDatesArgs = {
    params: UnitAndTripSearchParamsInput;
};

export type RangeFilterInput = {
    max?: InputMaybe<Scalars['Float']>;
    min?: InputMaybe<Scalars['Float']>;
};

export type Rate = {
    __typename?: 'Rate';
    id: Scalars['ID'];
    /**   This value is language dependent. */
    name: Scalars['String'];
    position: Scalars['Int'];
    price: Scalars['Float'];
};

export type RegisterInput = {
    agreedToTerms: Scalars['Boolean'];
    email: Scalars['String'];
    landingUrl: Scalars['String'];
    newsletterOptIn: Scalars['Boolean'];
};

export type RegisterPayload = {
    __typename?: 'RegisterPayload';
    customer?: Maybe<Customer>;
};

export type RemoveFavoriteInput = {
    favoriteId: Scalars['ID'];
};

export type RemoveFavoritePayload = {
    __typename?: 'RemoveFavoritePayload';
    success: Scalars['Boolean'];
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnit = {
    __typename?: 'RentalUnit';
    /**   The Accommodation this RentalUnit is a part of. */
    accommodation: Accommodation;
    /**   Search for Trips in other RentalUnits within the same Accommodation. */
    alternativesInAccommodation?: Maybe<UnitSearchResult>;
    /**   The amount of bathrooms present in this RentalUnit. */
    amountBathrooms?: Maybe<Scalars['Int']>;
    /**   The amount of bedrooms present in this RentalUnit. */
    amountBedrooms?: Maybe<Scalars['Int']>;
    /**   The amount of beds present in this RentalUnit. */
    amountBeds?: Maybe<Scalars['Int']>;
    /**   The amount of toilets present in this RentalUnit. */
    amountToilets?: Maybe<Scalars['Int']>;
    /**   The attributes assigned to this RentalUnit, organized into categories. */
    attributeCategories: Array<AttributeCategory>;
    /**   Indicates the Travelbase brand this RentalUnit belongs to (used on aggregate sites). */
    brand: Scalars['String'];
    /**   Whether or not cancellation insurance can be purchased during checkout for this RentalUnit. */
    cancellationInsuranceAvailable: Scalars['Boolean'];
    /**   Unique code representing this RentalUnit to facilitate communication with partners and customers. */
    code: Scalars['String'];
    /**   Whether or not contents insurance can be purchased during checkout for this RentalUnit. */
    contentsInsuranceAvailable: Scalars['Boolean'];
    /**
     *   An optional description of features unique to this RentalUnit.
     *  This value is language dependent.
     */
    descriptionExtras?: Maybe<Scalars['String']>;
    /**
     *   A general description of the RentalUnit, describing its layout.
     *  This value is language dependent.
     */
    descriptionLayout: Scalars['String'];
    /**
     *   A text containing important information for any (prospective) guests.
     *  This information is also displayed on the booking voucher.
     *  This value is language dependent.
     */
    descriptionParticulars?: Maybe<Scalars['String']>;
    /**
     *   The first date for which a Trip is available, if any.
     *  Formatted as yyyy-mm-dd.
     */
    firstTripDate?: Maybe<Scalars['String']>;
    /**   If set, bookings for this RentalUnit can be cancelled with full refund up to this many days before arrival. */
    flexCancellationDays?: Maybe<Scalars['Int']>;
    /**   A URL for an embeddable 3D view of the RentalUnit. */
    fullCircleImageUrl?: Maybe<Scalars['String']>;
    /**   Way to check if this RentalUnit matches a specific filter property. */
    hasFilterProperty: Scalars['Boolean'];
    /**   Unique ID representing this RentalUnit. */
    id: Scalars['ID'];
    /**   A set of images intended to be displayed as some form of photo gallery. */
    images: Array<AccommodationImage>;
    /**   An image (photo) intended for use in a listing/as a thumbnail. */
    listImage: GenericImage;
    /**   The living area (in square meters) of the RentalUnit. */
    livingArea?: Maybe<Scalars['Int']>;
    /**   An image (photo) intended for use in a large (hero) display. */
    mainImage?: Maybe<GenericImage>;
    /**   The maximum amount of babies that may be brought along without counting against the occupancy and without being charged as an occupant. */
    maxExtraBabyOccupancy: Scalars['Int'];
    /**   The maximum travel party size for which this RentalUnit can be booked. */
    maxOccupancy: Scalars['Int'];
    /**   The minimum travel party size for which this RentalUnit can be booked. */
    minOccupancy: Scalars['Int'];
    /**   The municipal registration number for this RentalUnit. */
    municipalRegistration?: Maybe<Scalars['String']>;
    /**   The name of this RentalUnit. */
    name: Scalars['String'];
    /**   The outdoor area (in square meters) private to the RentalUnit. */
    outdoorArea?: Maybe<Scalars['Int']>;
    /**   The amount of pets allowed in this RentalUnit. */
    petsAllowed: Scalars['Int'];
    /**
     *   Search for alternative RentalUnits given the specified search parameters.
     *  Alternatives will always have the same `type` and `maxOccupancy`, and will prioritize similar `livingArea` and geographic location.
     */
    relevantAlternatives?: Maybe<UnitSearchResult>;
    /**   A summary of the reviews for this RentalUnit. */
    reviewStats?: Maybe<ReviewStats>;
    /**   The reviews submitted by past guests for this RentalUnit. */
    reviews: Array<Review>;
    /**   Unique slug to represent this RentalUnit, most commonly in URLs. */
    slug: Scalars['String'];
    /**   The surcharges valid on the specified arrival date, or today if no date is submitted. */
    surcharges: Array<RentalUnitSurcharge>;
    /**
     *   This is a short "marketing-oriented" description used as a display title.
     *  This value is language dependent.
     */
    tagline: Scalars['String'];
    /**   Search within available Trips for this RentalUnit in a specific time period. */
    trips: Array<Trip>;
    /**   The type of this RentalUnit. */
    type: RentalUnitTypeEnum;
    /**   A URL for an embeddable video presenting the RentalUnit. */
    videoUrl?: Maybe<Scalars['String']>;
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitAlternativesInAccommodationArgs = {
    params?: InputMaybe<UnitSearchParamsInput>;
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitHasFilterPropertyArgs = {
    handle: Scalars['String'];
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitRelevantAlternativesArgs = {
    params?: InputMaybe<UnitSearchParamsInput>;
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitReviewsArgs = {
    limit: Scalars['Int'];
    offset?: InputMaybe<Scalars['Int']>;
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitSurchargesArgs = {
    arrivalDate?: InputMaybe<Scalars['String']>;
};

/**
 *   This is a representation of a specific (type of) rental object within an Accommodation.
 *  For example: a 2-person hotel room with ocean view (of which there are multiple) or a privately owned vacation home (of which there is only one).
 */
export type RentalUnitTripsArgs = {
    babies: Scalars['Int'];
    endDate: Scalars['String'];
    persons: Scalars['Int'];
    pets: Scalars['Int'];
    startDate: Scalars['String'];
};

export type RentalUnitSurcharge = {
    __typename?: 'RentalUnitSurcharge';
    calculation: SurchargeCalculationEnum;
    /**   This value is language dependent. */
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    maxAmount: Scalars['Int'];
    minAmount: Scalars['Int'];
    /**   This value is language dependent. */
    name: Scalars['String'];
    unitPrice: Scalars['Float'];
};

/**   Types of RentalUnit. */
export enum RentalUnitTypeEnum {
    Bed = 'BED',
    Boat = 'BOAT',
    CamperSpot = 'CAMPER_SPOT',
    CampingPitch = 'CAMPING_PITCH',
    Home = 'HOME',
    Room = 'ROOM',
    Tent = 'TENT',
}

/** This is the interface implemented by Retour. */
export type RetourInterface = {
    /** The id of the Element associated with this redirect (unused/vestigial). */
    associatedElementId?: Maybe<Scalars['Int']>;
    /** Whether the redirect is enabled or not. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The number of times this redirect has been hit. */
    hitCount?: Maybe<Scalars['Int']>;
    /** A datetime string of when this redirect was last hit. */
    hitLastTime?: Maybe<Scalars['String']>;
    /** The id of the redirect. */
    id?: Maybe<Scalars['Int']>;
    /** The URL that should be redirected to. */
    redirectDestUrl?: Maybe<Scalars['String']>;
    /** The http status code that should be used for the redirect. */
    redirectHttpCode?: Maybe<Scalars['Int']>;
    /** Whether an `exactmatch` or `regexmatch` should be used when matching the URL. */
    redirectMatchType?: Maybe<Scalars['String']>;
    /** Should the legacy URL be matched by path or by full URL? */
    redirectSrcMatch?: Maybe<Scalars['String']>;
    /** The unparsed URL pattern that Retour should match. */
    redirectSrcUrl?: Maybe<Scalars['String']>;
    /** The parsed URL pattern that Retour should match. */
    redirectSrcUrlParsed?: Maybe<Scalars['String']>;
    /** The site handle of the redirect (or null for all sites). */
    site?: Maybe<Scalars['String']>;
    /** The siteId of the redirect (0 or null for all sites). */
    siteId?: Maybe<Scalars['Int']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

/** This entity has all the Retour fields */
export type RetourType = RetourInterface & {
    __typename?: 'RetourType';
    /** The id of the Element associated with this redirect (unused/vestigial). */
    associatedElementId?: Maybe<Scalars['Int']>;
    /** Whether the redirect is enabled or not. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The number of times this redirect has been hit. */
    hitCount?: Maybe<Scalars['Int']>;
    /** A datetime string of when this redirect was last hit. */
    hitLastTime?: Maybe<Scalars['String']>;
    /** The id of the redirect. */
    id?: Maybe<Scalars['Int']>;
    /** The URL that should be redirected to. */
    redirectDestUrl?: Maybe<Scalars['String']>;
    /** The http status code that should be used for the redirect. */
    redirectHttpCode?: Maybe<Scalars['Int']>;
    /** Whether an `exactmatch` or `regexmatch` should be used when matching the URL. */
    redirectMatchType?: Maybe<Scalars['String']>;
    /** Should the legacy URL be matched by path or by full URL? */
    redirectSrcMatch?: Maybe<Scalars['String']>;
    /** The unparsed URL pattern that Retour should match. */
    redirectSrcUrl?: Maybe<Scalars['String']>;
    /** The parsed URL pattern that Retour should match. */
    redirectSrcUrlParsed?: Maybe<Scalars['String']>;
    /** The site handle of the redirect (or null for all sites). */
    site?: Maybe<Scalars['String']>;
    /** The siteId of the redirect (0 or null for all sites). */
    siteId?: Maybe<Scalars['Int']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

/**   A review of a RentalUnit submitted by a Customer. */
export type Review = {
    __typename?: 'Review';
    /** @deprecated No longer supported */
    anonymous?: Maybe<Scalars['Boolean']>;
    /**   The average of the specific ratings given. */
    average: Scalars['Float'];
    /**   The city the Customer is from. */
    city?: Maybe<Scalars['String']>;
    /**   The main text submitted by the Customer. */
    comment: Scalars['String'];
    /**
     *   The date this Review was submitted.
     *  Formatted as yyyy-mm-dd.
     */
    date: Scalars['String'];
    /** @deprecated No longer supported */
    displayName?: Maybe<Scalars['String']>;
    /**   Unique ID representing this Review. */
    id: Scalars['ID'];
    /**
     *   The language in which this Review was written.
     *  This is a 2-letter language code.
     */
    language?: Maybe<Scalars['String']>;
    /**   Rating (1-10) of the hospitality provided. */
    ratingHospitality?: Maybe<Scalars['Int']>;
    /**   Rating (1-10) of the RentalUnit's layout. */
    ratingLayout?: Maybe<Scalars['Int']>;
    /**   Rating (1-10) of the location of the Accommodation. */
    ratingLocation?: Maybe<Scalars['Int']>;
    /**   Rating (1-10) of the level of maintenance. */
    ratingMaintenance?: Maybe<Scalars['Int']>;
    /**   Rating (1-10) of value for money. */
    ratingValueForMoney?: Maybe<Scalars['Int']>;
    /**   The reply to this review submitted by the Partner, if any. */
    reply?: Maybe<Scalars['String']>;
    /**
     *   Tips the Customer submitted for the Partner, if any.
     *  This field is not publicly visible, only logged in Customers can see this for the Reviews they submitted themselves.
     */
    tips?: Maybe<Scalars['String']>;
    /**   The title/short summary the Customer submitted. */
    title: Scalars['String'];
    /**
     *   The type of travel group this Customer visited the RentalUnit with.
     *  This field is not publicly visible, only logged in Customers can see this for the Reviews they submitted themselves.
     */
    travelGroupType?: Maybe<TravelGroupType>;
};

/**   Summary of the Reviews submitted. */
export type ReviewStats = {
    __typename?: 'ReviewStats';
    /**   The average of all ratings across all Reviews. */
    average: Scalars['Float'];
    /**   Amount of Reviews available. */
    count: Scalars['Int'];
    /**   The average of the `hospitality` ratings across all Reviews. */
    hospitality: Scalars['Float'];
    /**   The average of the `layout` ratings across all Reviews. */
    layout: Scalars['Float'];
    /**   The average of the `location` ratings across all Reviews. */
    location: Scalars['Float'];
    /**   The average of the `maintenance` ratings across all Reviews. */
    maintenance: Scalars['Float'];
    /**   The average of the `valueForMoney` ratings across all Reviews. */
    valueForMoney: Scalars['Float'];
};

/** This is the interface implemented by all rows. */
export type RowInterface = {
    /** The row’s fields. */
    fields?: Maybe<Array<Maybe<FieldInterface>>>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type RowType = RowInterface & {
    __typename?: 'RowType';
    /** The row’s fields. */
    fields?: Maybe<Array<Maybe<FieldInterface>>>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
};

export type SearchImage = {
    __typename?: 'SearchImage';
    /** The image's extension */
    extension?: Maybe<Scalars['String']>;
    /** The image's placeholderUrl */
    placeholderUrl?: Maybe<Scalars['String']>;
    /** The image's srcset */
    srcset?: Maybe<Scalars['String']>;
    /** The image's srcsetWebp */
    srcsetWebp?: Maybe<Scalars['String']>;
    /** The image's title */
    title?: Maybe<Scalars['String']>;
    /** The image's url */
    url?: Maybe<Scalars['String']>;
};

/**   RentalUnit search result ordering options. */
export enum SearchRentalUnitsOrderEnum {
    /**
     *  Randomize order.
     * # The random seed used is the current date, so the order will be identical between pageloads and between users, but change daily.
     */
    RandomBoosted = 'RANDOM_BOOSTED',
    /**  Sort by amount of recent bookings. */
    RecentBookingsBoosted = 'RECENT_BOOKINGS_BOOSTED',
    /**  Sort by average RentalUnit review rating, descending. */
    ReviewRatingDesc = 'REVIEW_RATING_DESC',
}

/**   Results of a RentalUnit search query. */
export type SearchRentalUnitsResult = {
    __typename?: 'SearchRentalUnitsResult';
    /**   The search results. */
    hits: Array<RentalUnit>;
    /**   Available filter properties and corresponding result counts within the result set. */
    propertyFilterCounts: Array<PropertyFilterCount>;
    /**   Total amount of matching RentalUnits. */
    totalHits: Scalars['Int'];
};

export type SearchResult = {
    __typename?: 'SearchResult';
    hits?: Maybe<Array<Maybe<SearchType>>>;
    totalHits?: Maybe<Scalars['Int']>;
    typeCounts?: Maybe<Array<Maybe<Bucket>>>;
};

/**   The results of a search within Trips. */
export type SearchTripsResult = {
    __typename?: 'SearchTripsResult';
    /**   Fetch Trips matching the specified parameters. */
    hits: Array<Trip>;
    /**   Statistical information about the Trips matching the specified parameters. */
    stats: SearchTripsStats;
};

/**   The results of a search within Trips. */
export type SearchTripsResultHitsArgs = {
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    order?: InputMaybe<UnitSearchOrderEnum>;
};

/**   Statistics for a specific search whitin Trips. */
export type SearchTripsStats = {
    __typename?: 'SearchTripsStats';
    /**
     *   List of dates and the respective amount of Trips found for that date.
     *  Only useful in case the search parameters contain multiple dates. Any date with 0 Trips found will not appear in this result.
     */
    dateCounts: Array<DateCount>;
    /**   The highest price among the Trips found. */
    maxPrice?: Maybe<Scalars['Float']>;
    /**   The lowest price among the Trips found. */
    minPrice?: Maybe<Scalars['Float']>;
    /**   List of filter properties and the respective amount of Trips found. */
    propertyFilterCounts: Array<PropertyFilterCount>;
    /**   List of Specials and the respective amount of Trips found for that Special. */
    specialCounts: Array<SpecialCount>;
    /**   The total amount of Trips found. */
    totalHits: Scalars['Int'];
    /**   List of TripTypes and the respective amount of Trips found for that TripType. */
    tripTypeCounts: Array<TripTypeCount>;
};

export type SearchType = {
    __typename?: 'SearchType';
    /** The document's image */
    image?: Maybe<SearchImage>;
    /** The document's language */
    language?: Maybe<Scalars['String']>;
    /** The document's post date */
    postDate?: Maybe<Scalars['String']>;
    /** The document's tags */
    tags?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** The document's title */
    title?: Maybe<Scalars['String']>;
    /** The document's type */
    type?: Maybe<Scalars['String']>;
    /** The document's uri */
    uri?: Maybe<Scalars['String']>;
};

export type SendAuthenticationEmailInput = {
    email: Scalars['String'];
    landingUrl: Scalars['String'];
};

export type SendAuthenticationEmailPayload = {
    __typename?: 'SendAuthenticationEmailPayload';
    success?: Maybe<Scalars['Boolean']>;
};

export type Special = {
    __typename?: 'Special';
    /**   This value is language dependent. */
    description: Scalars['String'];
    id: Scalars['ID'];
    landingPageUrl?: Maybe<Scalars['String']>;
    /**   This value is language dependent. */
    name: Scalars['String'];
};

export type SpecialCount = {
    __typename?: 'SpecialCount';
    hits: Scalars['Int'];
    special: Special;
};

export type SpecialOperatingHours = {
    __typename?: 'SpecialOperatingHours';
    endDate: Scalars['String'];
    friday?: Maybe<Array<TimeOpenClosed>>;
    monday?: Maybe<Array<TimeOpenClosed>>;
    saturday?: Maybe<Array<TimeOpenClosed>>;
    startDate: Scalars['String'];
    sunday?: Maybe<Array<TimeOpenClosed>>;
    thursday?: Maybe<Array<TimeOpenClosed>>;
    tuesday?: Maybe<Array<TimeOpenClosed>>;
    wednesday?: Maybe<Array<TimeOpenClosed>>;
};

/** This is the interface implemented by all submissions. */
export type SubmissionInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** Whether the element is archived or not. */
    archived?: Maybe<Scalars['Boolean']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** Whether the element is enabled or not. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** The submission’s status. */
    status?: Maybe<Scalars['String']>;
    /** The submission’s status ID. */
    statusId?: Maybe<Scalars['Int']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted or not. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all submissions. */
export type SubmissionInterface_CountArgs = {
    field: Scalars['String'];
};

export enum SurchargeCalculationEnum {
    PerPerson = 'PER_PERSON',
    PerPersonPerNight = 'PER_PERSON_PER_NIGHT',
    PerPet = 'PER_PET',
    PerPetPerNight = 'PER_PET_PER_NIGHT',
    PerPiece = 'PER_PIECE',
    PerPiecePerNight = 'PER_PIECE_PER_NIGHT',
    Subsequent = 'SUBSEQUENT',
}

export type TagCriteriaInput = {
    /** Narrows the query results to only elements that have been archived. */
    archived?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on whether the elements are enabled in the site they’re being queried in, per the `site` argument. */
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the tag groups the tags belong to per the group’s handles. */
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the tag groups the tags belong to, per the groups’ IDs. */
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ statuses. */
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that have been soft-deleted. */
    trashed?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This is the interface implemented by all tags. */
export type TagInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** Whether the element is archived or not. */
    archived?: Maybe<Scalars['Boolean']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** Whether the element is enabled or not. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The handle of the group that contains the tag. */
    groupHandle?: Maybe<Scalars['String']>;
    /** The ID of the group that contains the tag. */
    groupId?: Maybe<Scalars['Int']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted or not. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all tags. */
export type TagInterface_CountArgs = {
    field: Scalars['String'];
};

export type TargetAudienceAggregationCount = {
    __typename?: 'TargetAudienceAggregationCount';
    hits: Scalars['Int'];
    value: TargetAudienceEnum;
};

/**   Available target audience options. */
export enum TargetAudienceEnum {
    Adults = 'ADULTS',
    AllAges = 'ALL_AGES',
    Children = 'CHILDREN',
    Pets = 'PETS',
    WheelchairAccessible = 'WHEELCHAIR_ACCESSIBLE',
}

export type Ticket = OrderItem & {
    __typename?: 'Ticket';
    activity?: Maybe<Activity>;
    downloadVoucherUrl?: Maybe<Scalars['String']>;
    endDateTime: Scalars['String'];
    errors: Array<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    paymentPrice: Scalars['Float'];
    priceLines: Array<PriceLine>;
    rateLines: Array<TicketRateLine>;
    startDateTime: Scalars['String'];
    status: TicketStatus;
    timeslot?: Maybe<Timeslot>;
    totalPrice: Scalars['Float'];
};

export type TicketCartItemInput = {
    rateLines: Array<TicketRateLineInput>;
    timeslotId: Scalars['ID'];
};

export type TicketRateLine = {
    __typename?: 'TicketRateLine';
    amount: Scalars['Int'];
    rateId?: Maybe<Scalars['ID']>;
    totalPrice: Scalars['Float'];
    unitPrice: Scalars['Float'];
};

export type TicketRateLineInput = {
    amount: Scalars['Int'];
    rateId: Scalars['ID'];
};

export enum TicketStatus {
    Accepted = 'ACCEPTED',
    Init = 'INIT',
    Waived = 'WAIVED',
}

export type TimeOpenClosed = {
    __typename?: 'TimeOpenClosed';
    timeClosed: Scalars['String'];
    timeOpen: Scalars['String'];
};

export type Timeslot = {
    __typename?: 'Timeslot';
    /**   If `null`, unlimited tickets are available */
    allotment?: Maybe<Scalars['Int']>;
    /**   Formatted as yyyy-mm-dd HH:MM */
    endDateTime: Scalars['String'];
    id: Scalars['ID'];
    label?: Maybe<Scalars['String']>;
    rateGroup?: Maybe<ActivityRateGroup>;
    /**   Formatted as yyyy-mm-dd HH:MM */
    startDateTime: Scalars['String'];
};

export type TravelGroupInput = {
    amountAdults: Scalars['Int'];
    amountBabies: Scalars['Int'];
    amountChildren: Scalars['Int'];
    amountPets: Scalars['Int'];
    amountYouths: Scalars['Int'];
};

export enum TravelGroupType {
    Alone = 'ALONE',
    BusinessTrip = 'BUSINESS_TRIP',
    FamilyWithOlderChildren = 'FAMILY_WITH_OLDER_CHILDREN',
    FamilyWithYoungChildren = 'FAMILY_WITH_YOUNG_CHILDREN',
    GroupOfFriends = 'GROUP_OF_FRIENDS',
    GroupTrip = 'GROUP_TRIP',
    OlderCouple = 'OLDER_COUPLE',
    YoungCouple = 'YOUNG_COUPLE',
}

export type TravelbaseObjectsSitemapType = {
    __typename?: 'TravelbaseObjectsSitemapType';
    /** The document's image */
    image?: Maybe<SearchImage>;
    /** The document's url */
    url?: Maybe<Scalars['String']>;
};

export type Trip = {
    __typename?: 'Trip';
    date: Scalars['String'];
    duration: Scalars['Int'];
    originalPrice?: Maybe<Scalars['Float']>;
    price: Scalars['Float'];
    rentalUnit: RentalUnit;
    special?: Maybe<Special>;
    specialId?: Maybe<Scalars['ID']>;
    type: TripTypeEnum;
};

export type TripTypeCount = {
    __typename?: 'TripTypeCount';
    hits: Scalars['Int'];
    tripType: TripTypeEnum;
};

/**   Available types of Trip. */
export enum TripTypeEnum {
    /**  Rate including an early booking discount. */
    EarlyBooking = 'EARLY_BOOKING',
    /**  Rate including a last minute discount. */
    LastMinute = 'LAST_MINUTE',
    /**  The regular rate. */
    Regular = 'REGULAR',
    /**  Rate including a Special. */
    Special = 'SPECIAL',
}

export type UnitAndTripSearchParamsInput = {
    babies: Scalars['Int'];
    date: Scalars['String'];
    duration: Scalars['Int'];
    filters?: InputMaybe<Array<PropertyFilterInput>>;
    persons: Scalars['Int'];
    pets: Scalars['Int'];
    price?: InputMaybe<RangeFilterInput>;
    specialId?: InputMaybe<Scalars['ID']>;
    tripType?: InputMaybe<Array<TripTypeEnum>>;
};

/**   A property of a RentalUnit or Accommodation which can be used to filter by. */
export type UnitFilterProperty = {
    __typename?: 'UnitFilterProperty';
    /**   A URL-friendly handle unique to this property, this should never change even if the name changes. */
    handle: Scalars['String'];
    /**
     *   Name of this property.
     *  This value is language dependent.
     */
    name: Scalars['String'];
};

/**   A category of FilterProperties. */
export type UnitFilterPropertyCategory = {
    __typename?: 'UnitFilterPropertyCategory';
    /**   The properties in this category. */
    filterProperties: Array<UnitFilterProperty>;
    /**   A URL-friendly handle unique to this category, this should never change even if the name changes. */
    handle: Scalars['String'];
    /**
     *   Name of this category.
     *  This value is language dependent.
     */
    name: Scalars['String'];
};

export type UnitSearchHit = {
    __typename?: 'UnitSearchHit';
    rentalUnit: RentalUnit;
    trips: Array<Trip>;
};

/**   RentalUnit/Trip search result ordering options. */
export enum UnitSearchOrderEnum {
    /**  Sort by Trip price, ascending. */
    PriceAsc = 'PRICE_ASC',
    /**  Sort by Trip price, descending. */
    PriceDesc = 'PRICE_DESC',
    /**
     *  Randomize order.
     * # The random seed used is the current date, so the order will be identical between pageloads and between users, but change daily.
     */
    RandomBoosted = 'RANDOM_BOOSTED',
    /**  Sort by amount of recent bookings. */
    RecentBookingsBoosted = 'RECENT_BOOKINGS_BOOSTED',
    /**  Sort by average RentalUnit review rating, descending. */
    ReviewRatingDesc = 'REVIEW_RATING_DESC',
}

/**   Parameters for searching within RentalUnits (optionally filtered by specific Trip availability). */
export type UnitSearchParamsInput = {
    /**   The amount of babies in the travel party. */
    babies?: InputMaybe<Scalars['Int']>;
    /**
     *   Filter by this arrival date.
     *  Formatted as yyyy-mm-dd.
     */
    date?: InputMaybe<Scalars['String']>;
    /**   Filter by this Trip duration. */
    duration?: InputMaybe<Scalars['Int']>;
    /**
     *   Filter by properties the RentalUnit/Accommodation must posses.
     *  Multiple filters are combined as "AND".
     */
    filters?: InputMaybe<Array<PropertyFilterInput>>;
    /**   The amount of non-baby people in the travel party. */
    persons?: InputMaybe<Scalars['Int']>;
    /**   The amount of pets in the travel party. */
    pets?: InputMaybe<Scalars['Int']>;
    /**   Filter by a price range. */
    price?: InputMaybe<RangeFilterInput>;
    /**   Filter by a specific Special (by ID). */
    specialId?: InputMaybe<Scalars['ID']>;
    /**   Filter by this/these TripType(s). */
    tripType?: InputMaybe<Array<TripTypeEnum>>;
};

/**   Results of a RentalUnit/Trip search query. */
export type UnitSearchResult = {
    __typename?: 'UnitSearchResult';
    /**   The search results. */
    hits: Array<UnitSearchHit>;
    /**   The maximum Trip price within the result set. */
    maxPrice?: Maybe<Scalars['Float']>;
    /**   The minimum Trip price within the result set. */
    minPrice?: Maybe<Scalars['Float']>;
    /**   Available filter properties and corresponding result counts within the result set. */
    propertyFilterCounts: Array<PropertyFilterCount>;
    /**   Available Specials and corresponding counts within the result set. */
    specialCounts: Array<SpecialCount>;
    /**   Total amount of matching RentalUnits. */
    totalHits: Scalars['Int'];
    /**   Available TripTypes and corresponding counts within the result set. */
    tripTypeCounts: Array<TripTypeCount>;
};

export type UpdateCartInput = {
    affiliateInfo?: InputMaybe<AffiliateInfoInput>;
    agreedToTerms?: InputMaybe<Scalars['Boolean']>;
    bookings?: InputMaybe<Array<BookingCartItemInput>>;
    cartId?: InputMaybe<Scalars['ID']>;
    customerInfo?: InputMaybe<CustomerInfoInput>;
    tickets?: InputMaybe<Array<TicketCartItemInput>>;
    upsellPurchases?: InputMaybe<Array<UpsellPurchaseCartItemInput>>;
};

export type UpdateCartPayload = {
    __typename?: 'UpdateCartPayload';
    cart?: Maybe<Cart>;
    /**   Indicates whether the current user (if any) is allowed to perform the checkout action for the submitted customer email address. */
    checkoutAllowed?: Maybe<Scalars['Boolean']>;
};

export type UpdateCustomerInput = {
    address?: InputMaybe<AddressInput>;
    /**   Formatted as yyyy-mm-dd */
    birthdate?: InputMaybe<Scalars['String']>;
    customerId: Scalars['ID'];
    firstName?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerPayload = {
    __typename?: 'UpdateCustomerPayload';
    customer?: Maybe<Customer>;
};

export type UpsellOffer = {
    __typename?: 'UpsellOffer';
    /**   This value is language dependent. */
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    image?: Maybe<GenericImage>;
    maxAmount: Scalars['Int'];
    /**   This value is language dependent. */
    name: Scalars['String'];
    unitPrice: Scalars['Float'];
};

export type UpsellPurchase = OrderItem & {
    __typename?: 'UpsellPurchase';
    amount: Scalars['Int'];
    errors: Array<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    offer?: Maybe<UpsellOffer>;
    paymentPrice: Scalars['Float'];
    priceLines: Array<PriceLine>;
    status: UpsellPurchaseStatus;
    totalPrice: Scalars['Float'];
};

export type UpsellPurchaseCartItemInput = {
    amount: Scalars['Int'];
    upsellOfferId: Scalars['ID'];
};

export enum UpsellPurchaseStatus {
    Accepted = 'ACCEPTED',
    Init = 'INIT',
    Waived = 'WAIVED',
}

export type User = ElementInterface &
    UserInterface & {
        __typename?: 'User';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The user’s email. */
        email?: Maybe<Scalars['String']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The user’s first name. */
        firstName?: Maybe<Scalars['String']>;
        /** The user’s first name or username. */
        friendlyName?: Maybe<Scalars['String']>;
        /** The user’s full name. */
        fullName?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The user’s last name. */
        lastName?: Maybe<Scalars['String']>;
        /** The user’s full name or username. */
        name?: Maybe<Scalars['String']>;
        /** The user’s preferences. */
        preferences?: Maybe<Scalars['String']>;
        /** The user’s preferred language. */
        preferredLanguage?: Maybe<Scalars['String']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The username. */
        username?: Maybe<Scalars['String']>;
    };

export type User_CountArgs = {
    field: Scalars['String'];
};

export type UserCriteriaInput = {
    /** Narrows the query results to only elements that have been archived. */
    archived?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ creation dates. */
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ last-updated dates. */
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the users’ email addresses. */
    email?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on whether the elements are enabled in the site they’re being queried in, per the `site` argument. */
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the users’ first names. */
    firstName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Causes the query results to be returned in the order specified by the `id` argument. */
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the user group the users belong to. */
    group?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the user group the users belong to, per the groups’ IDs. */
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to only users that have (or don’t have) a user photo. */
    hasPhoto?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ IDs. */
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Causes the query results to be returned in reverse order. */
    inReverse?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the users’ last names. */
    lastName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Sets the limit for paginated results. */
    limit?: InputMaybe<Scalars['Int']>;
    /** Sets the offset for paginated results. */
    offset?: InputMaybe<Scalars['Int']>;
    /** Sets the field the returned elements should be ordered by. */
    orderBy?: InputMaybe<Scalars['String']>;
    /** Determines which site should be selected when querying multi-site elements. */
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on a reference string. */
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to elements that relate to the provided element IDs. This argument is ignored, if `relatedToAll` is also used. */
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to *all* of the provided element IDs. Using this argument will cause `relatedTo` argument to be ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should be used instead. */
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results to elements that relate to an asset list defined with this argument. */
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a category list defined with this argument. */
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to an entry list defined with this argument. */
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a tag list defined with this argument. */
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    /** Narrows the query results to elements that relate to a use list defined with this argument. */
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    /** Narrows the query results to only elements that match a search query. */
    search?: InputMaybe<Scalars['String']>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the unique identifier for an element-site relation. */
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    /** Narrows the query results based on the elements’ slugs. */
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ statuses. */
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the elements’ titles. */
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results to only elements that have been soft-deleted. */
    trashed?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ UIDs. */
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Determines whether only elements with unique IDs should be returned by the query. */
    unique?: InputMaybe<Scalars['Boolean']>;
    /** Narrows the query results based on the elements’ URIs. */
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    /** Narrows the query results based on the users’ usernames. */
    username?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This is the interface implemented by all users. */
export type UserInterface = {
    /** Return a number of related elements for a field. */
    _count?: Maybe<Scalars['Int']>;
    /** Whether the element is archived or not. */
    archived?: Maybe<Scalars['Boolean']>;
    /** The date the element was created. */
    dateCreated?: Maybe<Scalars['DateTime']>;
    /** The date the element was last updated. */
    dateUpdated?: Maybe<Scalars['DateTime']>;
    /** The user’s email. */
    email?: Maybe<Scalars['String']>;
    /** Whether the element is enabled or not. */
    enabled?: Maybe<Scalars['Boolean']>;
    /** The user’s first name. */
    firstName?: Maybe<Scalars['String']>;
    /** The user’s first name or username. */
    friendlyName?: Maybe<Scalars['String']>;
    /** The user’s full name. */
    fullName?: Maybe<Scalars['String']>;
    /** The ID of the entity */
    id?: Maybe<Scalars['ID']>;
    /** The language of the site element is associated with. */
    language?: Maybe<Scalars['String']>;
    /** The user’s last name. */
    lastName?: Maybe<Scalars['String']>;
    /** The user’s full name or username. */
    name?: Maybe<Scalars['String']>;
    /** The user’s preferences. */
    preferences?: Maybe<Scalars['String']>;
    /** The user’s preferred language. */
    preferredLanguage?: Maybe<Scalars['String']>;
    /** The element’s search score, if the `search` parameter was used when querying for the element. */
    searchScore?: Maybe<Scalars['String']>;
    /** The ID of the site the element is associated with. */
    siteId?: Maybe<Scalars['Int']>;
    /** The unique identifier for an element-site relation. */
    siteSettingsId?: Maybe<Scalars['ID']>;
    /** The element’s slug. */
    slug?: Maybe<Scalars['String']>;
    /** The element’s status. */
    status?: Maybe<Scalars['String']>;
    /** The element’s title. */
    title?: Maybe<Scalars['String']>;
    /** Whether the element has been soft-deleted or not. */
    trashed?: Maybe<Scalars['Boolean']>;
    /** The UID of the entity */
    uid?: Maybe<Scalars['String']>;
    /** The element’s URI. */
    uri?: Maybe<Scalars['String']>;
    /** The username. */
    username?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all users. */
export type UserInterface_CountArgs = {
    field: Scalars['String'];
};

export type Action_Action_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'action_action_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        button?: Maybe<LinkField_Link>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants?: Maybe<Array<Maybe<EntryInterface>>>;
        description?: Maybe<Scalars['String']>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
        isUnsavedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the entry type that contains the entry. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Action_Action_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Action_Action_EntryAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Action_Action_EntryChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Action_Action_EntryDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Action_Action_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Action_Action_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Action_Action_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Action_Action_EntryParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Action_Action_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Action_Action_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_Agenda_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'agenda_agenda_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        googlePreview?: Maybe<Ether_SeoData>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
        isUnsavedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsSearchTags?: Maybe<Array<Maybe<TagInterface>>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the entry type that contains the entry. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Agenda_Agenda_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Agenda_Agenda_EntryAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_Agenda_EntryChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_Agenda_EntryDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_Agenda_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_Agenda_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_Agenda_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_Agenda_EntryParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_Agenda_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_Agenda_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Agenda_Agenda_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Articles_Article_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'articles_article_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        googlePreview?: Maybe<Ether_SeoData>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
        isUnsavedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        legacyId?: Maybe<Scalars['String']>;
        legacyUrl?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized?: Maybe<Array<Maybe<EntryInterface>>>;
        newsHeroImage?: Maybe<Array<Maybe<AssetInterface>>>;
        newsImages?: Maybe<Array<Maybe<AssetInterface>>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsSearchTags?: Maybe<Array<Maybe<TagInterface>>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        textBody?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the entry type that contains the entry. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Articles_Article_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Articles_Article_EntryAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Articles_Article_EntryChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Articles_Article_EntryDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Articles_Article_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Articles_Article_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Articles_Article_EntryNewsHeroImageArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Articles_Article_EntryNewsImagesArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Articles_Article_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Articles_Article_EntryParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Articles_Article_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Articles_Article_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Articles_Article_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AssetTags_Tag = ElementInterface &
    TagInterface & {
        __typename?: 'assetTags_Tag';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The handle of the group that contains the tag. */
        groupHandle?: Maybe<Scalars['String']>;
        /** The ID of the group that contains the tag. */
        groupId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type AssetTags_Tag_CountArgs = {
    field: Scalars['String'];
};

export type Banners_Banner_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'banners_banner_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        button?: Maybe<LinkField_Link>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        heroImage?: Maybe<Array<Maybe<AssetInterface>>>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
        isUnsavedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the entry type that contains the entry. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Banners_Banner_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Banners_Banner_EntryAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Banners_Banner_EntryChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Banners_Banner_EntryDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Banners_Banner_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Banners_Banner_EntryHeroImageArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Banners_Banner_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Banners_Banner_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Banners_Banner_EntryParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Banners_Banner_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Banners_Banner_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_Companies_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'companies_companies_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        googlePreview?: Maybe<Ether_SeoData>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
        isUnsavedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsSearchTags?: Maybe<Array<Maybe<TagInterface>>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the entry type that contains the entry. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Companies_Companies_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Companies_Companies_EntryAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_Companies_EntryChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_Companies_EntryDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_Companies_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_Companies_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_Companies_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_Companies_EntryParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_Companies_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_Companies_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Companies_Companies_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContactMatrix_MatrixField = ContactMatrix_Contact_BlockType;

export type ContactMatrix_Contact_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contactMatrix_contact_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        email?: Maybe<Scalars['Boolean']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        messenger?: Maybe<Scalars['Boolean']>;
        myTexel?: Maybe<Scalars['Boolean']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        phone?: Maybe<Scalars['Boolean']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        shop?: Maybe<Scalars['Boolean']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        whatsapp?: Maybe<Scalars['Boolean']>;
    };

export type ContactMatrix_Contact_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type Contact_GlobalSet = ElementInterface &
    GlobalSetInterface & {
        __typename?: 'contact_GlobalSet';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        contactEmailText?: Maybe<Scalars['String']>;
        contactMessengerText?: Maybe<Scalars['String']>;
        contactMyTexelText?: Maybe<Scalars['String']>;
        contactPhoneText?: Maybe<Scalars['String']>;
        contactShopText?: Maybe<Scalars['String']>;
        contactWhatsappText?: Maybe<Scalars['String']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The handle of the global set. */
        handle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The name of the global set. */
        name?: Maybe<Scalars['String']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type Contact_GlobalSet_CountArgs = {
    field: Scalars['String'];
};

export type Contact_Contact_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'contact_contact_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        button?: Maybe<LinkField_Link>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children?: Maybe<Array<Maybe<EntryInterface>>>;
        contactMatrix?: Maybe<Array<Maybe<ContactMatrix_MatrixField>>>;
        contentMatrix?: Maybe<Array<Maybe<ContentMatrix_MatrixField>>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        googlePreview?: Maybe<Ether_SeoData>;
        heroImage?: Maybe<Array<Maybe<AssetInterface>>>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
        isUnsavedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsSearchTags?: Maybe<Array<Maybe<TagInterface>>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        textBody?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the entry type that contains the entry. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Contact_Contact_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Contact_Contact_EntryAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_Contact_EntryChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_Contact_EntryContactMatrixArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Contact_Contact_EntryContentMatrixArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Contact_Contact_EntryDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_Contact_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_Contact_EntryHeroImageArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Contact_Contact_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_Contact_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_Contact_EntryParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_Contact_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_Contact_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Contact_Contact_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentMatrix_MatrixField =
    | ContentMatrix_SectionAccommodations_BlockType
    | ContentMatrix_SectionAccordion_BlockType
    | ContentMatrix_SectionAction_BlockType
    | ContentMatrix_SectionActivities_BlockType
    | ContentMatrix_SectionBanner_BlockType
    | ContentMatrix_SectionButton_BlockType
    | ContentMatrix_SectionCompanies_BlockType
    | ContentMatrix_SectionEmbed_BlockType
    | ContentMatrix_SectionForms_BlockType
    | ContentMatrix_SectionHeader_BlockType
    | ContentMatrix_SectionHierarchicalTiles_BlockType
    | ContentMatrix_SectionImages_BlockType
    | ContentMatrix_SectionMap_BlockType
    | ContentMatrix_SectionText_BlockType
    | ContentMatrix_SectionTiles_BlockType
    | ContentMatrix_SectionTip_BlockType
    | ContentMatrix_SectionTravelbaseTiles_BlockType
    | ContentMatrix_SectionVideo_BlockType
    | ContentMatrix_SectionWebcam_BlockType;

export type ContentMatrix_SectionAccommodations_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionAccommodations_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        accommodationsQuery?: Maybe<Scalars['String']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionAccommodations_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionAccordion_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionAccordion_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        header?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        text?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionAccordion_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionAction_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionAction_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        action?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionAction_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionAction_BlockTypeActionArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ContentMatrix_SectionActivities_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionActivities_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        activitiesQuery?: Maybe<Scalars['String']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionActivities_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionBanner_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionBanner_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        banner?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionBanner_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionBanner_BlockTypeBannerArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ContentMatrix_SectionButton_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionButton_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        button?: Maybe<LinkField_Link>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionButton_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionCompanies_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionCompanies_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        companiesQuery?: Maybe<Scalars['String']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionCompanies_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionEmbed_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionEmbed_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        embed?: Maybe<Scalars['String']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionEmbed_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionForms_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionForms_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        form?: Maybe<Array<Maybe<FormInterface>>>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionForms_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionForms_BlockTypeFormArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    handle?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentMatrix_SectionHeader_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionHeader_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        header?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionHeader_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionHierarchicalTiles_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionHierarchicalTiles_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        relationType?: Maybe<Scalars['String']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionHierarchicalTiles_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionHierarchicalTiles_BlockTypeRelationTypeArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type ContentMatrix_SectionImages_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionImages_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        images?: Maybe<Array<Maybe<AssetInterface>>>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionImages_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionImages_BlockTypeImagesArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentMatrix_SectionMap_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionMap_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        pins?: Maybe<Array<Maybe<Pins_TableRow>>>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionMap_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionText_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionText_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        text?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionText_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionTiles_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionTiles_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        tilesChildren?: Maybe<Scalars['Boolean']>;
        tilesEntries?: Maybe<Array<Maybe<EntryInterface>>>;
        tilesSiblings?: Maybe<Scalars['Boolean']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionTiles_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionTiles_BlockTypeTilesEntriesArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ContentMatrix_SectionTip_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionTip_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        tip?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionTip_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionTip_BlockTypeTipArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ContentMatrix_SectionTravelbaseTiles_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionTravelbaseTiles_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        travelbaseEntityType?: Maybe<Scalars['String']>;
        travelbaseSlugs?: Maybe<Array<Maybe<TravelbaseSlugs_TableRow>>>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionTravelbaseTiles_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionTravelbaseTiles_BlockTypeTravelbaseEntityTypeArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type ContentMatrix_SectionVideo_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionVideo_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        autoplay?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        videoUrl?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionVideo_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type ContentMatrix_SectionWebcam_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'contentMatrix_sectionWebcam_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        webcamUrl?: Maybe<Scalars['String']>;
    };

export type ContentMatrix_SectionWebcam_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type Footer_FooterCategory_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'footer_footerCategory_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
        isUnsavedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the entry type that contains the entry. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Footer_FooterCategory_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Footer_FooterCategory_EntryAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Footer_FooterCategory_EntryChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Footer_FooterCategory_EntryDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Footer_FooterCategory_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Footer_FooterCategory_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Footer_FooterCategory_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Footer_FooterCategory_EntryParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Footer_FooterCategory_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Footer_FooterCategory_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Footer_FooterLink_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'footer_footerLink_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
        isUnsavedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized?: Maybe<Array<Maybe<EntryInterface>>>;
        menuLink?: Maybe<LinkField_Link>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the entry type that contains the entry. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Footer_FooterLink_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Footer_FooterLink_EntryAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Footer_FooterLink_EntryChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Footer_FooterLink_EntryDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Footer_FooterLink_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Footer_FooterLink_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Footer_FooterLink_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Footer_FooterLink_EntryParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Footer_FooterLink_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Footer_FooterLink_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_Homepage_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'homepage_homepage_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        action?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        button?: Maybe<LinkField_Link>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        googlePreview?: Maybe<Ether_SeoData>;
        header?: Maybe<Scalars['String']>;
        heroImage?: Maybe<Array<Maybe<AssetInterface>>>;
        homeCurrent?: Maybe<Array<Maybe<EntryInterface>>>;
        homeHighlights?: Maybe<Array<Maybe<EntryInterface>>>;
        homeHighlightsTitle?: Maybe<Scalars['String']>;
        homeVideoDescription?: Maybe<Scalars['String']>;
        homeVideoEnabled?: Maybe<Scalars['Boolean']>;
        homeVideoTitle?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
        isUnsavedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsSearchTags?: Maybe<Array<Maybe<TagInterface>>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the entry type that contains the entry. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
        video?: Maybe<Scalars['String']>;
    };

export type Homepage_Homepage_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Homepage_Homepage_EntryActionArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_Homepage_EntryAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_Homepage_EntryChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_Homepage_EntryDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_Homepage_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_Homepage_EntryHeroImageArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Homepage_Homepage_EntryHomeCurrentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_Homepage_EntryHomeHighlightsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_Homepage_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_Homepage_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_Homepage_EntryParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_Homepage_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_Homepage_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Homepage_Homepage_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Library_Asset = AssetInterface &
    ElementInterface & {
        __typename?: 'library_Asset';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        assetRights?: Maybe<Scalars['String']>;
        assetSource?: Maybe<Scalars['String']>;
        assetTags?: Maybe<Array<Maybe<TagInterface>>>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the asset file was last modified. */
        dateModified?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The file extension for the asset file. */
        extension?: Maybe<Scalars['String']>;
        /** The filename of the asset file. */
        filename?: Maybe<Scalars['String']>;
        /** The focal point represented as an array with `x` and `y` keys, or null if it’s not an image. */
        focalPoint?: Maybe<Array<Maybe<Scalars['Float']>>>;
        /** The ID of the folder that the asset belongs to. */
        folderId?: Maybe<Scalars['Int']>;
        /** Whether a user-defined focal point is set on the asset. */
        hasFocalPoint?: Maybe<Scalars['Boolean']>;
        /** The height in pixels or null if it’s not an image. */
        height?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** An `<img>` tag based on this asset. */
        img?: Maybe<Scalars['String']>;
        /** The file kind. */
        kind?: Maybe<Scalars['String']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The file’s MIME type, if it can be determined. */
        mimeType?: Maybe<Scalars['String']>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<AssetInterface>;
        /** The asset’s path in the volume. */
        path?: Maybe<Scalars['String']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<AssetInterface>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The file size in bytes. */
        size?: Maybe<Scalars['String']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
        srcset?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
        url?: Maybe<Scalars['String']>;
        /** The ID of the volume that the asset belongs to. */
        volumeId?: Maybe<Scalars['Int']>;
        /** The width in pixels or null if it’s not an image. */
        width?: Maybe<Scalars['Int']>;
    };

export type Library_Asset_CountArgs = {
    field: Scalars['String'];
};

export type Library_AssetAssetRightsArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Library_AssetAssetTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Library_AssetHeightArgs = {
    format?: InputMaybe<Scalars['String']>;
    handle?: InputMaybe<Scalars['String']>;
    height?: InputMaybe<Scalars['Int']>;
    immediately?: InputMaybe<Scalars['Boolean']>;
    interlace?: InputMaybe<Scalars['String']>;
    mode?: InputMaybe<Scalars['String']>;
    position?: InputMaybe<Scalars['String']>;
    quality?: InputMaybe<Scalars['Int']>;
    transform?: InputMaybe<Scalars['String']>;
    width?: InputMaybe<Scalars['Int']>;
};

export type Library_AssetNextArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Library_AssetPrevArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Library_AssetSrcsetArgs = {
    sizes: Array<Scalars['String']>;
};

export type Library_AssetUrlArgs = {
    format?: InputMaybe<Scalars['String']>;
    handle?: InputMaybe<Scalars['String']>;
    height?: InputMaybe<Scalars['Int']>;
    immediately?: InputMaybe<Scalars['Boolean']>;
    interlace?: InputMaybe<Scalars['String']>;
    mode?: InputMaybe<Scalars['String']>;
    position?: InputMaybe<Scalars['String']>;
    quality?: InputMaybe<Scalars['Int']>;
    transform?: InputMaybe<Scalars['String']>;
    width?: InputMaybe<Scalars['Int']>;
};

export type Library_AssetWidthArgs = {
    format?: InputMaybe<Scalars['String']>;
    handle?: InputMaybe<Scalars['String']>;
    height?: InputMaybe<Scalars['Int']>;
    immediately?: InputMaybe<Scalars['Boolean']>;
    interlace?: InputMaybe<Scalars['String']>;
    mode?: InputMaybe<Scalars['String']>;
    position?: InputMaybe<Scalars['String']>;
    quality?: InputMaybe<Scalars['Int']>;
    transform?: InputMaybe<Scalars['String']>;
    width?: InputMaybe<Scalars['Int']>;
};

/** This is the interface implemented by all links. */
export type LinkField_Link = {
    __typename?: 'linkField_Link';
    ariaLabel?: Maybe<Scalars['String']>;
    customText?: Maybe<Scalars['String']>;
    element?: Maybe<ElementInterface>;
    target?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

export type Menu_Menu_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'menu_menu_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
        isUnsavedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized?: Maybe<Array<Maybe<EntryInterface>>>;
        menuLink?: Maybe<LinkField_Link>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the entry type that contains the entry. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Menu_Menu_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Menu_Menu_EntryAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Menu_Menu_EntryChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Menu_Menu_EntryDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Menu_Menu_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Menu_Menu_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Menu_Menu_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Menu_Menu_EntryParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Menu_Menu_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Menu_Menu_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_MyTexel_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'myTexel_myTexel_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        googlePreview?: Maybe<Ether_SeoData>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
        isUnsavedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsSearchTags?: Maybe<Array<Maybe<TagInterface>>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the entry type that contains the entry. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type MyTexel_MyTexel_Entry_CountArgs = {
    field: Scalars['String'];
};

export type MyTexel_MyTexel_EntryAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_MyTexel_EntryChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_MyTexel_EntryDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_MyTexel_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_MyTexel_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_MyTexel_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_MyTexel_EntryParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_MyTexel_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_MyTexel_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type MyTexel_MyTexel_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type News_News_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'news_news_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        googlePreview?: Maybe<Ether_SeoData>;
        heroImage?: Maybe<Array<Maybe<AssetInterface>>>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
        isUnsavedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsSearchTags?: Maybe<Array<Maybe<TagInterface>>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        textBody?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the entry type that contains the entry. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type News_News_Entry_CountArgs = {
    field: Scalars['String'];
};

export type News_News_EntryAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_News_EntryChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_News_EntryDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_News_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_News_EntryHeroImageArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type News_News_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_News_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_News_EntryParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_News_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_News_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type News_News_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Notification_GlobalSet = ElementInterface &
    GlobalSetInterface & {
        __typename?: 'notification_GlobalSet';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        active?: Maybe<Scalars['Boolean']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        button?: Maybe<LinkField_Link>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        description?: Maybe<Scalars['String']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The handle of the global set. */
        handle?: Maybe<Scalars['String']>;
        header?: Maybe<Scalars['String']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The name of the global set. */
        name?: Maybe<Scalars['String']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type Notification_GlobalSet_CountArgs = {
    field: Scalars['String'];
};

export type Pages_Page_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'pages_page_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children?: Maybe<Array<Maybe<EntryInterface>>>;
        contentMatrix?: Maybe<Array<Maybe<ContentMatrix_MatrixField>>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        googlePreview?: Maybe<Ether_SeoData>;
        heroImage?: Maybe<Array<Maybe<AssetInterface>>>;
        heroImageVisible?: Maybe<Scalars['Boolean']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
        isUnsavedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        legacyId?: Maybe<Scalars['String']>;
        legacyUrl?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMenuHidden?: Maybe<Scalars['Boolean']>;
        settingsMenuTitle?: Maybe<Scalars['String']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsNotSearchable?: Maybe<Scalars['Boolean']>;
        settingsSearchTags?: Maybe<Array<Maybe<TagInterface>>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the entry type that contains the entry. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Pages_Page_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Pages_Page_EntryAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Pages_Page_EntryChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Pages_Page_EntryContentMatrixArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pages_Page_EntryDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Pages_Page_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Pages_Page_EntryHeroImageArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Pages_Page_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Pages_Page_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Pages_Page_EntryParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Pages_Page_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Pages_Page_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Pages_Page_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** This is the interface implemented by all phone number fields. */
export type PhoneNumber_PhoneNumber = {
    __typename?: 'phoneNumber_PhoneNumber';
    countryCode?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    number?: Maybe<Scalars['String']>;
    region?: Maybe<Scalars['String']>;
    regionCode?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['Int']>;
};

export type Pins_TableRow = {
    __typename?: 'pins_TableRow';
    col1?: Maybe<Scalars['Number']>;
    col2?: Maybe<Scalars['Number']>;
    col3?: Maybe<Scalars['String']>;
    col4?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
    latitude?: Maybe<Scalars['Number']>;
    longitude?: Maybe<Scalars['Number']>;
    symbol?: Maybe<Scalars['String']>;
};

export type SearchTags_Tag = ElementInterface &
    TagInterface & {
        __typename?: 'searchTags_Tag';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The handle of the group that contains the tag. */
        groupHandle?: Maybe<Scalars['String']>;
        /** The ID of the group that contains the tag. */
        groupId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        tagsSynonyms?: Maybe<Array<Maybe<TagInterface>>>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type SearchTags_Tag_CountArgs = {
    field: Scalars['String'];
};

export type SearchTags_TagTagsSynonymsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Search_Search_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'search_search_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        googlePreview?: Maybe<Ether_SeoData>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
        isUnsavedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMenuHidden?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsNotSearchable?: Maybe<Scalars['Boolean']>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the entry type that contains the entry. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Search_Search_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Search_Search_EntryAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Search_Search_EntryChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Search_Search_EntryDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Search_Search_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Search_Search_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Search_Search_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Search_Search_EntryParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Search_Search_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Search_Search_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Synonyms_Tag = ElementInterface &
    TagInterface & {
        __typename?: 'synonyms_Tag';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The handle of the group that contains the tag. */
        groupHandle?: Maybe<Scalars['String']>;
        /** The ID of the group that contains the tag. */
        groupId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type Synonyms_Tag_CountArgs = {
    field: Scalars['String'];
};

export type ThankYou_ThankYou_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'thankYou_thankYou_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
        isUnsavedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        settingsCookieConsent?: Maybe<Scalars['Boolean']>;
        settingsMetaDescription?: Maybe<Scalars['String']>;
        settingsSearchTags?: Maybe<Array<Maybe<TagInterface>>>;
        settingsSeoTitle?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the entry type that contains the entry. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type ThankYou_ThankYou_Entry_CountArgs = {
    field: Scalars['String'];
};

export type ThankYou_ThankYou_EntryAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_ThankYou_EntryChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_ThankYou_EntryDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_ThankYou_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_ThankYou_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_ThankYou_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_ThankYou_EntryParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_ThankYou_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_ThankYou_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type ThankYou_ThankYou_EntrySettingsSearchTagsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    groupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    tagsSynonyms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Tips_Tip_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'tips_tip_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
        isUnsavedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        tipAvatar?: Maybe<Array<Maybe<AssetInterface>>>;
        tipName?: Maybe<Scalars['String']>;
        tipRole?: Maybe<Scalars['String']>;
        tipText?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the entry type that contains the entry. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
    };

export type Tips_Tip_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Tips_Tip_EntryAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tips_Tip_EntryChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tips_Tip_EntryDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tips_Tip_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tips_Tip_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tips_Tip_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tips_Tip_EntryParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tips_Tip_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tips_Tip_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Tips_Tip_EntryTipAvatarArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TravelbaseSlugs_TableRow = {
    __typename?: 'travelbaseSlugs_TableRow';
    col1?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
};

export type VoucherLocations_MatrixField = VoucherLocations_Location_BlockType;

export type VoucherLocations_Location_BlockType = ElementInterface &
    MatrixBlockInterface & {
        __typename?: 'voucherLocations_location_BlockType';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The ID of the field that owns the matrix block. */
        fieldId?: Maybe<Scalars['Int']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        locationAddress?: Maybe<Scalars['String']>;
        locationExtra?: Maybe<Scalars['String']>;
        locationLatLon?: Maybe<Ether_Map>;
        locationPlace?: Maybe<Scalars['String']>;
        /** The ID of the element that owns the matrix block. */
        ownerId?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /** The sort order of the matrix block within the owner element field. */
        sortOrder?: Maybe<Scalars['Int']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the matrix block’s type. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the matrix block’s type. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
    };

export type VoucherLocations_Location_BlockType_CountArgs = {
    field: Scalars['String'];
};

export type VoucherLocations_Location_BlockTypeLocationPlaceArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Vouchers_Voucher_Entry = ElementInterface &
    EntryInterface & {
        __typename?: 'vouchers_voucher_Entry';
        /** Return a number of related elements for a field. */
        _count?: Maybe<Scalars['Int']>;
        /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
        ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is archived or not. */
        archived?: Maybe<Scalars['Boolean']>;
        /** Returns the entry’s canonical ID. */
        canonicalId?: Maybe<Scalars['Int']>;
        /** Returns the entry’s canonical UUID. */
        canonicalUid?: Maybe<Scalars['String']>;
        /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
        children?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The current revision for the entry. */
        currentRevision?: Maybe<EntryInterface>;
        /** The date the element was created. */
        dateCreated?: Maybe<Scalars['DateTime']>;
        /** The date the element was last updated. */
        dateUpdated?: Maybe<Scalars['DateTime']>;
        /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
        descendants?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The creator of a given draft. */
        draftCreator?: Maybe<UserInterface>;
        /** The draft ID (from the `drafts` table). */
        draftId?: Maybe<Scalars['Int']>;
        /** The name of the draft. */
        draftName?: Maybe<Scalars['String']>;
        /** The notes for the draft. */
        draftNotes?: Maybe<Scalars['String']>;
        /** The drafts for the entry. */
        drafts?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Whether the element is enabled or not. */
        enabled?: Maybe<Scalars['Boolean']>;
        /** The expiry date of the entry. */
        expiryDate?: Maybe<Scalars['DateTime']>;
        /** The ID of the entity */
        id?: Maybe<Scalars['ID']>;
        /** Returns whether this is a draft. */
        isDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is a revision. */
        isRevision?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. */
        isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
        /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
        isUnsavedDraft?: Maybe<Scalars['Boolean']>;
        /** The language of the site element is associated with. */
        language?: Maybe<Scalars['String']>;
        /** The element’s level within its structure */
        level?: Maybe<Scalars['Int']>;
        /** The element’s left position within its structure. */
        lft?: Maybe<Scalars['Int']>;
        /** The same element in other locales. */
        localized?: Maybe<Array<Maybe<EntryInterface>>>;
        /** Returns the next element relative to this one, from a given set of criteria. */
        next?: Maybe<EntryInterface>;
        /** The entry’s parent, if the section is a structure. */
        parent?: Maybe<EntryInterface>;
        /** The entry’s post date. */
        postDate?: Maybe<Scalars['DateTime']>;
        /** Returns the previous element relative to this one, from a given set of criteria. */
        prev?: Maybe<EntryInterface>;
        /** The creator of a given revision. */
        revisionCreator?: Maybe<UserInterface>;
        /** The revision ID (from the `revisions` table). */
        revisionId?: Maybe<Scalars['Int']>;
        /** The revision notes (from the `revisions` table). */
        revisionNotes?: Maybe<Scalars['String']>;
        /** The revisions for the entry. */
        revisions?: Maybe<Array<Maybe<EntryInterface>>>;
        /** The element’s right position within its structure. */
        rgt?: Maybe<Scalars['Int']>;
        /** The element’s structure’s root ID */
        root?: Maybe<Scalars['Int']>;
        /** The element’s search score, if the `search` parameter was used when querying for the element. */
        searchScore?: Maybe<Scalars['String']>;
        /** The handle of the section that contains the entry. */
        sectionHandle?: Maybe<Scalars['String']>;
        /** The ID of the section that contains the entry. */
        sectionId?: Maybe<Scalars['Int']>;
        /** The ID of the site the element is associated with. */
        siteId?: Maybe<Scalars['Int']>;
        /** The unique identifier for an element-site relation. */
        siteSettingsId?: Maybe<Scalars['ID']>;
        /** The element’s slug. */
        slug?: Maybe<Scalars['String']>;
        /**
         * Returns the entry’s canonical ID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalId` instead.
         */
        sourceId?: Maybe<Scalars['Int']>;
        /**
         * Returns the entry’s canonical UUID.
         * @deprecated this field has been deprecated since Craft 3.7.7. Use `canonicalUid` instead.
         */
        sourceUid?: Maybe<Scalars['String']>;
        /** The element’s status. */
        status?: Maybe<Scalars['String']>;
        /** The element’s structure ID. */
        structureId?: Maybe<Scalars['Int']>;
        /** The element’s title. */
        title?: Maybe<Scalars['String']>;
        /** Whether the element has been soft-deleted or not. */
        trashed?: Maybe<Scalars['Boolean']>;
        /** The handle of the entry type that contains the entry. */
        typeHandle?: Maybe<Scalars['String']>;
        /** The ID of the entry type that contains the entry. */
        typeId?: Maybe<Scalars['Int']>;
        /** The UID of the entity */
        uid?: Maybe<Scalars['String']>;
        /** The element’s URI. */
        uri?: Maybe<Scalars['String']>;
        /** The element’s full URL */
        url?: Maybe<Scalars['String']>;
        voucherCategory?: Maybe<Scalars['String']>;
        voucherDescription?: Maybe<Scalars['String']>;
        voucherLinkUrl?: Maybe<Scalars['String']>;
        voucherLocations?: Maybe<Array<Maybe<VoucherLocations_MatrixField>>>;
        voucherPhoneNumber?: Maybe<PhoneNumber_PhoneNumber>;
        voucherPhotos?: Maybe<Array<Maybe<AssetInterface>>>;
        voucherProvider?: Maybe<Scalars['String']>;
        voucherTerms?: Maybe<Scalars['String']>;
        voucherValidationImage?: Maybe<Array<Maybe<AssetInterface>>>;
        voucherVisibility?: Maybe<Scalars['String']>;
    };

export type Vouchers_Voucher_Entry_CountArgs = {
    field: Scalars['String'];
};

export type Vouchers_Voucher_EntryAncestorsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Vouchers_Voucher_EntryChildrenArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Vouchers_Voucher_EntryDescendantsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Vouchers_Voucher_EntryDraftsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Vouchers_Voucher_EntryLocalizedArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Vouchers_Voucher_EntryNextArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Vouchers_Voucher_EntryParentArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Vouchers_Voucher_EntryPrevArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Vouchers_Voucher_EntryRevisionsArgs = {
    action?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    after?: InputMaybe<Scalars['String']>;
    ancestorDist?: InputMaybe<Scalars['Int']>;
    ancestorOf?: InputMaybe<Scalars['Int']>;
    archived?: InputMaybe<Scalars['Boolean']>;
    authorGroup?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    authorGroupId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    authorId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    before?: InputMaybe<Scalars['String']>;
    button?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    descendantDist?: InputMaybe<Scalars['Int']>;
    descendantOf?: InputMaybe<Scalars['Int']>;
    description?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    draftCreator?: InputMaybe<Scalars['Int']>;
    draftId?: InputMaybe<Scalars['Int']>;
    draftOf?: InputMaybe<Scalars['QueryArgument']>;
    drafts?: InputMaybe<Scalars['Boolean']>;
    editable?: InputMaybe<Scalars['Boolean']>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    expiryDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    googlePreview?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    hasDescendants?: InputMaybe<Scalars['Boolean']>;
    header?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    heroImageVisible?: InputMaybe<Scalars['Boolean']>;
    homeCurrent?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeHighlightsTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    homeVideoEnabled?: InputMaybe<Scalars['Boolean']>;
    homeVideoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    leaves?: InputMaybe<Scalars['Boolean']>;
    legacyId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    legacyUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    level?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
    menuLink?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsHeroImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    newsImages?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    nextSiblingOf?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    positionedAfter?: InputMaybe<Scalars['Int']>;
    positionedBefore?: InputMaybe<Scalars['Int']>;
    postDate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    prevSiblingOf?: InputMaybe<Scalars['Int']>;
    provisionalDrafts?: InputMaybe<Scalars['Boolean']>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    revisionCreator?: InputMaybe<Scalars['Int']>;
    revisionId?: InputMaybe<Scalars['Int']>;
    revisionOf?: InputMaybe<Scalars['QueryArgument']>;
    revisions?: InputMaybe<Scalars['Boolean']>;
    search?: InputMaybe<Scalars['String']>;
    section?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    sectionId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsCookieConsent?: InputMaybe<Scalars['Boolean']>;
    settingsMenuHidden?: InputMaybe<Scalars['Boolean']>;
    settingsMenuTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsMetaDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsNotSearchable?: InputMaybe<Scalars['Boolean']>;
    settingsSearchTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    settingsSeoTitle?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    structureId?: InputMaybe<Scalars['Int']>;
    textBody?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipAvatar?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipName?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipRole?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    tipText?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    video?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherCategory?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherDescription?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherLinkUrl?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhoneNumber?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherPhotos?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherProvider?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherTerms?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherValidationImage?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    voucherVisibility?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    withStructure?: InputMaybe<Scalars['Boolean']>;
};

export type Vouchers_Voucher_EntryVoucherCategoryArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type Vouchers_Voucher_EntryVoucherLocationsArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    fieldId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    typeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Vouchers_Voucher_EntryVoucherPhotosArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Vouchers_Voucher_EntryVoucherValidationImageArgs = {
    archived?: InputMaybe<Scalars['Boolean']>;
    assetRights?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetSource?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    assetTags?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    dateCreated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    dateModified?: InputMaybe<Scalars['String']>;
    dateUpdated?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    enabledForSite?: InputMaybe<Scalars['Boolean']>;
    filename?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fixedOrder?: InputMaybe<Scalars['Boolean']>;
    folderId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    height?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    id?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    inReverse?: InputMaybe<Scalars['Boolean']>;
    includeSubfolders?: InputMaybe<Scalars['Boolean']>;
    kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    preferSites?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    ref?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    relatedTo?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAll?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    relatedToAssets?: InputMaybe<Array<InputMaybe<AssetCriteriaInput>>>;
    relatedToCategories?: InputMaybe<Array<InputMaybe<CategoryCriteriaInput>>>;
    relatedToEntries?: InputMaybe<Array<InputMaybe<EntryCriteriaInput>>>;
    relatedToTags?: InputMaybe<Array<InputMaybe<TagCriteriaInput>>>;
    relatedToUsers?: InputMaybe<Array<InputMaybe<UserCriteriaInput>>>;
    search?: InputMaybe<Scalars['String']>;
    site?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    siteId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    siteSettingsId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    size?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    trashed?: InputMaybe<Scalars['Boolean']>;
    uid?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    unique?: InputMaybe<Scalars['Boolean']>;
    uploader?: InputMaybe<Scalars['QueryArgument']>;
    uri?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volume?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    volumeId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>>>;
    width?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    withTransforms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Vouchers_Voucher_EntryVoucherVisibilityArgs = {
    label?: InputMaybe<Scalars['Boolean']>;
};

export type ActionFragment = {
    title?: string | null;
    description?: string | null;
    button?:
        | ({
              customText?: string | null;
              target?: string | null;
              url?: string | null;
              type?: string | null;
              element?:
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'Element'
                            | 'Form'
                            | 'User'
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'assetTags_Tag'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contactMatrix_contact_BlockType'
                            | 'contact_GlobalSet'
                            | 'contact_contact_Entry'
                            | 'contentMatrix_sectionAccommodations_BlockType'
                            | 'contentMatrix_sectionAccordion_BlockType'
                            | 'contentMatrix_sectionAction_BlockType'
                            | 'contentMatrix_sectionActivities_BlockType'
                            | 'contentMatrix_sectionBanner_BlockType'
                            | 'contentMatrix_sectionButton_BlockType'
                            | 'contentMatrix_sectionCompanies_BlockType'
                            | 'contentMatrix_sectionEmbed_BlockType';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'contentMatrix_sectionForms_BlockType'
                            | 'contentMatrix_sectionHeader_BlockType'
                            | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                            | 'contentMatrix_sectionImages_BlockType'
                            | 'contentMatrix_sectionMap_BlockType'
                            | 'contentMatrix_sectionText_BlockType'
                            | 'contentMatrix_sectionTiles_BlockType'
                            | 'contentMatrix_sectionTip_BlockType'
                            | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                            | 'contentMatrix_sectionVideo_BlockType'
                            | 'contentMatrix_sectionWebcam_BlockType'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'library_Asset'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'notification_GlobalSet'
                            | 'pages_page_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'searchTags_Tag'
                            | 'search_search_Entry'
                            | 'synonyms_Tag'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'voucherLocations_location_BlockType'
                            | 'vouchers_voucher_Entry';
                    })
                  | null;
          } & { __typename?: 'linkField_Link' })
        | null;
} & { __typename?: 'action_action_Entry' };

type Card_S2VlD1Jymfoj7e8tOo2Gw7Vcvm5RslpkNvdRbOrtKc_Fragment = { title?: string | null; uri?: string | null } & {
    __typename?:
        | 'action_action_Entry'
        | 'banners_banner_Entry'
        | 'footer_footerCategory_Entry'
        | 'footer_footerLink_Entry'
        | 'menu_menu_Entry'
        | 'search_search_Entry'
        | 'tips_tip_Entry'
        | 'vouchers_voucher_Entry';
};

type Card_AnsVhR9s39kjoT5dXJxcPqJrrnsVkdjaVizuPvamj3w_Fragment = {
    title?: string | null;
    uri?: string | null;
    settingsMetaDescription?: string | null;
} & {
    __typename?:
        | 'agenda_agenda_Entry'
        | 'companies_companies_Entry'
        | 'myTexel_myTexel_Entry'
        | 'thankYou_thankYou_Entry';
};

type Card_Articles_Article_Entry_Fragment = {
    title?: string | null;
    uri?: string | null;
    postDate?: any | null;
    settingsMetaDescription?: string | null;
    heroImage?: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension?: string | null;
              url?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    > | null;
} & { __typename?: 'articles_article_Entry' };

type Card_Contact_Contact_Entry_Homepage_Homepage_Entry_News_News_Entry_Fragment = {
    title?: string | null;
    uri?: string | null;
    settingsMetaDescription?: string | null;
    heroImage?: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension?: string | null;
              url?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    > | null;
} & { __typename?: 'contact_contact_Entry' | 'homepage_homepage_Entry' | 'news_news_Entry' };

type Card_Pages_Page_Entry_Fragment = {
    title?: string | null;
    uri?: string | null;
    id?: string | null;
    settingsMetaDescription?: string | null;
    settingsNotSearchable?: boolean | null;
    heroImage?: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension?: string | null;
              url?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    > | null;
} & { __typename?: 'pages_page_Entry' };

export type CardFragment =
    | Card_S2VlD1Jymfoj7e8tOo2Gw7Vcvm5RslpkNvdRbOrtKc_Fragment
    | Card_AnsVhR9s39kjoT5dXJxcPqJrrnsVkdjaVizuPvamj3w_Fragment
    | Card_Articles_Article_Entry_Fragment
    | Card_Contact_Contact_Entry_Homepage_Homepage_Entry_News_News_Entry_Fragment
    | Card_Pages_Page_Entry_Fragment;

export type CardImageFragment = {
    focalPoint?: Array<number | null> | null;
    title?: string | null;
    extension?: string | null;
    url?: string | null;
    placeholderUrl?: string | null;
    srcset?: string | null;
    srcsetWebp?: string | null;
} & { __typename?: 'library_Asset' };

export type PageCardFragment = {
    id?: string | null;
    settingsMetaDescription?: string | null;
    settingsNotSearchable?: boolean | null;
    heroImage?: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension?: string | null;
              url?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    > | null;
} & { __typename?: 'pages_page_Entry' };

export type ArticleCardFragment = {
    postDate?: any | null;
    settingsMetaDescription?: string | null;
    heroImage?: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension?: string | null;
              url?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    > | null;
} & { __typename?: 'articles_article_Entry' };

export type NewsCardFragment = {
    settingsMetaDescription?: string | null;
    heroImage?: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension?: string | null;
              url?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    > | null;
} & { __typename?: 'news_news_Entry' };

export type HomeCardFragment = {
    settingsMetaDescription?: string | null;
    heroImage?: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension?: string | null;
              url?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    > | null;
} & { __typename?: 'homepage_homepage_Entry' };

export type ContactCardFragment = {
    settingsMetaDescription?: string | null;
    heroImage?: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension?: string | null;
              url?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    > | null;
} & { __typename?: 'contact_contact_Entry' };

export type AgendaCardFragment = { settingsMetaDescription?: string | null } & { __typename?: 'agenda_agenda_Entry' };

export type ThankYouCardFragment = { settingsMetaDescription?: string | null } & {
    __typename?: 'thankYou_thankYou_Entry';
};

export type CompanyCardFragment = { settingsMetaDescription?: string | null } & {
    __typename?: 'companies_companies_Entry';
};

export type MyTexelCardFragment = { settingsMetaDescription?: string | null } & {
    __typename?: 'myTexel_myTexel_Entry';
};

type ContentMatrix_ContentMatrix_SectionAccommodations_BlockType_Fragment = { accommodationsQuery?: string | null } & {
    __typename?: 'contentMatrix_sectionAccommodations_BlockType';
};

type ContentMatrix_ContentMatrix_SectionAccordion_BlockType_Fragment = {
    header?: string | null;
    text?: string | null;
} & { __typename?: 'contentMatrix_sectionAccordion_BlockType' };

type ContentMatrix_ContentMatrix_SectionAction_BlockType_Fragment = {
    __typename?: 'contentMatrix_sectionAction_BlockType';
};

type ContentMatrix_ContentMatrix_SectionActivities_BlockType_Fragment = { activitiesQuery?: string | null } & {
    __typename?: 'contentMatrix_sectionActivities_BlockType';
};

type ContentMatrix_ContentMatrix_SectionBanner_BlockType_Fragment = {
    banner?: Array<
        | {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          }
        | ({
              title?: string | null;
              heroImage?: Array<({ thumbUrl?: string | null } & { __typename?: 'library_Asset' }) | null> | null;
              button?:
                  | ({
                        customText?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Element'
                                      | 'Form'
                                      | 'User'
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'assetTags_Tag'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contactMatrix_contact_BlockType'
                                      | 'contact_GlobalSet'
                                      | 'contact_contact_Entry'
                                      | 'contentMatrix_sectionAccommodations_BlockType'
                                      | 'contentMatrix_sectionAccordion_BlockType'
                                      | 'contentMatrix_sectionAction_BlockType'
                                      | 'contentMatrix_sectionActivities_BlockType'
                                      | 'contentMatrix_sectionBanner_BlockType'
                                      | 'contentMatrix_sectionButton_BlockType'
                                      | 'contentMatrix_sectionCompanies_BlockType'
                                      | 'contentMatrix_sectionEmbed_BlockType';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'contentMatrix_sectionForms_BlockType'
                                      | 'contentMatrix_sectionHeader_BlockType'
                                      | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                      | 'contentMatrix_sectionImages_BlockType'
                                      | 'contentMatrix_sectionMap_BlockType'
                                      | 'contentMatrix_sectionText_BlockType'
                                      | 'contentMatrix_sectionTiles_BlockType'
                                      | 'contentMatrix_sectionTip_BlockType'
                                      | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                      | 'contentMatrix_sectionVideo_BlockType'
                                      | 'contentMatrix_sectionWebcam_BlockType'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'library_Asset'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'notification_GlobalSet'
                                      | 'pages_page_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'searchTags_Tag'
                                      | 'search_search_Entry'
                                      | 'synonyms_Tag'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'voucherLocations_location_BlockType'
                                      | 'vouchers_voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'linkField_Link' })
                  | null;
          } & { __typename?: 'banners_banner_Entry' })
        | null
    > | null;
} & { __typename?: 'contentMatrix_sectionBanner_BlockType' };

type ContentMatrix_ContentMatrix_SectionButton_BlockType_Fragment = {
    button?:
        | ({
              customText?: string | null;
              target?: string | null;
              url?: string | null;
              type?: string | null;
              element?:
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'Element'
                            | 'Form'
                            | 'User'
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'assetTags_Tag'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contactMatrix_contact_BlockType'
                            | 'contact_GlobalSet'
                            | 'contact_contact_Entry'
                            | 'contentMatrix_sectionAccommodations_BlockType'
                            | 'contentMatrix_sectionAccordion_BlockType'
                            | 'contentMatrix_sectionAction_BlockType'
                            | 'contentMatrix_sectionActivities_BlockType'
                            | 'contentMatrix_sectionBanner_BlockType'
                            | 'contentMatrix_sectionButton_BlockType'
                            | 'contentMatrix_sectionCompanies_BlockType'
                            | 'contentMatrix_sectionEmbed_BlockType';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'contentMatrix_sectionForms_BlockType'
                            | 'contentMatrix_sectionHeader_BlockType'
                            | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                            | 'contentMatrix_sectionImages_BlockType'
                            | 'contentMatrix_sectionMap_BlockType'
                            | 'contentMatrix_sectionText_BlockType'
                            | 'contentMatrix_sectionTiles_BlockType'
                            | 'contentMatrix_sectionTip_BlockType'
                            | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                            | 'contentMatrix_sectionVideo_BlockType'
                            | 'contentMatrix_sectionWebcam_BlockType'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'library_Asset'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'notification_GlobalSet'
                            | 'pages_page_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'searchTags_Tag'
                            | 'search_search_Entry'
                            | 'synonyms_Tag'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'voucherLocations_location_BlockType'
                            | 'vouchers_voucher_Entry';
                    })
                  | null;
          } & { __typename?: 'linkField_Link' })
        | null;
} & { __typename?: 'contentMatrix_sectionButton_BlockType' };

type ContentMatrix_ContentMatrix_SectionCompanies_BlockType_Fragment = { companiesQuery?: string | null } & {
    __typename?: 'contentMatrix_sectionCompanies_BlockType';
};

type ContentMatrix_ContentMatrix_SectionEmbed_BlockType_Fragment = { embed?: string | null } & {
    __typename?: 'contentMatrix_sectionEmbed_BlockType';
};

type ContentMatrix_ContentMatrix_SectionForms_BlockType_Fragment = {
    form?: Array<
        | ({
              id?: string | null;
              handle?: string | null;
              siteId?: number | null;
              settings?:
                  | ({
                        submitActionFormHide?: boolean | null;
                        submitActionMessageHtml?: string | null;
                        submitAction?: string | null;
                        submitActionTab?: string | null;
                        submitActionUrl?: string | null;
                        integrations?: Array<
                            | ({ handle?: string | null; enabled?: boolean | null } & {
                                  __typename?: 'FormIntegrationsType';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'FormSettingsType' })
                  | null;
              fields?: Array<
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        address1Label?: string | null;
                        address1Hidden?: boolean | null;
                        address1Enabled?: boolean | null;
                        address1ErrorMessage?: string | null;
                        address1Required?: boolean | null;
                        address1DefaultValue?: string | null;
                        address1Placeholder?: string | null;
                        zipLabel?: string | null;
                        zipHidden?: boolean | null;
                        zipEnabled?: boolean | null;
                        zipRequired?: boolean | null;
                        zipCollapsed?: boolean | null;
                        zipPlaceholder?: string | null;
                        zipDefaultValue?: string | null;
                        cityLabel?: string | null;
                        cityHidden?: boolean | null;
                        cityEnabled?: boolean | null;
                        cityRequired?: boolean | null;
                        cityPlaceholder?: string | null;
                        cityDefaultValue?: string | null;
                        cityErrorMessage?: string | null;
                        countryLabel?: string | null;
                        countryHidden?: boolean | null;
                        countryEnabled?: boolean | null;
                        countryRequired?: boolean | null;
                        countryPlaceholder?: string | null;
                        countryDefaultValue?: string | null;
                    } & { __typename?: 'Field_Address' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        checkedValue?: string | null;
                        uncheckedValue?: string | null;
                        descriptionHtml?: string | null;
                        defaultState?: boolean | null;
                    } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Categories'
                            | 'Field_Entries'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MissingField'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'KeyValueType' })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        displayType?: string | null;
                        defaultDate?: any | null;
                        defaultOption?: string | null;
                    } & { __typename?: 'Field_Date' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                                  __typename?: 'KeyValueType';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Dropdown' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        limit?: boolean | null;
                        limitType?: string | null;
                        limitAmount?: number | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Email' | 'Field_MultiLineText' | 'Field_Phone' | 'Field_SingleLineText' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        restrictFiles?: string | null;
                        allowedKinds?: Array<string | null> | null;
                        limitFiles?: string | null;
                        limitAmount?: number | null;
                        sizeLimit?: string | null;
                        sizeMinLimit?: string | null;
                    } & { __typename?: 'Field_FileUpload' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        fields?: Array<
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  address1Label?: string | null;
                                  address1Hidden?: boolean | null;
                                  address1Enabled?: boolean | null;
                                  address1ErrorMessage?: string | null;
                                  address1Required?: boolean | null;
                                  address1DefaultValue?: string | null;
                                  address1Placeholder?: string | null;
                                  zipLabel?: string | null;
                                  zipHidden?: boolean | null;
                                  zipEnabled?: boolean | null;
                                  zipRequired?: boolean | null;
                                  zipCollapsed?: boolean | null;
                                  zipPlaceholder?: string | null;
                                  zipDefaultValue?: string | null;
                                  cityLabel?: string | null;
                                  cityHidden?: boolean | null;
                                  cityEnabled?: boolean | null;
                                  cityRequired?: boolean | null;
                                  cityPlaceholder?: string | null;
                                  cityDefaultValue?: string | null;
                                  cityErrorMessage?: string | null;
                                  countryLabel?: string | null;
                                  countryHidden?: boolean | null;
                                  countryEnabled?: boolean | null;
                                  countryRequired?: boolean | null;
                                  countryPlaceholder?: string | null;
                                  countryDefaultValue?: string | null;
                              } & { __typename?: 'Field_Address' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  checkedValue?: string | null;
                                  uncheckedValue?: string | null;
                                  descriptionHtml?: string | null;
                                  defaultState?: boolean | null;
                              } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Categories'
                                      | 'Field_Entries'
                                      | 'Field_Group'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MissingField'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  options?: Array<
                                      | ({ isDefault?: boolean | null; value?: string | null } & {
                                            __typename?: 'KeyValueType';
                                        })
                                      | null
                                  > | null;
                              } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  displayType?: string | null;
                                  defaultDate?: any | null;
                                  defaultOption?: string | null;
                              } & { __typename?: 'Field_Date' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  options?: Array<
                                      | ({
                                            label?: string | null;
                                            isDefault?: boolean | null;
                                            value?: string | null;
                                        } & { __typename?: 'KeyValueType' })
                                      | null
                                  > | null;
                              } & { __typename?: 'Field_Dropdown' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  limit?: boolean | null;
                                  limitType?: string | null;
                                  limitAmount?: number | null;
                                  defaultValue?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Email'
                                      | 'Field_MultiLineText'
                                      | 'Field_Phone'
                                      | 'Field_SingleLineText';
                              })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  restrictFiles?: string | null;
                                  allowedKinds?: Array<string | null> | null;
                                  limitFiles?: string | null;
                                  limitAmount?: number | null;
                                  sizeLimit?: string | null;
                                  sizeMinLimit?: string | null;
                              } & { __typename?: 'Field_FileUpload' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  headingSize?: string | null;
                              } & { __typename?: 'Field_Heading' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  prefixLabel?: string | null;
                                  prefixEnabled?: boolean | null;
                                  prefixRequired?: boolean | null;
                                  firstNameLabel?: string | null;
                                  firstNameEnabled?: boolean | null;
                                  firstNamePlaceholder?: string | null;
                                  firstNameRequired?: boolean | null;
                                  middleNameLabel?: string | null;
                                  middleNameEnabled?: boolean | null;
                                  middleNamePlaceholder?: string | null;
                                  middleNameRequired?: boolean | null;
                                  lastNameLabel?: string | null;
                                  lastNameEnabled?: boolean | null;
                                  lastNamePlaceholder?: string | null;
                                  lastNameRequired?: boolean | null;
                              } & { __typename?: 'Field_Name' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  min?: string | null;
                                  max?: string | null;
                              } & { __typename?: 'Field_Number' })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Group' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        headingSize?: string | null;
                    } & { __typename?: 'Field_Heading' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        prefixLabel?: string | null;
                        prefixEnabled?: boolean | null;
                        prefixRequired?: boolean | null;
                        firstNameLabel?: string | null;
                        firstNameEnabled?: boolean | null;
                        firstNamePlaceholder?: string | null;
                        firstNameRequired?: boolean | null;
                        middleNameLabel?: string | null;
                        middleNameEnabled?: boolean | null;
                        middleNamePlaceholder?: string | null;
                        middleNameRequired?: boolean | null;
                        lastNameLabel?: string | null;
                        lastNameEnabled?: boolean | null;
                        lastNamePlaceholder?: string | null;
                        lastNameRequired?: boolean | null;
                    } & { __typename?: 'Field_Name' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        min?: string | null;
                        max?: string | null;
                    } & { __typename?: 'Field_Number' })
                  | null
              > | null;
          } & { __typename?: 'Form' })
        | null
    > | null;
} & { __typename?: 'contentMatrix_sectionForms_BlockType' };

type ContentMatrix_ContentMatrix_SectionHeader_BlockType_Fragment = { header?: string | null } & {
    __typename?: 'contentMatrix_sectionHeader_BlockType';
};

type ContentMatrix_ContentMatrix_SectionHierarchicalTiles_BlockType_Fragment = { relationType?: string | null } & {
    __typename?: 'contentMatrix_sectionHierarchicalTiles_BlockType';
};

type ContentMatrix_ContentMatrix_SectionImages_BlockType_Fragment = {
    gallery?: Array<
        | ({
              assetSource?: string | null;
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    > | null;
} & { __typename?: 'contentMatrix_sectionImages_BlockType' };

type ContentMatrix_ContentMatrix_SectionMap_BlockType_Fragment = {
    pins?: Array<
        | ({ latitude?: any | null; longitude?: any | null; symbol?: string | null; label?: string | null } & {
              __typename?: 'pins_TableRow';
          })
        | null
    > | null;
} & { __typename?: 'contentMatrix_sectionMap_BlockType' };

type ContentMatrix_ContentMatrix_SectionText_BlockType_Fragment = { text?: string | null } & {
    __typename?: 'contentMatrix_sectionText_BlockType';
};

type ContentMatrix_ContentMatrix_SectionTiles_BlockType_Fragment = {
    tilesChildren?: boolean | null;
    tilesSiblings?: boolean | null;
    tilesEntries?: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'banners_banner_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'menu_menu_Entry'
                  | 'search_search_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
              __typename?:
                  | 'agenda_agenda_Entry'
                  | 'companies_companies_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'thankYou_thankYou_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              postDate?: any | null;
              settingsMetaDescription?: string | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'articles_article_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              settingsMetaDescription?: string | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'contact_contact_Entry' | 'homepage_homepage_Entry' | 'news_news_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              id?: string | null;
              settingsMetaDescription?: string | null;
              settingsNotSearchable?: boolean | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'pages_page_Entry' })
        | null
    > | null;
} & { __typename?: 'contentMatrix_sectionTiles_BlockType' };

type ContentMatrix_ContentMatrix_SectionTip_BlockType_Fragment = {
    tip?: Array<
        | {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'vouchers_voucher_Entry';
          }
        | ({
              title?: string | null;
              tipText?: string | null;
              tipName?: string | null;
              tipRole?: string | null;
              tipAvatar?: Array<
                  | ({ height?: number | null; width?: number | null; thumbUrl?: string | null } & {
                        __typename?: 'library_Asset';
                    })
                  | null
              > | null;
          } & { __typename?: 'tips_tip_Entry' })
        | null
    > | null;
} & { __typename?: 'contentMatrix_sectionTip_BlockType' };

type ContentMatrix_ContentMatrix_SectionTravelbaseTiles_BlockType_Fragment = {
    travelbaseEntityType?: string | null;
    travelbaseSlugs?: Array<({ slug?: string | null } & { __typename?: 'travelbaseSlugs_TableRow' }) | null> | null;
} & { __typename?: 'contentMatrix_sectionTravelbaseTiles_BlockType' };

type ContentMatrix_ContentMatrix_SectionVideo_BlockType_Fragment = {
    videoUrl?: string | null;
    autoplay?: boolean | null;
} & { __typename?: 'contentMatrix_sectionVideo_BlockType' };

type ContentMatrix_ContentMatrix_SectionWebcam_BlockType_Fragment = { webcamUrl?: string | null } & {
    __typename?: 'contentMatrix_sectionWebcam_BlockType';
};

export type ContentMatrixFragment =
    | ContentMatrix_ContentMatrix_SectionAccommodations_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionAccordion_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionAction_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionActivities_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionBanner_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionButton_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionCompanies_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionEmbed_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionForms_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionHeader_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionHierarchicalTiles_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionImages_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionMap_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionText_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionTiles_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionTip_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionTravelbaseTiles_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionVideo_BlockType_Fragment
    | ContentMatrix_ContentMatrix_SectionWebcam_BlockType_Fragment;

export type TravelbaseTileFragment = {
    travelbaseEntityType?: string | null;
    travelbaseSlugs?: Array<({ slug?: string | null } & { __typename?: 'travelbaseSlugs_TableRow' }) | null> | null;
} & { __typename?: 'contentMatrix_sectionTravelbaseTiles_BlockType' };

type FieldRenderer_Field_Address_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    name?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    address1Label?: string | null;
    address1Hidden?: boolean | null;
    address1Enabled?: boolean | null;
    address1ErrorMessage?: string | null;
    address1Required?: boolean | null;
    address1DefaultValue?: string | null;
    address1Placeholder?: string | null;
    zipLabel?: string | null;
    zipHidden?: boolean | null;
    zipEnabled?: boolean | null;
    zipRequired?: boolean | null;
    zipCollapsed?: boolean | null;
    zipPlaceholder?: string | null;
    zipDefaultValue?: string | null;
    cityLabel?: string | null;
    cityHidden?: boolean | null;
    cityEnabled?: boolean | null;
    cityRequired?: boolean | null;
    cityPlaceholder?: string | null;
    cityDefaultValue?: string | null;
    cityErrorMessage?: string | null;
    countryLabel?: string | null;
    countryHidden?: boolean | null;
    countryEnabled?: boolean | null;
    countryRequired?: boolean | null;
    countryPlaceholder?: string | null;
    countryDefaultValue?: string | null;
} & { __typename?: 'Field_Address' };

type FieldRenderer_Field_Agree_Field_Newsletter_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    name?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    checkedValue?: string | null;
    uncheckedValue?: string | null;
    descriptionHtml?: string | null;
    defaultState?: boolean | null;
} & { __typename?: 'Field_Agree' | 'Field_Newsletter' };

type FieldRenderer_WgHgcMuWw6Pm4PugrhD2Dd0Dp5HbEjzcvv2hDdjnM_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    name?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
} & {
    __typename?:
        | 'Field_Categories'
        | 'Field_Entries'
        | 'Field_Group'
        | 'Field_Hidden'
        | 'Field_Html'
        | 'Field_MissingField'
        | 'Field_Recipients'
        | 'Field_Repeater'
        | 'Field_Section'
        | 'Field_Table'
        | 'Field_Tags'
        | 'Field_Users';
};

type FieldRenderer_Field_Checkboxes_Field_Radio_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    name?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    options?: Array<
        ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'KeyValueType' }) | null
    > | null;
} & { __typename?: 'Field_Checkboxes' | 'Field_Radio' };

type FieldRenderer_Field_Date_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    name?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    displayType?: string | null;
    defaultDate?: any | null;
    defaultOption?: string | null;
} & { __typename?: 'Field_Date' };

type FieldRenderer_Field_Dropdown_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    name?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    options?: Array<
        | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
              __typename?: 'KeyValueType';
          })
        | null
    > | null;
} & { __typename?: 'Field_Dropdown' };

type FieldRenderer_XrCjAr3G5mtgjWc1IqdQofJeNlYu4zq66ofEZrVilwY_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    name?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    limit?: boolean | null;
    limitType?: string | null;
    limitAmount?: number | null;
    defaultValue?: string | null;
} & { __typename?: 'Field_Email' | 'Field_MultiLineText' | 'Field_Phone' | 'Field_SingleLineText' };

type FieldRenderer_Field_FileUpload_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    name?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    restrictFiles?: string | null;
    allowedKinds?: Array<string | null> | null;
    limitFiles?: string | null;
    limitAmount?: number | null;
    sizeLimit?: string | null;
    sizeMinLimit?: string | null;
} & { __typename?: 'Field_FileUpload' };

type FieldRenderer_Field_Heading_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    name?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    headingSize?: string | null;
} & { __typename?: 'Field_Heading' };

type FieldRenderer_Field_Name_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    name?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    prefixLabel?: string | null;
    prefixEnabled?: boolean | null;
    prefixRequired?: boolean | null;
    firstNameLabel?: string | null;
    firstNameEnabled?: boolean | null;
    firstNamePlaceholder?: string | null;
    firstNameRequired?: boolean | null;
    middleNameLabel?: string | null;
    middleNameEnabled?: boolean | null;
    middleNamePlaceholder?: string | null;
    middleNameRequired?: boolean | null;
    lastNameLabel?: string | null;
    lastNameEnabled?: boolean | null;
    lastNamePlaceholder?: string | null;
    lastNameRequired?: boolean | null;
} & { __typename?: 'Field_Name' };

type FieldRenderer_Field_Number_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    name?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
    defaultValue?: string | null;
    min?: string | null;
    max?: string | null;
} & { __typename?: 'Field_Number' };

export type FieldRendererFragment =
    | FieldRenderer_Field_Address_Fragment
    | FieldRenderer_Field_Agree_Field_Newsletter_Fragment
    | FieldRenderer_WgHgcMuWw6Pm4PugrhD2Dd0Dp5HbEjzcvv2hDdjnM_Fragment
    | FieldRenderer_Field_Checkboxes_Field_Radio_Fragment
    | FieldRenderer_Field_Date_Fragment
    | FieldRenderer_Field_Dropdown_Fragment
    | FieldRenderer_XrCjAr3G5mtgjWc1IqdQofJeNlYu4zq66ofEZrVilwY_Fragment
    | FieldRenderer_Field_FileUpload_Fragment
    | FieldRenderer_Field_Heading_Fragment
    | FieldRenderer_Field_Name_Fragment
    | FieldRenderer_Field_Number_Fragment;

type Field_Vgyjym6Lxj5hJgRunous9J7WSsbeKyMxfg3yxczaQ_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    name?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
} & {
    __typename?:
        | 'Field_Address'
        | 'Field_Agree'
        | 'Field_Categories'
        | 'Field_Checkboxes'
        | 'Field_Date'
        | 'Field_Dropdown'
        | 'Field_Email'
        | 'Field_Entries'
        | 'Field_FileUpload'
        | 'Field_Group'
        | 'Field_Heading'
        | 'Field_Hidden'
        | 'Field_Html'
        | 'Field_MissingField'
        | 'Field_MultiLineText'
        | 'Field_Name'
        | 'Field_Newsletter'
        | 'Field_Number'
        | 'Field_Phone'
        | 'Field_Radio';
};

type Field_Dqrf5lzefFtjdxWydaeslUnSs7EShpDMiuPinihPdcM_Fragment = {
    instructions?: string | null;
    instructionsPosition?: string | null;
    name?: string | null;
    placeholder?: string | null;
    required?: boolean | null;
    handle?: string | null;
    errorMessage?: string | null;
} & {
    __typename?:
        | 'Field_Recipients'
        | 'Field_Repeater'
        | 'Field_Section'
        | 'Field_SingleLineText'
        | 'Field_Table'
        | 'Field_Tags'
        | 'Field_Users';
};

export type FieldFragment =
    | Field_Vgyjym6Lxj5hJgRunous9J7WSsbeKyMxfg3yxczaQ_Fragment
    | Field_Dqrf5lzefFtjdxWydaeslUnSs7EShpDMiuPinihPdcM_Fragment;

export type FooterItemsFragment = {
    title?: string | null;
    children?: Array<
        | {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          }
        | ({
              title?: string | null;
              menuLink?:
                  | ({
                        customText?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Element'
                                      | 'Form'
                                      | 'User'
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'assetTags_Tag'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contactMatrix_contact_BlockType'
                                      | 'contact_GlobalSet'
                                      | 'contact_contact_Entry'
                                      | 'contentMatrix_sectionAccommodations_BlockType'
                                      | 'contentMatrix_sectionAccordion_BlockType'
                                      | 'contentMatrix_sectionAction_BlockType'
                                      | 'contentMatrix_sectionActivities_BlockType'
                                      | 'contentMatrix_sectionBanner_BlockType'
                                      | 'contentMatrix_sectionButton_BlockType'
                                      | 'contentMatrix_sectionCompanies_BlockType'
                                      | 'contentMatrix_sectionEmbed_BlockType';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'contentMatrix_sectionForms_BlockType'
                                      | 'contentMatrix_sectionHeader_BlockType'
                                      | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                      | 'contentMatrix_sectionImages_BlockType'
                                      | 'contentMatrix_sectionMap_BlockType'
                                      | 'contentMatrix_sectionText_BlockType'
                                      | 'contentMatrix_sectionTiles_BlockType'
                                      | 'contentMatrix_sectionTip_BlockType'
                                      | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                      | 'contentMatrix_sectionVideo_BlockType'
                                      | 'contentMatrix_sectionWebcam_BlockType'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'library_Asset'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'notification_GlobalSet'
                                      | 'pages_page_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'searchTags_Tag'
                                      | 'search_search_Entry'
                                      | 'synonyms_Tag'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'voucherLocations_location_BlockType'
                                      | 'vouchers_voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'linkField_Link' })
                  | null;
          } & { __typename: 'footer_footerLink_Entry' })
        | null
    > | null;
} & { __typename?: 'footer_footerCategory_Entry' };

export type FooterSubmenuFragment = {
    title?: string | null;
    menuLink?:
        | ({
              customText?: string | null;
              target?: string | null;
              url?: string | null;
              type?: string | null;
              element?:
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'Element'
                            | 'Form'
                            | 'User'
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'assetTags_Tag'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contactMatrix_contact_BlockType'
                            | 'contact_GlobalSet'
                            | 'contact_contact_Entry'
                            | 'contentMatrix_sectionAccommodations_BlockType'
                            | 'contentMatrix_sectionAccordion_BlockType'
                            | 'contentMatrix_sectionAction_BlockType'
                            | 'contentMatrix_sectionActivities_BlockType'
                            | 'contentMatrix_sectionBanner_BlockType'
                            | 'contentMatrix_sectionButton_BlockType'
                            | 'contentMatrix_sectionCompanies_BlockType'
                            | 'contentMatrix_sectionEmbed_BlockType';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'contentMatrix_sectionForms_BlockType'
                            | 'contentMatrix_sectionHeader_BlockType'
                            | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                            | 'contentMatrix_sectionImages_BlockType'
                            | 'contentMatrix_sectionMap_BlockType'
                            | 'contentMatrix_sectionText_BlockType'
                            | 'contentMatrix_sectionTiles_BlockType'
                            | 'contentMatrix_sectionTip_BlockType'
                            | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                            | 'contentMatrix_sectionVideo_BlockType'
                            | 'contentMatrix_sectionWebcam_BlockType'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'library_Asset'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'notification_GlobalSet'
                            | 'pages_page_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'searchTags_Tag'
                            | 'search_search_Entry'
                            | 'synonyms_Tag'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'voucherLocations_location_BlockType'
                            | 'vouchers_voucher_Entry';
                    })
                  | null;
          } & { __typename?: 'linkField_Link' })
        | null;
} & { __typename: 'footer_footerLink_Entry' };

export type FormFragment = {
    id?: string | null;
    handle?: string | null;
    siteId?: number | null;
    settings?:
        | ({
              submitActionFormHide?: boolean | null;
              submitActionMessageHtml?: string | null;
              submitAction?: string | null;
              submitActionTab?: string | null;
              submitActionUrl?: string | null;
              integrations?: Array<
                  | ({ handle?: string | null; enabled?: boolean | null } & { __typename?: 'FormIntegrationsType' })
                  | null
              > | null;
          } & { __typename?: 'FormSettingsType' })
        | null;
    fields?: Array<
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              address1Label?: string | null;
              address1Hidden?: boolean | null;
              address1Enabled?: boolean | null;
              address1ErrorMessage?: string | null;
              address1Required?: boolean | null;
              address1DefaultValue?: string | null;
              address1Placeholder?: string | null;
              zipLabel?: string | null;
              zipHidden?: boolean | null;
              zipEnabled?: boolean | null;
              zipRequired?: boolean | null;
              zipCollapsed?: boolean | null;
              zipPlaceholder?: string | null;
              zipDefaultValue?: string | null;
              cityLabel?: string | null;
              cityHidden?: boolean | null;
              cityEnabled?: boolean | null;
              cityRequired?: boolean | null;
              cityPlaceholder?: string | null;
              cityDefaultValue?: string | null;
              cityErrorMessage?: string | null;
              countryLabel?: string | null;
              countryHidden?: boolean | null;
              countryEnabled?: boolean | null;
              countryRequired?: boolean | null;
              countryPlaceholder?: string | null;
              countryDefaultValue?: string | null;
          } & { __typename?: 'Field_Address' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              checkedValue?: string | null;
              uncheckedValue?: string | null;
              descriptionHtml?: string | null;
              defaultState?: boolean | null;
          } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_Categories'
                  | 'Field_Entries'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_MissingField'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Table'
                  | 'Field_Tags'
                  | 'Field_Users';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'KeyValueType' }) | null
              > | null;
          } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              displayType?: string | null;
              defaultDate?: any | null;
              defaultOption?: string | null;
          } & { __typename?: 'Field_Date' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                        __typename?: 'KeyValueType';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Dropdown' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              limit?: boolean | null;
              limitType?: string | null;
              limitAmount?: number | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Email' | 'Field_MultiLineText' | 'Field_Phone' | 'Field_SingleLineText' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              restrictFiles?: string | null;
              allowedKinds?: Array<string | null> | null;
              limitFiles?: string | null;
              limitAmount?: number | null;
              sizeLimit?: string | null;
              sizeMinLimit?: string | null;
          } & { __typename?: 'Field_FileUpload' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              fields?: Array<
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        address1Label?: string | null;
                        address1Hidden?: boolean | null;
                        address1Enabled?: boolean | null;
                        address1ErrorMessage?: string | null;
                        address1Required?: boolean | null;
                        address1DefaultValue?: string | null;
                        address1Placeholder?: string | null;
                        zipLabel?: string | null;
                        zipHidden?: boolean | null;
                        zipEnabled?: boolean | null;
                        zipRequired?: boolean | null;
                        zipCollapsed?: boolean | null;
                        zipPlaceholder?: string | null;
                        zipDefaultValue?: string | null;
                        cityLabel?: string | null;
                        cityHidden?: boolean | null;
                        cityEnabled?: boolean | null;
                        cityRequired?: boolean | null;
                        cityPlaceholder?: string | null;
                        cityDefaultValue?: string | null;
                        cityErrorMessage?: string | null;
                        countryLabel?: string | null;
                        countryHidden?: boolean | null;
                        countryEnabled?: boolean | null;
                        countryRequired?: boolean | null;
                        countryPlaceholder?: string | null;
                        countryDefaultValue?: string | null;
                    } & { __typename?: 'Field_Address' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        checkedValue?: string | null;
                        uncheckedValue?: string | null;
                        descriptionHtml?: string | null;
                        defaultState?: boolean | null;
                    } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                    } & {
                        __typename?:
                            | 'Field_Categories'
                            | 'Field_Entries'
                            | 'Field_Group'
                            | 'Field_Hidden'
                            | 'Field_Html'
                            | 'Field_MissingField'
                            | 'Field_Recipients'
                            | 'Field_Repeater'
                            | 'Field_Section'
                            | 'Field_Table'
                            | 'Field_Tags'
                            | 'Field_Users';
                    })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'KeyValueType' })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        displayType?: string | null;
                        defaultDate?: any | null;
                        defaultOption?: string | null;
                    } & { __typename?: 'Field_Date' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        options?: Array<
                            | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                                  __typename?: 'KeyValueType';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'Field_Dropdown' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        limit?: boolean | null;
                        limitType?: string | null;
                        limitAmount?: number | null;
                        defaultValue?: string | null;
                    } & { __typename?: 'Field_Email' | 'Field_MultiLineText' | 'Field_Phone' | 'Field_SingleLineText' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        restrictFiles?: string | null;
                        allowedKinds?: Array<string | null> | null;
                        limitFiles?: string | null;
                        limitAmount?: number | null;
                        sizeLimit?: string | null;
                        sizeMinLimit?: string | null;
                    } & { __typename?: 'Field_FileUpload' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        headingSize?: string | null;
                    } & { __typename?: 'Field_Heading' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        prefixLabel?: string | null;
                        prefixEnabled?: boolean | null;
                        prefixRequired?: boolean | null;
                        firstNameLabel?: string | null;
                        firstNameEnabled?: boolean | null;
                        firstNamePlaceholder?: string | null;
                        firstNameRequired?: boolean | null;
                        middleNameLabel?: string | null;
                        middleNameEnabled?: boolean | null;
                        middleNamePlaceholder?: string | null;
                        middleNameRequired?: boolean | null;
                        lastNameLabel?: string | null;
                        lastNameEnabled?: boolean | null;
                        lastNamePlaceholder?: string | null;
                        lastNameRequired?: boolean | null;
                    } & { __typename?: 'Field_Name' })
                  | ({
                        instructions?: string | null;
                        instructionsPosition?: string | null;
                        name?: string | null;
                        placeholder?: string | null;
                        required?: boolean | null;
                        handle?: string | null;
                        errorMessage?: string | null;
                        defaultValue?: string | null;
                        min?: string | null;
                        max?: string | null;
                    } & { __typename?: 'Field_Number' })
                  | null
              > | null;
          } & { __typename?: 'Field_Group' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              headingSize?: string | null;
          } & { __typename?: 'Field_Heading' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              prefixLabel?: string | null;
              prefixEnabled?: boolean | null;
              prefixRequired?: boolean | null;
              firstNameLabel?: string | null;
              firstNameEnabled?: boolean | null;
              firstNamePlaceholder?: string | null;
              firstNameRequired?: boolean | null;
              middleNameLabel?: string | null;
              middleNameEnabled?: boolean | null;
              middleNamePlaceholder?: string | null;
              middleNameRequired?: boolean | null;
              lastNameLabel?: string | null;
              lastNameEnabled?: boolean | null;
              lastNamePlaceholder?: string | null;
              lastNameRequired?: boolean | null;
          } & { __typename?: 'Field_Name' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              min?: string | null;
              max?: string | null;
          } & { __typename?: 'Field_Number' })
        | null
    > | null;
} & { __typename?: 'Form' };

type Highlight_Ond3QxpgAqndMzGXxdhgseStnuXxSyxsNlzLmU6nohM_Fragment = { title?: string | null; uri?: string | null } & {
    __typename:
        | 'action_action_Entry'
        | 'agenda_agenda_Entry'
        | 'banners_banner_Entry'
        | 'companies_companies_Entry'
        | 'contact_contact_Entry'
        | 'footer_footerCategory_Entry'
        | 'footer_footerLink_Entry'
        | 'homepage_homepage_Entry'
        | 'menu_menu_Entry'
        | 'myTexel_myTexel_Entry'
        | 'news_news_Entry'
        | 'search_search_Entry'
        | 'thankYou_thankYou_Entry'
        | 'tips_tip_Entry'
        | 'vouchers_voucher_Entry';
};

type Highlight_Articles_Article_Entry_Fragment = {
    title?: string | null;
    uri?: string | null;
    newsHeroImage?: Array<
        | ({
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension?: string | null;
              url?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
              placeholderUrl?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    > | null;
} & { __typename: 'articles_article_Entry' };

type Highlight_Pages_Page_Entry_Fragment = {
    title?: string | null;
    uri?: string | null;
    heroImage?: Array<
        | ({
              width?: number | null;
              height?: number | null;
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension?: string | null;
              url?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
              placeholderUrl?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    > | null;
} & { __typename: 'pages_page_Entry' };

export type HighlightFragment =
    | Highlight_Ond3QxpgAqndMzGXxdhgseStnuXxSyxsNlzLmU6nohM_Fragment
    | Highlight_Articles_Article_Entry_Fragment
    | Highlight_Pages_Page_Entry_Fragment;

export type LinkFieldFragment = {
    customText?: string | null;
    target?: string | null;
    url?: string | null;
    type?: string | null;
    element?:
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'Element'
                  | 'Form'
                  | 'User'
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'assetTags_Tag'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contactMatrix_contact_BlockType'
                  | 'contact_GlobalSet'
                  | 'contact_contact_Entry'
                  | 'contentMatrix_sectionAccommodations_BlockType'
                  | 'contentMatrix_sectionAccordion_BlockType'
                  | 'contentMatrix_sectionAction_BlockType'
                  | 'contentMatrix_sectionActivities_BlockType'
                  | 'contentMatrix_sectionBanner_BlockType'
                  | 'contentMatrix_sectionButton_BlockType'
                  | 'contentMatrix_sectionCompanies_BlockType'
                  | 'contentMatrix_sectionEmbed_BlockType';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'contentMatrix_sectionForms_BlockType'
                  | 'contentMatrix_sectionHeader_BlockType'
                  | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                  | 'contentMatrix_sectionImages_BlockType'
                  | 'contentMatrix_sectionMap_BlockType'
                  | 'contentMatrix_sectionText_BlockType'
                  | 'contentMatrix_sectionTiles_BlockType'
                  | 'contentMatrix_sectionTip_BlockType'
                  | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                  | 'contentMatrix_sectionVideo_BlockType'
                  | 'contentMatrix_sectionWebcam_BlockType'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'library_Asset'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'notification_GlobalSet'
                  | 'pages_page_Entry';
          })
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'searchTags_Tag'
                  | 'search_search_Entry'
                  | 'synonyms_Tag'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'voucherLocations_location_BlockType'
                  | 'vouchers_voucher_Entry';
          })
        | null;
} & { __typename?: 'linkField_Link' };

export type MenuItemsFragment = {
    id?: string | null;
    title?: string | null;
    menuLink?:
        | ({
              customText?: string | null;
              target?: string | null;
              url?: string | null;
              type?: string | null;
              element?:
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'Element'
                            | 'Form'
                            | 'User'
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'assetTags_Tag'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contactMatrix_contact_BlockType'
                            | 'contact_GlobalSet'
                            | 'contact_contact_Entry'
                            | 'contentMatrix_sectionAccommodations_BlockType'
                            | 'contentMatrix_sectionAccordion_BlockType'
                            | 'contentMatrix_sectionAction_BlockType'
                            | 'contentMatrix_sectionActivities_BlockType'
                            | 'contentMatrix_sectionBanner_BlockType'
                            | 'contentMatrix_sectionButton_BlockType'
                            | 'contentMatrix_sectionCompanies_BlockType'
                            | 'contentMatrix_sectionEmbed_BlockType';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'contentMatrix_sectionForms_BlockType'
                            | 'contentMatrix_sectionHeader_BlockType'
                            | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                            | 'contentMatrix_sectionImages_BlockType'
                            | 'contentMatrix_sectionMap_BlockType'
                            | 'contentMatrix_sectionText_BlockType'
                            | 'contentMatrix_sectionTiles_BlockType'
                            | 'contentMatrix_sectionTip_BlockType'
                            | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                            | 'contentMatrix_sectionVideo_BlockType'
                            | 'contentMatrix_sectionWebcam_BlockType'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'library_Asset'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'notification_GlobalSet'
                            | 'searchTags_Tag';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'search_search_Entry'
                            | 'synonyms_Tag'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'voucherLocations_location_BlockType'
                            | 'vouchers_voucher_Entry';
                    })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        slug?: string | null;
                        id?: string | null;
                        settingsMenuHidden?: boolean | null;
                        settingsMenuTitle?: string | null;
                        parent?:
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'pages_page_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | null;
                        children?: Array<
                            | ({
                                  children?: Array<
                                      | ({
                                            children?: Array<
                                                | ({
                                                      children?: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'pages_page_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'pages_page_Entry' })
                                                          | null
                                                      > | null;
                                                  } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children?: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'pages_page_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'pages_page_Entry' })
                                                          | null
                                                      > | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                        } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children?: Array<
                                                | ({
                                                      children?: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'pages_page_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'pages_page_Entry' })
                                                          | null
                                                      > | null;
                                                  } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children?: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'pages_page_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'pages_page_Entry' })
                                                          | null
                                                      > | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                              } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children?: Array<
                                      | ({
                                            children?: Array<
                                                | ({
                                                      children?: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'pages_page_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'pages_page_Entry' })
                                                          | null
                                                      > | null;
                                                  } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children?: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'pages_page_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'pages_page_Entry' })
                                                          | null
                                                      > | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                        } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children?: Array<
                                                | ({
                                                      children?: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'pages_page_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'pages_page_Entry' })
                                                          | null
                                                      > | null;
                                                  } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children?: Array<
                                                          | {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            }
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'pages_page_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'pages_page_Entry' })
                                                          | null
                                                      > | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'pages_page_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null
                        > | null;
                    } & { __typename?: 'pages_page_Entry' })
                  | null;
          } & { __typename?: 'linkField_Link' })
        | null;
} & { __typename?: 'menu_menu_Entry' };

export type MenuItemPageRecursiveFragment = {
    children?: Array<
        | ({
              children?: Array<
                  | ({
                        children?: Array<
                            | ({
                                  children?: Array<
                                      | {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                              } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children?: Array<
                                      | {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'pages_page_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null
                        > | null;
                    } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | ({
                        slug?: string | null;
                        id?: string | null;
                        title?: string | null;
                        uri?: string | null;
                        settingsMenuHidden?: boolean | null;
                        settingsMenuTitle?: string | null;
                        children?: Array<
                            | ({
                                  children?: Array<
                                      | {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                              } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children?: Array<
                                      | {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'pages_page_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null
                        > | null;
                        parent?:
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'pages_page_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'pages_page_Entry' })
                  | null
              > | null;
          } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | ({
              slug?: string | null;
              id?: string | null;
              title?: string | null;
              uri?: string | null;
              settingsMenuHidden?: boolean | null;
              settingsMenuTitle?: string | null;
              children?: Array<
                  | ({
                        children?: Array<
                            | ({
                                  children?: Array<
                                      | {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                              } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children?: Array<
                                      | {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'pages_page_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null
                        > | null;
                    } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | ({
                        slug?: string | null;
                        id?: string | null;
                        title?: string | null;
                        uri?: string | null;
                        settingsMenuHidden?: boolean | null;
                        settingsMenuTitle?: string | null;
                        children?: Array<
                            | ({
                                  children?: Array<
                                      | {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                              } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children?: Array<
                                      | {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'pages_page_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null
                        > | null;
                        parent?:
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'pages_page_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'pages_page_Entry' })
                  | null
              > | null;
              parent?:
                  | ({ uri?: string | null; id?: string | null } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | null;
          } & { __typename?: 'pages_page_Entry' })
        | null
    > | null;
} & { __typename?: 'pages_page_Entry' };

export type MenuItemPageFieldsFragment = {
    slug?: string | null;
    id?: string | null;
    title?: string | null;
    uri?: string | null;
    settingsMenuHidden?: boolean | null;
    settingsMenuTitle?: string | null;
    parent?:
        | ({ uri?: string | null; id?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | null;
} & { __typename?: 'pages_page_Entry' };

export type NotificationFragment = {
    header?: string | null;
    active?: boolean | null;
    enabled?: boolean | null;
    description?: string | null;
    button?:
        | ({
              text?: string | null;
              customText?: string | null;
              target?: string | null;
              url?: string | null;
              type?: string | null;
              element?:
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'Element'
                            | 'Form'
                            | 'User'
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'assetTags_Tag'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contactMatrix_contact_BlockType'
                            | 'contact_GlobalSet'
                            | 'contact_contact_Entry'
                            | 'contentMatrix_sectionAccommodations_BlockType'
                            | 'contentMatrix_sectionAccordion_BlockType'
                            | 'contentMatrix_sectionAction_BlockType'
                            | 'contentMatrix_sectionActivities_BlockType'
                            | 'contentMatrix_sectionBanner_BlockType'
                            | 'contentMatrix_sectionButton_BlockType'
                            | 'contentMatrix_sectionCompanies_BlockType'
                            | 'contentMatrix_sectionEmbed_BlockType';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'contentMatrix_sectionForms_BlockType'
                            | 'contentMatrix_sectionHeader_BlockType'
                            | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                            | 'contentMatrix_sectionImages_BlockType'
                            | 'contentMatrix_sectionMap_BlockType'
                            | 'contentMatrix_sectionText_BlockType'
                            | 'contentMatrix_sectionTiles_BlockType'
                            | 'contentMatrix_sectionTip_BlockType'
                            | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                            | 'contentMatrix_sectionVideo_BlockType'
                            | 'contentMatrix_sectionWebcam_BlockType'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'library_Asset'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'notification_GlobalSet'
                            | 'pages_page_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'searchTags_Tag'
                            | 'search_search_Entry'
                            | 'synonyms_Tag'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'voucherLocations_location_BlockType'
                            | 'vouchers_voucher_Entry';
                    })
                  | null;
          } & { __typename?: 'linkField_Link' })
        | null;
} & { __typename?: 'notification_GlobalSet' };

export type PhotosCarouselGalleryFragment = {
    assetSource?: string | null;
    focalPoint?: Array<number | null> | null;
    title?: string | null;
    extension?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    placeholderUrl?: string | null;
    srcset?: string | null;
    srcsetWebp?: string | null;
} & { __typename?: 'library_Asset' };

export type ReadMoreQueryVariables = Exact<{
    parentId?: InputMaybe<Scalars['Int']>;
    id?: InputMaybe<Scalars['Int']>;
    q?: InputMaybe<Scalars['String']>;
    notId?: InputMaybe<Array<InputMaybe<Scalars['QueryArgument']>> | InputMaybe<Scalars['QueryArgument']>>;
    lang?: InputMaybe<Scalars['String']>;
}>;

export type ReadMoreQuery = {
    siblings?: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              newsHeroImage?: Array<
                  | ({
                        width?: number | null;
                        height?: number | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        placeholderUrl?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename: 'articles_article_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              heroImage?: Array<
                  | ({
                        width?: number | null;
                        height?: number | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        placeholderUrl?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename: 'pages_page_Entry' })
        | null
    > | null;
    ancestors?: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              newsHeroImage?: Array<
                  | ({
                        width?: number | null;
                        height?: number | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        placeholderUrl?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename: 'articles_article_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              heroImage?: Array<
                  | ({
                        width?: number | null;
                        height?: number | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        placeholderUrl?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename: 'pages_page_Entry' })
        | null
    > | null;
    search?: Array<
        | ({
              postDate?: string | null;
              title?: string | null;
              type?: string | null;
              uri?: string | null;
              image?:
                  | ({
                        extension?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        title?: string | null;
                        url?: string | null;
                    } & { __typename?: 'SearchImage' })
                  | null;
          } & { __typename?: 'SearchType' })
        | null
    > | null;
} & { __typename?: 'Query' };

export type RentalUnitsQueryVariables = Exact<{
    slugs: Array<Scalars['String']> | Scalars['String'];
}>;

export type RentalUnitsQuery = {
    rentalUnits: Array<
        {
            id: string;
            name: string;
            slug: string;
            type: RentalUnitTypeEnum;
            amountBedrooms?: number | null;
            maxOccupancy: number;
            tagline: string;
            accommodation: { name: string; hasPublicPage: boolean; place: string } & { __typename?: 'Accommodation' };
            reviewStats?: ({ average: number } & { __typename?: 'ReviewStats' }) | null;
            webpListImage: {
                transform?:
                    | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
                    | null;
            } & { __typename?: 'GenericImage' };
            listImage: {
                transform?:
                    | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
                    | null;
            } & { __typename?: 'GenericImage' };
        } & { __typename?: 'RentalUnit' }
    >;
} & { __typename?: 'Query' };

export type RentalUnitFragment = {
    id: string;
    name: string;
    slug: string;
    type: RentalUnitTypeEnum;
    amountBedrooms?: number | null;
    maxOccupancy: number;
    tagline: string;
    accommodation: { name: string; hasPublicPage: boolean; place: string } & { __typename?: 'Accommodation' };
    reviewStats?: ({ average: number } & { __typename?: 'ReviewStats' }) | null;
    webpListImage: {
        transform?:
            | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
            | null;
    } & { __typename?: 'GenericImage' };
    listImage: {
        transform?:
            | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
            | null;
    } & { __typename?: 'GenericImage' };
} & { __typename?: 'RentalUnit' };

export type ListImageFragment = { placeholder?: string | null; srcSet: string; src: string } & {
    __typename?: 'ImageTransform';
};

export type SearchFieldQueryVariables = Exact<{
    locale: Scalars['String'];
    q: Scalars['String'];
}>;

export type SearchFieldQuery = {
    globalSearch?:
        | ({
              totalHits?: number | null;
              hits?: Array<
                  | ({
                        postDate?: string | null;
                        title?: string | null;
                        type?: string | null;
                        uri?: string | null;
                        image?:
                            | ({
                                  extension?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                                  title?: string | null;
                                  url?: string | null;
                              } & { __typename?: 'SearchImage' })
                            | null;
                    } & { __typename?: 'SearchType' })
                  | null
              > | null;
          } & { __typename?: 'SearchResult' })
        | null;
} & { __typename?: 'Query' };

export type UpcomingReservationsQueryVariables = Exact<{ [key: string]: never }>;

export type UpcomingReservationsQuery = {
    viewer?:
        | ({
              id?: string | null;
              orders: Array<
                  {
                      id?: string | null;
                      number?: string | null;
                      createdDate?: string | null;
                      paymentOptions?:
                          | ({ fullPaymentDueDate?: string | null; totalAmountDue: number } & {
                                __typename?: 'PaymentOptions';
                            })
                          | null;
                      orderItems: Array<
                          | ({
                                downloadVoucherUrl?: string | null;
                                status: BookingStatus;
                                requiresApproval: boolean;
                                amountAdults: number;
                                amountPets: number;
                                amountChildren: number;
                                amountBabies: number;
                                amountYouths: number;
                                arrivalDate: string;
                                departureDate: string;
                                handleDepositPayment: boolean;
                                deposit: number;
                                paymentPrice: number;
                                rentalUnit?:
                                    | ({
                                          id: string;
                                          code: string;
                                          name: string;
                                          slug: string;
                                          type: RentalUnitTypeEnum;
                                          maxOccupancy: number;
                                          amountBedrooms?: number | null;
                                          accommodation: {
                                              id: string;
                                              hasPublicPage: boolean;
                                              name: string;
                                              place: string;
                                              checkInEndTime: string;
                                              checkInStartTime: string;
                                              checkOutTime: string;
                                              type: AccommodationTypeEnum;
                                          } & { __typename?: 'Accommodation' };
                                          jpegThumbnail: {
                                              transform?:
                                                  | ({
                                                        placeholder?: string | null;
                                                        srcSet: string;
                                                        src: string;
                                                        ratio: number;
                                                    } & { __typename?: 'ImageTransform' })
                                                  | null;
                                          } & { __typename?: 'GenericImage' };
                                          webpThumbnail: {
                                              transform?:
                                                  | ({
                                                        placeholder?: string | null;
                                                        srcSet: string;
                                                        src: string;
                                                        ratio: number;
                                                    } & { __typename?: 'ImageTransform' })
                                                  | null;
                                          } & { __typename?: 'GenericImage' };
                                      } & { __typename?: 'RentalUnit' })
                                    | null;
                            } & { __typename?: 'Booking' })
                          | { __typename?: 'Ticket' | 'UpsellPurchase' }
                      >;
                  } & { __typename?: 'Order' }
              >;
          } & { __typename?: 'Customer' })
        | null;
} & { __typename?: 'Query' };

export type MyOrderFragment = {
    id?: string | null;
    number?: string | null;
    createdDate?: string | null;
    paymentOptions?:
        | ({ fullPaymentDueDate?: string | null; totalAmountDue: number } & { __typename?: 'PaymentOptions' })
        | null;
    orderItems: Array<
        | ({
              downloadVoucherUrl?: string | null;
              status: BookingStatus;
              requiresApproval: boolean;
              amountAdults: number;
              amountPets: number;
              amountChildren: number;
              amountBabies: number;
              amountYouths: number;
              arrivalDate: string;
              departureDate: string;
              handleDepositPayment: boolean;
              deposit: number;
              paymentPrice: number;
              rentalUnit?:
                  | ({
                        id: string;
                        code: string;
                        name: string;
                        slug: string;
                        type: RentalUnitTypeEnum;
                        maxOccupancy: number;
                        amountBedrooms?: number | null;
                        accommodation: {
                            id: string;
                            hasPublicPage: boolean;
                            name: string;
                            place: string;
                            checkInEndTime: string;
                            checkInStartTime: string;
                            checkOutTime: string;
                            type: AccommodationTypeEnum;
                        } & { __typename?: 'Accommodation' };
                        jpegThumbnail: {
                            transform?:
                                | ({ placeholder?: string | null; srcSet: string; src: string; ratio: number } & {
                                      __typename?: 'ImageTransform';
                                  })
                                | null;
                        } & { __typename?: 'GenericImage' };
                        webpThumbnail: {
                            transform?:
                                | ({ placeholder?: string | null; srcSet: string; src: string; ratio: number } & {
                                      __typename?: 'ImageTransform';
                                  })
                                | null;
                        } & { __typename?: 'GenericImage' };
                    } & { __typename?: 'RentalUnit' })
                  | null;
          } & { __typename?: 'Booking' })
        | { __typename?: 'Ticket' | 'UpsellPurchase' }
    >;
} & { __typename?: 'Order' };

type OrderItem_Booking_Fragment = {
    downloadVoucherUrl?: string | null;
    status: BookingStatus;
    requiresApproval: boolean;
    amountAdults: number;
    amountPets: number;
    amountChildren: number;
    amountBabies: number;
    amountYouths: number;
    arrivalDate: string;
    departureDate: string;
    handleDepositPayment: boolean;
    deposit: number;
    paymentPrice: number;
    rentalUnit?:
        | ({
              id: string;
              code: string;
              name: string;
              slug: string;
              type: RentalUnitTypeEnum;
              maxOccupancy: number;
              amountBedrooms?: number | null;
              accommodation: {
                  id: string;
                  hasPublicPage: boolean;
                  name: string;
                  place: string;
                  checkInEndTime: string;
                  checkInStartTime: string;
                  checkOutTime: string;
                  type: AccommodationTypeEnum;
              } & { __typename?: 'Accommodation' };
              jpegThumbnail: {
                  transform?:
                      | ({ placeholder?: string | null; srcSet: string; src: string; ratio: number } & {
                            __typename?: 'ImageTransform';
                        })
                      | null;
              } & { __typename?: 'GenericImage' };
              webpThumbnail: {
                  transform?:
                      | ({ placeholder?: string | null; srcSet: string; src: string; ratio: number } & {
                            __typename?: 'ImageTransform';
                        })
                      | null;
              } & { __typename?: 'GenericImage' };
          } & { __typename?: 'RentalUnit' })
        | null;
} & { __typename?: 'Booking' };

type OrderItem_Ticket_UpsellPurchase_Fragment = { __typename?: 'Ticket' | 'UpsellPurchase' };

export type OrderItemFragment = OrderItem_Booking_Fragment | OrderItem_Ticket_UpsellPurchase_Fragment;

export type TransformFragment = { placeholder?: string | null; srcSet: string; src: string; ratio: number } & {
    __typename?: 'ImageTransform';
};

export type BannerFragment = {
    title?: string | null;
    heroImage?: Array<({ thumbUrl?: string | null } & { __typename?: 'library_Asset' }) | null> | null;
    button?:
        | ({
              customText?: string | null;
              target?: string | null;
              url?: string | null;
              type?: string | null;
              element?:
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'Element'
                            | 'Form'
                            | 'User'
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'assetTags_Tag'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contactMatrix_contact_BlockType'
                            | 'contact_GlobalSet'
                            | 'contact_contact_Entry'
                            | 'contentMatrix_sectionAccommodations_BlockType'
                            | 'contentMatrix_sectionAccordion_BlockType'
                            | 'contentMatrix_sectionAction_BlockType'
                            | 'contentMatrix_sectionActivities_BlockType'
                            | 'contentMatrix_sectionBanner_BlockType'
                            | 'contentMatrix_sectionButton_BlockType'
                            | 'contentMatrix_sectionCompanies_BlockType'
                            | 'contentMatrix_sectionEmbed_BlockType';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'contentMatrix_sectionForms_BlockType'
                            | 'contentMatrix_sectionHeader_BlockType'
                            | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                            | 'contentMatrix_sectionImages_BlockType'
                            | 'contentMatrix_sectionMap_BlockType'
                            | 'contentMatrix_sectionText_BlockType'
                            | 'contentMatrix_sectionTiles_BlockType'
                            | 'contentMatrix_sectionTip_BlockType'
                            | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                            | 'contentMatrix_sectionVideo_BlockType'
                            | 'contentMatrix_sectionWebcam_BlockType'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'library_Asset'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'notification_GlobalSet'
                            | 'pages_page_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'searchTags_Tag'
                            | 'search_search_Entry'
                            | 'synonyms_Tag'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'voucherLocations_location_BlockType'
                            | 'vouchers_voucher_Entry';
                    })
                  | null;
          } & { __typename?: 'linkField_Link' })
        | null;
} & { __typename?: 'banners_banner_Entry' };

export type PinsFragment = {
    latitude?: any | null;
    longitude?: any | null;
    symbol?: string | null;
    label?: string | null;
} & { __typename?: 'pins_TableRow' };

export type TilesBlockFragment = {
    tilesChildren?: boolean | null;
    tilesSiblings?: boolean | null;
    tilesEntries?: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'banners_banner_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'menu_menu_Entry'
                  | 'search_search_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
              __typename?:
                  | 'agenda_agenda_Entry'
                  | 'companies_companies_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'thankYou_thankYou_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              postDate?: any | null;
              settingsMetaDescription?: string | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'articles_article_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              settingsMetaDescription?: string | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'contact_contact_Entry' | 'homepage_homepage_Entry' | 'news_news_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              id?: string | null;
              settingsMetaDescription?: string | null;
              settingsNotSearchable?: boolean | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'pages_page_Entry' })
        | null
    > | null;
} & { __typename?: 'contentMatrix_sectionTiles_BlockType' };

export type HierarchicalTilesBlockFragment = { relationType?: string | null } & {
    __typename?: 'contentMatrix_sectionHierarchicalTiles_BlockType';
};

export type TipFragment = {
    title?: string | null;
    tipText?: string | null;
    tipName?: string | null;
    tipRole?: string | null;
    tipAvatar?: Array<
        | ({ height?: number | null; width?: number | null; thumbUrl?: string | null } & {
              __typename?: 'library_Asset';
          })
        | null
    > | null;
} & { __typename?: 'tips_tip_Entry' };

export type RentalUnitsBySlugQueryVariables = Exact<{
    slugs: Array<Scalars['String']> | Scalars['String'];
}>;

export type RentalUnitsBySlugQuery = {
    rentalUnits: Array<
        {
            id: string;
            name: string;
            slug: string;
            tagline: string;
            webpListImage: {
                transform?:
                    | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
                    | null;
            } & { __typename?: 'GenericImage' };
            listImage: {
                transform?:
                    | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
                    | null;
            } & { __typename?: 'GenericImage' };
        } & { __typename?: 'RentalUnit' }
    >;
} & { __typename?: 'Query' };

export type CompaniesBySlugQueryVariables = Exact<{
    slugs: Array<Scalars['String']> | Scalars['String'];
}>;

export type CompaniesBySlugQuery = {
    companiesBySlug: Array<
        {
            id: string;
            name: string;
            slug: string;
            description?: string | null;
            webpListImage?:
                | ({
                      transform?:
                          | ({ placeholder?: string | null; srcSet: string; src: string } & {
                                __typename?: 'ImageTransform';
                            })
                          | null;
                  } & { __typename?: 'GenericImage' })
                | null;
            listImage?:
                | ({
                      transform?:
                          | ({ placeholder?: string | null; srcSet: string; src: string } & {
                                __typename?: 'ImageTransform';
                            })
                          | null;
                  } & { __typename?: 'GenericImage' })
                | null;
        } & { __typename?: 'Company' }
    >;
} & { __typename?: 'Query' };

export type ActivitiesBySlugQueryVariables = Exact<{
    slugs: Array<Scalars['String']> | Scalars['String'];
}>;

export type ActivitiesBySlugQuery = {
    activitiesBySlug: Array<
        {
            id: string;
            name: string;
            slug: string;
            description?: string | null;
            webpListImage?:
                | ({
                      transform?:
                          | ({ placeholder?: string | null; srcSet: string; src: string } & {
                                __typename?: 'ImageTransform';
                            })
                          | null;
                  } & { __typename?: 'GenericImage' })
                | null;
            listImage?:
                | ({
                      transform?:
                          | ({ placeholder?: string | null; srcSet: string; src: string } & {
                                __typename?: 'ImageTransform';
                            })
                          | null;
                  } & { __typename?: 'GenericImage' })
                | null;
        } & { __typename?: 'Activity' }
    >;
} & { __typename?: 'Query' };

export type AccommodationsBySlugQueryVariables = Exact<{
    slugs: Array<Scalars['String']> | Scalars['String'];
}>;

export type AccommodationsBySlugQuery = {
    accommodationsBySlug: Array<
        {
            id: string;
            name: string;
            slug: string;
            descriptionGeneral?: string | null;
            webpListImage?:
                | ({
                      transform?:
                          | ({ placeholder?: string | null; srcSet: string; src: string } & {
                                __typename?: 'ImageTransform';
                            })
                          | null;
                  } & { __typename?: 'GenericImage' })
                | null;
            mainImage?:
                | ({
                      transform?:
                          | ({ placeholder?: string | null; srcSet: string; src: string } & {
                                __typename?: 'ImageTransform';
                            })
                          | null;
                  } & { __typename?: 'GenericImage' })
                | null;
        } & { __typename?: 'Accommodation' }
    >;
} & { __typename?: 'Query' };

export type RentalUnitsBySlugFragment = {
    id: string;
    name: string;
    slug: string;
    tagline: string;
    webpListImage: {
        transform?:
            | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
            | null;
    } & { __typename?: 'GenericImage' };
    listImage: {
        transform?:
            | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
            | null;
    } & { __typename?: 'GenericImage' };
} & { __typename?: 'RentalUnit' };

export type AccommodationsBySlugFragment = {
    id: string;
    name: string;
    slug: string;
    descriptionGeneral?: string | null;
    webpListImage?:
        | ({
              transform?:
                  | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
                  | null;
          } & { __typename?: 'GenericImage' })
        | null;
    mainImage?:
        | ({
              transform?:
                  | ({ placeholder?: string | null; srcSet: string; src: string } & { __typename?: 'ImageTransform' })
                  | null;
          } & { __typename?: 'GenericImage' })
        | null;
} & { __typename?: 'Accommodation' };

export type AddressFieldFragment = {
    address1Label?: string | null;
    address1Hidden?: boolean | null;
    address1Enabled?: boolean | null;
    address1ErrorMessage?: string | null;
    address1Required?: boolean | null;
    address1DefaultValue?: string | null;
    address1Placeholder?: string | null;
    zipLabel?: string | null;
    zipHidden?: boolean | null;
    zipEnabled?: boolean | null;
    zipRequired?: boolean | null;
    zipCollapsed?: boolean | null;
    zipPlaceholder?: string | null;
    zipDefaultValue?: string | null;
    cityLabel?: string | null;
    cityHidden?: boolean | null;
    cityEnabled?: boolean | null;
    cityRequired?: boolean | null;
    cityPlaceholder?: string | null;
    cityDefaultValue?: string | null;
    cityErrorMessage?: string | null;
    countryLabel?: string | null;
    countryHidden?: boolean | null;
    countryEnabled?: boolean | null;
    countryRequired?: boolean | null;
    countryPlaceholder?: string | null;
    countryDefaultValue?: string | null;
} & { __typename?: 'Field_Address' };

type AgreeField_HhTKrI4ODsGvMkpme2N2gIkoAgZgKAp45D8J2vfF18_Fragment = {
    __typename?:
        | 'Field_Address'
        | 'Field_Categories'
        | 'Field_Checkboxes'
        | 'Field_Date'
        | 'Field_Dropdown'
        | 'Field_Email'
        | 'Field_Entries'
        | 'Field_FileUpload'
        | 'Field_Group'
        | 'Field_Heading'
        | 'Field_Hidden'
        | 'Field_Html'
        | 'Field_MissingField'
        | 'Field_MultiLineText'
        | 'Field_Name'
        | 'Field_Number'
        | 'Field_Phone'
        | 'Field_Radio'
        | 'Field_Recipients'
        | 'Field_Repeater';
};

type AgreeField_FEgxS2FqkOvwQ3FoV0Ua0p7Kc5Q4W0IkEsjQAqlfp4_Fragment = {
    __typename?: 'Field_Section' | 'Field_SingleLineText' | 'Field_Table' | 'Field_Tags' | 'Field_Users';
};

type AgreeField_Field_Agree_Field_Newsletter_Fragment = {
    checkedValue?: string | null;
    uncheckedValue?: string | null;
    descriptionHtml?: string | null;
    defaultState?: boolean | null;
    required?: boolean | null;
} & { __typename?: 'Field_Agree' | 'Field_Newsletter' };

export type AgreeFieldFragment =
    | AgreeField_HhTKrI4ODsGvMkpme2N2gIkoAgZgKAp45D8J2vfF18_Fragment
    | AgreeField_FEgxS2FqkOvwQ3FoV0Ua0p7Kc5Q4W0IkEsjQAqlfp4_Fragment
    | AgreeField_Field_Agree_Field_Newsletter_Fragment;

export type BaseAgreeFieldFragment = {
    checkedValue?: string | null;
    uncheckedValue?: string | null;
    descriptionHtml?: string | null;
    defaultState?: boolean | null;
    required?: boolean | null;
} & { __typename?: 'Field_Agree' };

export type NewsletterAgreeFieldFragment = {
    checkedValue?: string | null;
    uncheckedValue?: string | null;
    descriptionHtml?: string | null;
    defaultState?: boolean | null;
    required?: boolean | null;
} & { __typename?: 'Field_Newsletter' };

export type CheckboxesFieldFragment = {
    options?: Array<
        ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'KeyValueType' }) | null
    > | null;
} & { __typename?: 'Field_Checkboxes' };

export type DateFieldFragment = {
    displayType?: string | null;
    defaultDate?: any | null;
    defaultOption?: string | null;
} & { __typename?: 'Field_Date' };

export type DropdownFieldFragment = {
    options?: Array<
        | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
              __typename?: 'KeyValueType';
          })
        | null
    > | null;
} & { __typename?: 'Field_Dropdown' };

export type FileFieldFragment = {
    restrictFiles?: string | null;
    allowedKinds?: Array<string | null> | null;
    limitFiles?: string | null;
    limitAmount?: number | null;
    sizeLimit?: string | null;
    sizeMinLimit?: string | null;
} & { __typename?: 'Field_FileUpload' };

export type GroupFieldFragment = {
    fields?: Array<
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              address1Label?: string | null;
              address1Hidden?: boolean | null;
              address1Enabled?: boolean | null;
              address1ErrorMessage?: string | null;
              address1Required?: boolean | null;
              address1DefaultValue?: string | null;
              address1Placeholder?: string | null;
              zipLabel?: string | null;
              zipHidden?: boolean | null;
              zipEnabled?: boolean | null;
              zipRequired?: boolean | null;
              zipCollapsed?: boolean | null;
              zipPlaceholder?: string | null;
              zipDefaultValue?: string | null;
              cityLabel?: string | null;
              cityHidden?: boolean | null;
              cityEnabled?: boolean | null;
              cityRequired?: boolean | null;
              cityPlaceholder?: string | null;
              cityDefaultValue?: string | null;
              cityErrorMessage?: string | null;
              countryLabel?: string | null;
              countryHidden?: boolean | null;
              countryEnabled?: boolean | null;
              countryRequired?: boolean | null;
              countryPlaceholder?: string | null;
              countryDefaultValue?: string | null;
          } & { __typename?: 'Field_Address' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              checkedValue?: string | null;
              uncheckedValue?: string | null;
              descriptionHtml?: string | null;
              defaultState?: boolean | null;
          } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
          } & {
              __typename?:
                  | 'Field_Categories'
                  | 'Field_Entries'
                  | 'Field_Group'
                  | 'Field_Hidden'
                  | 'Field_Html'
                  | 'Field_MissingField'
                  | 'Field_Recipients'
                  | 'Field_Repeater'
                  | 'Field_Section'
                  | 'Field_Table'
                  | 'Field_Tags'
                  | 'Field_Users';
          })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'KeyValueType' }) | null
              > | null;
          } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              displayType?: string | null;
              defaultDate?: any | null;
              defaultOption?: string | null;
          } & { __typename?: 'Field_Date' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              options?: Array<
                  | ({ label?: string | null; isDefault?: boolean | null; value?: string | null } & {
                        __typename?: 'KeyValueType';
                    })
                  | null
              > | null;
          } & { __typename?: 'Field_Dropdown' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              limit?: boolean | null;
              limitType?: string | null;
              limitAmount?: number | null;
              defaultValue?: string | null;
          } & { __typename?: 'Field_Email' | 'Field_MultiLineText' | 'Field_Phone' | 'Field_SingleLineText' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              restrictFiles?: string | null;
              allowedKinds?: Array<string | null> | null;
              limitFiles?: string | null;
              limitAmount?: number | null;
              sizeLimit?: string | null;
              sizeMinLimit?: string | null;
          } & { __typename?: 'Field_FileUpload' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              headingSize?: string | null;
          } & { __typename?: 'Field_Heading' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              prefixLabel?: string | null;
              prefixEnabled?: boolean | null;
              prefixRequired?: boolean | null;
              firstNameLabel?: string | null;
              firstNameEnabled?: boolean | null;
              firstNamePlaceholder?: string | null;
              firstNameRequired?: boolean | null;
              middleNameLabel?: string | null;
              middleNameEnabled?: boolean | null;
              middleNamePlaceholder?: string | null;
              middleNameRequired?: boolean | null;
              lastNameLabel?: string | null;
              lastNameEnabled?: boolean | null;
              lastNamePlaceholder?: string | null;
              lastNameRequired?: boolean | null;
          } & { __typename?: 'Field_Name' })
        | ({
              instructions?: string | null;
              instructionsPosition?: string | null;
              name?: string | null;
              placeholder?: string | null;
              required?: boolean | null;
              handle?: string | null;
              errorMessage?: string | null;
              defaultValue?: string | null;
              min?: string | null;
              max?: string | null;
          } & { __typename?: 'Field_Number' })
        | null
    > | null;
} & { __typename?: 'Field_Group' };

export type HeadingFieldFragment = { headingSize?: string | null } & { __typename?: 'Field_Heading' };

export type NameFieldFragment = {
    name?: string | null;
    prefixLabel?: string | null;
    prefixEnabled?: boolean | null;
    prefixRequired?: boolean | null;
    firstNameLabel?: string | null;
    firstNameEnabled?: boolean | null;
    firstNamePlaceholder?: string | null;
    firstNameRequired?: boolean | null;
    middleNameLabel?: string | null;
    middleNameEnabled?: boolean | null;
    middleNamePlaceholder?: string | null;
    middleNameRequired?: boolean | null;
    lastNameLabel?: string | null;
    lastNameEnabled?: boolean | null;
    lastNamePlaceholder?: string | null;
    lastNameRequired?: boolean | null;
} & { __typename?: 'Field_Name' };

export type NumberFieldFragment = { defaultValue?: string | null; min?: string | null; max?: string | null } & {
    __typename?: 'Field_Number';
};

export type RadioFieldFragment = {
    options?: Array<
        ({ isDefault?: boolean | null; value?: string | null } & { __typename?: 'KeyValueType' }) | null
    > | null;
} & { __typename?: 'Field_Radio' };

type TextField_BgnK5uhvOEnYqanBqZkLtY4xVyq8lWw7GejU70eXduu_Fragment = {
    __typename?:
        | 'Field_Address'
        | 'Field_Agree'
        | 'Field_Categories'
        | 'Field_Checkboxes'
        | 'Field_Date'
        | 'Field_Dropdown'
        | 'Field_Entries'
        | 'Field_FileUpload'
        | 'Field_Group'
        | 'Field_Heading'
        | 'Field_Hidden'
        | 'Field_Html'
        | 'Field_MissingField'
        | 'Field_Name'
        | 'Field_Newsletter'
        | 'Field_Number'
        | 'Field_Radio'
        | 'Field_Recipients'
        | 'Field_Repeater'
        | 'Field_Section';
};

type TextField_Field_Table_Field_Tags_Field_Users_Fragment = {
    __typename?: 'Field_Table' | 'Field_Tags' | 'Field_Users';
};

type TextField_XrCjAr3G5mtgjWc1IqdQofJeNlYu4zq66ofEZrVilwY_Fragment = {
    limit?: boolean | null;
    limitType?: string | null;
    limitAmount?: number | null;
    defaultValue?: string | null;
} & { __typename?: 'Field_Email' | 'Field_MultiLineText' | 'Field_Phone' | 'Field_SingleLineText' };

export type TextFieldFragment =
    | TextField_BgnK5uhvOEnYqanBqZkLtY4xVyq8lWw7GejU70eXduu_Fragment
    | TextField_Field_Table_Field_Tags_Field_Users_Fragment
    | TextField_XrCjAr3G5mtgjWc1IqdQofJeNlYu4zq66ofEZrVilwY_Fragment;

export type SingleTextFieldFragment = {
    limit?: boolean | null;
    limitType?: string | null;
    limitAmount?: number | null;
    defaultValue?: string | null;
} & { __typename?: 'Field_SingleLineText' };

export type MultipleTextFieldFragment = {
    limit?: boolean | null;
    limitType?: string | null;
    limitAmount?: number | null;
    defaultValue?: string | null;
} & { __typename?: 'Field_MultiLineText' };

export type EmailTextFieldFragment = {
    limit?: boolean | null;
    limitType?: string | null;
    limitAmount?: number | null;
    defaultValue?: string | null;
} & { __typename?: 'Field_Email' };

export type PhoneTextFieldFragment = {
    limit?: boolean | null;
    limitType?: string | null;
    limitAmount?: number | null;
    defaultValue?: string | null;
} & { __typename?: 'Field_Phone' };

export type ArticleQueryVariables = Exact<{
    slug?: InputMaybe<Scalars['String']>;
    lang?: InputMaybe<Scalars['String']>;
    notSlug?: InputMaybe<Scalars['String']>;
}>;

export type ArticleQuery = {
    entry?:
        | {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          }
        | ({
              postDate?: any | null;
              title?: string | null;
              textBody?: string | null;
              settingsCookieConsent?: boolean | null;
              settingsMetaDescription?: string | null;
              settingsSeoTitle?: string | null;
              id?: string | null;
              newsHeroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        ogImageUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        srcsetMobile?: string | null;
                        srcsetMobileWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
              gallery?: Array<
                  | ({
                        assetSource?: string | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        width?: number | null;
                        height?: number | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
              parent?:
                  | ({ id?: string | null } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | null;
              settingsSearchTags?: Array<
                  | ({ title?: string | null } & { __typename?: 'assetTags_Tag' | 'searchTags_Tag' | 'synonyms_Tag' })
                  | null
              > | null;
              localized?: Array<
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | null
              > | null;
          } & { __typename?: 'articles_article_Entry' })
        | null;
    related?: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'banners_banner_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'menu_menu_Entry'
                  | 'search_search_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
              __typename?:
                  | 'agenda_agenda_Entry'
                  | 'companies_companies_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'thankYou_thankYou_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              postDate?: any | null;
              settingsMetaDescription?: string | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'articles_article_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              settingsMetaDescription?: string | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'contact_contact_Entry' | 'homepage_homepage_Entry' | 'news_news_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              id?: string | null;
              settingsMetaDescription?: string | null;
              settingsNotSearchable?: boolean | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'pages_page_Entry' })
        | null
    > | null;
} & { __typename?: 'Query' };

export type ArticlePathsQueryVariables = Exact<{ [key: string]: never }>;

export type ArticlePathsQuery = {
    entries?: Array<
        | ({ slug?: string | null; language?: string | null } & {
              __typename:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | null
    > | null;
} & { __typename?: 'Query' };

export type ArticleFragment = {
    postDate?: any | null;
    title?: string | null;
    textBody?: string | null;
    settingsCookieConsent?: boolean | null;
    settingsMetaDescription?: string | null;
    settingsSeoTitle?: string | null;
    id?: string | null;
    newsHeroImage?: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension?: string | null;
              url?: string | null;
              placeholderUrl?: string | null;
              ogImageUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
              srcsetMobile?: string | null;
              srcsetMobileWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    > | null;
    gallery?: Array<
        | ({
              assetSource?: string | null;
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    > | null;
    parent?:
        | ({ id?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | null;
    settingsSearchTags?: Array<
        ({ title?: string | null } & { __typename?: 'assetTags_Tag' | 'searchTags_Tag' | 'synonyms_Tag' }) | null
    > | null;
    localized?: Array<
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | null
    > | null;
} & { __typename?: 'articles_article_Entry' };

export type ContactQueryVariables = Exact<{
    lang?: InputMaybe<Scalars['String']>;
}>;

export type ContactQuery = {
    globalSet?:
        | ({
              contactEmailText?: string | null;
              contactMessengerText?: string | null;
              contactMyTexelText?: string | null;
              contactPhoneText?: string | null;
              contactShopText?: string | null;
              contactWhatsappText?: string | null;
          } & { __typename?: 'contact_GlobalSet' })
        | { __typename?: 'notification_GlobalSet' }
        | null;
    entry?:
        | {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          }
        | ({
              title?: string | null;
              textBody?: string | null;
              settingsCookieConsent?: boolean | null;
              settingsMetaDescription?: string | null;
              settingsSeoTitle?: string | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        ogImageUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        srcsetMobile?: string | null;
                        srcsetMobileWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
              button?:
                  | ({
                        customText?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Element'
                                      | 'Form'
                                      | 'User'
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'assetTags_Tag'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contactMatrix_contact_BlockType'
                                      | 'contact_GlobalSet'
                                      | 'contact_contact_Entry'
                                      | 'contentMatrix_sectionAccommodations_BlockType'
                                      | 'contentMatrix_sectionAccordion_BlockType'
                                      | 'contentMatrix_sectionAction_BlockType'
                                      | 'contentMatrix_sectionActivities_BlockType'
                                      | 'contentMatrix_sectionBanner_BlockType'
                                      | 'contentMatrix_sectionButton_BlockType'
                                      | 'contentMatrix_sectionCompanies_BlockType'
                                      | 'contentMatrix_sectionEmbed_BlockType';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'contentMatrix_sectionForms_BlockType'
                                      | 'contentMatrix_sectionHeader_BlockType'
                                      | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                      | 'contentMatrix_sectionImages_BlockType'
                                      | 'contentMatrix_sectionMap_BlockType'
                                      | 'contentMatrix_sectionText_BlockType'
                                      | 'contentMatrix_sectionTiles_BlockType'
                                      | 'contentMatrix_sectionTip_BlockType'
                                      | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                      | 'contentMatrix_sectionVideo_BlockType'
                                      | 'contentMatrix_sectionWebcam_BlockType'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'library_Asset'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'notification_GlobalSet'
                                      | 'pages_page_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'searchTags_Tag'
                                      | 'search_search_Entry'
                                      | 'synonyms_Tag'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'voucherLocations_location_BlockType'
                                      | 'vouchers_voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'linkField_Link' })
                  | null;
              contactMatrix?: Array<
                  | ({
                        whatsapp?: boolean | null;
                        phone?: boolean | null;
                        email?: boolean | null;
                        messenger?: boolean | null;
                        myTexel?: boolean | null;
                        shop?: boolean | null;
                    } & { __typename?: 'contactMatrix_contact_BlockType' })
                  | null
              > | null;
              contentMatrix?: Array<
                  | ({ accommodationsQuery?: string | null } & {
                        __typename?: 'contentMatrix_sectionAccommodations_BlockType';
                    })
                  | ({ header?: string | null; text?: string | null } & {
                        __typename?: 'contentMatrix_sectionAccordion_BlockType';
                    })
                  | { __typename?: 'contentMatrix_sectionAction_BlockType' }
                  | ({ activitiesQuery?: string | null } & { __typename?: 'contentMatrix_sectionActivities_BlockType' })
                  | ({
                        banner?: Array<
                            | {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'pages_page_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              }
                            | ({
                                  title?: string | null;
                                  heroImage?: Array<
                                      ({ thumbUrl?: string | null } & { __typename?: 'library_Asset' }) | null
                                  > | null;
                                  button?:
                                      | ({
                                            customText?: string | null;
                                            target?: string | null;
                                            url?: string | null;
                                            type?: string | null;
                                            element?:
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'Element'
                                                          | 'Form'
                                                          | 'User'
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'assetTags_Tag'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contactMatrix_contact_BlockType'
                                                          | 'contact_GlobalSet'
                                                          | 'contact_contact_Entry'
                                                          | 'contentMatrix_sectionAccommodations_BlockType'
                                                          | 'contentMatrix_sectionAccordion_BlockType'
                                                          | 'contentMatrix_sectionAction_BlockType'
                                                          | 'contentMatrix_sectionActivities_BlockType'
                                                          | 'contentMatrix_sectionBanner_BlockType'
                                                          | 'contentMatrix_sectionButton_BlockType'
                                                          | 'contentMatrix_sectionCompanies_BlockType'
                                                          | 'contentMatrix_sectionEmbed_BlockType';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'contentMatrix_sectionForms_BlockType'
                                                          | 'contentMatrix_sectionHeader_BlockType'
                                                          | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                                          | 'contentMatrix_sectionImages_BlockType'
                                                          | 'contentMatrix_sectionMap_BlockType'
                                                          | 'contentMatrix_sectionText_BlockType'
                                                          | 'contentMatrix_sectionTiles_BlockType'
                                                          | 'contentMatrix_sectionTip_BlockType'
                                                          | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                                          | 'contentMatrix_sectionVideo_BlockType'
                                                          | 'contentMatrix_sectionWebcam_BlockType'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'library_Asset'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'notification_GlobalSet'
                                                          | 'pages_page_Entry';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'searchTags_Tag'
                                                          | 'search_search_Entry'
                                                          | 'synonyms_Tag'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'voucherLocations_location_BlockType'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'linkField_Link' })
                                      | null;
                              } & { __typename?: 'banners_banner_Entry' })
                            | null
                        > | null;
                    } & { __typename?: 'contentMatrix_sectionBanner_BlockType' })
                  | ({
                        button?:
                            | ({
                                  customText?: string | null;
                                  target?: string | null;
                                  url?: string | null;
                                  type?: string | null;
                                  element?:
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'Element'
                                                | 'Form'
                                                | 'User'
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'assetTags_Tag'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contactMatrix_contact_BlockType'
                                                | 'contact_GlobalSet'
                                                | 'contact_contact_Entry'
                                                | 'contentMatrix_sectionAccommodations_BlockType'
                                                | 'contentMatrix_sectionAccordion_BlockType'
                                                | 'contentMatrix_sectionAction_BlockType'
                                                | 'contentMatrix_sectionActivities_BlockType'
                                                | 'contentMatrix_sectionBanner_BlockType'
                                                | 'contentMatrix_sectionButton_BlockType'
                                                | 'contentMatrix_sectionCompanies_BlockType'
                                                | 'contentMatrix_sectionEmbed_BlockType';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'contentMatrix_sectionForms_BlockType'
                                                | 'contentMatrix_sectionHeader_BlockType'
                                                | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                                | 'contentMatrix_sectionImages_BlockType'
                                                | 'contentMatrix_sectionMap_BlockType'
                                                | 'contentMatrix_sectionText_BlockType'
                                                | 'contentMatrix_sectionTiles_BlockType'
                                                | 'contentMatrix_sectionTip_BlockType'
                                                | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                                | 'contentMatrix_sectionVideo_BlockType'
                                                | 'contentMatrix_sectionWebcam_BlockType'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'library_Asset'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'notification_GlobalSet'
                                                | 'pages_page_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'searchTags_Tag'
                                                | 'search_search_Entry'
                                                | 'synonyms_Tag'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'voucherLocations_location_BlockType'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'linkField_Link' })
                            | null;
                    } & { __typename?: 'contentMatrix_sectionButton_BlockType' })
                  | ({ companiesQuery?: string | null } & { __typename?: 'contentMatrix_sectionCompanies_BlockType' })
                  | ({ embed?: string | null } & { __typename?: 'contentMatrix_sectionEmbed_BlockType' })
                  | ({
                        form?: Array<
                            | ({
                                  id?: string | null;
                                  handle?: string | null;
                                  siteId?: number | null;
                                  settings?:
                                      | ({
                                            submitActionFormHide?: boolean | null;
                                            submitActionMessageHtml?: string | null;
                                            submitAction?: string | null;
                                            submitActionTab?: string | null;
                                            submitActionUrl?: string | null;
                                            integrations?: Array<
                                                | ({ handle?: string | null; enabled?: boolean | null } & {
                                                      __typename?: 'FormIntegrationsType';
                                                  })
                                                | null
                                            > | null;
                                        } & { __typename?: 'FormSettingsType' })
                                      | null;
                                  fields?: Array<
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            address1Label?: string | null;
                                            address1Hidden?: boolean | null;
                                            address1Enabled?: boolean | null;
                                            address1ErrorMessage?: string | null;
                                            address1Required?: boolean | null;
                                            address1DefaultValue?: string | null;
                                            address1Placeholder?: string | null;
                                            zipLabel?: string | null;
                                            zipHidden?: boolean | null;
                                            zipEnabled?: boolean | null;
                                            zipRequired?: boolean | null;
                                            zipCollapsed?: boolean | null;
                                            zipPlaceholder?: string | null;
                                            zipDefaultValue?: string | null;
                                            cityLabel?: string | null;
                                            cityHidden?: boolean | null;
                                            cityEnabled?: boolean | null;
                                            cityRequired?: boolean | null;
                                            cityPlaceholder?: string | null;
                                            cityDefaultValue?: string | null;
                                            cityErrorMessage?: string | null;
                                            countryLabel?: string | null;
                                            countryHidden?: boolean | null;
                                            countryEnabled?: boolean | null;
                                            countryRequired?: boolean | null;
                                            countryPlaceholder?: string | null;
                                            countryDefaultValue?: string | null;
                                        } & { __typename?: 'Field_Address' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            checkedValue?: string | null;
                                            uncheckedValue?: string | null;
                                            descriptionHtml?: string | null;
                                            defaultState?: boolean | null;
                                        } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                        } & {
                                            __typename?:
                                                | 'Field_Categories'
                                                | 'Field_Entries'
                                                | 'Field_Hidden'
                                                | 'Field_Html'
                                                | 'Field_MissingField'
                                                | 'Field_Recipients'
                                                | 'Field_Repeater'
                                                | 'Field_Section'
                                                | 'Field_Table'
                                                | 'Field_Tags'
                                                | 'Field_Users';
                                        })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            options?: Array<
                                                | ({ isDefault?: boolean | null; value?: string | null } & {
                                                      __typename?: 'KeyValueType';
                                                  })
                                                | null
                                            > | null;
                                        } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            displayType?: string | null;
                                            defaultDate?: any | null;
                                            defaultOption?: string | null;
                                        } & { __typename?: 'Field_Date' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            options?: Array<
                                                | ({
                                                      label?: string | null;
                                                      isDefault?: boolean | null;
                                                      value?: string | null;
                                                  } & { __typename?: 'KeyValueType' })
                                                | null
                                            > | null;
                                        } & { __typename?: 'Field_Dropdown' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            limit?: boolean | null;
                                            limitType?: string | null;
                                            limitAmount?: number | null;
                                            defaultValue?: string | null;
                                        } & {
                                            __typename?:
                                                | 'Field_Email'
                                                | 'Field_MultiLineText'
                                                | 'Field_Phone'
                                                | 'Field_SingleLineText';
                                        })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            restrictFiles?: string | null;
                                            allowedKinds?: Array<string | null> | null;
                                            limitFiles?: string | null;
                                            limitAmount?: number | null;
                                            sizeLimit?: string | null;
                                            sizeMinLimit?: string | null;
                                        } & { __typename?: 'Field_FileUpload' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            fields?: Array<
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      address1Label?: string | null;
                                                      address1Hidden?: boolean | null;
                                                      address1Enabled?: boolean | null;
                                                      address1ErrorMessage?: string | null;
                                                      address1Required?: boolean | null;
                                                      address1DefaultValue?: string | null;
                                                      address1Placeholder?: string | null;
                                                      zipLabel?: string | null;
                                                      zipHidden?: boolean | null;
                                                      zipEnabled?: boolean | null;
                                                      zipRequired?: boolean | null;
                                                      zipCollapsed?: boolean | null;
                                                      zipPlaceholder?: string | null;
                                                      zipDefaultValue?: string | null;
                                                      cityLabel?: string | null;
                                                      cityHidden?: boolean | null;
                                                      cityEnabled?: boolean | null;
                                                      cityRequired?: boolean | null;
                                                      cityPlaceholder?: string | null;
                                                      cityDefaultValue?: string | null;
                                                      cityErrorMessage?: string | null;
                                                      countryLabel?: string | null;
                                                      countryHidden?: boolean | null;
                                                      countryEnabled?: boolean | null;
                                                      countryRequired?: boolean | null;
                                                      countryPlaceholder?: string | null;
                                                      countryDefaultValue?: string | null;
                                                  } & { __typename?: 'Field_Address' })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      checkedValue?: string | null;
                                                      uncheckedValue?: string | null;
                                                      descriptionHtml?: string | null;
                                                      defaultState?: boolean | null;
                                                  } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                  } & {
                                                      __typename?:
                                                          | 'Field_Categories'
                                                          | 'Field_Entries'
                                                          | 'Field_Group'
                                                          | 'Field_Hidden'
                                                          | 'Field_Html'
                                                          | 'Field_MissingField'
                                                          | 'Field_Recipients'
                                                          | 'Field_Repeater'
                                                          | 'Field_Section'
                                                          | 'Field_Table'
                                                          | 'Field_Tags'
                                                          | 'Field_Users';
                                                  })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      options?: Array<
                                                          | ({ isDefault?: boolean | null; value?: string | null } & {
                                                                __typename?: 'KeyValueType';
                                                            })
                                                          | null
                                                      > | null;
                                                  } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      displayType?: string | null;
                                                      defaultDate?: any | null;
                                                      defaultOption?: string | null;
                                                  } & { __typename?: 'Field_Date' })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      options?: Array<
                                                          | ({
                                                                label?: string | null;
                                                                isDefault?: boolean | null;
                                                                value?: string | null;
                                                            } & { __typename?: 'KeyValueType' })
                                                          | null
                                                      > | null;
                                                  } & { __typename?: 'Field_Dropdown' })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      limit?: boolean | null;
                                                      limitType?: string | null;
                                                      limitAmount?: number | null;
                                                      defaultValue?: string | null;
                                                  } & {
                                                      __typename?:
                                                          | 'Field_Email'
                                                          | 'Field_MultiLineText'
                                                          | 'Field_Phone'
                                                          | 'Field_SingleLineText';
                                                  })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      restrictFiles?: string | null;
                                                      allowedKinds?: Array<string | null> | null;
                                                      limitFiles?: string | null;
                                                      limitAmount?: number | null;
                                                      sizeLimit?: string | null;
                                                      sizeMinLimit?: string | null;
                                                  } & { __typename?: 'Field_FileUpload' })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      headingSize?: string | null;
                                                  } & { __typename?: 'Field_Heading' })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      prefixLabel?: string | null;
                                                      prefixEnabled?: boolean | null;
                                                      prefixRequired?: boolean | null;
                                                      firstNameLabel?: string | null;
                                                      firstNameEnabled?: boolean | null;
                                                      firstNamePlaceholder?: string | null;
                                                      firstNameRequired?: boolean | null;
                                                      middleNameLabel?: string | null;
                                                      middleNameEnabled?: boolean | null;
                                                      middleNamePlaceholder?: string | null;
                                                      middleNameRequired?: boolean | null;
                                                      lastNameLabel?: string | null;
                                                      lastNameEnabled?: boolean | null;
                                                      lastNamePlaceholder?: string | null;
                                                      lastNameRequired?: boolean | null;
                                                  } & { __typename?: 'Field_Name' })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      defaultValue?: string | null;
                                                      min?: string | null;
                                                      max?: string | null;
                                                  } & { __typename?: 'Field_Number' })
                                                | null
                                            > | null;
                                        } & { __typename?: 'Field_Group' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            headingSize?: string | null;
                                        } & { __typename?: 'Field_Heading' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            prefixLabel?: string | null;
                                            prefixEnabled?: boolean | null;
                                            prefixRequired?: boolean | null;
                                            firstNameLabel?: string | null;
                                            firstNameEnabled?: boolean | null;
                                            firstNamePlaceholder?: string | null;
                                            firstNameRequired?: boolean | null;
                                            middleNameLabel?: string | null;
                                            middleNameEnabled?: boolean | null;
                                            middleNamePlaceholder?: string | null;
                                            middleNameRequired?: boolean | null;
                                            lastNameLabel?: string | null;
                                            lastNameEnabled?: boolean | null;
                                            lastNamePlaceholder?: string | null;
                                            lastNameRequired?: boolean | null;
                                        } & { __typename?: 'Field_Name' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            defaultValue?: string | null;
                                            min?: string | null;
                                            max?: string | null;
                                        } & { __typename?: 'Field_Number' })
                                      | null
                                  > | null;
                              } & { __typename?: 'Form' })
                            | null
                        > | null;
                    } & { __typename?: 'contentMatrix_sectionForms_BlockType' })
                  | ({ header?: string | null } & { __typename?: 'contentMatrix_sectionHeader_BlockType' })
                  | ({ relationType?: string | null } & {
                        __typename?: 'contentMatrix_sectionHierarchicalTiles_BlockType';
                    })
                  | ({
                        gallery?: Array<
                            | ({
                                  assetSource?: string | null;
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  width?: number | null;
                                  height?: number | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename?: 'contentMatrix_sectionImages_BlockType' })
                  | ({
                        pins?: Array<
                            | ({
                                  latitude?: any | null;
                                  longitude?: any | null;
                                  symbol?: string | null;
                                  label?: string | null;
                              } & { __typename?: 'pins_TableRow' })
                            | null
                        > | null;
                    } & { __typename?: 'contentMatrix_sectionMap_BlockType' })
                  | ({ text?: string | null } & { __typename?: 'contentMatrix_sectionText_BlockType' })
                  | ({
                        tilesChildren?: boolean | null;
                        tilesSiblings?: boolean | null;
                        tilesEntries?: Array<
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'banners_banner_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'menu_menu_Entry'
                                      | 'search_search_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMetaDescription?: string | null;
                              } & {
                                  __typename?:
                                      | 'agenda_agenda_Entry'
                                      | 'companies_companies_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'thankYou_thankYou_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  postDate?: any | null;
                                  settingsMetaDescription?: string | null;
                                  heroImage?: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension?: string | null;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  > | null;
                              } & { __typename?: 'articles_article_Entry' })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMetaDescription?: string | null;
                                  heroImage?: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension?: string | null;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  > | null;
                              } & {
                                  __typename?: 'contact_contact_Entry' | 'homepage_homepage_Entry' | 'news_news_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  id?: string | null;
                                  settingsMetaDescription?: string | null;
                                  settingsNotSearchable?: boolean | null;
                                  heroImage?: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension?: string | null;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  > | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null
                        > | null;
                    } & { __typename?: 'contentMatrix_sectionTiles_BlockType' })
                  | ({
                        tip?: Array<
                            | {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'pages_page_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'vouchers_voucher_Entry';
                              }
                            | ({
                                  title?: string | null;
                                  tipText?: string | null;
                                  tipName?: string | null;
                                  tipRole?: string | null;
                                  tipAvatar?: Array<
                                      | ({ height?: number | null; width?: number | null; thumbUrl?: string | null } & {
                                            __typename?: 'library_Asset';
                                        })
                                      | null
                                  > | null;
                              } & { __typename?: 'tips_tip_Entry' })
                            | null
                        > | null;
                    } & { __typename?: 'contentMatrix_sectionTip_BlockType' })
                  | ({
                        travelbaseEntityType?: string | null;
                        travelbaseSlugs?: Array<
                            ({ slug?: string | null } & { __typename?: 'travelbaseSlugs_TableRow' }) | null
                        > | null;
                    } & { __typename?: 'contentMatrix_sectionTravelbaseTiles_BlockType' })
                  | ({ videoUrl?: string | null; autoplay?: boolean | null } & {
                        __typename?: 'contentMatrix_sectionVideo_BlockType';
                    })
                  | ({ webcamUrl?: string | null } & { __typename?: 'contentMatrix_sectionWebcam_BlockType' })
                  | null
              > | null;
              localized?: Array<
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | null
              > | null;
          } & { __typename?: 'contact_contact_Entry' })
        | null;
} & { __typename?: 'Query' };

export type ContactMeQueryVariables = Exact<{ [key: string]: never }>;

export type ContactMeQuery = { viewer?: ({ id?: string | null } & { __typename?: 'Customer' }) | null } & {
    __typename?: 'Query';
};

export type ContactFragment = {
    title?: string | null;
    textBody?: string | null;
    settingsCookieConsent?: boolean | null;
    settingsMetaDescription?: string | null;
    settingsSeoTitle?: string | null;
    heroImage?: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension?: string | null;
              url?: string | null;
              placeholderUrl?: string | null;
              ogImageUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
              srcsetMobile?: string | null;
              srcsetMobileWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    > | null;
    button?:
        | ({
              customText?: string | null;
              target?: string | null;
              url?: string | null;
              type?: string | null;
              element?:
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'Element'
                            | 'Form'
                            | 'User'
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'assetTags_Tag'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contactMatrix_contact_BlockType'
                            | 'contact_GlobalSet'
                            | 'contact_contact_Entry'
                            | 'contentMatrix_sectionAccommodations_BlockType'
                            | 'contentMatrix_sectionAccordion_BlockType'
                            | 'contentMatrix_sectionAction_BlockType'
                            | 'contentMatrix_sectionActivities_BlockType'
                            | 'contentMatrix_sectionBanner_BlockType'
                            | 'contentMatrix_sectionButton_BlockType'
                            | 'contentMatrix_sectionCompanies_BlockType'
                            | 'contentMatrix_sectionEmbed_BlockType';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'contentMatrix_sectionForms_BlockType'
                            | 'contentMatrix_sectionHeader_BlockType'
                            | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                            | 'contentMatrix_sectionImages_BlockType'
                            | 'contentMatrix_sectionMap_BlockType'
                            | 'contentMatrix_sectionText_BlockType'
                            | 'contentMatrix_sectionTiles_BlockType'
                            | 'contentMatrix_sectionTip_BlockType'
                            | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                            | 'contentMatrix_sectionVideo_BlockType'
                            | 'contentMatrix_sectionWebcam_BlockType'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'library_Asset'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'notification_GlobalSet'
                            | 'pages_page_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'searchTags_Tag'
                            | 'search_search_Entry'
                            | 'synonyms_Tag'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'voucherLocations_location_BlockType'
                            | 'vouchers_voucher_Entry';
                    })
                  | null;
          } & { __typename?: 'linkField_Link' })
        | null;
    contactMatrix?: Array<
        | ({
              whatsapp?: boolean | null;
              phone?: boolean | null;
              email?: boolean | null;
              messenger?: boolean | null;
              myTexel?: boolean | null;
              shop?: boolean | null;
          } & { __typename?: 'contactMatrix_contact_BlockType' })
        | null
    > | null;
    contentMatrix?: Array<
        | ({ accommodationsQuery?: string | null } & { __typename?: 'contentMatrix_sectionAccommodations_BlockType' })
        | ({ header?: string | null; text?: string | null } & {
              __typename?: 'contentMatrix_sectionAccordion_BlockType';
          })
        | { __typename?: 'contentMatrix_sectionAction_BlockType' }
        | ({ activitiesQuery?: string | null } & { __typename?: 'contentMatrix_sectionActivities_BlockType' })
        | ({
              banner?: Array<
                  | {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    }
                  | ({
                        title?: string | null;
                        heroImage?: Array<
                            ({ thumbUrl?: string | null } & { __typename?: 'library_Asset' }) | null
                        > | null;
                        button?:
                            | ({
                                  customText?: string | null;
                                  target?: string | null;
                                  url?: string | null;
                                  type?: string | null;
                                  element?:
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'Element'
                                                | 'Form'
                                                | 'User'
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'assetTags_Tag'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contactMatrix_contact_BlockType'
                                                | 'contact_GlobalSet'
                                                | 'contact_contact_Entry'
                                                | 'contentMatrix_sectionAccommodations_BlockType'
                                                | 'contentMatrix_sectionAccordion_BlockType'
                                                | 'contentMatrix_sectionAction_BlockType'
                                                | 'contentMatrix_sectionActivities_BlockType'
                                                | 'contentMatrix_sectionBanner_BlockType'
                                                | 'contentMatrix_sectionButton_BlockType'
                                                | 'contentMatrix_sectionCompanies_BlockType'
                                                | 'contentMatrix_sectionEmbed_BlockType';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'contentMatrix_sectionForms_BlockType'
                                                | 'contentMatrix_sectionHeader_BlockType'
                                                | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                                | 'contentMatrix_sectionImages_BlockType'
                                                | 'contentMatrix_sectionMap_BlockType'
                                                | 'contentMatrix_sectionText_BlockType'
                                                | 'contentMatrix_sectionTiles_BlockType'
                                                | 'contentMatrix_sectionTip_BlockType'
                                                | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                                | 'contentMatrix_sectionVideo_BlockType'
                                                | 'contentMatrix_sectionWebcam_BlockType'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'library_Asset'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'notification_GlobalSet'
                                                | 'pages_page_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'searchTags_Tag'
                                                | 'search_search_Entry'
                                                | 'synonyms_Tag'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'voucherLocations_location_BlockType'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'linkField_Link' })
                            | null;
                    } & { __typename?: 'banners_banner_Entry' })
                  | null
              > | null;
          } & { __typename?: 'contentMatrix_sectionBanner_BlockType' })
        | ({
              button?:
                  | ({
                        customText?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Element'
                                      | 'Form'
                                      | 'User'
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'assetTags_Tag'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contactMatrix_contact_BlockType'
                                      | 'contact_GlobalSet'
                                      | 'contact_contact_Entry'
                                      | 'contentMatrix_sectionAccommodations_BlockType'
                                      | 'contentMatrix_sectionAccordion_BlockType'
                                      | 'contentMatrix_sectionAction_BlockType'
                                      | 'contentMatrix_sectionActivities_BlockType'
                                      | 'contentMatrix_sectionBanner_BlockType'
                                      | 'contentMatrix_sectionButton_BlockType'
                                      | 'contentMatrix_sectionCompanies_BlockType'
                                      | 'contentMatrix_sectionEmbed_BlockType';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'contentMatrix_sectionForms_BlockType'
                                      | 'contentMatrix_sectionHeader_BlockType'
                                      | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                      | 'contentMatrix_sectionImages_BlockType'
                                      | 'contentMatrix_sectionMap_BlockType'
                                      | 'contentMatrix_sectionText_BlockType'
                                      | 'contentMatrix_sectionTiles_BlockType'
                                      | 'contentMatrix_sectionTip_BlockType'
                                      | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                      | 'contentMatrix_sectionVideo_BlockType'
                                      | 'contentMatrix_sectionWebcam_BlockType'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'library_Asset'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'notification_GlobalSet'
                                      | 'pages_page_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'searchTags_Tag'
                                      | 'search_search_Entry'
                                      | 'synonyms_Tag'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'voucherLocations_location_BlockType'
                                      | 'vouchers_voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'linkField_Link' })
                  | null;
          } & { __typename?: 'contentMatrix_sectionButton_BlockType' })
        | ({ companiesQuery?: string | null } & { __typename?: 'contentMatrix_sectionCompanies_BlockType' })
        | ({ embed?: string | null } & { __typename?: 'contentMatrix_sectionEmbed_BlockType' })
        | ({
              form?: Array<
                  | ({
                        id?: string | null;
                        handle?: string | null;
                        siteId?: number | null;
                        settings?:
                            | ({
                                  submitActionFormHide?: boolean | null;
                                  submitActionMessageHtml?: string | null;
                                  submitAction?: string | null;
                                  submitActionTab?: string | null;
                                  submitActionUrl?: string | null;
                                  integrations?: Array<
                                      | ({ handle?: string | null; enabled?: boolean | null } & {
                                            __typename?: 'FormIntegrationsType';
                                        })
                                      | null
                                  > | null;
                              } & { __typename?: 'FormSettingsType' })
                            | null;
                        fields?: Array<
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  address1Label?: string | null;
                                  address1Hidden?: boolean | null;
                                  address1Enabled?: boolean | null;
                                  address1ErrorMessage?: string | null;
                                  address1Required?: boolean | null;
                                  address1DefaultValue?: string | null;
                                  address1Placeholder?: string | null;
                                  zipLabel?: string | null;
                                  zipHidden?: boolean | null;
                                  zipEnabled?: boolean | null;
                                  zipRequired?: boolean | null;
                                  zipCollapsed?: boolean | null;
                                  zipPlaceholder?: string | null;
                                  zipDefaultValue?: string | null;
                                  cityLabel?: string | null;
                                  cityHidden?: boolean | null;
                                  cityEnabled?: boolean | null;
                                  cityRequired?: boolean | null;
                                  cityPlaceholder?: string | null;
                                  cityDefaultValue?: string | null;
                                  cityErrorMessage?: string | null;
                                  countryLabel?: string | null;
                                  countryHidden?: boolean | null;
                                  countryEnabled?: boolean | null;
                                  countryRequired?: boolean | null;
                                  countryPlaceholder?: string | null;
                                  countryDefaultValue?: string | null;
                              } & { __typename?: 'Field_Address' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  checkedValue?: string | null;
                                  uncheckedValue?: string | null;
                                  descriptionHtml?: string | null;
                                  defaultState?: boolean | null;
                              } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Categories'
                                      | 'Field_Entries'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MissingField'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  options?: Array<
                                      | ({ isDefault?: boolean | null; value?: string | null } & {
                                            __typename?: 'KeyValueType';
                                        })
                                      | null
                                  > | null;
                              } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  displayType?: string | null;
                                  defaultDate?: any | null;
                                  defaultOption?: string | null;
                              } & { __typename?: 'Field_Date' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  options?: Array<
                                      | ({
                                            label?: string | null;
                                            isDefault?: boolean | null;
                                            value?: string | null;
                                        } & { __typename?: 'KeyValueType' })
                                      | null
                                  > | null;
                              } & { __typename?: 'Field_Dropdown' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  limit?: boolean | null;
                                  limitType?: string | null;
                                  limitAmount?: number | null;
                                  defaultValue?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Email'
                                      | 'Field_MultiLineText'
                                      | 'Field_Phone'
                                      | 'Field_SingleLineText';
                              })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  restrictFiles?: string | null;
                                  allowedKinds?: Array<string | null> | null;
                                  limitFiles?: string | null;
                                  limitAmount?: number | null;
                                  sizeLimit?: string | null;
                                  sizeMinLimit?: string | null;
                              } & { __typename?: 'Field_FileUpload' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  fields?: Array<
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            address1Label?: string | null;
                                            address1Hidden?: boolean | null;
                                            address1Enabled?: boolean | null;
                                            address1ErrorMessage?: string | null;
                                            address1Required?: boolean | null;
                                            address1DefaultValue?: string | null;
                                            address1Placeholder?: string | null;
                                            zipLabel?: string | null;
                                            zipHidden?: boolean | null;
                                            zipEnabled?: boolean | null;
                                            zipRequired?: boolean | null;
                                            zipCollapsed?: boolean | null;
                                            zipPlaceholder?: string | null;
                                            zipDefaultValue?: string | null;
                                            cityLabel?: string | null;
                                            cityHidden?: boolean | null;
                                            cityEnabled?: boolean | null;
                                            cityRequired?: boolean | null;
                                            cityPlaceholder?: string | null;
                                            cityDefaultValue?: string | null;
                                            cityErrorMessage?: string | null;
                                            countryLabel?: string | null;
                                            countryHidden?: boolean | null;
                                            countryEnabled?: boolean | null;
                                            countryRequired?: boolean | null;
                                            countryPlaceholder?: string | null;
                                            countryDefaultValue?: string | null;
                                        } & { __typename?: 'Field_Address' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            checkedValue?: string | null;
                                            uncheckedValue?: string | null;
                                            descriptionHtml?: string | null;
                                            defaultState?: boolean | null;
                                        } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                        } & {
                                            __typename?:
                                                | 'Field_Categories'
                                                | 'Field_Entries'
                                                | 'Field_Group'
                                                | 'Field_Hidden'
                                                | 'Field_Html'
                                                | 'Field_MissingField'
                                                | 'Field_Recipients'
                                                | 'Field_Repeater'
                                                | 'Field_Section'
                                                | 'Field_Table'
                                                | 'Field_Tags'
                                                | 'Field_Users';
                                        })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            options?: Array<
                                                | ({ isDefault?: boolean | null; value?: string | null } & {
                                                      __typename?: 'KeyValueType';
                                                  })
                                                | null
                                            > | null;
                                        } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            displayType?: string | null;
                                            defaultDate?: any | null;
                                            defaultOption?: string | null;
                                        } & { __typename?: 'Field_Date' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            options?: Array<
                                                | ({
                                                      label?: string | null;
                                                      isDefault?: boolean | null;
                                                      value?: string | null;
                                                  } & { __typename?: 'KeyValueType' })
                                                | null
                                            > | null;
                                        } & { __typename?: 'Field_Dropdown' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            limit?: boolean | null;
                                            limitType?: string | null;
                                            limitAmount?: number | null;
                                            defaultValue?: string | null;
                                        } & {
                                            __typename?:
                                                | 'Field_Email'
                                                | 'Field_MultiLineText'
                                                | 'Field_Phone'
                                                | 'Field_SingleLineText';
                                        })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            restrictFiles?: string | null;
                                            allowedKinds?: Array<string | null> | null;
                                            limitFiles?: string | null;
                                            limitAmount?: number | null;
                                            sizeLimit?: string | null;
                                            sizeMinLimit?: string | null;
                                        } & { __typename?: 'Field_FileUpload' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            headingSize?: string | null;
                                        } & { __typename?: 'Field_Heading' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            prefixLabel?: string | null;
                                            prefixEnabled?: boolean | null;
                                            prefixRequired?: boolean | null;
                                            firstNameLabel?: string | null;
                                            firstNameEnabled?: boolean | null;
                                            firstNamePlaceholder?: string | null;
                                            firstNameRequired?: boolean | null;
                                            middleNameLabel?: string | null;
                                            middleNameEnabled?: boolean | null;
                                            middleNamePlaceholder?: string | null;
                                            middleNameRequired?: boolean | null;
                                            lastNameLabel?: string | null;
                                            lastNameEnabled?: boolean | null;
                                            lastNamePlaceholder?: string | null;
                                            lastNameRequired?: boolean | null;
                                        } & { __typename?: 'Field_Name' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            defaultValue?: string | null;
                                            min?: string | null;
                                            max?: string | null;
                                        } & { __typename?: 'Field_Number' })
                                      | null
                                  > | null;
                              } & { __typename?: 'Field_Group' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  headingSize?: string | null;
                              } & { __typename?: 'Field_Heading' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  prefixLabel?: string | null;
                                  prefixEnabled?: boolean | null;
                                  prefixRequired?: boolean | null;
                                  firstNameLabel?: string | null;
                                  firstNameEnabled?: boolean | null;
                                  firstNamePlaceholder?: string | null;
                                  firstNameRequired?: boolean | null;
                                  middleNameLabel?: string | null;
                                  middleNameEnabled?: boolean | null;
                                  middleNamePlaceholder?: string | null;
                                  middleNameRequired?: boolean | null;
                                  lastNameLabel?: string | null;
                                  lastNameEnabled?: boolean | null;
                                  lastNamePlaceholder?: string | null;
                                  lastNameRequired?: boolean | null;
                              } & { __typename?: 'Field_Name' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  min?: string | null;
                                  max?: string | null;
                              } & { __typename?: 'Field_Number' })
                            | null
                        > | null;
                    } & { __typename?: 'Form' })
                  | null
              > | null;
          } & { __typename?: 'contentMatrix_sectionForms_BlockType' })
        | ({ header?: string | null } & { __typename?: 'contentMatrix_sectionHeader_BlockType' })
        | ({ relationType?: string | null } & { __typename?: 'contentMatrix_sectionHierarchicalTiles_BlockType' })
        | ({
              gallery?: Array<
                  | ({
                        assetSource?: string | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        width?: number | null;
                        height?: number | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'contentMatrix_sectionImages_BlockType' })
        | ({
              pins?: Array<
                  | ({
                        latitude?: any | null;
                        longitude?: any | null;
                        symbol?: string | null;
                        label?: string | null;
                    } & { __typename?: 'pins_TableRow' })
                  | null
              > | null;
          } & { __typename?: 'contentMatrix_sectionMap_BlockType' })
        | ({ text?: string | null } & { __typename?: 'contentMatrix_sectionText_BlockType' })
        | ({
              tilesChildren?: boolean | null;
              tilesSiblings?: boolean | null;
              tilesEntries?: Array<
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'banners_banner_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'menu_menu_Entry'
                            | 'search_search_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
                        __typename?:
                            | 'agenda_agenda_Entry'
                            | 'companies_companies_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'thankYou_thankYou_Entry';
                    })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        postDate?: any | null;
                        settingsMetaDescription?: string | null;
                        heroImage?: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename?: 'articles_article_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        settingsMetaDescription?: string | null;
                        heroImage?: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename?: 'contact_contact_Entry' | 'homepage_homepage_Entry' | 'news_news_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        id?: string | null;
                        settingsMetaDescription?: string | null;
                        settingsNotSearchable?: boolean | null;
                        heroImage?: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename?: 'pages_page_Entry' })
                  | null
              > | null;
          } & { __typename?: 'contentMatrix_sectionTiles_BlockType' })
        | ({
              tip?: Array<
                  | {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'vouchers_voucher_Entry';
                    }
                  | ({
                        title?: string | null;
                        tipText?: string | null;
                        tipName?: string | null;
                        tipRole?: string | null;
                        tipAvatar?: Array<
                            | ({ height?: number | null; width?: number | null; thumbUrl?: string | null } & {
                                  __typename?: 'library_Asset';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'tips_tip_Entry' })
                  | null
              > | null;
          } & { __typename?: 'contentMatrix_sectionTip_BlockType' })
        | ({
              travelbaseEntityType?: string | null;
              travelbaseSlugs?: Array<
                  ({ slug?: string | null } & { __typename?: 'travelbaseSlugs_TableRow' }) | null
              > | null;
          } & { __typename?: 'contentMatrix_sectionTravelbaseTiles_BlockType' })
        | ({ videoUrl?: string | null; autoplay?: boolean | null } & {
              __typename?: 'contentMatrix_sectionVideo_BlockType';
          })
        | ({ webcamUrl?: string | null } & { __typename?: 'contentMatrix_sectionWebcam_BlockType' })
        | null
    > | null;
    localized?: Array<
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | null
    > | null;
} & { __typename?: 'contact_contact_Entry' };

export type ContactMatrixFragment = {
    whatsapp?: boolean | null;
    phone?: boolean | null;
    email?: boolean | null;
    messenger?: boolean | null;
    myTexel?: boolean | null;
    shop?: boolean | null;
} & { __typename?: 'contactMatrix_contact_BlockType' };

export type ContactGlobalsFragment = {
    contactEmailText?: string | null;
    contactMessengerText?: string | null;
    contactMyTexelText?: string | null;
    contactPhoneText?: string | null;
    contactShopText?: string | null;
    contactWhatsappText?: string | null;
} & { __typename?: 'contact_GlobalSet' };

export type HomeQueryVariables = Exact<{
    lang?: InputMaybe<Scalars['String']>;
}>;

export type HomeQuery = {
    entry?:
        | {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          }
        | ({
              title?: string | null;
              video?: string | null;
              homeVideoEnabled?: boolean | null;
              homeVideoTitle?: string | null;
              homeVideoDescription?: string | null;
              homeHighlightsTitle?: string | null;
              settingsCookieConsent?: boolean | null;
              settingsMetaDescription?: string | null;
              settingsSeoTitle?: string | null;
              header?: string | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        ogImageUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        srcsetMobile?: string | null;
                        srcsetMobileWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
              homeHighlights?: Array<
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        newsHeroImage?: Array<
                            | ({
                                  width?: number | null;
                                  height?: number | null;
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                                  placeholderUrl?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename: 'articles_article_Entry' })
                  | ({
                        settingsSeoTitle?: string | null;
                        settingsMetaDescription?: string | null;
                        title?: string | null;
                        uri?: string | null;
                        heroImage?: Array<
                            | ({
                                  width?: number | null;
                                  height?: number | null;
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                                  placeholderUrl?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename: 'pages_page_Entry' })
                  | null
              > | null;
              homeCurrent?: Array<
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'banners_banner_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'menu_menu_Entry'
                            | 'search_search_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
                        __typename?:
                            | 'agenda_agenda_Entry'
                            | 'companies_companies_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'thankYou_thankYou_Entry';
                    })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        postDate?: any | null;
                        settingsMetaDescription?: string | null;
                        heroImage?: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename?: 'articles_article_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        settingsMetaDescription?: string | null;
                        heroImage?: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename?: 'contact_contact_Entry' | 'homepage_homepage_Entry' | 'news_news_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        id?: string | null;
                        settingsMetaDescription?: string | null;
                        settingsNotSearchable?: boolean | null;
                        heroImage?: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename?: 'pages_page_Entry' })
                  | null
              > | null;
              action?: Array<
                  | ({
                        title?: string | null;
                        description?: string | null;
                        button?:
                            | ({
                                  customText?: string | null;
                                  target?: string | null;
                                  url?: string | null;
                                  type?: string | null;
                                  element?:
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'Element'
                                                | 'Form'
                                                | 'User'
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'assetTags_Tag'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contactMatrix_contact_BlockType'
                                                | 'contact_GlobalSet'
                                                | 'contact_contact_Entry'
                                                | 'contentMatrix_sectionAccommodations_BlockType'
                                                | 'contentMatrix_sectionAccordion_BlockType'
                                                | 'contentMatrix_sectionAction_BlockType'
                                                | 'contentMatrix_sectionActivities_BlockType'
                                                | 'contentMatrix_sectionBanner_BlockType'
                                                | 'contentMatrix_sectionButton_BlockType'
                                                | 'contentMatrix_sectionCompanies_BlockType'
                                                | 'contentMatrix_sectionEmbed_BlockType';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'contentMatrix_sectionForms_BlockType'
                                                | 'contentMatrix_sectionHeader_BlockType'
                                                | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                                | 'contentMatrix_sectionImages_BlockType'
                                                | 'contentMatrix_sectionMap_BlockType'
                                                | 'contentMatrix_sectionText_BlockType'
                                                | 'contentMatrix_sectionTiles_BlockType'
                                                | 'contentMatrix_sectionTip_BlockType'
                                                | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                                | 'contentMatrix_sectionVideo_BlockType'
                                                | 'contentMatrix_sectionWebcam_BlockType'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'library_Asset'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'notification_GlobalSet'
                                                | 'pages_page_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'searchTags_Tag'
                                                | 'search_search_Entry'
                                                | 'synonyms_Tag'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'voucherLocations_location_BlockType'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'linkField_Link' })
                            | null;
                    } & { __typename?: 'action_action_Entry' })
                  | {
                        __typename?:
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    }
                  | null
              > | null;
              localized?: Array<
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | null
              > | null;
          } & { __typename?: 'homepage_homepage_Entry' })
        | null;
} & { __typename?: 'Query' };

export type HomeFragment = {
    title?: string | null;
    video?: string | null;
    homeVideoEnabled?: boolean | null;
    homeVideoTitle?: string | null;
    homeVideoDescription?: string | null;
    homeHighlightsTitle?: string | null;
    settingsCookieConsent?: boolean | null;
    settingsMetaDescription?: string | null;
    settingsSeoTitle?: string | null;
    header?: string | null;
    heroImage?: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension?: string | null;
              url?: string | null;
              placeholderUrl?: string | null;
              ogImageUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
              srcsetMobile?: string | null;
              srcsetMobileWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    > | null;
    homeHighlights?: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              newsHeroImage?: Array<
                  | ({
                        width?: number | null;
                        height?: number | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        placeholderUrl?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename: 'articles_article_Entry' })
        | ({
              settingsSeoTitle?: string | null;
              settingsMetaDescription?: string | null;
              title?: string | null;
              uri?: string | null;
              heroImage?: Array<
                  | ({
                        width?: number | null;
                        height?: number | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        placeholderUrl?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename: 'pages_page_Entry' })
        | null
    > | null;
    homeCurrent?: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'banners_banner_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'menu_menu_Entry'
                  | 'search_search_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
              __typename?:
                  | 'agenda_agenda_Entry'
                  | 'companies_companies_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'thankYou_thankYou_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              postDate?: any | null;
              settingsMetaDescription?: string | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'articles_article_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              settingsMetaDescription?: string | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'contact_contact_Entry' | 'homepage_homepage_Entry' | 'news_news_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              id?: string | null;
              settingsMetaDescription?: string | null;
              settingsNotSearchable?: boolean | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'pages_page_Entry' })
        | null
    > | null;
    action?: Array<
        | ({
              title?: string | null;
              description?: string | null;
              button?:
                  | ({
                        customText?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Element'
                                      | 'Form'
                                      | 'User'
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'assetTags_Tag'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contactMatrix_contact_BlockType'
                                      | 'contact_GlobalSet'
                                      | 'contact_contact_Entry'
                                      | 'contentMatrix_sectionAccommodations_BlockType'
                                      | 'contentMatrix_sectionAccordion_BlockType'
                                      | 'contentMatrix_sectionAction_BlockType'
                                      | 'contentMatrix_sectionActivities_BlockType'
                                      | 'contentMatrix_sectionBanner_BlockType'
                                      | 'contentMatrix_sectionButton_BlockType'
                                      | 'contentMatrix_sectionCompanies_BlockType'
                                      | 'contentMatrix_sectionEmbed_BlockType';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'contentMatrix_sectionForms_BlockType'
                                      | 'contentMatrix_sectionHeader_BlockType'
                                      | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                      | 'contentMatrix_sectionImages_BlockType'
                                      | 'contentMatrix_sectionMap_BlockType'
                                      | 'contentMatrix_sectionText_BlockType'
                                      | 'contentMatrix_sectionTiles_BlockType'
                                      | 'contentMatrix_sectionTip_BlockType'
                                      | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                      | 'contentMatrix_sectionVideo_BlockType'
                                      | 'contentMatrix_sectionWebcam_BlockType'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'library_Asset'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'notification_GlobalSet'
                                      | 'pages_page_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'searchTags_Tag'
                                      | 'search_search_Entry'
                                      | 'synonyms_Tag'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'voucherLocations_location_BlockType'
                                      | 'vouchers_voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'linkField_Link' })
                  | null;
          } & { __typename?: 'action_action_Entry' })
        | {
              __typename?:
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          }
        | null
    > | null;
    localized?: Array<
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | null
    > | null;
} & { __typename?: 'homepage_homepage_Entry' };

export type NewsQueryVariables = Exact<{
    lang?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
    limit?: InputMaybe<Scalars['Int']>;
}>;

export type NewsQuery = {
    articleCount: number;
    articles?: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'banners_banner_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'menu_menu_Entry'
                  | 'search_search_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
              __typename?:
                  | 'agenda_agenda_Entry'
                  | 'companies_companies_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'thankYou_thankYou_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              postDate?: any | null;
              settingsMetaDescription?: string | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'articles_article_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              settingsMetaDescription?: string | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'contact_contact_Entry' | 'homepage_homepage_Entry' | 'news_news_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              id?: string | null;
              settingsMetaDescription?: string | null;
              settingsNotSearchable?: boolean | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'pages_page_Entry' })
        | null
    > | null;
    entry?:
        | {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          }
        | ({
              title?: string | null;
              textBody?: string | null;
              settingsCookieConsent?: boolean | null;
              settingsMetaDescription?: string | null;
              settingsSeoTitle?: string | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        ogImageUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        srcsetMobile?: string | null;
                        srcsetMobileWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
              localized?: Array<
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | null
              > | null;
          } & { __typename?: 'news_news_Entry' })
        | null;
} & { __typename?: 'Query' };

export type NewsFragment = {
    title?: string | null;
    textBody?: string | null;
    settingsCookieConsent?: boolean | null;
    settingsMetaDescription?: string | null;
    settingsSeoTitle?: string | null;
    heroImage?: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension?: string | null;
              url?: string | null;
              placeholderUrl?: string | null;
              ogImageUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
              srcsetMobile?: string | null;
              srcsetMobileWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    > | null;
    localized?: Array<
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | null
    > | null;
} & { __typename?: 'news_news_Entry' };

export type PageQueryVariables = Exact<{
    uri?: InputMaybe<Scalars['String']>;
    lang?: InputMaybe<Scalars['String']>;
    retourUri?: InputMaybe<Scalars['String']>;
}>;

export type PageQuery = {
    entry?:
        | {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          }
        | ({
              uri?: string | null;
              title?: string | null;
              settingsCookieConsent?: boolean | null;
              settingsMetaDescription?: string | null;
              settingsSeoTitle?: string | null;
              settingsNotSearchable?: boolean | null;
              heroImageVisible?: boolean | null;
              root?: number | null;
              id?: string | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        ogImageUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                        srcsetMobile?: string | null;
                        srcsetMobileWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
              contentMatrix?: Array<
                  | ({ accommodationsQuery?: string | null } & {
                        __typename?: 'contentMatrix_sectionAccommodations_BlockType';
                    })
                  | ({ header?: string | null; text?: string | null } & {
                        __typename?: 'contentMatrix_sectionAccordion_BlockType';
                    })
                  | { __typename?: 'contentMatrix_sectionAction_BlockType' }
                  | ({ activitiesQuery?: string | null } & { __typename?: 'contentMatrix_sectionActivities_BlockType' })
                  | ({
                        banner?: Array<
                            | {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'pages_page_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              }
                            | ({
                                  title?: string | null;
                                  heroImage?: Array<
                                      ({ thumbUrl?: string | null } & { __typename?: 'library_Asset' }) | null
                                  > | null;
                                  button?:
                                      | ({
                                            customText?: string | null;
                                            target?: string | null;
                                            url?: string | null;
                                            type?: string | null;
                                            element?:
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'Element'
                                                          | 'Form'
                                                          | 'User'
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'assetTags_Tag'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contactMatrix_contact_BlockType'
                                                          | 'contact_GlobalSet'
                                                          | 'contact_contact_Entry'
                                                          | 'contentMatrix_sectionAccommodations_BlockType'
                                                          | 'contentMatrix_sectionAccordion_BlockType'
                                                          | 'contentMatrix_sectionAction_BlockType'
                                                          | 'contentMatrix_sectionActivities_BlockType'
                                                          | 'contentMatrix_sectionBanner_BlockType'
                                                          | 'contentMatrix_sectionButton_BlockType'
                                                          | 'contentMatrix_sectionCompanies_BlockType'
                                                          | 'contentMatrix_sectionEmbed_BlockType';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'contentMatrix_sectionForms_BlockType'
                                                          | 'contentMatrix_sectionHeader_BlockType'
                                                          | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                                          | 'contentMatrix_sectionImages_BlockType'
                                                          | 'contentMatrix_sectionMap_BlockType'
                                                          | 'contentMatrix_sectionText_BlockType'
                                                          | 'contentMatrix_sectionTiles_BlockType'
                                                          | 'contentMatrix_sectionTip_BlockType'
                                                          | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                                          | 'contentMatrix_sectionVideo_BlockType'
                                                          | 'contentMatrix_sectionWebcam_BlockType'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'library_Asset'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'notification_GlobalSet'
                                                          | 'pages_page_Entry';
                                                  })
                                                | ({ title?: string | null; uri?: string | null } & {
                                                      __typename?:
                                                          | 'searchTags_Tag'
                                                          | 'search_search_Entry'
                                                          | 'synonyms_Tag'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'voucherLocations_location_BlockType'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'linkField_Link' })
                                      | null;
                              } & { __typename?: 'banners_banner_Entry' })
                            | null
                        > | null;
                    } & { __typename?: 'contentMatrix_sectionBanner_BlockType' })
                  | ({
                        button?:
                            | ({
                                  customText?: string | null;
                                  target?: string | null;
                                  url?: string | null;
                                  type?: string | null;
                                  element?:
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'Element'
                                                | 'Form'
                                                | 'User'
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'assetTags_Tag'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contactMatrix_contact_BlockType'
                                                | 'contact_GlobalSet'
                                                | 'contact_contact_Entry'
                                                | 'contentMatrix_sectionAccommodations_BlockType'
                                                | 'contentMatrix_sectionAccordion_BlockType'
                                                | 'contentMatrix_sectionAction_BlockType'
                                                | 'contentMatrix_sectionActivities_BlockType'
                                                | 'contentMatrix_sectionBanner_BlockType'
                                                | 'contentMatrix_sectionButton_BlockType'
                                                | 'contentMatrix_sectionCompanies_BlockType'
                                                | 'contentMatrix_sectionEmbed_BlockType';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'contentMatrix_sectionForms_BlockType'
                                                | 'contentMatrix_sectionHeader_BlockType'
                                                | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                                | 'contentMatrix_sectionImages_BlockType'
                                                | 'contentMatrix_sectionMap_BlockType'
                                                | 'contentMatrix_sectionText_BlockType'
                                                | 'contentMatrix_sectionTiles_BlockType'
                                                | 'contentMatrix_sectionTip_BlockType'
                                                | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                                | 'contentMatrix_sectionVideo_BlockType'
                                                | 'contentMatrix_sectionWebcam_BlockType'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'library_Asset'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'notification_GlobalSet'
                                                | 'pages_page_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'searchTags_Tag'
                                                | 'search_search_Entry'
                                                | 'synonyms_Tag'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'voucherLocations_location_BlockType'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'linkField_Link' })
                            | null;
                    } & { __typename?: 'contentMatrix_sectionButton_BlockType' })
                  | ({ companiesQuery?: string | null } & { __typename?: 'contentMatrix_sectionCompanies_BlockType' })
                  | ({ embed?: string | null } & { __typename?: 'contentMatrix_sectionEmbed_BlockType' })
                  | ({
                        form?: Array<
                            | ({
                                  id?: string | null;
                                  handle?: string | null;
                                  siteId?: number | null;
                                  settings?:
                                      | ({
                                            submitActionFormHide?: boolean | null;
                                            submitActionMessageHtml?: string | null;
                                            submitAction?: string | null;
                                            submitActionTab?: string | null;
                                            submitActionUrl?: string | null;
                                            integrations?: Array<
                                                | ({ handle?: string | null; enabled?: boolean | null } & {
                                                      __typename?: 'FormIntegrationsType';
                                                  })
                                                | null
                                            > | null;
                                        } & { __typename?: 'FormSettingsType' })
                                      | null;
                                  fields?: Array<
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            address1Label?: string | null;
                                            address1Hidden?: boolean | null;
                                            address1Enabled?: boolean | null;
                                            address1ErrorMessage?: string | null;
                                            address1Required?: boolean | null;
                                            address1DefaultValue?: string | null;
                                            address1Placeholder?: string | null;
                                            zipLabel?: string | null;
                                            zipHidden?: boolean | null;
                                            zipEnabled?: boolean | null;
                                            zipRequired?: boolean | null;
                                            zipCollapsed?: boolean | null;
                                            zipPlaceholder?: string | null;
                                            zipDefaultValue?: string | null;
                                            cityLabel?: string | null;
                                            cityHidden?: boolean | null;
                                            cityEnabled?: boolean | null;
                                            cityRequired?: boolean | null;
                                            cityPlaceholder?: string | null;
                                            cityDefaultValue?: string | null;
                                            cityErrorMessage?: string | null;
                                            countryLabel?: string | null;
                                            countryHidden?: boolean | null;
                                            countryEnabled?: boolean | null;
                                            countryRequired?: boolean | null;
                                            countryPlaceholder?: string | null;
                                            countryDefaultValue?: string | null;
                                        } & { __typename?: 'Field_Address' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            checkedValue?: string | null;
                                            uncheckedValue?: string | null;
                                            descriptionHtml?: string | null;
                                            defaultState?: boolean | null;
                                        } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                        } & {
                                            __typename?:
                                                | 'Field_Categories'
                                                | 'Field_Entries'
                                                | 'Field_Hidden'
                                                | 'Field_Html'
                                                | 'Field_MissingField'
                                                | 'Field_Recipients'
                                                | 'Field_Repeater'
                                                | 'Field_Section'
                                                | 'Field_Table'
                                                | 'Field_Tags'
                                                | 'Field_Users';
                                        })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            options?: Array<
                                                | ({ isDefault?: boolean | null; value?: string | null } & {
                                                      __typename?: 'KeyValueType';
                                                  })
                                                | null
                                            > | null;
                                        } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            displayType?: string | null;
                                            defaultDate?: any | null;
                                            defaultOption?: string | null;
                                        } & { __typename?: 'Field_Date' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            options?: Array<
                                                | ({
                                                      label?: string | null;
                                                      isDefault?: boolean | null;
                                                      value?: string | null;
                                                  } & { __typename?: 'KeyValueType' })
                                                | null
                                            > | null;
                                        } & { __typename?: 'Field_Dropdown' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            limit?: boolean | null;
                                            limitType?: string | null;
                                            limitAmount?: number | null;
                                            defaultValue?: string | null;
                                        } & {
                                            __typename?:
                                                | 'Field_Email'
                                                | 'Field_MultiLineText'
                                                | 'Field_Phone'
                                                | 'Field_SingleLineText';
                                        })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            restrictFiles?: string | null;
                                            allowedKinds?: Array<string | null> | null;
                                            limitFiles?: string | null;
                                            limitAmount?: number | null;
                                            sizeLimit?: string | null;
                                            sizeMinLimit?: string | null;
                                        } & { __typename?: 'Field_FileUpload' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            fields?: Array<
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      address1Label?: string | null;
                                                      address1Hidden?: boolean | null;
                                                      address1Enabled?: boolean | null;
                                                      address1ErrorMessage?: string | null;
                                                      address1Required?: boolean | null;
                                                      address1DefaultValue?: string | null;
                                                      address1Placeholder?: string | null;
                                                      zipLabel?: string | null;
                                                      zipHidden?: boolean | null;
                                                      zipEnabled?: boolean | null;
                                                      zipRequired?: boolean | null;
                                                      zipCollapsed?: boolean | null;
                                                      zipPlaceholder?: string | null;
                                                      zipDefaultValue?: string | null;
                                                      cityLabel?: string | null;
                                                      cityHidden?: boolean | null;
                                                      cityEnabled?: boolean | null;
                                                      cityRequired?: boolean | null;
                                                      cityPlaceholder?: string | null;
                                                      cityDefaultValue?: string | null;
                                                      cityErrorMessage?: string | null;
                                                      countryLabel?: string | null;
                                                      countryHidden?: boolean | null;
                                                      countryEnabled?: boolean | null;
                                                      countryRequired?: boolean | null;
                                                      countryPlaceholder?: string | null;
                                                      countryDefaultValue?: string | null;
                                                  } & { __typename?: 'Field_Address' })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      checkedValue?: string | null;
                                                      uncheckedValue?: string | null;
                                                      descriptionHtml?: string | null;
                                                      defaultState?: boolean | null;
                                                  } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                  } & {
                                                      __typename?:
                                                          | 'Field_Categories'
                                                          | 'Field_Entries'
                                                          | 'Field_Group'
                                                          | 'Field_Hidden'
                                                          | 'Field_Html'
                                                          | 'Field_MissingField'
                                                          | 'Field_Recipients'
                                                          | 'Field_Repeater'
                                                          | 'Field_Section'
                                                          | 'Field_Table'
                                                          | 'Field_Tags'
                                                          | 'Field_Users';
                                                  })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      options?: Array<
                                                          | ({ isDefault?: boolean | null; value?: string | null } & {
                                                                __typename?: 'KeyValueType';
                                                            })
                                                          | null
                                                      > | null;
                                                  } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      displayType?: string | null;
                                                      defaultDate?: any | null;
                                                      defaultOption?: string | null;
                                                  } & { __typename?: 'Field_Date' })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      options?: Array<
                                                          | ({
                                                                label?: string | null;
                                                                isDefault?: boolean | null;
                                                                value?: string | null;
                                                            } & { __typename?: 'KeyValueType' })
                                                          | null
                                                      > | null;
                                                  } & { __typename?: 'Field_Dropdown' })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      limit?: boolean | null;
                                                      limitType?: string | null;
                                                      limitAmount?: number | null;
                                                      defaultValue?: string | null;
                                                  } & {
                                                      __typename?:
                                                          | 'Field_Email'
                                                          | 'Field_MultiLineText'
                                                          | 'Field_Phone'
                                                          | 'Field_SingleLineText';
                                                  })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      restrictFiles?: string | null;
                                                      allowedKinds?: Array<string | null> | null;
                                                      limitFiles?: string | null;
                                                      limitAmount?: number | null;
                                                      sizeLimit?: string | null;
                                                      sizeMinLimit?: string | null;
                                                  } & { __typename?: 'Field_FileUpload' })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      headingSize?: string | null;
                                                  } & { __typename?: 'Field_Heading' })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      prefixLabel?: string | null;
                                                      prefixEnabled?: boolean | null;
                                                      prefixRequired?: boolean | null;
                                                      firstNameLabel?: string | null;
                                                      firstNameEnabled?: boolean | null;
                                                      firstNamePlaceholder?: string | null;
                                                      firstNameRequired?: boolean | null;
                                                      middleNameLabel?: string | null;
                                                      middleNameEnabled?: boolean | null;
                                                      middleNamePlaceholder?: string | null;
                                                      middleNameRequired?: boolean | null;
                                                      lastNameLabel?: string | null;
                                                      lastNameEnabled?: boolean | null;
                                                      lastNamePlaceholder?: string | null;
                                                      lastNameRequired?: boolean | null;
                                                  } & { __typename?: 'Field_Name' })
                                                | ({
                                                      instructions?: string | null;
                                                      instructionsPosition?: string | null;
                                                      name?: string | null;
                                                      placeholder?: string | null;
                                                      required?: boolean | null;
                                                      handle?: string | null;
                                                      errorMessage?: string | null;
                                                      defaultValue?: string | null;
                                                      min?: string | null;
                                                      max?: string | null;
                                                  } & { __typename?: 'Field_Number' })
                                                | null
                                            > | null;
                                        } & { __typename?: 'Field_Group' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            headingSize?: string | null;
                                        } & { __typename?: 'Field_Heading' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            prefixLabel?: string | null;
                                            prefixEnabled?: boolean | null;
                                            prefixRequired?: boolean | null;
                                            firstNameLabel?: string | null;
                                            firstNameEnabled?: boolean | null;
                                            firstNamePlaceholder?: string | null;
                                            firstNameRequired?: boolean | null;
                                            middleNameLabel?: string | null;
                                            middleNameEnabled?: boolean | null;
                                            middleNamePlaceholder?: string | null;
                                            middleNameRequired?: boolean | null;
                                            lastNameLabel?: string | null;
                                            lastNameEnabled?: boolean | null;
                                            lastNamePlaceholder?: string | null;
                                            lastNameRequired?: boolean | null;
                                        } & { __typename?: 'Field_Name' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            defaultValue?: string | null;
                                            min?: string | null;
                                            max?: string | null;
                                        } & { __typename?: 'Field_Number' })
                                      | null
                                  > | null;
                              } & { __typename?: 'Form' })
                            | null
                        > | null;
                    } & { __typename?: 'contentMatrix_sectionForms_BlockType' })
                  | ({ header?: string | null } & { __typename?: 'contentMatrix_sectionHeader_BlockType' })
                  | ({ relationType?: string | null } & {
                        __typename?: 'contentMatrix_sectionHierarchicalTiles_BlockType';
                    })
                  | ({
                        gallery?: Array<
                            | ({
                                  assetSource?: string | null;
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  width?: number | null;
                                  height?: number | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename?: 'contentMatrix_sectionImages_BlockType' })
                  | ({
                        pins?: Array<
                            | ({
                                  latitude?: any | null;
                                  longitude?: any | null;
                                  symbol?: string | null;
                                  label?: string | null;
                              } & { __typename?: 'pins_TableRow' })
                            | null
                        > | null;
                    } & { __typename?: 'contentMatrix_sectionMap_BlockType' })
                  | ({ text?: string | null } & { __typename?: 'contentMatrix_sectionText_BlockType' })
                  | ({
                        tilesChildren?: boolean | null;
                        tilesSiblings?: boolean | null;
                        tilesEntries?: Array<
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'banners_banner_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'menu_menu_Entry'
                                      | 'search_search_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMetaDescription?: string | null;
                              } & {
                                  __typename?:
                                      | 'agenda_agenda_Entry'
                                      | 'companies_companies_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'thankYou_thankYou_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  postDate?: any | null;
                                  settingsMetaDescription?: string | null;
                                  heroImage?: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension?: string | null;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  > | null;
                              } & { __typename?: 'articles_article_Entry' })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMetaDescription?: string | null;
                                  heroImage?: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension?: string | null;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  > | null;
                              } & {
                                  __typename?: 'contact_contact_Entry' | 'homepage_homepage_Entry' | 'news_news_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  id?: string | null;
                                  settingsMetaDescription?: string | null;
                                  settingsNotSearchable?: boolean | null;
                                  heroImage?: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension?: string | null;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  > | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null
                        > | null;
                    } & { __typename?: 'contentMatrix_sectionTiles_BlockType' })
                  | ({
                        tip?: Array<
                            | {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'pages_page_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'vouchers_voucher_Entry';
                              }
                            | ({
                                  title?: string | null;
                                  tipText?: string | null;
                                  tipName?: string | null;
                                  tipRole?: string | null;
                                  tipAvatar?: Array<
                                      | ({ height?: number | null; width?: number | null; thumbUrl?: string | null } & {
                                            __typename?: 'library_Asset';
                                        })
                                      | null
                                  > | null;
                              } & { __typename?: 'tips_tip_Entry' })
                            | null
                        > | null;
                    } & { __typename?: 'contentMatrix_sectionTip_BlockType' })
                  | ({
                        travelbaseEntityType?: string | null;
                        travelbaseSlugs?: Array<
                            ({ slug?: string | null } & { __typename?: 'travelbaseSlugs_TableRow' }) | null
                        > | null;
                    } & { __typename?: 'contentMatrix_sectionTravelbaseTiles_BlockType' })
                  | ({ videoUrl?: string | null; autoplay?: boolean | null } & {
                        __typename?: 'contentMatrix_sectionVideo_BlockType';
                    })
                  | ({ webcamUrl?: string | null } & { __typename?: 'contentMatrix_sectionWebcam_BlockType' })
                  | null
              > | null;
              children?: Array<
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'banners_banner_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'menu_menu_Entry'
                            | 'search_search_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
                        __typename?:
                            | 'agenda_agenda_Entry'
                            | 'companies_companies_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'thankYou_thankYou_Entry';
                    })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        postDate?: any | null;
                        settingsMetaDescription?: string | null;
                        heroImage?: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename?: 'articles_article_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        settingsMetaDescription?: string | null;
                        heroImage?: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename?: 'contact_contact_Entry' | 'homepage_homepage_Entry' | 'news_news_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        id?: string | null;
                        settingsMetaDescription?: string | null;
                        settingsNotSearchable?: boolean | null;
                        heroImage?: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename?: 'pages_page_Entry' })
                  | null
              > | null;
              parent?:
                  | ({
                        id?: string | null;
                        children?: Array<
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'banners_banner_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'menu_menu_Entry'
                                      | 'search_search_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMetaDescription?: string | null;
                              } & {
                                  __typename?:
                                      | 'agenda_agenda_Entry'
                                      | 'companies_companies_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'thankYou_thankYou_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  postDate?: any | null;
                                  settingsMetaDescription?: string | null;
                                  heroImage?: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension?: string | null;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  > | null;
                              } & { __typename?: 'articles_article_Entry' })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMetaDescription?: string | null;
                                  heroImage?: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension?: string | null;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  > | null;
                              } & {
                                  __typename?: 'contact_contact_Entry' | 'homepage_homepage_Entry' | 'news_news_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  id?: string | null;
                                  settingsMetaDescription?: string | null;
                                  settingsNotSearchable?: boolean | null;
                                  heroImage?: Array<
                                      | ({
                                            focalPoint?: Array<number | null> | null;
                                            title?: string | null;
                                            extension?: string | null;
                                            url?: string | null;
                                            placeholderUrl?: string | null;
                                            srcset?: string | null;
                                            srcsetWebp?: string | null;
                                        } & { __typename?: 'library_Asset' })
                                      | null
                                  > | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null
                        > | null;
                    } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | null;
              settingsSearchTags?: Array<
                  | ({ title?: string | null } & { __typename?: 'assetTags_Tag' | 'searchTags_Tag' | 'synonyms_Tag' })
                  | null
              > | null;
              localized?: Array<
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | null
              > | null;
          } & { __typename?: 'pages_page_Entry' })
        | null;
    retourResolveRedirect?:
        | ({
              redirectDestUrl?: string | null;
              redirectSrcUrl?: string | null;
              redirectHttpCode?: number | null;
              redirectSrcMatch?: string | null;
              siteId?: number | null;
          } & { __typename?: 'RetourType' })
        | null;
} & { __typename?: 'Query' };

export type PageFragment = {
    uri?: string | null;
    title?: string | null;
    settingsCookieConsent?: boolean | null;
    settingsMetaDescription?: string | null;
    settingsSeoTitle?: string | null;
    settingsNotSearchable?: boolean | null;
    heroImageVisible?: boolean | null;
    root?: number | null;
    id?: string | null;
    heroImage?: Array<
        | ({
              focalPoint?: Array<number | null> | null;
              title?: string | null;
              extension?: string | null;
              url?: string | null;
              placeholderUrl?: string | null;
              ogImageUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
              srcsetMobile?: string | null;
              srcsetMobileWebp?: string | null;
          } & { __typename?: 'library_Asset' })
        | null
    > | null;
    contentMatrix?: Array<
        | ({ accommodationsQuery?: string | null } & { __typename?: 'contentMatrix_sectionAccommodations_BlockType' })
        | ({ header?: string | null; text?: string | null } & {
              __typename?: 'contentMatrix_sectionAccordion_BlockType';
          })
        | { __typename?: 'contentMatrix_sectionAction_BlockType' }
        | ({ activitiesQuery?: string | null } & { __typename?: 'contentMatrix_sectionActivities_BlockType' })
        | ({
              banner?: Array<
                  | {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    }
                  | ({
                        title?: string | null;
                        heroImage?: Array<
                            ({ thumbUrl?: string | null } & { __typename?: 'library_Asset' }) | null
                        > | null;
                        button?:
                            | ({
                                  customText?: string | null;
                                  target?: string | null;
                                  url?: string | null;
                                  type?: string | null;
                                  element?:
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'Element'
                                                | 'Form'
                                                | 'User'
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'assetTags_Tag'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contactMatrix_contact_BlockType'
                                                | 'contact_GlobalSet'
                                                | 'contact_contact_Entry'
                                                | 'contentMatrix_sectionAccommodations_BlockType'
                                                | 'contentMatrix_sectionAccordion_BlockType'
                                                | 'contentMatrix_sectionAction_BlockType'
                                                | 'contentMatrix_sectionActivities_BlockType'
                                                | 'contentMatrix_sectionBanner_BlockType'
                                                | 'contentMatrix_sectionButton_BlockType'
                                                | 'contentMatrix_sectionCompanies_BlockType'
                                                | 'contentMatrix_sectionEmbed_BlockType';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'contentMatrix_sectionForms_BlockType'
                                                | 'contentMatrix_sectionHeader_BlockType'
                                                | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                                | 'contentMatrix_sectionImages_BlockType'
                                                | 'contentMatrix_sectionMap_BlockType'
                                                | 'contentMatrix_sectionText_BlockType'
                                                | 'contentMatrix_sectionTiles_BlockType'
                                                | 'contentMatrix_sectionTip_BlockType'
                                                | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                                | 'contentMatrix_sectionVideo_BlockType'
                                                | 'contentMatrix_sectionWebcam_BlockType'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'library_Asset'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'notification_GlobalSet'
                                                | 'pages_page_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'searchTags_Tag'
                                                | 'search_search_Entry'
                                                | 'synonyms_Tag'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'voucherLocations_location_BlockType'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'linkField_Link' })
                            | null;
                    } & { __typename?: 'banners_banner_Entry' })
                  | null
              > | null;
          } & { __typename?: 'contentMatrix_sectionBanner_BlockType' })
        | ({
              button?:
                  | ({
                        customText?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Element'
                                      | 'Form'
                                      | 'User'
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'assetTags_Tag'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contactMatrix_contact_BlockType'
                                      | 'contact_GlobalSet'
                                      | 'contact_contact_Entry'
                                      | 'contentMatrix_sectionAccommodations_BlockType'
                                      | 'contentMatrix_sectionAccordion_BlockType'
                                      | 'contentMatrix_sectionAction_BlockType'
                                      | 'contentMatrix_sectionActivities_BlockType'
                                      | 'contentMatrix_sectionBanner_BlockType'
                                      | 'contentMatrix_sectionButton_BlockType'
                                      | 'contentMatrix_sectionCompanies_BlockType'
                                      | 'contentMatrix_sectionEmbed_BlockType';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'contentMatrix_sectionForms_BlockType'
                                      | 'contentMatrix_sectionHeader_BlockType'
                                      | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                      | 'contentMatrix_sectionImages_BlockType'
                                      | 'contentMatrix_sectionMap_BlockType'
                                      | 'contentMatrix_sectionText_BlockType'
                                      | 'contentMatrix_sectionTiles_BlockType'
                                      | 'contentMatrix_sectionTip_BlockType'
                                      | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                      | 'contentMatrix_sectionVideo_BlockType'
                                      | 'contentMatrix_sectionWebcam_BlockType'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'library_Asset'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'notification_GlobalSet'
                                      | 'pages_page_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'searchTags_Tag'
                                      | 'search_search_Entry'
                                      | 'synonyms_Tag'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'voucherLocations_location_BlockType'
                                      | 'vouchers_voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'linkField_Link' })
                  | null;
          } & { __typename?: 'contentMatrix_sectionButton_BlockType' })
        | ({ companiesQuery?: string | null } & { __typename?: 'contentMatrix_sectionCompanies_BlockType' })
        | ({ embed?: string | null } & { __typename?: 'contentMatrix_sectionEmbed_BlockType' })
        | ({
              form?: Array<
                  | ({
                        id?: string | null;
                        handle?: string | null;
                        siteId?: number | null;
                        settings?:
                            | ({
                                  submitActionFormHide?: boolean | null;
                                  submitActionMessageHtml?: string | null;
                                  submitAction?: string | null;
                                  submitActionTab?: string | null;
                                  submitActionUrl?: string | null;
                                  integrations?: Array<
                                      | ({ handle?: string | null; enabled?: boolean | null } & {
                                            __typename?: 'FormIntegrationsType';
                                        })
                                      | null
                                  > | null;
                              } & { __typename?: 'FormSettingsType' })
                            | null;
                        fields?: Array<
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  address1Label?: string | null;
                                  address1Hidden?: boolean | null;
                                  address1Enabled?: boolean | null;
                                  address1ErrorMessage?: string | null;
                                  address1Required?: boolean | null;
                                  address1DefaultValue?: string | null;
                                  address1Placeholder?: string | null;
                                  zipLabel?: string | null;
                                  zipHidden?: boolean | null;
                                  zipEnabled?: boolean | null;
                                  zipRequired?: boolean | null;
                                  zipCollapsed?: boolean | null;
                                  zipPlaceholder?: string | null;
                                  zipDefaultValue?: string | null;
                                  cityLabel?: string | null;
                                  cityHidden?: boolean | null;
                                  cityEnabled?: boolean | null;
                                  cityRequired?: boolean | null;
                                  cityPlaceholder?: string | null;
                                  cityDefaultValue?: string | null;
                                  cityErrorMessage?: string | null;
                                  countryLabel?: string | null;
                                  countryHidden?: boolean | null;
                                  countryEnabled?: boolean | null;
                                  countryRequired?: boolean | null;
                                  countryPlaceholder?: string | null;
                                  countryDefaultValue?: string | null;
                              } & { __typename?: 'Field_Address' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  checkedValue?: string | null;
                                  uncheckedValue?: string | null;
                                  descriptionHtml?: string | null;
                                  defaultState?: boolean | null;
                              } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Categories'
                                      | 'Field_Entries'
                                      | 'Field_Hidden'
                                      | 'Field_Html'
                                      | 'Field_MissingField'
                                      | 'Field_Recipients'
                                      | 'Field_Repeater'
                                      | 'Field_Section'
                                      | 'Field_Table'
                                      | 'Field_Tags'
                                      | 'Field_Users';
                              })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  options?: Array<
                                      | ({ isDefault?: boolean | null; value?: string | null } & {
                                            __typename?: 'KeyValueType';
                                        })
                                      | null
                                  > | null;
                              } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  displayType?: string | null;
                                  defaultDate?: any | null;
                                  defaultOption?: string | null;
                              } & { __typename?: 'Field_Date' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  options?: Array<
                                      | ({
                                            label?: string | null;
                                            isDefault?: boolean | null;
                                            value?: string | null;
                                        } & { __typename?: 'KeyValueType' })
                                      | null
                                  > | null;
                              } & { __typename?: 'Field_Dropdown' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  limit?: boolean | null;
                                  limitType?: string | null;
                                  limitAmount?: number | null;
                                  defaultValue?: string | null;
                              } & {
                                  __typename?:
                                      | 'Field_Email'
                                      | 'Field_MultiLineText'
                                      | 'Field_Phone'
                                      | 'Field_SingleLineText';
                              })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  restrictFiles?: string | null;
                                  allowedKinds?: Array<string | null> | null;
                                  limitFiles?: string | null;
                                  limitAmount?: number | null;
                                  sizeLimit?: string | null;
                                  sizeMinLimit?: string | null;
                              } & { __typename?: 'Field_FileUpload' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  fields?: Array<
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            address1Label?: string | null;
                                            address1Hidden?: boolean | null;
                                            address1Enabled?: boolean | null;
                                            address1ErrorMessage?: string | null;
                                            address1Required?: boolean | null;
                                            address1DefaultValue?: string | null;
                                            address1Placeholder?: string | null;
                                            zipLabel?: string | null;
                                            zipHidden?: boolean | null;
                                            zipEnabled?: boolean | null;
                                            zipRequired?: boolean | null;
                                            zipCollapsed?: boolean | null;
                                            zipPlaceholder?: string | null;
                                            zipDefaultValue?: string | null;
                                            cityLabel?: string | null;
                                            cityHidden?: boolean | null;
                                            cityEnabled?: boolean | null;
                                            cityRequired?: boolean | null;
                                            cityPlaceholder?: string | null;
                                            cityDefaultValue?: string | null;
                                            cityErrorMessage?: string | null;
                                            countryLabel?: string | null;
                                            countryHidden?: boolean | null;
                                            countryEnabled?: boolean | null;
                                            countryRequired?: boolean | null;
                                            countryPlaceholder?: string | null;
                                            countryDefaultValue?: string | null;
                                        } & { __typename?: 'Field_Address' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            checkedValue?: string | null;
                                            uncheckedValue?: string | null;
                                            descriptionHtml?: string | null;
                                            defaultState?: boolean | null;
                                        } & { __typename?: 'Field_Agree' | 'Field_Newsletter' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                        } & {
                                            __typename?:
                                                | 'Field_Categories'
                                                | 'Field_Entries'
                                                | 'Field_Group'
                                                | 'Field_Hidden'
                                                | 'Field_Html'
                                                | 'Field_MissingField'
                                                | 'Field_Recipients'
                                                | 'Field_Repeater'
                                                | 'Field_Section'
                                                | 'Field_Table'
                                                | 'Field_Tags'
                                                | 'Field_Users';
                                        })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            options?: Array<
                                                | ({ isDefault?: boolean | null; value?: string | null } & {
                                                      __typename?: 'KeyValueType';
                                                  })
                                                | null
                                            > | null;
                                        } & { __typename?: 'Field_Checkboxes' | 'Field_Radio' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            displayType?: string | null;
                                            defaultDate?: any | null;
                                            defaultOption?: string | null;
                                        } & { __typename?: 'Field_Date' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            options?: Array<
                                                | ({
                                                      label?: string | null;
                                                      isDefault?: boolean | null;
                                                      value?: string | null;
                                                  } & { __typename?: 'KeyValueType' })
                                                | null
                                            > | null;
                                        } & { __typename?: 'Field_Dropdown' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            limit?: boolean | null;
                                            limitType?: string | null;
                                            limitAmount?: number | null;
                                            defaultValue?: string | null;
                                        } & {
                                            __typename?:
                                                | 'Field_Email'
                                                | 'Field_MultiLineText'
                                                | 'Field_Phone'
                                                | 'Field_SingleLineText';
                                        })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            restrictFiles?: string | null;
                                            allowedKinds?: Array<string | null> | null;
                                            limitFiles?: string | null;
                                            limitAmount?: number | null;
                                            sizeLimit?: string | null;
                                            sizeMinLimit?: string | null;
                                        } & { __typename?: 'Field_FileUpload' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            headingSize?: string | null;
                                        } & { __typename?: 'Field_Heading' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            prefixLabel?: string | null;
                                            prefixEnabled?: boolean | null;
                                            prefixRequired?: boolean | null;
                                            firstNameLabel?: string | null;
                                            firstNameEnabled?: boolean | null;
                                            firstNamePlaceholder?: string | null;
                                            firstNameRequired?: boolean | null;
                                            middleNameLabel?: string | null;
                                            middleNameEnabled?: boolean | null;
                                            middleNamePlaceholder?: string | null;
                                            middleNameRequired?: boolean | null;
                                            lastNameLabel?: string | null;
                                            lastNameEnabled?: boolean | null;
                                            lastNamePlaceholder?: string | null;
                                            lastNameRequired?: boolean | null;
                                        } & { __typename?: 'Field_Name' })
                                      | ({
                                            instructions?: string | null;
                                            instructionsPosition?: string | null;
                                            name?: string | null;
                                            placeholder?: string | null;
                                            required?: boolean | null;
                                            handle?: string | null;
                                            errorMessage?: string | null;
                                            defaultValue?: string | null;
                                            min?: string | null;
                                            max?: string | null;
                                        } & { __typename?: 'Field_Number' })
                                      | null
                                  > | null;
                              } & { __typename?: 'Field_Group' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  headingSize?: string | null;
                              } & { __typename?: 'Field_Heading' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  prefixLabel?: string | null;
                                  prefixEnabled?: boolean | null;
                                  prefixRequired?: boolean | null;
                                  firstNameLabel?: string | null;
                                  firstNameEnabled?: boolean | null;
                                  firstNamePlaceholder?: string | null;
                                  firstNameRequired?: boolean | null;
                                  middleNameLabel?: string | null;
                                  middleNameEnabled?: boolean | null;
                                  middleNamePlaceholder?: string | null;
                                  middleNameRequired?: boolean | null;
                                  lastNameLabel?: string | null;
                                  lastNameEnabled?: boolean | null;
                                  lastNamePlaceholder?: string | null;
                                  lastNameRequired?: boolean | null;
                              } & { __typename?: 'Field_Name' })
                            | ({
                                  instructions?: string | null;
                                  instructionsPosition?: string | null;
                                  name?: string | null;
                                  placeholder?: string | null;
                                  required?: boolean | null;
                                  handle?: string | null;
                                  errorMessage?: string | null;
                                  defaultValue?: string | null;
                                  min?: string | null;
                                  max?: string | null;
                              } & { __typename?: 'Field_Number' })
                            | null
                        > | null;
                    } & { __typename?: 'Form' })
                  | null
              > | null;
          } & { __typename?: 'contentMatrix_sectionForms_BlockType' })
        | ({ header?: string | null } & { __typename?: 'contentMatrix_sectionHeader_BlockType' })
        | ({ relationType?: string | null } & { __typename?: 'contentMatrix_sectionHierarchicalTiles_BlockType' })
        | ({
              gallery?: Array<
                  | ({
                        assetSource?: string | null;
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        width?: number | null;
                        height?: number | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'contentMatrix_sectionImages_BlockType' })
        | ({
              pins?: Array<
                  | ({
                        latitude?: any | null;
                        longitude?: any | null;
                        symbol?: string | null;
                        label?: string | null;
                    } & { __typename?: 'pins_TableRow' })
                  | null
              > | null;
          } & { __typename?: 'contentMatrix_sectionMap_BlockType' })
        | ({ text?: string | null } & { __typename?: 'contentMatrix_sectionText_BlockType' })
        | ({
              tilesChildren?: boolean | null;
              tilesSiblings?: boolean | null;
              tilesEntries?: Array<
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'banners_banner_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'menu_menu_Entry'
                            | 'search_search_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
                        __typename?:
                            | 'agenda_agenda_Entry'
                            | 'companies_companies_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'thankYou_thankYou_Entry';
                    })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        postDate?: any | null;
                        settingsMetaDescription?: string | null;
                        heroImage?: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename?: 'articles_article_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        settingsMetaDescription?: string | null;
                        heroImage?: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename?: 'contact_contact_Entry' | 'homepage_homepage_Entry' | 'news_news_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        id?: string | null;
                        settingsMetaDescription?: string | null;
                        settingsNotSearchable?: boolean | null;
                        heroImage?: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename?: 'pages_page_Entry' })
                  | null
              > | null;
          } & { __typename?: 'contentMatrix_sectionTiles_BlockType' })
        | ({
              tip?: Array<
                  | {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'vouchers_voucher_Entry';
                    }
                  | ({
                        title?: string | null;
                        tipText?: string | null;
                        tipName?: string | null;
                        tipRole?: string | null;
                        tipAvatar?: Array<
                            | ({ height?: number | null; width?: number | null; thumbUrl?: string | null } & {
                                  __typename?: 'library_Asset';
                              })
                            | null
                        > | null;
                    } & { __typename?: 'tips_tip_Entry' })
                  | null
              > | null;
          } & { __typename?: 'contentMatrix_sectionTip_BlockType' })
        | ({
              travelbaseEntityType?: string | null;
              travelbaseSlugs?: Array<
                  ({ slug?: string | null } & { __typename?: 'travelbaseSlugs_TableRow' }) | null
              > | null;
          } & { __typename?: 'contentMatrix_sectionTravelbaseTiles_BlockType' })
        | ({ videoUrl?: string | null; autoplay?: boolean | null } & {
              __typename?: 'contentMatrix_sectionVideo_BlockType';
          })
        | ({ webcamUrl?: string | null } & { __typename?: 'contentMatrix_sectionWebcam_BlockType' })
        | null
    > | null;
    children?: Array<
        | ({ title?: string | null; uri?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'banners_banner_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'menu_menu_Entry'
                  | 'search_search_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
              __typename?:
                  | 'agenda_agenda_Entry'
                  | 'companies_companies_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'thankYou_thankYou_Entry';
          })
        | ({
              title?: string | null;
              uri?: string | null;
              postDate?: any | null;
              settingsMetaDescription?: string | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'articles_article_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              settingsMetaDescription?: string | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'contact_contact_Entry' | 'homepage_homepage_Entry' | 'news_news_Entry' })
        | ({
              title?: string | null;
              uri?: string | null;
              id?: string | null;
              settingsMetaDescription?: string | null;
              settingsNotSearchable?: boolean | null;
              heroImage?: Array<
                  | ({
                        focalPoint?: Array<number | null> | null;
                        title?: string | null;
                        extension?: string | null;
                        url?: string | null;
                        placeholderUrl?: string | null;
                        srcset?: string | null;
                        srcsetWebp?: string | null;
                    } & { __typename?: 'library_Asset' })
                  | null
              > | null;
          } & { __typename?: 'pages_page_Entry' })
        | null
    > | null;
    parent?:
        | ({
              id?: string | null;
              children?: Array<
                  | ({ title?: string | null; uri?: string | null } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'banners_banner_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'menu_menu_Entry'
                            | 'search_search_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | ({ title?: string | null; uri?: string | null; settingsMetaDescription?: string | null } & {
                        __typename?:
                            | 'agenda_agenda_Entry'
                            | 'companies_companies_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'thankYou_thankYou_Entry';
                    })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        postDate?: any | null;
                        settingsMetaDescription?: string | null;
                        heroImage?: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename?: 'articles_article_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        settingsMetaDescription?: string | null;
                        heroImage?: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename?: 'contact_contact_Entry' | 'homepage_homepage_Entry' | 'news_news_Entry' })
                  | ({
                        title?: string | null;
                        uri?: string | null;
                        id?: string | null;
                        settingsMetaDescription?: string | null;
                        settingsNotSearchable?: boolean | null;
                        heroImage?: Array<
                            | ({
                                  focalPoint?: Array<number | null> | null;
                                  title?: string | null;
                                  extension?: string | null;
                                  url?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                              } & { __typename?: 'library_Asset' })
                            | null
                        > | null;
                    } & { __typename?: 'pages_page_Entry' })
                  | null
              > | null;
          } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | null;
    settingsSearchTags?: Array<
        ({ title?: string | null } & { __typename?: 'assetTags_Tag' | 'searchTags_Tag' | 'synonyms_Tag' }) | null
    > | null;
    localized?: Array<
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | null
    > | null;
} & { __typename?: 'pages_page_Entry' };

export type PagePathsQueryVariables = Exact<{ [key: string]: never }>;

export type PagePathsQuery = {
    entries?: Array<
        | ({ uri?: string | null; slug?: string | null; language?: string | null } & {
              __typename:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | null
    > | null;
} & { __typename?: 'Query' };

export type SearchPageQueryVariables = Exact<{
    lang: Scalars['String'];
    q: Scalars['String'];
    types?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type SearchPageQuery = {
    globalSearch?:
        | ({
              totalHits?: number | null;
              typeCounts?: Array<
                  ({ count?: number | null; label?: string | null } & { __typename?: 'Bucket' }) | null
              > | null;
              hits?: Array<
                  | ({
                        postDate?: string | null;
                        title?: string | null;
                        type?: string | null;
                        uri?: string | null;
                        image?:
                            | ({
                                  extension?: string | null;
                                  placeholderUrl?: string | null;
                                  srcset?: string | null;
                                  srcsetWebp?: string | null;
                                  title?: string | null;
                                  url?: string | null;
                              } & { __typename?: 'SearchImage' })
                            | null;
                    } & { __typename?: 'SearchType' })
                  | null
              > | null;
          } & { __typename?: 'SearchResult' })
        | null;
} & { __typename?: 'Query' };

export type SearchPageAllAvailableFiltersQueryVariables = Exact<{
    lang: Scalars['String'];
    q: Scalars['String'];
}>;

export type SearchPageAllAvailableFiltersQuery = {
    globalSearch?:
        | ({
              totalHits?: number | null;
              typeCounts?: Array<
                  ({ count?: number | null; label?: string | null } & { __typename?: 'Bucket' }) | null
              > | null;
          } & { __typename?: 'SearchResult' })
        | null;
} & { __typename?: 'Query' };

export type SearchQueryVariables = Exact<{
    lang?: InputMaybe<Scalars['String']>;
}>;

export type SearchQuery = {
    entry?:
        | {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          }
        | ({
              title?: string | null;
              settingsCookieConsent?: boolean | null;
              settingsMetaDescription?: string | null;
              settingsSeoTitle?: string | null;
              localized?: Array<
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | null
              > | null;
          } & { __typename?: 'search_search_Entry' })
        | null;
} & { __typename?: 'Query' };

export type SearchFragment = {
    title?: string | null;
    settingsCookieConsent?: boolean | null;
    settingsMetaDescription?: string | null;
    settingsSeoTitle?: string | null;
    localized?: Array<
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | null
    > | null;
} & { __typename?: 'search_search_Entry' };

export type SearchHitFragment = {
    postDate?: string | null;
    title?: string | null;
    type?: string | null;
    uri?: string | null;
    image?:
        | ({
              extension?: string | null;
              placeholderUrl?: string | null;
              srcset?: string | null;
              srcsetWebp?: string | null;
              title?: string | null;
              url?: string | null;
          } & { __typename?: 'SearchImage' })
        | null;
} & { __typename?: 'SearchType' };

export type HeroTransformFragment = {
    focalPoint?: Array<number | null> | null;
    title?: string | null;
    extension?: string | null;
    url?: string | null;
    placeholderUrl?: string | null;
    ogImageUrl?: string | null;
    srcset?: string | null;
    srcsetWebp?: string | null;
    srcsetMobile?: string | null;
    srcsetMobileWebp?: string | null;
} & { __typename?: 'library_Asset' };

export type TileTransformFragment = {
    focalPoint?: Array<number | null> | null;
    title?: string | null;
    extension?: string | null;
    url?: string | null;
    placeholderUrl?: string | null;
    srcset?: string | null;
    srcsetWebp?: string | null;
} & { __typename?: 'library_Asset' };

export type GalleryTransformFragment = {
    focalPoint?: Array<number | null> | null;
    title?: string | null;
    extension?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    placeholderUrl?: string | null;
    srcset?: string | null;
    srcsetWebp?: string | null;
} & { __typename?: 'library_Asset' };

export type HighlightTransformFragment = {
    width?: number | null;
    height?: number | null;
    focalPoint?: Array<number | null> | null;
    title?: string | null;
    extension?: string | null;
    url?: string | null;
    srcset?: string | null;
    srcsetWebp?: string | null;
    placeholderUrl?: string | null;
} & { __typename?: 'library_Asset' };

export type AgendaQueryVariables = Exact<{
    lang?: InputMaybe<Scalars['String']>;
}>;

export type AgendaQuery = {
    entry?:
        | {
              __typename?:
                  | 'action_action_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          }
        | ({
              title?: string | null;
              settingsMetaDescription?: string | null;
              settingsSeoTitle?: string | null;
              localized?: Array<
                  | ({ url?: string | null; language?: string | null } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | null
              > | null;
          } & { __typename?: 'agenda_agenda_Entry' })
        | null;
} & { __typename?: 'Query' };

export type AgendaFragment = {
    title?: string | null;
    settingsMetaDescription?: string | null;
    settingsSeoTitle?: string | null;
    localized?: Array<
        | ({ url?: string | null; language?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | null
    > | null;
} & { __typename?: 'agenda_agenda_Entry' };

export type AccoFeedQueryVariables = Exact<{
    amount?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type AccoFeedQuery = {
    unitSearch: {
        totalHits: number;
        hits: Array<
            {
                rentalUnit: {
                    id: string;
                    name: string;
                    slug: string;
                    tagline: string;
                    descriptionLayout: string;
                    type: RentalUnitTypeEnum;
                    maxOccupancy: number;
                    livingArea?: number | null;
                    outdoorArea?: number | null;
                    images: Array<
                        { transform?: ({ src: string } & { __typename?: 'ImageTransform' }) | null } & {
                            __typename?: 'AccommodationImage';
                        }
                    >;
                    accommodation: { place: string } & { __typename?: 'Accommodation' };
                } & { __typename?: 'RentalUnit' };
                trips: Array<{ price: number } & { __typename?: 'Trip' }>;
            } & { __typename?: 'UnitSearchHit' }
        >;
    } & { __typename?: 'UnitSearchResult' };
} & { __typename?: 'Query' };

export type SitemapQueryVariables = Exact<{
    locale?: InputMaybe<Scalars['String']>;
    size?: InputMaybe<Scalars['Int']>;
}>;

export type SitemapQuery = {
    entries?: Array<
        | ({ url?: string | null; dateUpdated?: any | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | ({
              url?: string | null;
              dateUpdated?: any | null;
              heroImage?: Array<
                  ({ url?: string | null; title?: string | null } & { __typename?: 'library_Asset' }) | null
              > | null;
          } & { __typename?: 'news_news_Entry' | 'pages_page_Entry' })
        | null
    > | null;
    travelbaseObjectsSitemap?: Array<
        | ({
              url?: string | null;
              image?: ({ url?: string | null; title?: string | null } & { __typename?: 'SearchImage' }) | null;
          } & { __typename?: 'TravelbaseObjectsSitemapType' })
        | null
    > | null;
} & { __typename?: 'Query' };

export type SqzlyFeedQueryVariables = Exact<{
    amount?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type SqzlyFeedQuery = {
    unitSearch: {
        totalHits: number;
        hits: Array<
            {
                rentalUnit: {
                    code: string;
                    name: string;
                    slug: string;
                    type: RentalUnitTypeEnum;
                    listImage: { transform?: ({ src: string } & { __typename?: 'ImageTransform' }) | null } & {
                        __typename?: 'GenericImage';
                    };
                    accommodation: { name: string; id: string } & { __typename?: 'Accommodation' };
                } & { __typename?: 'RentalUnit' };
                trips: Array<{ price: number } & { __typename?: 'Trip' }>;
            } & { __typename?: 'UnitSearchHit' }
        >;
    } & { __typename?: 'UnitSearchResult' };
} & { __typename?: 'Query' };

export type GlobalQueryVariables = Exact<{
    lang?: InputMaybe<Scalars['String']>;
}>;

export type GlobalQuery = {
    menu?: Array<
        | {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          }
        | ({
              id?: string | null;
              title?: string | null;
              menuLink?:
                  | ({
                        customText?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Element'
                                      | 'Form'
                                      | 'User'
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'assetTags_Tag'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contactMatrix_contact_BlockType'
                                      | 'contact_GlobalSet'
                                      | 'contact_contact_Entry'
                                      | 'contentMatrix_sectionAccommodations_BlockType'
                                      | 'contentMatrix_sectionAccordion_BlockType'
                                      | 'contentMatrix_sectionAction_BlockType'
                                      | 'contentMatrix_sectionActivities_BlockType'
                                      | 'contentMatrix_sectionBanner_BlockType'
                                      | 'contentMatrix_sectionButton_BlockType'
                                      | 'contentMatrix_sectionCompanies_BlockType'
                                      | 'contentMatrix_sectionEmbed_BlockType';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'contentMatrix_sectionForms_BlockType'
                                      | 'contentMatrix_sectionHeader_BlockType'
                                      | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                      | 'contentMatrix_sectionImages_BlockType'
                                      | 'contentMatrix_sectionMap_BlockType'
                                      | 'contentMatrix_sectionText_BlockType'
                                      | 'contentMatrix_sectionTiles_BlockType'
                                      | 'contentMatrix_sectionTip_BlockType'
                                      | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                      | 'contentMatrix_sectionVideo_BlockType'
                                      | 'contentMatrix_sectionWebcam_BlockType'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'library_Asset'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'notification_GlobalSet'
                                      | 'searchTags_Tag';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'search_search_Entry'
                                      | 'synonyms_Tag'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'voucherLocations_location_BlockType'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  slug?: string | null;
                                  id?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'pages_page_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                                  children?: Array<
                                      | ({
                                            children?: Array<
                                                | ({
                                                      children?: Array<
                                                          | ({
                                                                children?: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_action_Entry'
                                                                                        | 'agenda_agenda_Entry'
                                                                                        | 'articles_article_Entry'
                                                                                        | 'banners_banner_Entry'
                                                                                        | 'companies_companies_Entry'
                                                                                        | 'contact_contact_Entry'
                                                                                        | 'footer_footerCategory_Entry'
                                                                                        | 'footer_footerLink_Entry'
                                                                                        | 'homepage_homepage_Entry'
                                                                                        | 'menu_menu_Entry'
                                                                                        | 'myTexel_myTexel_Entry'
                                                                                        | 'news_news_Entry'
                                                                                        | 'pages_page_Entry'
                                                                                        | 'search_search_Entry'
                                                                                        | 'thankYou_thankYou_Entry'
                                                                                        | 'tips_tip_Entry'
                                                                                        | 'vouchers_voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'pages_page_Entry' })
                                                                    | null
                                                                > | null;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children?: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_action_Entry'
                                                                                        | 'agenda_agenda_Entry'
                                                                                        | 'articles_article_Entry'
                                                                                        | 'banners_banner_Entry'
                                                                                        | 'companies_companies_Entry'
                                                                                        | 'contact_contact_Entry'
                                                                                        | 'footer_footerCategory_Entry'
                                                                                        | 'footer_footerLink_Entry'
                                                                                        | 'homepage_homepage_Entry'
                                                                                        | 'menu_menu_Entry'
                                                                                        | 'myTexel_myTexel_Entry'
                                                                                        | 'news_news_Entry'
                                                                                        | 'pages_page_Entry'
                                                                                        | 'search_search_Entry'
                                                                                        | 'thankYou_thankYou_Entry'
                                                                                        | 'tips_tip_Entry'
                                                                                        | 'vouchers_voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'pages_page_Entry' })
                                                                    | null
                                                                > | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'pages_page_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'pages_page_Entry' })
                                                          | null
                                                      > | null;
                                                  } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children?: Array<
                                                          | ({
                                                                children?: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_action_Entry'
                                                                                        | 'agenda_agenda_Entry'
                                                                                        | 'articles_article_Entry'
                                                                                        | 'banners_banner_Entry'
                                                                                        | 'companies_companies_Entry'
                                                                                        | 'contact_contact_Entry'
                                                                                        | 'footer_footerCategory_Entry'
                                                                                        | 'footer_footerLink_Entry'
                                                                                        | 'homepage_homepage_Entry'
                                                                                        | 'menu_menu_Entry'
                                                                                        | 'myTexel_myTexel_Entry'
                                                                                        | 'news_news_Entry'
                                                                                        | 'pages_page_Entry'
                                                                                        | 'search_search_Entry'
                                                                                        | 'thankYou_thankYou_Entry'
                                                                                        | 'tips_tip_Entry'
                                                                                        | 'vouchers_voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'pages_page_Entry' })
                                                                    | null
                                                                > | null;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children?: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_action_Entry'
                                                                                        | 'agenda_agenda_Entry'
                                                                                        | 'articles_article_Entry'
                                                                                        | 'banners_banner_Entry'
                                                                                        | 'companies_companies_Entry'
                                                                                        | 'contact_contact_Entry'
                                                                                        | 'footer_footerCategory_Entry'
                                                                                        | 'footer_footerLink_Entry'
                                                                                        | 'homepage_homepage_Entry'
                                                                                        | 'menu_menu_Entry'
                                                                                        | 'myTexel_myTexel_Entry'
                                                                                        | 'news_news_Entry'
                                                                                        | 'pages_page_Entry'
                                                                                        | 'search_search_Entry'
                                                                                        | 'thankYou_thankYou_Entry'
                                                                                        | 'tips_tip_Entry'
                                                                                        | 'vouchers_voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'pages_page_Entry' })
                                                                    | null
                                                                > | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'pages_page_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'pages_page_Entry' })
                                                          | null
                                                      > | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                        } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children?: Array<
                                                | ({
                                                      children?: Array<
                                                          | ({
                                                                children?: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_action_Entry'
                                                                                        | 'agenda_agenda_Entry'
                                                                                        | 'articles_article_Entry'
                                                                                        | 'banners_banner_Entry'
                                                                                        | 'companies_companies_Entry'
                                                                                        | 'contact_contact_Entry'
                                                                                        | 'footer_footerCategory_Entry'
                                                                                        | 'footer_footerLink_Entry'
                                                                                        | 'homepage_homepage_Entry'
                                                                                        | 'menu_menu_Entry'
                                                                                        | 'myTexel_myTexel_Entry'
                                                                                        | 'news_news_Entry'
                                                                                        | 'pages_page_Entry'
                                                                                        | 'search_search_Entry'
                                                                                        | 'thankYou_thankYou_Entry'
                                                                                        | 'tips_tip_Entry'
                                                                                        | 'vouchers_voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'pages_page_Entry' })
                                                                    | null
                                                                > | null;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children?: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_action_Entry'
                                                                                        | 'agenda_agenda_Entry'
                                                                                        | 'articles_article_Entry'
                                                                                        | 'banners_banner_Entry'
                                                                                        | 'companies_companies_Entry'
                                                                                        | 'contact_contact_Entry'
                                                                                        | 'footer_footerCategory_Entry'
                                                                                        | 'footer_footerLink_Entry'
                                                                                        | 'homepage_homepage_Entry'
                                                                                        | 'menu_menu_Entry'
                                                                                        | 'myTexel_myTexel_Entry'
                                                                                        | 'news_news_Entry'
                                                                                        | 'pages_page_Entry'
                                                                                        | 'search_search_Entry'
                                                                                        | 'thankYou_thankYou_Entry'
                                                                                        | 'tips_tip_Entry'
                                                                                        | 'vouchers_voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'pages_page_Entry' })
                                                                    | null
                                                                > | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'pages_page_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'pages_page_Entry' })
                                                          | null
                                                      > | null;
                                                  } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children?: Array<
                                                          | ({
                                                                children?: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_action_Entry'
                                                                                        | 'agenda_agenda_Entry'
                                                                                        | 'articles_article_Entry'
                                                                                        | 'banners_banner_Entry'
                                                                                        | 'companies_companies_Entry'
                                                                                        | 'contact_contact_Entry'
                                                                                        | 'footer_footerCategory_Entry'
                                                                                        | 'footer_footerLink_Entry'
                                                                                        | 'homepage_homepage_Entry'
                                                                                        | 'menu_menu_Entry'
                                                                                        | 'myTexel_myTexel_Entry'
                                                                                        | 'news_news_Entry'
                                                                                        | 'pages_page_Entry'
                                                                                        | 'search_search_Entry'
                                                                                        | 'thankYou_thankYou_Entry'
                                                                                        | 'tips_tip_Entry'
                                                                                        | 'vouchers_voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'pages_page_Entry' })
                                                                    | null
                                                                > | null;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children?: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_action_Entry'
                                                                                        | 'agenda_agenda_Entry'
                                                                                        | 'articles_article_Entry'
                                                                                        | 'banners_banner_Entry'
                                                                                        | 'companies_companies_Entry'
                                                                                        | 'contact_contact_Entry'
                                                                                        | 'footer_footerCategory_Entry'
                                                                                        | 'footer_footerLink_Entry'
                                                                                        | 'homepage_homepage_Entry'
                                                                                        | 'menu_menu_Entry'
                                                                                        | 'myTexel_myTexel_Entry'
                                                                                        | 'news_news_Entry'
                                                                                        | 'pages_page_Entry'
                                                                                        | 'search_search_Entry'
                                                                                        | 'thankYou_thankYou_Entry'
                                                                                        | 'tips_tip_Entry'
                                                                                        | 'vouchers_voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'pages_page_Entry' })
                                                                    | null
                                                                > | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'pages_page_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'pages_page_Entry' })
                                                          | null
                                                      > | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null;
                    } & { __typename?: 'linkField_Link' })
                  | null;
          } & { __typename?: 'menu_menu_Entry' })
        | null
    > | null;
    breadcrumb?: Array<
        | {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          }
        | ({
              id?: string | null;
              title?: string | null;
              slug?: string | null;
              uri?: string | null;
              settingsMenuHidden?: boolean | null;
              settingsMenuTitle?: string | null;
              parent?:
                  | ({ uri?: string | null; id?: string | null } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | null;
              children?: Array<
                  | ({
                        children?: Array<
                            | ({
                                  children?: Array<
                                      | ({
                                            children?: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                        } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children?: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                              } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children?: Array<
                                      | ({
                                            children?: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                        } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children?: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'pages_page_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null
                        > | null;
                    } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | ({
                        slug?: string | null;
                        id?: string | null;
                        title?: string | null;
                        uri?: string | null;
                        settingsMenuHidden?: boolean | null;
                        settingsMenuTitle?: string | null;
                        children?: Array<
                            | ({
                                  children?: Array<
                                      | ({
                                            children?: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                        } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children?: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                              } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children?: Array<
                                      | ({
                                            children?: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                        } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children?: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'pages_page_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null
                        > | null;
                        parent?:
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'pages_page_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'pages_page_Entry' })
                  | null
              > | null;
          } & { __typename?: 'pages_page_Entry' })
        | null
    > | null;
    footer?: Array<
        | {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          }
        | ({
              title?: string | null;
              children?: Array<
                  | {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    }
                  | ({
                        title?: string | null;
                        menuLink?:
                            | ({
                                  customText?: string | null;
                                  target?: string | null;
                                  url?: string | null;
                                  type?: string | null;
                                  element?:
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'Element'
                                                | 'Form'
                                                | 'User'
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'assetTags_Tag'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contactMatrix_contact_BlockType'
                                                | 'contact_GlobalSet'
                                                | 'contact_contact_Entry'
                                                | 'contentMatrix_sectionAccommodations_BlockType'
                                                | 'contentMatrix_sectionAccordion_BlockType'
                                                | 'contentMatrix_sectionAction_BlockType'
                                                | 'contentMatrix_sectionActivities_BlockType'
                                                | 'contentMatrix_sectionBanner_BlockType'
                                                | 'contentMatrix_sectionButton_BlockType'
                                                | 'contentMatrix_sectionCompanies_BlockType'
                                                | 'contentMatrix_sectionEmbed_BlockType';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'contentMatrix_sectionForms_BlockType'
                                                | 'contentMatrix_sectionHeader_BlockType'
                                                | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                                | 'contentMatrix_sectionImages_BlockType'
                                                | 'contentMatrix_sectionMap_BlockType'
                                                | 'contentMatrix_sectionText_BlockType'
                                                | 'contentMatrix_sectionTiles_BlockType'
                                                | 'contentMatrix_sectionTip_BlockType'
                                                | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                                | 'contentMatrix_sectionVideo_BlockType'
                                                | 'contentMatrix_sectionWebcam_BlockType'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'library_Asset'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'notification_GlobalSet'
                                                | 'pages_page_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'searchTags_Tag'
                                                | 'search_search_Entry'
                                                | 'synonyms_Tag'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'voucherLocations_location_BlockType'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'linkField_Link' })
                            | null;
                    } & { __typename: 'footer_footerLink_Entry' })
                  | null
              > | null;
          } & { __typename?: 'footer_footerCategory_Entry' })
        | null
    > | null;
    notification?:
        | { __typename?: 'contact_GlobalSet' }
        | ({
              header?: string | null;
              active?: boolean | null;
              enabled?: boolean | null;
              description?: string | null;
              button?:
                  | ({
                        text?: string | null;
                        customText?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Element'
                                      | 'Form'
                                      | 'User'
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'assetTags_Tag'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contactMatrix_contact_BlockType'
                                      | 'contact_GlobalSet'
                                      | 'contact_contact_Entry'
                                      | 'contentMatrix_sectionAccommodations_BlockType'
                                      | 'contentMatrix_sectionAccordion_BlockType'
                                      | 'contentMatrix_sectionAction_BlockType'
                                      | 'contentMatrix_sectionActivities_BlockType'
                                      | 'contentMatrix_sectionBanner_BlockType'
                                      | 'contentMatrix_sectionButton_BlockType'
                                      | 'contentMatrix_sectionCompanies_BlockType'
                                      | 'contentMatrix_sectionEmbed_BlockType';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'contentMatrix_sectionForms_BlockType'
                                      | 'contentMatrix_sectionHeader_BlockType'
                                      | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                      | 'contentMatrix_sectionImages_BlockType'
                                      | 'contentMatrix_sectionMap_BlockType'
                                      | 'contentMatrix_sectionText_BlockType'
                                      | 'contentMatrix_sectionTiles_BlockType'
                                      | 'contentMatrix_sectionTip_BlockType'
                                      | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                      | 'contentMatrix_sectionVideo_BlockType'
                                      | 'contentMatrix_sectionWebcam_BlockType'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'library_Asset'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'notification_GlobalSet'
                                      | 'pages_page_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'searchTags_Tag'
                                      | 'search_search_Entry'
                                      | 'synonyms_Tag'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'voucherLocations_location_BlockType'
                                      | 'vouchers_voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'linkField_Link' })
                  | null;
          } & { __typename?: 'notification_GlobalSet' })
        | null;
    search?:
        | ({ uri?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | null;
} & { __typename?: 'Query' };

export type GlobalQueryFragment = {
    menu?: Array<
        | {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          }
        | ({
              id?: string | null;
              title?: string | null;
              menuLink?:
                  | ({
                        customText?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Element'
                                      | 'Form'
                                      | 'User'
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'assetTags_Tag'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contactMatrix_contact_BlockType'
                                      | 'contact_GlobalSet'
                                      | 'contact_contact_Entry'
                                      | 'contentMatrix_sectionAccommodations_BlockType'
                                      | 'contentMatrix_sectionAccordion_BlockType'
                                      | 'contentMatrix_sectionAction_BlockType'
                                      | 'contentMatrix_sectionActivities_BlockType'
                                      | 'contentMatrix_sectionBanner_BlockType'
                                      | 'contentMatrix_sectionButton_BlockType'
                                      | 'contentMatrix_sectionCompanies_BlockType'
                                      | 'contentMatrix_sectionEmbed_BlockType';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'contentMatrix_sectionForms_BlockType'
                                      | 'contentMatrix_sectionHeader_BlockType'
                                      | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                      | 'contentMatrix_sectionImages_BlockType'
                                      | 'contentMatrix_sectionMap_BlockType'
                                      | 'contentMatrix_sectionText_BlockType'
                                      | 'contentMatrix_sectionTiles_BlockType'
                                      | 'contentMatrix_sectionTip_BlockType'
                                      | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                      | 'contentMatrix_sectionVideo_BlockType'
                                      | 'contentMatrix_sectionWebcam_BlockType'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'library_Asset'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'notification_GlobalSet'
                                      | 'searchTags_Tag';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'search_search_Entry'
                                      | 'synonyms_Tag'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'voucherLocations_location_BlockType'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  title?: string | null;
                                  uri?: string | null;
                                  slug?: string | null;
                                  id?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'pages_page_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                                  children?: Array<
                                      | ({
                                            children?: Array<
                                                | ({
                                                      children?: Array<
                                                          | ({
                                                                children?: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_action_Entry'
                                                                                        | 'agenda_agenda_Entry'
                                                                                        | 'articles_article_Entry'
                                                                                        | 'banners_banner_Entry'
                                                                                        | 'companies_companies_Entry'
                                                                                        | 'contact_contact_Entry'
                                                                                        | 'footer_footerCategory_Entry'
                                                                                        | 'footer_footerLink_Entry'
                                                                                        | 'homepage_homepage_Entry'
                                                                                        | 'menu_menu_Entry'
                                                                                        | 'myTexel_myTexel_Entry'
                                                                                        | 'news_news_Entry'
                                                                                        | 'pages_page_Entry'
                                                                                        | 'search_search_Entry'
                                                                                        | 'thankYou_thankYou_Entry'
                                                                                        | 'tips_tip_Entry'
                                                                                        | 'vouchers_voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'pages_page_Entry' })
                                                                    | null
                                                                > | null;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children?: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_action_Entry'
                                                                                        | 'agenda_agenda_Entry'
                                                                                        | 'articles_article_Entry'
                                                                                        | 'banners_banner_Entry'
                                                                                        | 'companies_companies_Entry'
                                                                                        | 'contact_contact_Entry'
                                                                                        | 'footer_footerCategory_Entry'
                                                                                        | 'footer_footerLink_Entry'
                                                                                        | 'homepage_homepage_Entry'
                                                                                        | 'menu_menu_Entry'
                                                                                        | 'myTexel_myTexel_Entry'
                                                                                        | 'news_news_Entry'
                                                                                        | 'pages_page_Entry'
                                                                                        | 'search_search_Entry'
                                                                                        | 'thankYou_thankYou_Entry'
                                                                                        | 'tips_tip_Entry'
                                                                                        | 'vouchers_voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'pages_page_Entry' })
                                                                    | null
                                                                > | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'pages_page_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'pages_page_Entry' })
                                                          | null
                                                      > | null;
                                                  } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children?: Array<
                                                          | ({
                                                                children?: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_action_Entry'
                                                                                        | 'agenda_agenda_Entry'
                                                                                        | 'articles_article_Entry'
                                                                                        | 'banners_banner_Entry'
                                                                                        | 'companies_companies_Entry'
                                                                                        | 'contact_contact_Entry'
                                                                                        | 'footer_footerCategory_Entry'
                                                                                        | 'footer_footerLink_Entry'
                                                                                        | 'homepage_homepage_Entry'
                                                                                        | 'menu_menu_Entry'
                                                                                        | 'myTexel_myTexel_Entry'
                                                                                        | 'news_news_Entry'
                                                                                        | 'pages_page_Entry'
                                                                                        | 'search_search_Entry'
                                                                                        | 'thankYou_thankYou_Entry'
                                                                                        | 'tips_tip_Entry'
                                                                                        | 'vouchers_voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'pages_page_Entry' })
                                                                    | null
                                                                > | null;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children?: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_action_Entry'
                                                                                        | 'agenda_agenda_Entry'
                                                                                        | 'articles_article_Entry'
                                                                                        | 'banners_banner_Entry'
                                                                                        | 'companies_companies_Entry'
                                                                                        | 'contact_contact_Entry'
                                                                                        | 'footer_footerCategory_Entry'
                                                                                        | 'footer_footerLink_Entry'
                                                                                        | 'homepage_homepage_Entry'
                                                                                        | 'menu_menu_Entry'
                                                                                        | 'myTexel_myTexel_Entry'
                                                                                        | 'news_news_Entry'
                                                                                        | 'pages_page_Entry'
                                                                                        | 'search_search_Entry'
                                                                                        | 'thankYou_thankYou_Entry'
                                                                                        | 'tips_tip_Entry'
                                                                                        | 'vouchers_voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'pages_page_Entry' })
                                                                    | null
                                                                > | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'pages_page_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'pages_page_Entry' })
                                                          | null
                                                      > | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                        } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children?: Array<
                                                | ({
                                                      children?: Array<
                                                          | ({
                                                                children?: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_action_Entry'
                                                                                        | 'agenda_agenda_Entry'
                                                                                        | 'articles_article_Entry'
                                                                                        | 'banners_banner_Entry'
                                                                                        | 'companies_companies_Entry'
                                                                                        | 'contact_contact_Entry'
                                                                                        | 'footer_footerCategory_Entry'
                                                                                        | 'footer_footerLink_Entry'
                                                                                        | 'homepage_homepage_Entry'
                                                                                        | 'menu_menu_Entry'
                                                                                        | 'myTexel_myTexel_Entry'
                                                                                        | 'news_news_Entry'
                                                                                        | 'pages_page_Entry'
                                                                                        | 'search_search_Entry'
                                                                                        | 'thankYou_thankYou_Entry'
                                                                                        | 'tips_tip_Entry'
                                                                                        | 'vouchers_voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'pages_page_Entry' })
                                                                    | null
                                                                > | null;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children?: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_action_Entry'
                                                                                        | 'agenda_agenda_Entry'
                                                                                        | 'articles_article_Entry'
                                                                                        | 'banners_banner_Entry'
                                                                                        | 'companies_companies_Entry'
                                                                                        | 'contact_contact_Entry'
                                                                                        | 'footer_footerCategory_Entry'
                                                                                        | 'footer_footerLink_Entry'
                                                                                        | 'homepage_homepage_Entry'
                                                                                        | 'menu_menu_Entry'
                                                                                        | 'myTexel_myTexel_Entry'
                                                                                        | 'news_news_Entry'
                                                                                        | 'pages_page_Entry'
                                                                                        | 'search_search_Entry'
                                                                                        | 'thankYou_thankYou_Entry'
                                                                                        | 'tips_tip_Entry'
                                                                                        | 'vouchers_voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'pages_page_Entry' })
                                                                    | null
                                                                > | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'pages_page_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'pages_page_Entry' })
                                                          | null
                                                      > | null;
                                                  } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      children?: Array<
                                                          | ({
                                                                children?: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_action_Entry'
                                                                                        | 'agenda_agenda_Entry'
                                                                                        | 'articles_article_Entry'
                                                                                        | 'banners_banner_Entry'
                                                                                        | 'companies_companies_Entry'
                                                                                        | 'contact_contact_Entry'
                                                                                        | 'footer_footerCategory_Entry'
                                                                                        | 'footer_footerLink_Entry'
                                                                                        | 'homepage_homepage_Entry'
                                                                                        | 'menu_menu_Entry'
                                                                                        | 'myTexel_myTexel_Entry'
                                                                                        | 'news_news_Entry'
                                                                                        | 'pages_page_Entry'
                                                                                        | 'search_search_Entry'
                                                                                        | 'thankYou_thankYou_Entry'
                                                                                        | 'tips_tip_Entry'
                                                                                        | 'vouchers_voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'pages_page_Entry' })
                                                                    | null
                                                                > | null;
                                                            } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | ({
                                                                slug?: string | null;
                                                                id?: string | null;
                                                                title?: string | null;
                                                                uri?: string | null;
                                                                settingsMenuHidden?: boolean | null;
                                                                settingsMenuTitle?: string | null;
                                                                children?: Array<
                                                                    | {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      }
                                                                    | ({
                                                                          slug?: string | null;
                                                                          id?: string | null;
                                                                          title?: string | null;
                                                                          uri?: string | null;
                                                                          settingsMenuHidden?: boolean | null;
                                                                          settingsMenuTitle?: string | null;
                                                                          parent?:
                                                                              | ({
                                                                                    uri?: string | null;
                                                                                    id?: string | null;
                                                                                } & {
                                                                                    __typename?:
                                                                                        | 'action_action_Entry'
                                                                                        | 'agenda_agenda_Entry'
                                                                                        | 'articles_article_Entry'
                                                                                        | 'banners_banner_Entry'
                                                                                        | 'companies_companies_Entry'
                                                                                        | 'contact_contact_Entry'
                                                                                        | 'footer_footerCategory_Entry'
                                                                                        | 'footer_footerLink_Entry'
                                                                                        | 'homepage_homepage_Entry'
                                                                                        | 'menu_menu_Entry'
                                                                                        | 'myTexel_myTexel_Entry'
                                                                                        | 'news_news_Entry'
                                                                                        | 'pages_page_Entry'
                                                                                        | 'search_search_Entry'
                                                                                        | 'thankYou_thankYou_Entry'
                                                                                        | 'tips_tip_Entry'
                                                                                        | 'vouchers_voucher_Entry';
                                                                                })
                                                                              | null;
                                                                      } & { __typename?: 'pages_page_Entry' })
                                                                    | null
                                                                > | null;
                                                                parent?:
                                                                    | ({ uri?: string | null; id?: string | null } & {
                                                                          __typename?:
                                                                              | 'action_action_Entry'
                                                                              | 'agenda_agenda_Entry'
                                                                              | 'articles_article_Entry'
                                                                              | 'banners_banner_Entry'
                                                                              | 'companies_companies_Entry'
                                                                              | 'contact_contact_Entry'
                                                                              | 'footer_footerCategory_Entry'
                                                                              | 'footer_footerLink_Entry'
                                                                              | 'homepage_homepage_Entry'
                                                                              | 'menu_menu_Entry'
                                                                              | 'myTexel_myTexel_Entry'
                                                                              | 'news_news_Entry'
                                                                              | 'pages_page_Entry'
                                                                              | 'search_search_Entry'
                                                                              | 'thankYou_thankYou_Entry'
                                                                              | 'tips_tip_Entry'
                                                                              | 'vouchers_voucher_Entry';
                                                                      })
                                                                    | null;
                                                            } & { __typename?: 'pages_page_Entry' })
                                                          | null
                                                      > | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null;
                    } & { __typename?: 'linkField_Link' })
                  | null;
          } & { __typename?: 'menu_menu_Entry' })
        | null
    > | null;
    breadcrumb?: Array<
        | {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          }
        | ({
              id?: string | null;
              title?: string | null;
              slug?: string | null;
              uri?: string | null;
              settingsMenuHidden?: boolean | null;
              settingsMenuTitle?: string | null;
              parent?:
                  | ({ uri?: string | null; id?: string | null } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | null;
              children?: Array<
                  | ({
                        children?: Array<
                            | ({
                                  children?: Array<
                                      | ({
                                            children?: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                        } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children?: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                              } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children?: Array<
                                      | ({
                                            children?: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                        } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children?: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'pages_page_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null
                        > | null;
                    } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | ({
                        slug?: string | null;
                        id?: string | null;
                        title?: string | null;
                        uri?: string | null;
                        settingsMenuHidden?: boolean | null;
                        settingsMenuTitle?: string | null;
                        children?: Array<
                            | ({
                                  children?: Array<
                                      | ({
                                            children?: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                        } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children?: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                              } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children?: Array<
                                      | ({
                                            children?: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                        } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            children?: Array<
                                                | {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  }
                                                | ({
                                                      slug?: string | null;
                                                      id?: string | null;
                                                      title?: string | null;
                                                      uri?: string | null;
                                                      settingsMenuHidden?: boolean | null;
                                                      settingsMenuTitle?: string | null;
                                                      parent?:
                                                          | ({ uri?: string | null; id?: string | null } & {
                                                                __typename?:
                                                                    | 'action_action_Entry'
                                                                    | 'agenda_agenda_Entry'
                                                                    | 'articles_article_Entry'
                                                                    | 'banners_banner_Entry'
                                                                    | 'companies_companies_Entry'
                                                                    | 'contact_contact_Entry'
                                                                    | 'footer_footerCategory_Entry'
                                                                    | 'footer_footerLink_Entry'
                                                                    | 'homepage_homepage_Entry'
                                                                    | 'menu_menu_Entry'
                                                                    | 'myTexel_myTexel_Entry'
                                                                    | 'news_news_Entry'
                                                                    | 'pages_page_Entry'
                                                                    | 'search_search_Entry'
                                                                    | 'thankYou_thankYou_Entry'
                                                                    | 'tips_tip_Entry'
                                                                    | 'vouchers_voucher_Entry';
                                                            })
                                                          | null;
                                                  } & { __typename?: 'pages_page_Entry' })
                                                | null
                                            > | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'pages_page_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null
                        > | null;
                        parent?:
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'pages_page_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'pages_page_Entry' })
                  | null
              > | null;
          } & { __typename?: 'pages_page_Entry' })
        | null
    > | null;
    footer?: Array<
        | {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          }
        | ({
              title?: string | null;
              children?: Array<
                  | {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    }
                  | ({
                        title?: string | null;
                        menuLink?:
                            | ({
                                  customText?: string | null;
                                  target?: string | null;
                                  url?: string | null;
                                  type?: string | null;
                                  element?:
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'Element'
                                                | 'Form'
                                                | 'User'
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'assetTags_Tag'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contactMatrix_contact_BlockType'
                                                | 'contact_GlobalSet'
                                                | 'contact_contact_Entry'
                                                | 'contentMatrix_sectionAccommodations_BlockType'
                                                | 'contentMatrix_sectionAccordion_BlockType'
                                                | 'contentMatrix_sectionAction_BlockType'
                                                | 'contentMatrix_sectionActivities_BlockType'
                                                | 'contentMatrix_sectionBanner_BlockType'
                                                | 'contentMatrix_sectionButton_BlockType'
                                                | 'contentMatrix_sectionCompanies_BlockType'
                                                | 'contentMatrix_sectionEmbed_BlockType';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'contentMatrix_sectionForms_BlockType'
                                                | 'contentMatrix_sectionHeader_BlockType'
                                                | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                                | 'contentMatrix_sectionImages_BlockType'
                                                | 'contentMatrix_sectionMap_BlockType'
                                                | 'contentMatrix_sectionText_BlockType'
                                                | 'contentMatrix_sectionTiles_BlockType'
                                                | 'contentMatrix_sectionTip_BlockType'
                                                | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                                | 'contentMatrix_sectionVideo_BlockType'
                                                | 'contentMatrix_sectionWebcam_BlockType'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'library_Asset'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'notification_GlobalSet'
                                                | 'pages_page_Entry';
                                        })
                                      | ({ title?: string | null; uri?: string | null } & {
                                            __typename?:
                                                | 'searchTags_Tag'
                                                | 'search_search_Entry'
                                                | 'synonyms_Tag'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'voucherLocations_location_BlockType'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'linkField_Link' })
                            | null;
                    } & { __typename: 'footer_footerLink_Entry' })
                  | null
              > | null;
          } & { __typename?: 'footer_footerCategory_Entry' })
        | null
    > | null;
    notification?:
        | { __typename?: 'contact_GlobalSet' }
        | ({
              header?: string | null;
              active?: boolean | null;
              enabled?: boolean | null;
              description?: string | null;
              button?:
                  | ({
                        text?: string | null;
                        customText?: string | null;
                        target?: string | null;
                        url?: string | null;
                        type?: string | null;
                        element?:
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'Element'
                                      | 'Form'
                                      | 'User'
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'assetTags_Tag'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contactMatrix_contact_BlockType'
                                      | 'contact_GlobalSet'
                                      | 'contact_contact_Entry'
                                      | 'contentMatrix_sectionAccommodations_BlockType'
                                      | 'contentMatrix_sectionAccordion_BlockType'
                                      | 'contentMatrix_sectionAction_BlockType'
                                      | 'contentMatrix_sectionActivities_BlockType'
                                      | 'contentMatrix_sectionBanner_BlockType'
                                      | 'contentMatrix_sectionButton_BlockType'
                                      | 'contentMatrix_sectionCompanies_BlockType'
                                      | 'contentMatrix_sectionEmbed_BlockType';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'contentMatrix_sectionForms_BlockType'
                                      | 'contentMatrix_sectionHeader_BlockType'
                                      | 'contentMatrix_sectionHierarchicalTiles_BlockType'
                                      | 'contentMatrix_sectionImages_BlockType'
                                      | 'contentMatrix_sectionMap_BlockType'
                                      | 'contentMatrix_sectionText_BlockType'
                                      | 'contentMatrix_sectionTiles_BlockType'
                                      | 'contentMatrix_sectionTip_BlockType'
                                      | 'contentMatrix_sectionTravelbaseTiles_BlockType'
                                      | 'contentMatrix_sectionVideo_BlockType'
                                      | 'contentMatrix_sectionWebcam_BlockType'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'library_Asset'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'notification_GlobalSet'
                                      | 'pages_page_Entry';
                              })
                            | ({ title?: string | null; uri?: string | null } & {
                                  __typename?:
                                      | 'searchTags_Tag'
                                      | 'search_search_Entry'
                                      | 'synonyms_Tag'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'voucherLocations_location_BlockType'
                                      | 'vouchers_voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'linkField_Link' })
                  | null;
          } & { __typename?: 'notification_GlobalSet' })
        | null;
    search?:
        | ({ uri?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | null;
} & { __typename?: 'Query' };

export type BreadcrumbFragment = {
    id?: string | null;
    title?: string | null;
    slug?: string | null;
    uri?: string | null;
    settingsMenuHidden?: boolean | null;
    settingsMenuTitle?: string | null;
    parent?:
        | ({ uri?: string | null; id?: string | null } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'pages_page_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | null;
    children?: Array<
        | ({
              children?: Array<
                  | ({
                        children?: Array<
                            | ({
                                  children?: Array<
                                      | {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                              } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children?: Array<
                                      | {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'pages_page_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null
                        > | null;
                    } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | ({
                        slug?: string | null;
                        id?: string | null;
                        title?: string | null;
                        uri?: string | null;
                        settingsMenuHidden?: boolean | null;
                        settingsMenuTitle?: string | null;
                        children?: Array<
                            | ({
                                  children?: Array<
                                      | {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                              } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children?: Array<
                                      | {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'pages_page_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null
                        > | null;
                        parent?:
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'pages_page_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'pages_page_Entry' })
                  | null
              > | null;
          } & {
              __typename?:
                  | 'action_action_Entry'
                  | 'agenda_agenda_Entry'
                  | 'articles_article_Entry'
                  | 'banners_banner_Entry'
                  | 'companies_companies_Entry'
                  | 'contact_contact_Entry'
                  | 'footer_footerCategory_Entry'
                  | 'footer_footerLink_Entry'
                  | 'homepage_homepage_Entry'
                  | 'menu_menu_Entry'
                  | 'myTexel_myTexel_Entry'
                  | 'news_news_Entry'
                  | 'search_search_Entry'
                  | 'thankYou_thankYou_Entry'
                  | 'tips_tip_Entry'
                  | 'vouchers_voucher_Entry';
          })
        | ({
              slug?: string | null;
              id?: string | null;
              title?: string | null;
              uri?: string | null;
              settingsMenuHidden?: boolean | null;
              settingsMenuTitle?: string | null;
              children?: Array<
                  | ({
                        children?: Array<
                            | ({
                                  children?: Array<
                                      | {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                              } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children?: Array<
                                      | {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'pages_page_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null
                        > | null;
                    } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | ({
                        slug?: string | null;
                        id?: string | null;
                        title?: string | null;
                        uri?: string | null;
                        settingsMenuHidden?: boolean | null;
                        settingsMenuTitle?: string | null;
                        children?: Array<
                            | ({
                                  children?: Array<
                                      | {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                              } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | ({
                                  slug?: string | null;
                                  id?: string | null;
                                  title?: string | null;
                                  uri?: string | null;
                                  settingsMenuHidden?: boolean | null;
                                  settingsMenuTitle?: string | null;
                                  children?: Array<
                                      | {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        }
                                      | ({
                                            slug?: string | null;
                                            id?: string | null;
                                            title?: string | null;
                                            uri?: string | null;
                                            settingsMenuHidden?: boolean | null;
                                            settingsMenuTitle?: string | null;
                                            parent?:
                                                | ({ uri?: string | null; id?: string | null } & {
                                                      __typename?:
                                                          | 'action_action_Entry'
                                                          | 'agenda_agenda_Entry'
                                                          | 'articles_article_Entry'
                                                          | 'banners_banner_Entry'
                                                          | 'companies_companies_Entry'
                                                          | 'contact_contact_Entry'
                                                          | 'footer_footerCategory_Entry'
                                                          | 'footer_footerLink_Entry'
                                                          | 'homepage_homepage_Entry'
                                                          | 'menu_menu_Entry'
                                                          | 'myTexel_myTexel_Entry'
                                                          | 'news_news_Entry'
                                                          | 'pages_page_Entry'
                                                          | 'search_search_Entry'
                                                          | 'thankYou_thankYou_Entry'
                                                          | 'tips_tip_Entry'
                                                          | 'vouchers_voucher_Entry';
                                                  })
                                                | null;
                                        } & { __typename?: 'pages_page_Entry' })
                                      | null
                                  > | null;
                                  parent?:
                                      | ({ uri?: string | null; id?: string | null } & {
                                            __typename?:
                                                | 'action_action_Entry'
                                                | 'agenda_agenda_Entry'
                                                | 'articles_article_Entry'
                                                | 'banners_banner_Entry'
                                                | 'companies_companies_Entry'
                                                | 'contact_contact_Entry'
                                                | 'footer_footerCategory_Entry'
                                                | 'footer_footerLink_Entry'
                                                | 'homepage_homepage_Entry'
                                                | 'menu_menu_Entry'
                                                | 'myTexel_myTexel_Entry'
                                                | 'news_news_Entry'
                                                | 'pages_page_Entry'
                                                | 'search_search_Entry'
                                                | 'thankYou_thankYou_Entry'
                                                | 'tips_tip_Entry'
                                                | 'vouchers_voucher_Entry';
                                        })
                                      | null;
                              } & { __typename?: 'pages_page_Entry' })
                            | null
                        > | null;
                        parent?:
                            | ({ uri?: string | null; id?: string | null } & {
                                  __typename?:
                                      | 'action_action_Entry'
                                      | 'agenda_agenda_Entry'
                                      | 'articles_article_Entry'
                                      | 'banners_banner_Entry'
                                      | 'companies_companies_Entry'
                                      | 'contact_contact_Entry'
                                      | 'footer_footerCategory_Entry'
                                      | 'footer_footerLink_Entry'
                                      | 'homepage_homepage_Entry'
                                      | 'menu_menu_Entry'
                                      | 'myTexel_myTexel_Entry'
                                      | 'news_news_Entry'
                                      | 'pages_page_Entry'
                                      | 'search_search_Entry'
                                      | 'thankYou_thankYou_Entry'
                                      | 'tips_tip_Entry'
                                      | 'vouchers_voucher_Entry';
                              })
                            | null;
                    } & { __typename?: 'pages_page_Entry' })
                  | null
              > | null;
              parent?:
                  | ({ uri?: string | null; id?: string | null } & {
                        __typename?:
                            | 'action_action_Entry'
                            | 'agenda_agenda_Entry'
                            | 'articles_article_Entry'
                            | 'banners_banner_Entry'
                            | 'companies_companies_Entry'
                            | 'contact_contact_Entry'
                            | 'footer_footerCategory_Entry'
                            | 'footer_footerLink_Entry'
                            | 'homepage_homepage_Entry'
                            | 'menu_menu_Entry'
                            | 'myTexel_myTexel_Entry'
                            | 'news_news_Entry'
                            | 'pages_page_Entry'
                            | 'search_search_Entry'
                            | 'thankYou_thankYou_Entry'
                            | 'tips_tip_Entry'
                            | 'vouchers_voucher_Entry';
                    })
                  | null;
          } & { __typename?: 'pages_page_Entry' })
        | null
    > | null;
} & { __typename?: 'pages_page_Entry' };

export const ListImageFragmentDoc = gql`
    fragment ListImage on ImageTransform {
        placeholder
        srcSet
        src
    }
`;
export const RentalUnitFragmentDoc = gql`
    fragment rentalUnit on RentalUnit {
        id
        name
        slug
        type
        amountBedrooms
        maxOccupancy
        tagline
        accommodation {
            name
            hasPublicPage
            place
        }
        reviewStats {
            average
        }
        webpListImage: listImage {
            transform(config: TILE, format: WEBP) {
                ...ListImage
            }
        }
        listImage {
            transform(config: TILE, format: JPEG) {
                ...ListImage
            }
        }
    }
    ${ListImageFragmentDoc}
`;
export const TransformFragmentDoc = gql`
    fragment Transform on ImageTransform {
        placeholder
        srcSet
        src
        ratio
    }
`;
export const OrderItemFragmentDoc = gql`
    fragment OrderItem on OrderItem {
        ... on Booking {
            downloadVoucherUrl
            status
            requiresApproval
            amountAdults
            amountPets
            amountChildren
            amountBabies
            amountYouths
            arrivalDate
            departureDate
            handleDepositPayment
            deposit
            paymentPrice
            rentalUnit {
                id
                code
                name
                slug
                accommodation {
                    id
                    hasPublicPage
                    name
                    place
                    checkInEndTime
                    checkInStartTime
                    checkOutTime
                    type
                }
                type
                maxOccupancy
                amountBedrooms
                jpegThumbnail: listImage {
                    transform(config: TILE, format: JPEG) {
                        ...Transform
                    }
                }
                webpThumbnail: listImage {
                    transform(config: TILE, format: WEBP) {
                        ...Transform
                    }
                }
            }
        }
    }
    ${TransformFragmentDoc}
`;
export const MyOrderFragmentDoc = gql`
    fragment MyOrder on Order {
        id
        number
        createdDate
        paymentOptions {
            fullPaymentDueDate
            totalAmountDue
        }
        orderItems {
            ...OrderItem
        }
    }
    ${OrderItemFragmentDoc}
`;
export const RentalUnitsBySlugFragmentDoc = gql`
    fragment RentalUnitsBySlug on RentalUnit {
        id
        name
        slug
        tagline
        webpListImage: listImage {
            transform(config: TILE, format: WEBP) {
                ...ListImage
            }
        }
        listImage {
            transform(config: TILE, format: JPEG) {
                ...ListImage
            }
        }
    }
    ${ListImageFragmentDoc}
`;
export const AccommodationsBySlugFragmentDoc = gql`
    fragment AccommodationsBySlug on Accommodation {
        id
        name
        slug
        descriptionGeneral
        webpListImage: mainImage {
            transform(config: TILE, format: WEBP) {
                ...ListImage
            }
        }
        mainImage {
            transform(config: TILE, format: JPEG) {
                ...ListImage
            }
        }
    }
    ${ListImageFragmentDoc}
`;
export const HeroTransformFragmentDoc = gql`
    fragment HeroTransform on AssetInterface {
        focalPoint
        title
        placeholderUrl: url @transform(width: 48, height: 15, mode: "crop", immediately: false)
        extension
        url
        ogImageUrl: url @transform(width: 1200, immediately: false)
        srcset: url
            @transformsrcset(
                sizes: ["1024w", "1920w", "2560w"]
                width: 1920
                height: 640
                mode: "crop"
                immediately: false
                quality: 70
            )
        srcsetWebp: url
            @transformsrcset(
                sizes: ["1024w", "1920w", "2560w"]
                width: 1920
                height: 640
                mode: "crop"
                immediately: false
            )
        srcsetMobile: url
            @transformsrcset(sizes: ["320w", "640w"], width: 640, height: 480, mode: "crop", immediately: false)
        srcsetMobileWebp: url
            @transformsrcset(
                sizes: ["320w", "640w"]
                width: 640
                height: 480
                mode: "crop"
                format: "webp"
                immediately: false
                quality: 70
            )
    }
`;
export const GalleryTransformFragmentDoc = gql`
    fragment GalleryTransform on AssetInterface {
        focalPoint
        title
        placeholderUrl: url @transform(width: 48, height: 27, mode: "crop", immediately: false)
        extension
        url
        width
        height
        srcset: url
            @transformsrcset(
                sizes: ["320w", "640w", "1024w", "1920w", "2560w"]
                width: 1920
                immediately: false
                quality: 70
            )
        srcsetWebp: url
            @transformsrcset(
                sizes: ["320w", "640w", "1024w", "1920w", "2560w"]
                width: 1920
                format: "webp"
                immediately: false
                quality: 70
            )
    }
`;
export const PhotosCarouselGalleryFragmentDoc = gql`
    fragment PhotosCarouselGallery on AssetInterface {
        ... on library_Asset {
            assetSource
        }
        ...GalleryTransform
    }
    ${GalleryTransformFragmentDoc}
`;
export const ArticleFragmentDoc = gql`
    fragment Article on articles_article_Entry {
        newsHeroImage {
            ...HeroTransform
        }
        postDate @formatDateTime(format: "j F Y", locale: $lang)
        title
        textBody
        gallery: newsImages {
            ...PhotosCarouselGallery
        }
        settingsCookieConsent
        settingsMetaDescription
        settingsSeoTitle
        id
        parent {
            id
        }
        settingsSearchTags {
            title
        }
        localized {
            url
            language
        }
    }
    ${HeroTransformFragmentDoc}
    ${PhotosCarouselGalleryFragmentDoc}
`;
export const LinkFieldFragmentDoc = gql`
    fragment LinkField on linkField_Link {
        customText
        target
        url
        type
        element {
            title
            uri
        }
    }
`;
export const ContactMatrixFragmentDoc = gql`
    fragment ContactMatrix on contactMatrix_contact_BlockType {
        whatsapp
        phone
        email
        messenger
        myTexel
        shop
    }
`;
export const PinsFragmentDoc = gql`
    fragment Pins on pins_TableRow {
        latitude
        longitude
        symbol
        label
    }
`;
export const TipFragmentDoc = gql`
    fragment Tip on tips_tip_Entry {
        title
        tipText
        tipName
        tipRole
        tipAvatar {
            height
            width
            thumbUrl: url @transform(width: 56, height: 56, immediately: true)
        }
    }
`;
export const BannerFragmentDoc = gql`
    fragment Banner on banners_banner_Entry {
        title
        heroImage {
            thumbUrl: url @transform(width: 328, height: 340, immediately: true)
        }
        button {
            ...LinkField
        }
    }
    ${LinkFieldFragmentDoc}
`;
export const TileTransformFragmentDoc = gql`
    fragment TileTransform on AssetInterface {
        focalPoint
        title
        placeholderUrl: url @transform(width: 64, height: 45, mode: "crop", immediately: false)
        extension
        url
        srcset: url
            @transformsrcset(
                sizes: ["64w", "320w", "640w"]
                width: 1920
                height: 1080
                mode: "crop"
                immediately: false
                quality: 70
            )
        srcsetWebp: url
            @transformsrcset(
                sizes: ["64w", "320w", "640w"]
                width: 1920
                height: 1080
                mode: "crop"
                format: "webp"
                immediately: false
                quality: 70
            )
    }
`;
export const CardImageFragmentDoc = gql`
    fragment CardImage on AssetInterface {
        ...TileTransform
    }
    ${TileTransformFragmentDoc}
`;
export const PageCardFragmentDoc = gql`
    fragment PageCard on pages_page_Entry {
        id
        settingsMetaDescription
        settingsNotSearchable
        heroImage {
            ...CardImage
        }
    }
    ${CardImageFragmentDoc}
`;
export const NewsCardFragmentDoc = gql`
    fragment NewsCard on news_news_Entry {
        settingsMetaDescription
        heroImage {
            ...CardImage
        }
    }
    ${CardImageFragmentDoc}
`;
export const ArticleCardFragmentDoc = gql`
    fragment ArticleCard on articles_article_Entry {
        postDate @formatDateTime(format: "j F Y", locale: $lang)
        settingsMetaDescription
        heroImage: newsHeroImage {
            ...CardImage
        }
    }
    ${CardImageFragmentDoc}
`;
export const HomeCardFragmentDoc = gql`
    fragment HomeCard on homepage_homepage_Entry {
        settingsMetaDescription
        heroImage {
            ...CardImage
        }
    }
    ${CardImageFragmentDoc}
`;
export const ContactCardFragmentDoc = gql`
    fragment ContactCard on contact_contact_Entry {
        settingsMetaDescription
        heroImage {
            ...CardImage
        }
    }
    ${CardImageFragmentDoc}
`;
export const AgendaCardFragmentDoc = gql`
    fragment AgendaCard on agenda_agenda_Entry {
        settingsMetaDescription
    }
`;
export const ThankYouCardFragmentDoc = gql`
    fragment ThankYouCard on thankYou_thankYou_Entry {
        settingsMetaDescription
    }
`;
export const CompanyCardFragmentDoc = gql`
    fragment CompanyCard on companies_companies_Entry {
        settingsMetaDescription
    }
`;
export const MyTexelCardFragmentDoc = gql`
    fragment MyTexelCard on myTexel_myTexel_Entry {
        settingsMetaDescription
    }
`;
export const CardFragmentDoc = gql`
    fragment Card on EntryInterface {
        title
        uri
        ...PageCard
        ...NewsCard
        ...ArticleCard
        ...HomeCard
        ...ContactCard
        ...AgendaCard
        ...ThankYouCard
        ...CompanyCard
        ...MyTexelCard
    }
    ${PageCardFragmentDoc}
    ${NewsCardFragmentDoc}
    ${ArticleCardFragmentDoc}
    ${HomeCardFragmentDoc}
    ${ContactCardFragmentDoc}
    ${AgendaCardFragmentDoc}
    ${ThankYouCardFragmentDoc}
    ${CompanyCardFragmentDoc}
    ${MyTexelCardFragmentDoc}
`;
export const TilesBlockFragmentDoc = gql`
    fragment TilesBlock on contentMatrix_sectionTiles_BlockType {
        tilesChildren
        tilesSiblings
        tilesEntries {
            ...Card
        }
    }
    ${CardFragmentDoc}
`;
export const HierarchicalTilesBlockFragmentDoc = gql`
    fragment HierarchicalTilesBlock on contentMatrix_sectionHierarchicalTiles_BlockType {
        relationType
    }
`;
export const FieldFragmentDoc = gql`
    fragment Field on FieldInterface {
        instructions
        instructionsPosition
        name
        placeholder
        required
        handle
        errorMessage
    }
`;
export const SingleTextFieldFragmentDoc = gql`
    fragment SingleTextField on Field_SingleLineText {
        limit
        limitType
        limitAmount
        defaultValue
    }
`;
export const MultipleTextFieldFragmentDoc = gql`
    fragment MultipleTextField on Field_MultiLineText {
        limit
        limitType
        limitAmount
        defaultValue
    }
`;
export const EmailTextFieldFragmentDoc = gql`
    fragment EmailTextField on Field_Email {
        limit
        limitType
        limitAmount
        defaultValue
    }
`;
export const PhoneTextFieldFragmentDoc = gql`
    fragment PhoneTextField on Field_Phone {
        limit
        limitType
        limitAmount
        defaultValue
    }
`;
export const TextFieldFragmentDoc = gql`
    fragment TextField on FieldInterface {
        ...SingleTextField
        ...MultipleTextField
        ...EmailTextField
        ...PhoneTextField
    }
    ${SingleTextFieldFragmentDoc}
    ${MultipleTextFieldFragmentDoc}
    ${EmailTextFieldFragmentDoc}
    ${PhoneTextFieldFragmentDoc}
`;
export const NumberFieldFragmentDoc = gql`
    fragment NumberField on Field_Number {
        defaultValue
        min
        max
    }
`;
export const CheckboxesFieldFragmentDoc = gql`
    fragment CheckboxesField on Field_Checkboxes {
        options {
            isDefault
            value
        }
    }
`;
export const RadioFieldFragmentDoc = gql`
    fragment RadioField on Field_Radio {
        options {
            isDefault
            value
        }
    }
`;
export const DropdownFieldFragmentDoc = gql`
    fragment DropdownField on Field_Dropdown {
        options {
            label
            isDefault
            value
        }
    }
`;
export const DateFieldFragmentDoc = gql`
    fragment DateField on Field_Date {
        displayType
        defaultDate
        defaultOption
    }
`;
export const FileFieldFragmentDoc = gql`
    fragment FileField on Field_FileUpload {
        restrictFiles
        allowedKinds
        limitFiles
        limitAmount
        sizeLimit
        sizeMinLimit
    }
`;
export const HeadingFieldFragmentDoc = gql`
    fragment HeadingField on Field_Heading {
        headingSize
    }
`;
export const AddressFieldFragmentDoc = gql`
    fragment AddressField on Field_Address {
        address1Label
        address1Hidden
        address1Enabled
        address1ErrorMessage
        address1Required
        address1DefaultValue
        address1Placeholder
        zipLabel
        zipHidden
        zipEnabled
        zipRequired
        zipCollapsed
        zipPlaceholder
        zipDefaultValue
        cityLabel
        cityHidden
        cityEnabled
        cityRequired
        cityPlaceholder
        cityDefaultValue
        cityErrorMessage
        countryLabel
        countryHidden
        countryEnabled
        countryRequired
        countryPlaceholder
        countryDefaultValue
        countryDefaultValue
    }
`;
export const BaseAgreeFieldFragmentDoc = gql`
    fragment BaseAgreeField on Field_Agree {
        checkedValue
        uncheckedValue
        descriptionHtml
        defaultState
        required
    }
`;
export const NewsletterAgreeFieldFragmentDoc = gql`
    fragment NewsletterAgreeField on Field_Newsletter {
        checkedValue
        uncheckedValue
        descriptionHtml
        defaultState
        required
    }
`;
export const AgreeFieldFragmentDoc = gql`
    fragment AgreeField on FieldInterface {
        ...BaseAgreeField
        ...NewsletterAgreeField
    }
    ${BaseAgreeFieldFragmentDoc}
    ${NewsletterAgreeFieldFragmentDoc}
`;
export const NameFieldFragmentDoc = gql`
    fragment NameField on Field_Name {
        name
        prefixLabel
        prefixEnabled
        prefixRequired
        firstNameLabel
        firstNameEnabled
        firstNamePlaceholder
        firstNameRequired
        middleNameLabel
        middleNameEnabled
        middleNamePlaceholder
        middleNameRequired
        lastNameLabel
        lastNameEnabled
        lastNamePlaceholder
        lastNameRequired
    }
`;
export const FieldRendererFragmentDoc = gql`
    fragment FieldRenderer on FieldInterface {
        ...Field
        ...TextField
        ...NumberField
        ...CheckboxesField
        ...RadioField
        ...DropdownField
        ...DateField
        ...FileField
        ...HeadingField
        ...AddressField
        ...AgreeField
        ...NameField
    }
    ${FieldFragmentDoc}
    ${TextFieldFragmentDoc}
    ${NumberFieldFragmentDoc}
    ${CheckboxesFieldFragmentDoc}
    ${RadioFieldFragmentDoc}
    ${DropdownFieldFragmentDoc}
    ${DateFieldFragmentDoc}
    ${FileFieldFragmentDoc}
    ${HeadingFieldFragmentDoc}
    ${AddressFieldFragmentDoc}
    ${AgreeFieldFragmentDoc}
    ${NameFieldFragmentDoc}
`;
export const GroupFieldFragmentDoc = gql`
    fragment GroupField on Field_Group {
        fields {
            ...FieldRenderer
        }
    }
    ${FieldRendererFragmentDoc}
`;
export const FormFragmentDoc = gql`
    fragment Form on FormInterface {
        id
        handle
        siteId
        settings {
            submitActionFormHide
            submitActionMessageHtml
            submitAction
            submitActionTab
            submitActionUrl
            integrations {
                handle
                enabled
            }
        }
        fields {
            ...FieldRenderer
            ...GroupField
        }
    }
    ${FieldRendererFragmentDoc}
    ${GroupFieldFragmentDoc}
`;
export const TravelbaseTileFragmentDoc = gql`
    fragment TravelbaseTile on contentMatrix_sectionTravelbaseTiles_BlockType {
        travelbaseSlugs {
            slug
        }
        travelbaseEntityType
    }
`;
export const ContentMatrixFragmentDoc = gql`
    fragment ContentMatrix on contentMatrix_MatrixField {
        ... on contentMatrix_sectionText_BlockType {
            text
        }
        ... on contentMatrix_sectionHeader_BlockType {
            header
        }
        ... on contentMatrix_sectionVideo_BlockType {
            videoUrl
            autoplay
        }
        ... on contentMatrix_sectionImages_BlockType {
            gallery: images {
                ...PhotosCarouselGallery
            }
        }
        ... on contentMatrix_sectionAccordion_BlockType {
            header
            text
        }
        ... on contentMatrix_sectionButton_BlockType {
            button {
                ...LinkField
            }
        }
        ... on contentMatrix_sectionWebcam_BlockType {
            webcamUrl
        }
        ... on contentMatrix_sectionMap_BlockType {
            pins {
                ...Pins
            }
        }
        ... on contentMatrix_sectionAccommodations_BlockType {
            accommodationsQuery
        }
        ... on contentMatrix_sectionCompanies_BlockType {
            companiesQuery
        }
        ... on contentMatrix_sectionActivities_BlockType {
            activitiesQuery
        }
        ... on contentMatrix_sectionTip_BlockType {
            tip {
                ...Tip
            }
        }
        ... on contentMatrix_sectionBanner_BlockType {
            banner {
                ...Banner
            }
        }
        ... on contentMatrix_sectionTiles_BlockType {
            ...TilesBlock
        }
        ... on contentMatrix_sectionHierarchicalTiles_BlockType {
            ...HierarchicalTilesBlock
        }
        ... on contentMatrix_sectionEmbed_BlockType {
            embed
        }
        ... on contentMatrix_sectionForms_BlockType {
            form {
                ...Form
            }
        }
        ... on contentMatrix_sectionTravelbaseTiles_BlockType {
            ...TravelbaseTile
        }
    }
    ${PhotosCarouselGalleryFragmentDoc}
    ${LinkFieldFragmentDoc}
    ${PinsFragmentDoc}
    ${TipFragmentDoc}
    ${BannerFragmentDoc}
    ${TilesBlockFragmentDoc}
    ${HierarchicalTilesBlockFragmentDoc}
    ${FormFragmentDoc}
    ${TravelbaseTileFragmentDoc}
`;
export const ContactFragmentDoc = gql`
    fragment Contact on contact_contact_Entry {
        title
        heroImage {
            ...HeroTransform
        }
        textBody
        button {
            ...LinkField
        }
        contactMatrix {
            ...ContactMatrix
        }
        contentMatrix {
            ...ContentMatrix
        }
        settingsCookieConsent
        settingsMetaDescription
        settingsSeoTitle
        settingsCookieConsent
        localized {
            url
            language
        }
    }
    ${HeroTransformFragmentDoc}
    ${LinkFieldFragmentDoc}
    ${ContactMatrixFragmentDoc}
    ${ContentMatrixFragmentDoc}
`;
export const ContactGlobalsFragmentDoc = gql`
    fragment ContactGlobals on contact_GlobalSet {
        contactEmailText
        contactMessengerText
        contactMyTexelText
        contactPhoneText
        contactShopText
        contactWhatsappText
    }
`;
export const HighlightTransformFragmentDoc = gql`
    fragment HighlightTransform on AssetInterface {
        width
        height
        focalPoint
        title
        extension
        url
        srcset: url
            @transformsrcset(
                sizes: ["64w", "128w", "80w", "160w"]
                width: 64
                height: 64
                mode: "crop"
                immediately: false
                quality: 70
            )
        srcsetWebp: url
            @transformsrcset(
                sizes: ["64w", "128w", "80w", "160w"]
                width: 64
                height: 64
                mode: "crop"
                format: "webp"
                immediately: false
                quality: 70
            )
        placeholderUrl: url @transform(width: 4, height: 4, mode: "crop", immediately: false, quality: 70)
    }
`;
export const HighlightFragmentDoc = gql`
    fragment Highlight on EntryInterface {
        title
        uri
        __typename
        ... on pages_page_Entry {
            heroImage {
                ...HighlightTransform
            }
        }
        ... on articles_article_Entry {
            newsHeroImage {
                ...HighlightTransform
            }
        }
    }
    ${HighlightTransformFragmentDoc}
`;
export const ActionFragmentDoc = gql`
    fragment Action on action_action_Entry {
        title
        description
        button {
            ...LinkField
        }
    }
    ${LinkFieldFragmentDoc}
`;
export const HomeFragmentDoc = gql`
    fragment Home on homepage_homepage_Entry {
        heroImage {
            ...HeroTransform
        }
        title
        video
        homeVideoEnabled
        homeVideoTitle
        homeVideoDescription
        homeHighlightsTitle
        homeHighlights {
            ...Highlight
            ... on pages_page_Entry {
                settingsSeoTitle
                settingsMetaDescription
            }
        }
        homeCurrent {
            ...Card
        }
        action {
            ...Action
        }
        settingsCookieConsent
        settingsMetaDescription
        settingsSeoTitle
        header
        localized {
            url
            language
        }
    }
    ${HeroTransformFragmentDoc}
    ${HighlightFragmentDoc}
    ${CardFragmentDoc}
    ${ActionFragmentDoc}
`;
export const NewsFragmentDoc = gql`
    fragment News on news_news_Entry {
        title
        heroImage {
            ...HeroTransform
        }
        textBody
        settingsCookieConsent
        settingsMetaDescription
        settingsSeoTitle
        localized {
            url
            language
        }
    }
    ${HeroTransformFragmentDoc}
`;
export const PageFragmentDoc = gql`
    fragment Page on pages_page_Entry {
        uri
        title
        settingsCookieConsent
        settingsMetaDescription
        settingsSeoTitle
        settingsNotSearchable
        heroImage {
            ...HeroTransform
        }
        heroImageVisible
        root
        contentMatrix {
            ...ContentMatrix
        }
        children {
            ...Card
        }
        id
        parent {
            id
            children(section: ["pages"]) {
                ...Card
            }
        }
        settingsSearchTags {
            title
        }
        localized {
            url
            language
        }
    }
    ${HeroTransformFragmentDoc}
    ${ContentMatrixFragmentDoc}
    ${CardFragmentDoc}
`;
export const SearchFragmentDoc = gql`
    fragment Search on search_search_Entry {
        title
        settingsCookieConsent
        settingsMetaDescription
        settingsSeoTitle
        localized {
            url
            language
        }
    }
`;
export const SearchHitFragmentDoc = gql`
    fragment SearchHit on SearchType {
        postDate
        title
        type
        uri
        image {
            extension
            placeholderUrl
            srcset
            srcsetWebp
            title
            url
        }
    }
`;
export const AgendaFragmentDoc = gql`
    fragment Agenda on agenda_agenda_Entry {
        title
        settingsMetaDescription
        settingsSeoTitle
        localized {
            url
            language
        }
    }
`;
export const MenuItemPageFieldsFragmentDoc = gql`
    fragment MenuItemPageFields on pages_page_Entry {
        slug
        id
        title
        uri
        settingsMenuHidden
        settingsMenuTitle
        parent {
            uri
            id
        }
    }
`;
export const MenuItemPageRecursiveFragmentDoc = gql`
    fragment MenuItemPageRecursive on pages_page_Entry {
        children {
            ...MenuItemPageFields
            children {
                ...MenuItemPageFields
                children {
                    ...MenuItemPageFields
                    children {
                        ...MenuItemPageFields
                    }
                }
            }
        }
    }
    ${MenuItemPageFieldsFragmentDoc}
`;
export const MenuItemsFragmentDoc = gql`
    fragment MenuItems on menu_menu_Entry {
        id
        title
        menuLink {
            ...LinkField
            element {
                ...MenuItemPageFields
                ...MenuItemPageRecursive
            }
        }
    }
    ${LinkFieldFragmentDoc}
    ${MenuItemPageFieldsFragmentDoc}
    ${MenuItemPageRecursiveFragmentDoc}
`;
export const BreadcrumbFragmentDoc = gql`
    fragment Breadcrumb on pages_page_Entry {
        id
        title
        ...MenuItemPageFields
        ...MenuItemPageRecursive
    }
    ${MenuItemPageFieldsFragmentDoc}
    ${MenuItemPageRecursiveFragmentDoc}
`;
export const FooterSubmenuFragmentDoc = gql`
    fragment FooterSubmenu on footer_footerLink_Entry {
        title
        __typename
        menuLink {
            ...LinkField
        }
    }
    ${LinkFieldFragmentDoc}
`;
export const FooterItemsFragmentDoc = gql`
    fragment FooterItems on footer_footerCategory_Entry {
        title
        children {
            ...FooterSubmenu
        }
    }
    ${FooterSubmenuFragmentDoc}
`;
export const NotificationFragmentDoc = gql`
    fragment Notification on notification_GlobalSet {
        button {
            ...LinkField
            text
        }
        header
        active
        enabled
        description
    }
    ${LinkFieldFragmentDoc}
`;
export const GlobalQueryFragmentDoc = gql`
    fragment GlobalQuery on Query {
        menu: entries(level: 1, site: [$lang], section: "menu") {
            ...MenuItems
        }
        breadcrumb: entries(level: 1, site: [$lang], section: "pages") {
            ...Breadcrumb
        }
        footer: entries(level: 1, site: [$lang], section: "footer") {
            ...FooterItems
        }
        notification: globalSet(handle: "notification", site: [$lang]) {
            ...Notification
        }
        search: entry(type: "search", site: [$lang]) {
            uri
        }
    }
    ${MenuItemsFragmentDoc}
    ${BreadcrumbFragmentDoc}
    ${FooterItemsFragmentDoc}
    ${NotificationFragmentDoc}
`;
export const ReadMoreDocument = gql`
    query ReadMore($parentId: Int, $id: Int, $q: String, $notId: [QueryArgument], $lang: String) {
        siblings: entries(
            descendantOf: $parentId
            descendantDist: 1
            limit: 4
            type: ["page", "article"]
            id: $notId
            site: [$lang]
        ) {
            ...Highlight
        }
        ancestors: entries(ancestorOf: $id, limit: 4, type: ["page", "article"], site: [$lang]) {
            ...Highlight
        }
        search(q: $q, language: $lang, type: ["pages", "articles"]) {
            ...SearchHit
        }
    }
    ${HighlightFragmentDoc}
    ${SearchHitFragmentDoc}
`;

/**
 * __useReadMoreQuery__
 *
 * To run a query within a React component, call `useReadMoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMoreQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      id: // value for 'id'
 *      q: // value for 'q'
 *      notId: // value for 'notId'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useReadMoreQuery(baseOptions?: Apollo.QueryHookOptions<ReadMoreQuery, ReadMoreQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ReadMoreQuery, ReadMoreQueryVariables>(ReadMoreDocument, options);
}
export function useReadMoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMoreQuery, ReadMoreQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ReadMoreQuery, ReadMoreQueryVariables>(ReadMoreDocument, options);
}
export type ReadMoreQueryHookResult = ReturnType<typeof useReadMoreQuery>;
export type ReadMoreLazyQueryHookResult = ReturnType<typeof useReadMoreLazyQuery>;
export type ReadMoreQueryResult = Apollo.QueryResult<ReadMoreQuery, ReadMoreQueryVariables>;
export const RentalUnitsDocument = gql`
    query RentalUnits($slugs: [String!]!) {
        rentalUnits(slugs: $slugs) {
            ...rentalUnit
        }
    }
    ${RentalUnitFragmentDoc}
`;

/**
 * __useRentalUnitsQuery__
 *
 * To run a query within a React component, call `useRentalUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRentalUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRentalUnitsQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *   },
 * });
 */
export function useRentalUnitsQuery(baseOptions: Apollo.QueryHookOptions<RentalUnitsQuery, RentalUnitsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<RentalUnitsQuery, RentalUnitsQueryVariables>(RentalUnitsDocument, options);
}
export function useRentalUnitsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RentalUnitsQuery, RentalUnitsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<RentalUnitsQuery, RentalUnitsQueryVariables>(RentalUnitsDocument, options);
}
export type RentalUnitsQueryHookResult = ReturnType<typeof useRentalUnitsQuery>;
export type RentalUnitsLazyQueryHookResult = ReturnType<typeof useRentalUnitsLazyQuery>;
export type RentalUnitsQueryResult = Apollo.QueryResult<RentalUnitsQuery, RentalUnitsQueryVariables>;
export const SearchFieldDocument = gql`
    query SearchField($locale: String!, $q: String!) {
        globalSearch(language: $locale, query: $q, limit: 50) {
            totalHits
            hits {
                ...SearchHit
            }
        }
    }
    ${SearchHitFragmentDoc}
`;

/**
 * __useSearchFieldQuery__
 *
 * To run a query within a React component, call `useSearchFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFieldQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useSearchFieldQuery(baseOptions: Apollo.QueryHookOptions<SearchFieldQuery, SearchFieldQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SearchFieldQuery, SearchFieldQueryVariables>(SearchFieldDocument, options);
}
export function useSearchFieldLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SearchFieldQuery, SearchFieldQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SearchFieldQuery, SearchFieldQueryVariables>(SearchFieldDocument, options);
}
export type SearchFieldQueryHookResult = ReturnType<typeof useSearchFieldQuery>;
export type SearchFieldLazyQueryHookResult = ReturnType<typeof useSearchFieldLazyQuery>;
export type SearchFieldQueryResult = Apollo.QueryResult<SearchFieldQuery, SearchFieldQueryVariables>;
export const UpcomingReservationsDocument = gql`
    query UpcomingReservations {
        viewer {
            id
            orders {
                ...MyOrder
            }
        }
    }
    ${MyOrderFragmentDoc}
`;

/**
 * __useUpcomingReservationsQuery__
 *
 * To run a query within a React component, call `useUpcomingReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingReservationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpcomingReservationsQuery(
    baseOptions?: Apollo.QueryHookOptions<UpcomingReservationsQuery, UpcomingReservationsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<UpcomingReservationsQuery, UpcomingReservationsQueryVariables>(
        UpcomingReservationsDocument,
        options
    );
}
export function useUpcomingReservationsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<UpcomingReservationsQuery, UpcomingReservationsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<UpcomingReservationsQuery, UpcomingReservationsQueryVariables>(
        UpcomingReservationsDocument,
        options
    );
}
export type UpcomingReservationsQueryHookResult = ReturnType<typeof useUpcomingReservationsQuery>;
export type UpcomingReservationsLazyQueryHookResult = ReturnType<typeof useUpcomingReservationsLazyQuery>;
export type UpcomingReservationsQueryResult = Apollo.QueryResult<
    UpcomingReservationsQuery,
    UpcomingReservationsQueryVariables
>;
export const RentalUnitsBySlugDocument = gql`
    query RentalUnitsBySlug($slugs: [String!]!) {
        rentalUnits(slugs: $slugs) {
            ...RentalUnitsBySlug
        }
    }
    ${RentalUnitsBySlugFragmentDoc}
`;

/**
 * __useRentalUnitsBySlugQuery__
 *
 * To run a query within a React component, call `useRentalUnitsBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useRentalUnitsBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRentalUnitsBySlugQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *   },
 * });
 */
export function useRentalUnitsBySlugQuery(
    baseOptions: Apollo.QueryHookOptions<RentalUnitsBySlugQuery, RentalUnitsBySlugQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<RentalUnitsBySlugQuery, RentalUnitsBySlugQueryVariables>(RentalUnitsBySlugDocument, options);
}
export function useRentalUnitsBySlugLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RentalUnitsBySlugQuery, RentalUnitsBySlugQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<RentalUnitsBySlugQuery, RentalUnitsBySlugQueryVariables>(
        RentalUnitsBySlugDocument,
        options
    );
}
export type RentalUnitsBySlugQueryHookResult = ReturnType<typeof useRentalUnitsBySlugQuery>;
export type RentalUnitsBySlugLazyQueryHookResult = ReturnType<typeof useRentalUnitsBySlugLazyQuery>;
export type RentalUnitsBySlugQueryResult = Apollo.QueryResult<RentalUnitsBySlugQuery, RentalUnitsBySlugQueryVariables>;
export const CompaniesBySlugDocument = gql`
    query CompaniesBySlug($slugs: [String!]!) {
        companiesBySlug(slugs: $slugs) {
            id
            name
            slug
            description
            webpListImage: listImage {
                transform(config: TILE, format: WEBP) {
                    ...ListImage
                }
            }
            listImage {
                transform(config: TILE, format: JPEG) {
                    ...ListImage
                }
            }
        }
    }
    ${ListImageFragmentDoc}
`;

/**
 * __useCompaniesBySlugQuery__
 *
 * To run a query within a React component, call `useCompaniesBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesBySlugQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *   },
 * });
 */
export function useCompaniesBySlugQuery(
    baseOptions: Apollo.QueryHookOptions<CompaniesBySlugQuery, CompaniesBySlugQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CompaniesBySlugQuery, CompaniesBySlugQueryVariables>(CompaniesBySlugDocument, options);
}
export function useCompaniesBySlugLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<CompaniesBySlugQuery, CompaniesBySlugQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CompaniesBySlugQuery, CompaniesBySlugQueryVariables>(CompaniesBySlugDocument, options);
}
export type CompaniesBySlugQueryHookResult = ReturnType<typeof useCompaniesBySlugQuery>;
export type CompaniesBySlugLazyQueryHookResult = ReturnType<typeof useCompaniesBySlugLazyQuery>;
export type CompaniesBySlugQueryResult = Apollo.QueryResult<CompaniesBySlugQuery, CompaniesBySlugQueryVariables>;
export const ActivitiesBySlugDocument = gql`
    query ActivitiesBySlug($slugs: [String!]!) {
        activitiesBySlug(slugs: $slugs) {
            id
            name
            slug
            description
            webpListImage: listImage {
                transform(config: TILE, format: WEBP) {
                    ...ListImage
                }
            }
            listImage {
                transform(config: TILE, format: JPEG) {
                    ...ListImage
                }
            }
        }
    }
    ${ListImageFragmentDoc}
`;

/**
 * __useActivitiesBySlugQuery__
 *
 * To run a query within a React component, call `useActivitiesBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivitiesBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivitiesBySlugQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *   },
 * });
 */
export function useActivitiesBySlugQuery(
    baseOptions: Apollo.QueryHookOptions<ActivitiesBySlugQuery, ActivitiesBySlugQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ActivitiesBySlugQuery, ActivitiesBySlugQueryVariables>(ActivitiesBySlugDocument, options);
}
export function useActivitiesBySlugLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ActivitiesBySlugQuery, ActivitiesBySlugQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ActivitiesBySlugQuery, ActivitiesBySlugQueryVariables>(
        ActivitiesBySlugDocument,
        options
    );
}
export type ActivitiesBySlugQueryHookResult = ReturnType<typeof useActivitiesBySlugQuery>;
export type ActivitiesBySlugLazyQueryHookResult = ReturnType<typeof useActivitiesBySlugLazyQuery>;
export type ActivitiesBySlugQueryResult = Apollo.QueryResult<ActivitiesBySlugQuery, ActivitiesBySlugQueryVariables>;
export const AccommodationsBySlugDocument = gql`
    query AccommodationsBySlug($slugs: [String!]!) {
        accommodationsBySlug(slugs: $slugs) {
            ...AccommodationsBySlug
        }
    }
    ${AccommodationsBySlugFragmentDoc}
`;

/**
 * __useAccommodationsBySlugQuery__
 *
 * To run a query within a React component, call `useAccommodationsBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccommodationsBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccommodationsBySlugQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *   },
 * });
 */
export function useAccommodationsBySlugQuery(
    baseOptions: Apollo.QueryHookOptions<AccommodationsBySlugQuery, AccommodationsBySlugQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AccommodationsBySlugQuery, AccommodationsBySlugQueryVariables>(
        AccommodationsBySlugDocument,
        options
    );
}
export function useAccommodationsBySlugLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<AccommodationsBySlugQuery, AccommodationsBySlugQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AccommodationsBySlugQuery, AccommodationsBySlugQueryVariables>(
        AccommodationsBySlugDocument,
        options
    );
}
export type AccommodationsBySlugQueryHookResult = ReturnType<typeof useAccommodationsBySlugQuery>;
export type AccommodationsBySlugLazyQueryHookResult = ReturnType<typeof useAccommodationsBySlugLazyQuery>;
export type AccommodationsBySlugQueryResult = Apollo.QueryResult<
    AccommodationsBySlugQuery,
    AccommodationsBySlugQueryVariables
>;
export const ArticleDocument = gql`
    query Article($slug: String, $lang: String, $notSlug: String) {
        entry(type: ["article"], slug: [$slug], site: [$lang]) {
            ...Article
        }
        related: entries(slug: [$notSlug], section: ["articles"], limit: 3, orderBy: "postDate DESC", site: [$lang]) {
            ...Card
        }
    }
    ${ArticleFragmentDoc}
    ${CardFragmentDoc}
`;

/**
 * __useArticleQuery__
 *
 * To run a query within a React component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      lang: // value for 'lang'
 *      notSlug: // value for 'notSlug'
 *   },
 * });
 */
export function useArticleQuery(baseOptions?: Apollo.QueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
}
export function useArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
}
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleQueryResult = Apollo.QueryResult<ArticleQuery, ArticleQueryVariables>;
export const ArticlePathsDocument = gql`
    query ArticlePaths {
        entries(site: ["*"], section: ["articles"]) {
            __typename
            slug
            language
        }
    }
`;

/**
 * __useArticlePathsQuery__
 *
 * To run a query within a React component, call `useArticlePathsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlePathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlePathsQuery({
 *   variables: {
 *   },
 * });
 */
export function useArticlePathsQuery(
    baseOptions?: Apollo.QueryHookOptions<ArticlePathsQuery, ArticlePathsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ArticlePathsQuery, ArticlePathsQueryVariables>(ArticlePathsDocument, options);
}
export function useArticlePathsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ArticlePathsQuery, ArticlePathsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ArticlePathsQuery, ArticlePathsQueryVariables>(ArticlePathsDocument, options);
}
export type ArticlePathsQueryHookResult = ReturnType<typeof useArticlePathsQuery>;
export type ArticlePathsLazyQueryHookResult = ReturnType<typeof useArticlePathsLazyQuery>;
export type ArticlePathsQueryResult = Apollo.QueryResult<ArticlePathsQuery, ArticlePathsQueryVariables>;
export const ContactDocument = gql`
    query Contact($lang: String) {
        globalSet(handle: "contact", site: [$lang]) {
            ...ContactGlobals
        }
        entry(section: "contact", site: [$lang]) {
            ...Contact
        }
    }
    ${ContactGlobalsFragmentDoc}
    ${ContactFragmentDoc}
`;

/**
 * __useContactQuery__
 *
 * To run a query within a React component, call `useContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useContactQuery(baseOptions?: Apollo.QueryHookOptions<ContactQuery, ContactQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ContactQuery, ContactQueryVariables>(ContactDocument, options);
}
export function useContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactQuery, ContactQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ContactQuery, ContactQueryVariables>(ContactDocument, options);
}
export type ContactQueryHookResult = ReturnType<typeof useContactQuery>;
export type ContactLazyQueryHookResult = ReturnType<typeof useContactLazyQuery>;
export type ContactQueryResult = Apollo.QueryResult<ContactQuery, ContactQueryVariables>;
export const ContactMeDocument = gql`
    query ContactMe {
        viewer {
            id
        }
    }
`;

/**
 * __useContactMeQuery__
 *
 * To run a query within a React component, call `useContactMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactMeQuery(baseOptions?: Apollo.QueryHookOptions<ContactMeQuery, ContactMeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ContactMeQuery, ContactMeQueryVariables>(ContactMeDocument, options);
}
export function useContactMeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<ContactMeQuery, ContactMeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ContactMeQuery, ContactMeQueryVariables>(ContactMeDocument, options);
}
export type ContactMeQueryHookResult = ReturnType<typeof useContactMeQuery>;
export type ContactMeLazyQueryHookResult = ReturnType<typeof useContactMeLazyQuery>;
export type ContactMeQueryResult = Apollo.QueryResult<ContactMeQuery, ContactMeQueryVariables>;
export const HomeDocument = gql`
    query Home($lang: String) {
        entry(uri: ["__home__"], site: [$lang]) {
            ...Home
        }
    }
    ${HomeFragmentDoc}
`;

/**
 * __useHomeQuery__
 *
 * To run a query within a React component, call `useHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
}
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
}
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const NewsDocument = gql`
    query News($lang: String, $offset: Int, $limit: Int) {
        articleCount: entryCount(site: [$lang], section: "articles")
        articles: entries(site: [$lang], section: "articles", offset: $offset, limit: $limit) {
            ...Card
        }
        entry(section: ["news"], site: [$lang]) {
            ...News
        }
    }
    ${CardFragmentDoc}
    ${NewsFragmentDoc}
`;

/**
 * __useNewsQuery__
 *
 * To run a query within a React component, call `useNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useNewsQuery(baseOptions?: Apollo.QueryHookOptions<NewsQuery, NewsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<NewsQuery, NewsQueryVariables>(NewsDocument, options);
}
export function useNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsQuery, NewsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<NewsQuery, NewsQueryVariables>(NewsDocument, options);
}
export type NewsQueryHookResult = ReturnType<typeof useNewsQuery>;
export type NewsLazyQueryHookResult = ReturnType<typeof useNewsLazyQuery>;
export type NewsQueryResult = Apollo.QueryResult<NewsQuery, NewsQueryVariables>;
export const PageDocument = gql`
    query Page($uri: String, $lang: String, $retourUri: String) {
        entry(uri: [$uri], site: [$lang]) {
            ...Page
        }
        retourResolveRedirect(uri: $retourUri) {
            redirectDestUrl
            redirectSrcUrl
            redirectHttpCode
            redirectSrcMatch
            siteId
        }
    }
    ${PageFragmentDoc}
`;

/**
 * __usePageQuery__
 *
 * To run a query within a React component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageQuery({
 *   variables: {
 *      uri: // value for 'uri'
 *      lang: // value for 'lang'
 *      retourUri: // value for 'retourUri'
 *   },
 * });
 */
export function usePageQuery(baseOptions?: Apollo.QueryHookOptions<PageQuery, PageQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PageQuery, PageQueryVariables>(PageDocument, options);
}
export function usePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageQuery, PageQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, options);
}
export type PageQueryHookResult = ReturnType<typeof usePageQuery>;
export type PageLazyQueryHookResult = ReturnType<typeof usePageLazyQuery>;
export type PageQueryResult = Apollo.QueryResult<PageQuery, PageQueryVariables>;
export const PagePathsDocument = gql`
    query PagePaths {
        entries(site: ["*"], type: ["page"]) {
            __typename
            uri
            slug
            language
        }
    }
`;

/**
 * __usePagePathsQuery__
 *
 * To run a query within a React component, call `usePagePathsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagePathsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagePathsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePagePathsQuery(baseOptions?: Apollo.QueryHookOptions<PagePathsQuery, PagePathsQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<PagePathsQuery, PagePathsQueryVariables>(PagePathsDocument, options);
}
export function usePagePathsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<PagePathsQuery, PagePathsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<PagePathsQuery, PagePathsQueryVariables>(PagePathsDocument, options);
}
export type PagePathsQueryHookResult = ReturnType<typeof usePagePathsQuery>;
export type PagePathsLazyQueryHookResult = ReturnType<typeof usePagePathsLazyQuery>;
export type PagePathsQueryResult = Apollo.QueryResult<PagePathsQuery, PagePathsQueryVariables>;
export const SearchPageDocument = gql`
    query SearchPage($lang: String!, $q: String!, $types: [String!]) {
        globalSearch(language: $lang, query: $q, limit: 50, type: $types) {
            totalHits
            typeCounts {
                count
                label
            }
            hits {
                ...SearchHit
            }
        }
    }
    ${SearchHitFragmentDoc}
`;

/**
 * __useSearchPageQuery__
 *
 * To run a query within a React component, call `useSearchPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPageQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      q: // value for 'q'
 *      types: // value for 'types'
 *   },
 * });
 */
export function useSearchPageQuery(baseOptions: Apollo.QueryHookOptions<SearchPageQuery, SearchPageQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SearchPageQuery, SearchPageQueryVariables>(SearchPageDocument, options);
}
export function useSearchPageLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SearchPageQuery, SearchPageQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SearchPageQuery, SearchPageQueryVariables>(SearchPageDocument, options);
}
export type SearchPageQueryHookResult = ReturnType<typeof useSearchPageQuery>;
export type SearchPageLazyQueryHookResult = ReturnType<typeof useSearchPageLazyQuery>;
export type SearchPageQueryResult = Apollo.QueryResult<SearchPageQuery, SearchPageQueryVariables>;
export const SearchPageAllAvailableFiltersDocument = gql`
    query SearchPageAllAvailableFilters($lang: String!, $q: String!) {
        globalSearch(language: $lang, query: $q, limit: 50) {
            totalHits
            typeCounts {
                count
                label
            }
        }
    }
`;

/**
 * __useSearchPageAllAvailableFiltersQuery__
 *
 * To run a query within a React component, call `useSearchPageAllAvailableFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPageAllAvailableFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPageAllAvailableFiltersQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      q: // value for 'q'
 *   },
 * });
 */
export function useSearchPageAllAvailableFiltersQuery(
    baseOptions: Apollo.QueryHookOptions<
        SearchPageAllAvailableFiltersQuery,
        SearchPageAllAvailableFiltersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SearchPageAllAvailableFiltersQuery, SearchPageAllAvailableFiltersQueryVariables>(
        SearchPageAllAvailableFiltersDocument,
        options
    );
}
export function useSearchPageAllAvailableFiltersLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        SearchPageAllAvailableFiltersQuery,
        SearchPageAllAvailableFiltersQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SearchPageAllAvailableFiltersQuery, SearchPageAllAvailableFiltersQueryVariables>(
        SearchPageAllAvailableFiltersDocument,
        options
    );
}
export type SearchPageAllAvailableFiltersQueryHookResult = ReturnType<typeof useSearchPageAllAvailableFiltersQuery>;
export type SearchPageAllAvailableFiltersLazyQueryHookResult = ReturnType<
    typeof useSearchPageAllAvailableFiltersLazyQuery
>;
export type SearchPageAllAvailableFiltersQueryResult = Apollo.QueryResult<
    SearchPageAllAvailableFiltersQuery,
    SearchPageAllAvailableFiltersQueryVariables
>;
export const SearchDocument = gql`
    query Search($lang: String) {
        entry(section: ["search"], site: [$lang]) {
            ...Search
        }
    }
    ${SearchFragmentDoc}
`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useSearchQuery(baseOptions?: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const AgendaDocument = gql`
    query Agenda($lang: String) {
        entry(section: ["agenda"], site: [$lang]) {
            ...Agenda
        }
    }
    ${AgendaFragmentDoc}
`;

/**
 * __useAgendaQuery__
 *
 * To run a query within a React component, call `useAgendaQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgendaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgendaQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useAgendaQuery(baseOptions?: Apollo.QueryHookOptions<AgendaQuery, AgendaQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AgendaQuery, AgendaQueryVariables>(AgendaDocument, options);
}
export function useAgendaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgendaQuery, AgendaQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AgendaQuery, AgendaQueryVariables>(AgendaDocument, options);
}
export type AgendaQueryHookResult = ReturnType<typeof useAgendaQuery>;
export type AgendaLazyQueryHookResult = ReturnType<typeof useAgendaLazyQuery>;
export type AgendaQueryResult = Apollo.QueryResult<AgendaQuery, AgendaQueryVariables>;
export const AccoFeedDocument = gql`
    query AccoFeed($amount: Int, $offset: Int) {
        unitSearch(params: {}, limit: $amount, offset: $offset) {
            hits {
                rentalUnit {
                    id
                    name
                    slug
                    images {
                        transform(config: TILE, format: JPEG) {
                            src
                        }
                    }
                    tagline
                    descriptionLayout
                    type
                    accommodation {
                        place
                    }
                    maxOccupancy
                    livingArea
                    outdoorArea
                }
                trips {
                    price
                }
            }
            totalHits
        }
    }
`;

/**
 * __useAccoFeedQuery__
 *
 * To run a query within a React component, call `useAccoFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccoFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccoFeedQuery({
 *   variables: {
 *      amount: // value for 'amount'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAccoFeedQuery(baseOptions?: Apollo.QueryHookOptions<AccoFeedQuery, AccoFeedQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AccoFeedQuery, AccoFeedQueryVariables>(AccoFeedDocument, options);
}
export function useAccoFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccoFeedQuery, AccoFeedQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AccoFeedQuery, AccoFeedQueryVariables>(AccoFeedDocument, options);
}
export type AccoFeedQueryHookResult = ReturnType<typeof useAccoFeedQuery>;
export type AccoFeedLazyQueryHookResult = ReturnType<typeof useAccoFeedLazyQuery>;
export type AccoFeedQueryResult = Apollo.QueryResult<AccoFeedQuery, AccoFeedQueryVariables>;
export const SitemapDocument = gql`
    query Sitemap($locale: String, $size: Int) {
        entries(site: [$locale], uri: "!= ''") {
            url
            dateUpdated
            ... on pages_page_Entry {
                heroImage {
                    url
                    title
                }
            }
            ... on news_news_Entry {
                heroImage {
                    url
                    title
                }
            }
        }
        travelbaseObjectsSitemap(language: $locale, size: $size) {
            url
            image {
                url
                title
            }
        }
    }
`;

/**
 * __useSitemapQuery__
 *
 * To run a query within a React component, call `useSitemapQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitemapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitemapQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useSitemapQuery(baseOptions?: Apollo.QueryHookOptions<SitemapQuery, SitemapQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SitemapQuery, SitemapQueryVariables>(SitemapDocument, options);
}
export function useSitemapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SitemapQuery, SitemapQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SitemapQuery, SitemapQueryVariables>(SitemapDocument, options);
}
export type SitemapQueryHookResult = ReturnType<typeof useSitemapQuery>;
export type SitemapLazyQueryHookResult = ReturnType<typeof useSitemapLazyQuery>;
export type SitemapQueryResult = Apollo.QueryResult<SitemapQuery, SitemapQueryVariables>;
export const SqzlyFeedDocument = gql`
    query SqzlyFeed($amount: Int, $offset: Int) {
        unitSearch(params: {}, limit: $amount, offset: $offset) {
            hits {
                rentalUnit {
                    code
                    name
                    listImage {
                        transform(format: JPEG, config: TILE) {
                            src
                        }
                    }
                    slug
                    code
                    accommodation {
                        name
                        id
                    }
                    type
                }
                trips {
                    price
                }
            }
            totalHits
        }
    }
`;

/**
 * __useSqzlyFeedQuery__
 *
 * To run a query within a React component, call `useSqzlyFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSqzlyFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSqzlyFeedQuery({
 *   variables: {
 *      amount: // value for 'amount'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSqzlyFeedQuery(baseOptions?: Apollo.QueryHookOptions<SqzlyFeedQuery, SqzlyFeedQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SqzlyFeedQuery, SqzlyFeedQueryVariables>(SqzlyFeedDocument, options);
}
export function useSqzlyFeedLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SqzlyFeedQuery, SqzlyFeedQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SqzlyFeedQuery, SqzlyFeedQueryVariables>(SqzlyFeedDocument, options);
}
export type SqzlyFeedQueryHookResult = ReturnType<typeof useSqzlyFeedQuery>;
export type SqzlyFeedLazyQueryHookResult = ReturnType<typeof useSqzlyFeedLazyQuery>;
export type SqzlyFeedQueryResult = Apollo.QueryResult<SqzlyFeedQuery, SqzlyFeedQueryVariables>;
export const GlobalDocument = gql`
    query Global($lang: String) {
        ...GlobalQuery
    }
    ${GlobalQueryFragmentDoc}
`;

/**
 * __useGlobalQuery__
 *
 * To run a query within a React component, call `useGlobalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGlobalQuery(baseOptions?: Apollo.QueryHookOptions<GlobalQuery, GlobalQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GlobalQuery, GlobalQueryVariables>(GlobalDocument, options);
}
export function useGlobalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalQuery, GlobalQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GlobalQuery, GlobalQueryVariables>(GlobalDocument, options);
}
export type GlobalQueryHookResult = ReturnType<typeof useGlobalQuery>;
export type GlobalLazyQueryHookResult = ReturnType<typeof useGlobalLazyQuery>;
export type GlobalQueryResult = Apollo.QueryResult<GlobalQuery, GlobalQueryVariables>;
