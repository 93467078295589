import React from 'react';

const FlagEN = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" className="flag">
        <defs>
            <circle id="FlagEN-a" cx="12" cy="12" r="12" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <mask id="FlagEN-b" fill="#fff">
                <use href="#FlagEN-a" />
            </mask>
            <use fill="#D8D8D8" href="#FlagEN-a" />
            <g mask="url(#FlagEN-b)">
                <path fill="#094CA0" d="M-1.352 24.845h26.704V-.845H-1.352z" />
                <path
                    fill="#FEFEFE"
                    d="M8.946 7.784v-8.63h6.127v8.818L22.427.507h2.925v3.304L20.078 9.02h5.274v6.09h-5.231l5.231 4.807v2.225h-3.46l-6.819-5.85v8.554H8.946V15.11H-1.352v-6.09H8.946z"
                />
                <path
                    fill="#FEFEFE"
                    d="M15.054 7.784v-8.63H8.927v8.818L1.573.507h-2.925v3.304L3.922 9.02h-5.274v6.09h5.231l-5.231 4.807v2.225h3.46l6.819-5.85v8.554h6.127V15.11h10.298v-6.09H15.054z"
                />
                <path
                    fill="#EC1D24"
                    d="M-.845.845v1.838l6.314 6.275h2.137zM25.014.845v1.838L18.7 8.958h-2.137zM6.508 15.042L-.169 20.79h1.875l6.576-5.747zM19.013 15.042l6.677 5.747h-1.875l-6.576-5.747z"
                />
                <path
                    fill="#EC1D24"
                    d="M10.24-.845V10.31H-1.352v3.505H10.24v11.03h3.501v-11.03h11.611v-3.505H13.741V-.845z"
                />
            </g>
        </g>
    </svg>
);

export default FlagEN;
