import styled from 'styled-components';

const ItemButton = styled.button`
    background: none;
    width: 2.2rem;
    height: 2.2rem;
    border: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    padding: 0;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    transition: 0.25s background-color, 0.25s color, 0.25s border-color;
    justify-content: center;
    outline: none;

    svg {
        width: 1rem;
        height: 1.2rem;
        padding-left: 0.2rem;
    }

    &:hover {
        border-color: transparent;
        background: ${({ theme }) => theme.colors.primary[60]};
        color: ${({ theme }) => theme.colors.neutral[0]};
    }
`;

export default ItemButton;
