"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.promptErrorMessage = exports.default = void 0;
var _react = require("react");
var _router = require("next/router");
var _useRouterEvent = _interopRequireDefault(require("../hooks/useRouterEvent"));
const promptErrorMessage = exports.promptErrorMessage = 'Route change was aborted (this error can be safely ignored). See https://github.com/zeit/next.js/issues/2476.';

// An attempt at recreating the Prompt component from react-router for Next.js
const Prompt = ({
  when,
  message
}) => {
  const router = (0, _router.useRouter)();
  const handleUnload = (0, _react.useCallback)(event => {
    if (when) {
      // eslint-disable-next-line no-param-reassign
      (event || window.event).returnValue = null;
      return null;
    }
    return undefined;
  }, [when]);
  (0, _react.useEffect)(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => window.removeEventListener('beforeunload', handleUnload);
  }, [handleUnload]);
  const routeChangeStart = (0, _react.useCallback)(url => {
    if (router.asPath !== url && when) {
      router.events.emit('routeChangeError');
      if (!window.confirm(message)) {
        // Following is a hack-ish solution to abort a Next.js route change
        // as there's currently no official API to do so
        // See https://github.com/zeit/next.js/issues/2476#issuecomment-573460710

        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw promptErrorMessage;
      }
    }
  }, [message, router, when]);
  (0, _useRouterEvent.default)('routeChangeStart', routeChangeStart);
  return null;
};
var _default = exports.default = Prompt;