"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fragment = exports.default = exports.SummaryBarItemThumbnail = exports.SummaryBarItemField = exports.SummaryBarItemBody = void 0;
var _ErrorMessage = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/form/ErrorMessage"));
var _Body = _interopRequireWildcard(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _grid = require("@rebass/grid");
var _client = require("@apollo/client");
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _UnitThumbnail = _interopRequireDefault(require("./designsystem/UnitThumbnail"));
var _Loading = _interopRequireDefault(require("./Loading"));
var _Stack = _interopRequireDefault(require("./Stack"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const fragment = exports.fragment = (0, _client.gql)`
    fragment OrderSummaryItem on OrderItem {
        ...OrderSummaryItemBooking
        ...OrderSummaryItemTicket
        ...OrderSummaryItemUpsellPurchase
        ...CartOrderItem
    }
`;
const SummaryBarItem = ({
  updating,
  item,
  thumbnail,
  children
}) => __jsx(_grid.Box, null, thumbnail && thumbnail, updating && __jsx(_Loading.default, {
  mt: 4,
  spacing: 3
}), !updating && __jsx(_Stack.default, {
  mt: 4,
  spacing: 4
}, children, !!item?.errors?.length && item.errors.length > 0 && __jsx(_grid.Box, {
  my: 3
}, item.errors.map((error, i) => __jsx(_ErrorMessage.default, {
  key: i
}, error)))));
var _default = exports.default = SummaryBarItem;
const SummaryBarItemThumbnail = exports.SummaryBarItemThumbnail = (0, _styledComponents.default)(_UnitThumbnail.default).withConfig({
  displayName: "SummaryBarItem__SummaryBarItemThumbnail",
  componentId: "sc-1s83xwr-0"
})(["width:100%;", ""], props => props.isClickable && `
            cursor: pointer;
            @media screen and (min-width: ${props.theme.mediaQueries.m}) {
                position: relative;
                :hover {
                    ::before {
                        content: '';
                        position: absolute;
                        width: 0.8rem;
                        height: 0.8rem;
                        background: ${props.theme.colors.secondary['40']};
                        left: -2.4rem;
                        top: calc(50% - 0.4rem);
                        border-radius: 50%;
                    }
                }
            }
            `);
const SummaryBarItemField = ({
  title,
  children
}) => __jsx(_grid.Box, null, __jsx(_Body.default, {
  variant: "tiny"
}, title), __jsx(Content, null, children));
exports.SummaryBarItemField = SummaryBarItemField;
const Content = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "SummaryBarItem__Content",
  componentId: "sc-1s83xwr-1"
})(["", "{font-weight:300;}"], _Body.BodyInner);
const SummaryBarItemBody = exports.SummaryBarItemBody = (0, _styledComponents.default)(_Body.default).attrs({
  variant: 'small'
}).withConfig({
  displayName: "SummaryBarItem__SummaryBarItemBody",
  componentId: "sc-1s83xwr-2"
})(["text-transform:capitalize;margin:0;color:", ";display:-webkit-box;-webkit-line-clamp:1;text-overflow:ellipsis;-webkit-box-orient:vertical;overflow:hidden;"], ({
  theme
}) => theme.colors.secondary[100]);