import React, { SVGAttributes } from 'react';

const Paypal = (props: SVGAttributes<SVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 24" {...props}>
        <defs />
        <g fill="#FFF" fillRule="nonzero">
            <path
                d="M18.33 6.098c.292-1.867-.003-3.138-1.012-4.288C16.206.543 14.198 0 11.628 0h-7.46c-.525 0-.972.382-1.054.9L.008 20.598a.64.64 0 00.632.74h4.606l-.318 2.016A.56.56 0 005.48 24h3.881c.46 0 .85-.334.922-.788l.039-.197.731-4.637.047-.256a.933.933 0 01.922-.788h.581c3.76 0 6.705-1.528 7.565-5.946.36-1.846.174-3.388-.777-4.47a3.702 3.702 0 00-1.063-.82"
                opacity=".68"
            />
            <path
                d="M18.33 6.098c.292-1.867-.003-3.138-1.012-4.288C16.206.543 14.198 0 11.628 0h-7.46c-.525 0-.972.382-1.054.9L.008 20.598a.64.64 0 00.632.74h4.606l1.156-7.335-.036.23c.082-.519.526-.9 1.05-.9h2.19c4.298 0 7.664-1.747 8.647-6.798.029-.15.054-.294.076-.436"
                opacity=".7"
            />
            <path d="M7.645 6.122a.934.934 0 01.922-.788h5.847c.693 0 1.339.045 1.93.14.168.028.332.059.492.094a7.353 7.353 0 01.685.188c.29.096.56.21.809.341.292-1.867-.002-3.137-1.012-4.288C16.206.543 14.198 0 11.628 0h-7.46c-.525 0-.972.382-1.054.9L.008 20.598a.64.64 0 00.632.74h4.606l1.156-7.335 1.243-7.88z" />
        </g>
    </svg>
);

export default Paypal;
