"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var _ModalHeader = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/ModalHeader"));
var _Stack = _interopRequireDefault(require("./Stack"));
var _getAccommodationTypeOptions = require("../utils/getAccommodationTypeOptions");
var _Formed = _interopRequireDefault(require("./Formed"));
var _TenantContext = require("../context/TenantContext");
var _SearchFormMobile = _interopRequireDefault(require("./SearchFormMobile"));
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const SearchBoxModal = ({
  open,
  onClose,
  setValues,
  values,
  submitForm
}) => {
  const intl = (0, _reactIntl.useIntl)();
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const accommodationTypeOptions = (0, _getAccommodationTypeOptions.getAccommodationTypeOptions)(intl, brandConfig);
  return __jsx(StyledModal, {
    open: open,
    onClose: onClose,
    variant: "regular"
  }, __jsx(_ModalHeader.default, {
    style: {
      textTransform: 'capitalize'
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "SFVO9u",
    defaultMessage: [{
      "type": 0,
      "value": "Zoeken"
    }]
  })), __jsx(_Stack.default, {
    my: 6,
    spacing: 4
  }, __jsx(_Formed.default, {
    handleSubmit: innerValues => {
      setValues(_objectSpread(_objectSpread({}, values), innerValues));
      return submitForm();
    },
    initialValues: values,
    skipPrompt: true
  }, ({
    submitForm: submitInner,
    isSubmitting
  }) => __jsx(_react.default.Fragment, null, __jsx(_SearchFormMobile.default, {
    accommodationTypeOptions: accommodationTypeOptions
  }), __jsx(SearchButton, {
    size: "large",
    onClick: async () => {
      await submitInner();
      onClose();
    },
    "data-cy": "searchButton",
    submitting: isSubmitting
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "SFVO9u",
    defaultMessage: [{
      "type": 0,
      "value": "Zoeken"
    }]
  }))))));
};
var _default = exports.default = SearchBoxModal;
const SearchButton = (0, _styledComponents.default)(_Button.default).withConfig({
  displayName: "SearchBoxModal__SearchButton",
  componentId: "sc-1b68y1z-0"
})(["margin-top:", ";border-radius:", ";width:100%;@media screen and (min-width:", "){margin-top:", ";}"], ({
  theme
}) => theme.spacing['60_Large'], ({
  theme
}) => theme.radius.button, ({
  theme
}) => theme.mediaQueries.xs, ({
  theme
}) => theme.spacing['50_Semi']);
const StyledModal = (0, _styledComponents.default)(_Modal.default).withConfig({
  displayName: "SearchBoxModal__StyledModal",
  componentId: "sc-1b68y1z-1"
})(["&&{@media screen and (min-width:", "){> [data-reach-dialog-content]{overflow:unset;}}}"], ({
  theme
}) => theme.mediaQueries.s);