"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _devicesize = _interopRequireDefault(require("@oberoninternal/travelbase-ds/context/devicesize"));
var _globalStyling = _interopRequireDefault(require("@oberoninternal/travelbase-ds/globalStyling"));
var _dynamic = _interopRequireDefault(require("next/dynamic"));
var _head = _interopRequireDefault(require("next/head"));
var _router = require("next/router");
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _urlParse = _interopRequireDefault(require("url-parse"));
var _formats = _interopRequireDefault(require("../constants/formats"));
var _standalonePages = _interopRequireDefault(require("../constants/standalonePages"));
var _checkout = require("../context/checkout");
var _QueryParamProvider = require("../context/QueryParamProvider");
var _TenantContext = require("../context/TenantContext");
var _globalStyle = _interopRequireDefault(require("../globalStyle"));
var _usePageview = _interopRequireDefault(require("../hooks/usePageview"));
var _intlCache = _interopRequireDefault(require("../intlCache"));
require("../yupExtensions");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _affiliate = require("../constants/affiliate");
var _Toasts = _interopRequireDefault(require("./Toasts"));
var __jsx = _react.default.createElement;
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const DeskMode = (0, _dynamic.default)(() => Promise.resolve().then(() => _interopRequireWildcard(require('./DeskMode'))), {
  ssr: false,
  loadableGenerated: {
    webpack: () => [require.resolveWeak('./DeskMode')]
  }
});
const GlobalStylings = () => {
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  return __jsx(_react.default.Fragment, null, __jsx(_globalStyling.default, null), __jsx(_globalStyle.default, null), brandConfig.globalStyling && __jsx(_head.default, null, __jsx("style", {
    dangerouslySetInnerHTML: {
      __html: brandConfig.globalStyling
    }
  })));
};
const TorApp = props => {
  const {
    router
  } = props;
  const {
    query
  } = router;
  const {
    messages,
    locale,
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  (0, _usePageview.default)();
  (0, _react.useEffect)(() => {
    const {
      affiliateHandle,
      affiliateReference
    } = query;
    if (affiliateHandle && affiliateHandle.length > 0) {
      _jsCookie.default.set(_affiliate.TRAVELBASE_AFF, affiliateHandle, {
        expires: 60
      });
      delete query.affiliateHandle;
      if (affiliateReference) {
        _jsCookie.default.set(_affiliate.TRAVELBASE_AFF_REFERENCE, affiliateReference, {
          expires: 60
        });
        delete query.affiliateReference;
      } else {
        _jsCookie.default.remove(_affiliate.TRAVELBASE_AFF_REFERENCE);
      }
      router.replace({
        pathname: router?.pathname,
        query
      }, undefined, {
        shallow: true
      });
    }
  }, [router, query]);
  const {
    Navigation,
    Footer
  } = brandConfig.customContent ?? {};
  const intl = (0, _react.useMemo)(() => (0, _reactIntl.createIntl)({
    locale,
    messages,
    formats: _formats.default
  }, _intlCache.default), [locale, messages]);
  (0, _react.useEffect)(() => {
    _router.Router.events.on('beforeHistoryChange', url => {
      // only jump to top if pathname changes
      if (new _urlParse.default(url).pathname !== window.location.pathname) {
        window.scrollTo(0, 0);
      }
    });
    if (brandConfig.template?.onRouteChange) {
      const {
        onRouteChange
      } = brandConfig.template;
      onRouteChange();
      _router.Router.events.on('routeChangeComplete', () => {
        onRouteChange();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // a standalone page is for example the Planner or SearchBox page
  const hideDeskmode = _standalonePages.default.some(page => router.pathname.endsWith(page));
  const shouldRenderGTMScript = !brandConfig.template?.disableGTMWhenActive || hideDeskmode;
  return __jsx(_reactIntl.RawIntlProvider, {
    value: intl
  }, __jsx(GlobalStylings, null), __jsx(_head.default, null, brandConfig.seo.twitterUsername && __jsx("meta", {
    name: "twitter:site",
    content: brandConfig.seo.twitterUsername
  }), brandConfig.seo.siteDescription?.[locale] && __jsx("meta", {
    name: "description",
    content: brandConfig.seo.siteDescription[locale]
  }), brandConfig.seo.homeTitle?.[locale] && __jsx("title", null, brandConfig.seo.homeTitle[locale]), __jsx("meta", {
    property: "og:locale",
    content: locale
  }), __jsx("meta", {
    property: "og:url",
    content: `${process.env.NEXT_PUBLIC_BASE_URL ?? ''}/${router.locale}${router.asPath}`
  }), __jsx("meta", {
    property: "og:type",
    content: "website"
  }), brandConfig.gtmID && shouldRenderGTMScript && __jsx("script", {
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML: {
      __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${typeof brandConfig.gtmID === 'string' ? brandConfig.gtmID : brandConfig.gtmID[locale]}');`
    },
    type: "text/javascript"
  })), __jsx(_devicesize.default, null, __jsx(_QueryParamProvider.QueryParamProvider, null, __jsx(_checkout.CheckoutProvider, null, Navigation && __jsx(Navigation, null), !hideDeskmode && __jsx(DeskMode, null), props.children, Footer && __jsx(Footer, null)))), __jsx(_Toasts.default, null));
};
var _default = exports.default = TorApp;