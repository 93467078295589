"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _messages = _interopRequireDefault(require("../constants/messages"));
const getTripGroupText = (intl, {
  amountAdults = 0,
  amountChildren = 0,
  amountBabies = 0,
  amountPets = 0,
  amountYouths = 0
}, long = false) => {
  const {
    formatMessage,
    formatList
  } = intl;
  const amountGuests = amountAdults + amountYouths + amountChildren + amountBabies;
  const guestsText = formatMessage(_messages.default.guests, {
    amountGuests
  }) || '';
  const petsText = formatMessage(_messages.default.pets, {
    amountPets
  }) || '';
  if (long) {
    const list = [amountAdults && formatMessage(_messages.default.adults, {
      amountAdults
    }), amountYouths && formatMessage(_messages.default.youth, {
      amountYouths
    }), amountChildren && formatMessage(_messages.default.children, {
      amountChildren
    }), amountBabies && formatMessage(_messages.default.babies, {
      amountBabies
    }), formatMessage(_messages.default.pets, {
      amountPets
    })];
    return formatList(
    // filter out all falsey values
    list.filter(message => message), {
      type: 'conjunction'
    });
  }
  return `${guestsText}${petsText && `, ${petsText}`}`;
};
var _default = exports.default = getTripGroupText;