"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _devicesize = require("@oberoninternal/travelbase-ds/context/devicesize");
var _grid = require("@rebass/grid");
var _image = _interopRequireDefault(require("next/image"));
var _router = require("next/router");
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _TenantContext = require("../context/TenantContext");
var _Heading = _interopRequireDefault(require("./designsystem/Heading"));
var _Page = _interopRequireDefault(require("./Page"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const ErrorTemplate = ({
  title,
  description,
  children
}) => {
  const {
    push
  } = (0, _router.useRouter)();
  const {
    0: animate,
    1: setAnimate
  } = (0, _react.useState)(false);
  const pushToHome = () => {
    push(`/`);
  };
  const deviceSize = (0, _devicesize.useDeviceSize)();
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const onClickHandler = () => {
    if (deviceSize === 'mobile' || deviceSize === 'tablet') {
      pushToHome();
    } else {
      setAnimate(true);
    }
  };
  return __jsx(_Page.default, null, __jsx(_grid.Flex, {
    style: {
      height: '100vh',
      position: 'relative'
    },
    alignItems: "center"
  }, __jsx(Content, {
    flexDirection: "column",
    animate: animate
  }, __jsx(_Heading.default, {
    variant: "large"
  }, __jsx(_Title.default, {
    variant: "large"
  }, title)), __jsx(_Body.default, null, description), children, !children && __jsx(_Button.default, {
    onClick: onClickHandler,
    as: "a",
    style: {
      marginTop: '5.6rem',
      alignSelf: 'flex-start',
      cursor: 'pointer'
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "5a+zJe",
    defaultMessage: [{
      "type": 0,
      "value": "Terug naar de homepagina"
    }]
  }))), __jsx(ImgWrapper, {
    className: "gt-l",
    animate: animate,
    onAnimationEnd: pushToHome
  }, brandConfig.indexImage && __jsx(_image.default, {
    priority: true,
    alt: brandConfig.seo.siteName,
    src: brandConfig.indexImage,
    layout: "fill",
    objectFit: "cover",
    quality: 100,
    placeholder: "blur"
  }))));
};
var _default = exports.default = ErrorTemplate;
const Content = (0, _styledComponents.default)(_grid.Flex).withConfig({
  displayName: "ErrorTemplate__Content",
  componentId: "sc-4rvyej-0"
})(["margin:auto;z-index:1;@media screen and (min-width:", "){margin:unset;position:absolute;left:13vw;width:30%;animation:", " 300ms forwards ease-out;}@keyframes fadeout{from{opacity:1;transform:translateY(0);}to{opacity:0;transform:translateY(5rem);}}"], ({
  theme
}) => theme.mediaQueries.l, ({
  animate
}) => animate && 'fadeout');
const ImgWrapper = _styledComponents.default.div.withConfig({
  displayName: "ErrorTemplate__ImgWrapper",
  componentId: "sc-4rvyej-1"
})(["width:calc(100vw + var(--wrapperPadding));height:100vh;clip-path:inset(0 0 0 50%);@keyframes fadein{from{clip-path:inset(0 0 0 50%);}to{clip-path:inset(0);}}animation:", " 500ms forwards ease-out;animation-delay:300ms;img{object-fit:cover;}"], ({
  animate
}) => animate && 'fadein');