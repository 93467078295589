import React, { SVGAttributes } from 'react';

const Caret = (props: SVGAttributes<SVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 18 10" {...props}>
        <defs />
        <path
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1l3.75 3.75.644.644.806.806L9 9l8-8"
        />
    </svg>
);

export default Caret;
