"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CLASS_STICKY_BAR = exports.CLASS_SEARCH_BOX_TRIP_GROUP_FIELD_ROUND_BUTTON = exports.CLASS_SEARCH_BOX_TRIP_GROUP_FIELD = exports.CLASS_SEARCH_BOX_FIELDSET = exports.CLASS_SEARCH_BOX_DATE_PICKER_FIELD = exports.CLASS_SEARCH_BOX_CONTAINER_MOBILE = exports.CLASS_SEARCH_BOX_CONTAINER = exports.CLASS_SEARCH_BOX_BUTTON_CONTAINER = exports.CLASS_SEARCH_BOX_BUTTON = exports.CLASS_SEARCH_BOX_ACCOMMODATION_TYPE_FIELD = exports.CLASS_FIELDSET_LABEL = exports.CLASS_DESKMODE_BUTTON = void 0;
/*
 These class names are used to provide a means for style overrides by a third party stylesheet.
 Consider this an overview of stylable elements and a way to ensure these class names don't accidentally change or get
 deleted since they have external stylesheets relying on them. This should not replace theming but should provide more
 detailed control over the styles of some elements, mainly the search box iframe version that gets integrated into
 external websites.
 */

const CLASS_SEARCH_BOX_BUTTON_CONTAINER = exports.CLASS_SEARCH_BOX_BUTTON_CONTAINER = 'ext-search-box__button-container';
const CLASS_SEARCH_BOX_CONTAINER = exports.CLASS_SEARCH_BOX_CONTAINER = 'ext-search-box__container';
const CLASS_SEARCH_BOX_CONTAINER_MOBILE = exports.CLASS_SEARCH_BOX_CONTAINER_MOBILE = 'ext-search-box__container-mobile';
const CLASS_SEARCH_BOX_FIELDSET = exports.CLASS_SEARCH_BOX_FIELDSET = 'ext-search-box__fieldset';
const CLASS_SEARCH_BOX_BUTTON = exports.CLASS_SEARCH_BOX_BUTTON = 'ext-search-box__search-button';
const CLASS_SEARCH_BOX_TRIP_GROUP_FIELD = exports.CLASS_SEARCH_BOX_TRIP_GROUP_FIELD = 'ext-search-box__trip-group-field';
const CLASS_SEARCH_BOX_TRIP_GROUP_FIELD_ROUND_BUTTON = exports.CLASS_SEARCH_BOX_TRIP_GROUP_FIELD_ROUND_BUTTON = 'ext-search-box__trip-group-field__round-button';
const CLASS_SEARCH_BOX_DATE_PICKER_FIELD = exports.CLASS_SEARCH_BOX_DATE_PICKER_FIELD = 'ext-search-box__date-picker-field';
const CLASS_SEARCH_BOX_ACCOMMODATION_TYPE_FIELD = exports.CLASS_SEARCH_BOX_ACCOMMODATION_TYPE_FIELD = 'ext-search-box__accommodation-type-field';
const CLASS_FIELDSET_LABEL = exports.CLASS_FIELDSET_LABEL = 'ext-fieldset__label';
const CLASS_STICKY_BAR = exports.CLASS_STICKY_BAR = 'travelbase-stickybar';
const CLASS_DESKMODE_BUTTON = exports.CLASS_DESKMODE_BUTTON = 'ext-deskmode__button';