import React from 'react';
import NextLink, { LinkProps } from 'next/link';

interface Props extends LinkProps {
    prefetch?: boolean;
}

// The default next/link is wrapped to set prefetch to false, to reduce API requests
const Link: React.FC<React.PropsWithChildren<Props>> = ({ prefetch = false, ...rest }) => (
    <NextLink {...rest} prefetch={prefetch} legacyBehavior />
);

export default Link;
