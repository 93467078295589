"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _ = _interopRequireDefault(require("../pages/404"));
var _ErrorTemplate = _interopRequireDefault(require("./ErrorTemplate"));
var _CheckoutEmpty = _interopRequireDefault(require("./pages/CheckoutEmpty"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const ErrorFallback = props => {
  (0, _react.useEffect)(() => {
    window.scrollTo(0, 0);
  }, []);
  if (props.error) {
    switch (props.error.name) {
      case 'InvalidCheckoutError':
        return __jsx(_CheckoutEmpty.default, null);
      case 'NotFoundError':
        return __jsx(_.default, null);
      default:
        break;
    }
  }
  return __jsx(_ErrorTemplate.default, {
    title: __jsx(_reactIntl.FormattedMessage, {
      id: "FzrmrB",
      defaultMessage: [{
        "type": 0,
        "value": "Oeps! "
      }, {
        "type": 1,
        "value": "statusCode"
      }],
      values: {
        statusCode: ''
      }
    }),
    description: __jsx(_reactIntl.FormattedMessage, {
      id: "rqGIsJ",
      defaultMessage: [{
        "type": 0,
        "value": "Bij het opvragen van deze pagina is er iets misgegaan. Probeer het later opnieuw."
      }]
    })
  }, __jsx(_Button.default, {
    onClick: props.resetErrorBoundary,
    style: {
      marginTop: '5.6rem',
      alignSelf: 'flex-start',
      cursor: 'pointer'
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "f/XZUd",
    defaultMessage: [{
      "type": 0,
      "value": "Probeer het opnieuw"
    }]
  })));
};
var _default = exports.default = ErrorFallback;