"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = _interopRequireDefault(require("react"));
var _image = _interopRequireDefault(require("next/image"));
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _UnreachableCaseError = require("@oberoninternal/travelbase-ds/entities/UnreachableCaseError");
var _grid = require("@rebass/grid");
const _excluded = ["placeholder", "fallback", "sources", "loading", "width", "height", "alt", "className"];
/* eslint-disable jsx-a11y/alt-text */
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
/**
 * There are basically three ways to use the `Img` component.
 *
 * 1. Default, where width and height are required and an image with static dimensions will be displayed
 * 2. `layout: fill` will fill its parent container
 * 3. `layout: ratio` will size the image according to the ratio that's given as a prop
 */
function Img(props) {
  const {
      placeholder,
      fallback,
      sources,
      loading,
      width,
      height,
      alt,
      className
    } = props,
    rest = (0, _objectWithoutProperties2.default)(props, _excluded);
  const srcSet = sources?.[0].srcSet?.split(', ').map(src => src.split(' '));
  return __jsx(Container, {
    key: fallback.src,
    width: width,
    height: height,
    className: className ?? fallback.className ?? '',
    ratio: rest.layout === 'ratio' ? rest.ratio : 0,
    layout: rest.layout
  }, __jsx(_image.default, {
    src: sources?.[0].src ?? fallback.src ?? '',
    sizes: sources?.[0].sizes ?? '',
    alt: alt ?? '',
    width: width,
    height: height,
    fill: true,
    loader: ({
      src,
      width: srcWidth
    }) => {
      const foundSrc = srcSet?.reduce((prev, [s, w]) => {
        const innerWidth = parseInt(w, 10);
        if (!prev || Math.abs((srcWidth ?? 0) - innerWidth) < Math.abs((srcWidth ?? 0) - prev.width)) {
          return {
            src: s,
            width: innerWidth
          };
        }
        return prev;
      }, null)?.src;
      return foundSrc ?? src;
    },
    placeholder: placeholder ? 'blur' : 'empty',
    blurDataURL: placeholder,
    loading: loading
  }));
}
const parseDimension = amount => typeof amount === 'number' ? `${amount}px` : amount;
const Container = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "Img__Container",
  componentId: "rtrrd9-0"
})(["width:", ";height:", ";overflow:hidden;", " img{position:absolute;left:0;top:0;right:0;bottom:0;width:100%;height:100%;object-fit:cover;object-position:center;}"], ({
  width
}) => parseDimension(width), ({
  height
}) => parseDimension(height), ({
  layout,
  ratio = 0
}) => {
  if (!layout) {
    return undefined;
  }
  switch (layout) {
    case 'fill':
      return (0, _styledComponents.css)(["position:absolute;left:0;top:0;right:0;bottom:0;"]);
    case 'ratio':
      return (0, _styledComponents.css)(["position:relative;&:before{display:block;content:'';width:100%;padding-top:", "%;}"], Math.round(ratio * 100));
    default:
      throw new _UnreachableCaseError.UnreachableCaseError(layout);
  }
});
var _default = exports.default = Img;