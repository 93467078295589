"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
class NotFoundError extends Error {
  constructor(...args) {
    super(...args);
    (0, _defineProperty2.default)(this, "name", 'NotFoundError');
  }
}
exports.default = NotFoundError;