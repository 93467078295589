"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _grid = require("@rebass/grid");
var _Dropdown = _interopRequireWildcard(require("./designsystem/Dropdown"));
var _reactIntl = require("react-intl");
var _react = _interopRequireDefault(require("react"));
var _formik = require("formik");
var _Context = require("./designsystem/datepicker/Context");
var _SearchDateField = require("./SearchDateField");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const SearchDateFieldMobile = () => {
  const {
    values
  } = (0, _formik.useFormikContext)();
  const {
    formatDate
  } = (0, _reactIntl.useIntl)();
  const {
    datepicker: [, {
      set
    }]
  } = (0, _Context.useDatepickerContext)();
  return __jsx(_Dropdown.default, {
    open: false,
    onToggle: () => set({
      open: true
    }),
    surfaceElement: __jsx(_grid.Flex, {
      flexWrap: "wrap"
    }, __jsx(_grid.Box, {
      width: 1
    }, __jsx(_grid.Box, null, __jsx(_Dropdown.DropdownDescription, null, (0, _SearchDateField.getSearchDateLabel)(values.booking, formatDate)))))
  });
};
var _default = exports.default = SearchDateFieldMobile;