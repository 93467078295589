"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rentalUnitTypes = exports.getRentalUnitTypeByPrefixedType = exports.accommodationTypes = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// In a way to achieve some kind of type checking we can use these types for defining accommodation and rentalunit filters instead
// of just typing out the filter, which is prone to errors

const withPrefix = (obj, prefix) => Object.entries(obj).reduce((prev, [key, value]) => _objectSpread(_objectSpread({}, prev), {}, {
  [key]: `${prefix}.${value}`
}), {});

// Uber types
const accommodationTypes = exports.accommodationTypes = withPrefix({
  HOTEL: 'hotel',
  APARTMENT_BLOCK: 'apartment-block',
  BED_AND_BREAKFAST: 'bed-and-breakfast',
  BUNGALOW_PARK: 'bungalow-park',
  CAMPING: 'camping',
  LOCATION: 'locatie'
}, '_accommodation-type');
const getRentalUnitTypeByPrefixedType = type => Object.keys(rentalUnitTypes)[Object.values(rentalUnitTypes).indexOf(type)];

// Super types
exports.getRentalUnitTypeByPrefixedType = getRentalUnitTypeByPrefixedType;
const rentalUnitTypes = exports.rentalUnitTypes = withPrefix({
  BED: 'bed',
  CAMPER_SPOT: 'camper-spot',
  CAMPING_PITCH: 'camping-pitch',
  HOME: 'home',
  ROOM: 'room',
  TENT: 'tent',
  BOAT: 'boat'
}, '_rental-unit-type');