"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.APOLLO_STATE_PROP_NAME = void 0;
exports.addApolloState = addApolloState;
exports.initApolloClient = exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _client = require("@apollo/client");
var _deepmerge = _interopRequireDefault(require("deepmerge"));
var _lodash = _interopRequireDefault(require("lodash.isequal"));
var _nodeFetch = _interopRequireDefault(require("node-fetch"));
var _fragmentTypes = _interopRequireDefault(require("./generated/fragmentTypes.json"));
var _auth = require("./utils/auth");
var _getStorageWithExpiry = _interopRequireDefault(require("./utils/getStorageWithExpiry"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } // eslint-disable-next-line import/extensions
const APOLLO_STATE_PROP_NAME = exports.APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
const authLink = new _client.ApolloLink((operation, forward) => {
  // only add the access token on client-side
  if (typeof window !== 'undefined') {
    const token = (0, _getStorageWithExpiry.default)('local')?.getItem(_auth.ACCESS_TOKEN);
    if (token) {
      operation.setContext(ctx => _objectSpread(_objectSpread({}, ctx ?? {}), {}, {
        headers: {
          authorization: `Bearer ${token}`
        }
      }));
    }
  }
  return forward(operation);
});
const createApolloClient = (locale, initialState = {}, fragmentTypes, links = []) => {
  const ssrMode = typeof window === 'undefined';
  const cache = new _client.InMemoryCache({
    possibleTypes: fragmentTypes ?? _fragmentTypes.default.possibleTypes,
    typePolicies: {
      Cart: {
        fields: {
          order: {
            merge(existing, incoming) {
              return _objectSpread(_objectSpread({}, existing), incoming);
            }
          }
        }
      }
    }
  }).restore(initialState);
  const fetch = ssrMode ? _nodeFetch.default : window.fetch;
  return new _client.ApolloClient({
    ssrMode,
    link: _client.ApolloLink.from([authLink, ...links, new _client.HttpLink({
      uri: process.env.NEXT_PUBLIC_API_URL,
      fetch: fetch,
      headers: {
        'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
        'x-site-language': locale
      }
    })]),
    cache
  });
};
let globalApolloClient;
/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 */
const initApolloClient = (...args) => {
  const [, initialState] = args;
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient(...args);
  }

  // Reuse client on the client-side
  if (!globalApolloClient) {
    globalApolloClient = createApolloClient(...args);
  }
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = globalApolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = (0, _deepmerge.default)(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [...sourceArray, ...destinationArray.filter(d => sourceArray.every(s => !(0, _lodash.default)(d, s)))]
    });

    // Restore the cache with the merged data
    globalApolloClient.cache.restore(data);
  }
  return globalApolloClient;
};
exports.initApolloClient = initApolloClient;
function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }
  return pageProps;
}
var _default = exports.default = createApolloClient;