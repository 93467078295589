"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _Img = _interopRequireDefault(require("../Img"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireWildcard(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _UnreachableCaseError = require("@oberoninternal/travelbase-ds/entities/UnreachableCaseError");
var _grid = require("@rebass/grid");
var _react = _interopRequireDefault(require("react"));
var _reactLoadingSkeleton = _interopRequireDefault(require("react-loading-skeleton"));
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _Stack = _interopRequireDefault(require("../Stack"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
const _excluded = ["title", "strapline", "subTitle", "thumbnail", "loading", "variant"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const UnitThumbnail = _ref => {
  let {
      title,
      strapline,
      subTitle,
      thumbnail,
      loading,
      variant
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return __jsx(UnitThumbnailContainer, (0, _extends2.default)({
    withLocation: !!strapline
  }, props), loading ? __jsx(_reactLoadingSkeleton.default, {
    width: "8rem",
    height: "5.6rem"
  }) : __jsx(_react.default.Fragment, null, thumbnail && __jsx(Thumbnail, (0, _extends2.default)({}, thumbnail, {
    sources: thumbnail.sources?.map(source => _objectSpread(_objectSpread({}, source), {}, {
      sizes: '88px'
    })),
    variant: variant
  }))), __jsx(_grid.Flex, {
    flexDirection: "column",
    mx: 3,
    width: 1
  }, loading && __jsx(_Stack.default, {
    spacing: 1,
    width: 1
  }, __jsx(_reactLoadingSkeleton.default, {
    height: "1.5rem",
    width: "8rem"
  }), __jsx(_reactLoadingSkeleton.default, {
    height: "1.5rem",
    width: "100%"
  })), strapline && __jsx(_Title.default, {
    variant: "regular",
    title: strapline,
    style: {
      fontWeight: 'bold'
    }
  }, strapline), variant === 'large' && title ? __jsx(StyledTitle, {
    variant: "large",
    title: title
  }, title) : __jsx(StyledTitle, {
    variant: "micro",
    title: title
  }, title), subTitle && variant === 'large' ? __jsx(Subtitle, {
    variant: "micro"
  }, subTitle) : subTitle));
};
const StyledTitle = (0, _styledComponents.default)(_Title.default).withConfig({
  displayName: "UnitThumbnail__StyledTitle",
  componentId: "sc-13aow6q-0"
})(["font-weight:", ";"], ({
  theme
}) => theme.fontWeights.bold);
const UnitThumbnailContainer = (0, _styledComponents.default)(_grid.Flex).attrs({
  alignItems: 'center'
}).withConfig({
  displayName: "UnitThumbnail__UnitThumbnailContainer",
  componentId: "sc-13aow6q-1"
})(["", "{text-align:start;display:-webkit-box;-webkit-line-clamp:2;text-overflow:ellipsis;-webkit-box-orient:vertical;overflow:hidden;", ";line-height:1.25 !important;}", " padding-right:0;"], _Title.TitleInner, ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.title.micro), ({
  withLocation
}) => withLocation && (0, _styledComponents.css)(["", "{", ";line-height:1.25 !important;-webkit-line-clamp:1;}"], _Title.TitleInner, ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.title.micro)));
var _default = exports.default = UnitThumbnail;
const regularCss = (0, _styledComponents.css)(["width:8rem;height:5.6rem;"]);
const largeCss = (0, _styledComponents.css)(["height:4rem;width:4rem;@media screen and (min-width:", "){margin-right:", ";height:8.8rem;width:8.8rem;}"], ({
  theme
}) => theme.mediaQueries.m, ({
  theme
}) => theme.spacing['50_Semi']);
const getThumbnailCss = variant => {
  switch (variant) {
    case 'regular':
      return regularCss;
    case 'large':
      return largeCss;
    default:
      throw new _UnreachableCaseError.UnreachableCaseError(variant);
  }
};
const Thumbnail = (0, _styledComponents.default)(_Img.default).withConfig({
  displayName: "UnitThumbnail__Thumbnail",
  componentId: "sc-13aow6q-2"
})(["position:relative;flex-shrink:0;", ";img{object-fit:cover;}"], ({
  variant = 'regular'
}) => getThumbnailCss(variant));
const Subtitle = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "UnitThumbnail__Subtitle",
  componentId: "sc-13aow6q-3"
})(["", ";line-height:1.25;margin-top:0.4rem;@media screen and (min-width:", "){", ";}"], ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.title.micro), ({
  theme
}) => theme.mediaQueries.m, ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.title.tiny));