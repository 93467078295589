"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Caret = _interopRequireDefault(require("../svg/Caret.svg"));
const StyledCaret = (0, _styledComponents.default)(_Caret.default).withConfig({
  displayName: "StyledCaret",
  componentId: "sc-1yqgyn8-0"
})(["width:1.2rem;@media screen and (min-width:", "){width:1.4rem;}width:1.4rem;"], ({
  theme
}) => theme.mediaQueries.l);
var _default = exports.default = StyledCaret;