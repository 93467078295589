"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactIntl = require("react-intl");
// This is optional but highly recommended
// since it prevents memory leak
const cache = (0, _reactIntl.createIntlCache)();
var _default = exports.default = cache;