"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
const useOnEscape = callback => {
  (0, _react.useEffect)(() => {
    const onEscape = event => {
      if (event.code === 'Escape') {
        callback();
      }
    };
    document.addEventListener('keydown', onEscape);
    return () => document.removeEventListener('keydown', onEscape);
  }, [callback]);
};
var _default = exports.default = useOnEscape;