/* eslint-disable no-restricted-imports,import/extensions */
import { initApolloClient as initApollo } from '@oberoninternal/travelbase-website/dist/createApolloClient';
import { ApolloLink, NormalizedCacheObject } from '@apollo/client';
import fragmentTypes from './generated/fragmentTypes.json';

const createPreviewLink = (token: string) =>
    new ApolloLink((operation, forward) => {
        // are we previewing?
        operation.setContext((ctx = {}) => ({
            ...ctx,
            uri: `${process.env.NEXT_PUBLIC_API_URL}?token=${token}`,
        }));

        return forward(operation);
    });

export interface PreviewData {
    token: string;
}

const initApolloClient = (
    locale: string,
    opts: { previewData?: PreviewData; initialState?: NormalizedCacheObject } = {}
) => {
    const links: ApolloLink[] = [];

    const token = opts.previewData?.token;
    if (token) {
        links.push(createPreviewLink(token));
    }

    const client = initApollo(locale, opts.initialState, fragmentTypes.possibleTypes, links);

    return client;
};
export default initApolloClient;
