"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDateFnsLocale = void 0;
var _nl = _interopRequireDefault(require("date-fns/locale/nl"));
var _enGB = _interopRequireDefault(require("date-fns/locale/en-GB"));
var _de = _interopRequireDefault(require("date-fns/locale/de"));
const getDateFnsLocale = locale => ({
  nl: _nl.default,
  de: _de.default,
  en: _enGB.default
})[locale];
exports.getDateFnsLocale = getDateFnsLocale;