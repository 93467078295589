"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.createDayModifier = void 0;
var _ErrorMessage = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/form/ErrorMessage"));
var _devicesize = require("@oberoninternal/travelbase-ds/context/devicesize");
var _validInterval = _interopRequireDefault(require("@oberoninternal/travelbase-ds/utils/validInterval"));
var _grid = require("@rebass/grid");
var _differenceInCalendarDays = _interopRequireDefault(require("date-fns/differenceInCalendarDays"));
var _isBefore = _interopRequireDefault(require("date-fns/isBefore"));
var _isSameDay = _interopRequireDefault(require("date-fns/isSameDay"));
var _isWithinInterval = _interopRequireDefault(require("date-fns/isWithinInterval"));
var _startOfToday = _interopRequireDefault(require("date-fns/startOfToday"));
var _router = require("next/router");
var _react = _interopRequireWildcard(require("react"));
var _parseToDateString = _interopRequireDefault(require("../../constants/parseToDateString"));
var _useDatepicker = _interopRequireWildcard(require("../../hooks/useDatepicker"));
var _useOnEscape = _interopRequireDefault(require("../../hooks/useOnEscape"));
var _usePrevious = _interopRequireDefault(require("../../hooks/usePrevious"));
var _isDate = _interopRequireDefault(require("../../utils/isDate"));
var _localeHelpers = require("../../utils/localeHelpers");
var _Context = _interopRequireDefault(require("./datepicker/Context"));
var _Inputs = _interopRequireDefault(require("./datepicker/Inputs"));
var _Inline = _interopRequireDefault(require("./datepicker/Inline"));
var _useOnclickoutside = _interopRequireDefault(require("use-onclickoutside"));
var _DatepickerModal = _interopRequireDefault(require("./datepicker/DatepickerModal"));
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _Month = require("./datepicker/Month");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const createDayModifier = state => {
  const {
    period,
    selecting,
    focus
  } = state;
  return day => {
    if ((0, _isBefore.default)(day, (0, _startOfToday.default)())) {
      return {
        variant: 'disabled'
      };
    }
    if (!(0, _isDate.default)(period)) {
      const blocked = period?.start && ((0, _differenceInCalendarDays.default)(day, period.start) > _useDatepicker.MAX_DAYS || (0, _isBefore.default)(day, period.start));
      const {
        start,
        end
      } = period ?? {};
      if (start && end && (0, _isSameDay.default)(day, end) && (0, _isSameDay.default)(day, start)) {
        return {
          variant: 'selected'
        };
      }
      if (start && (0, _isSameDay.default)(day, start)) {
        return {
          variant: 'selected-start'
        };
      }
      if (start && end && (0, _isSameDay.default)(day, end)) {
        return {
          variant: 'selected-end'
        };
      }
      if ((selecting || focus === 'end') && blocked) {
        return {
          variant: 'blocked'
        };
      }
      if ((0, _validInterval.default)(period) && (0, _isWithinInterval.default)(day, period)) {
        return {
          variant: 'bookable-within'
        };
      }
    } else if ((0, _isSameDay.default)(period, day)) {
      return {
        variant: 'selected'
      };
    }
    return {
      variant: 'bookable'
    };
  };
};
exports.createDayModifier = createDayModifier;
const periodsAreEqual = (a, b) => (0, _validInterval.default)(a) && (0, _validInterval.default)(b) ? (0, _isSameDay.default)(a.start, b.start) && (0, _isSameDay.default)(a.end, b.end) : a === b;
const periodsDiffer = (a, b) => (0, _isDate.default)(a) || (0, _isDate.default)(b.current) ? !(0, _isSameDay.default)(a, b.current) : !periodsAreEqual(a, b.current);
const Datepicker = ({
  onChange,
  value,
  defaultValue,
  className,
  scrollIntoView,
  error,
  align,
  hideCalendarIcon = false,
  singleDate,
  optionalSingleDate,
  hideHelper,
  children,
  hideInput,
  hasFlexibleDates,
  datepicker: customDatepicker,
  ignoreClickOutside,
  variant = 'field'
}) => {
  const internalDatepicker = (0, _useDatepicker.default)({
    singleDate,
    optionalSingleDate,
    period: defaultValue,
    open: false
  }, variant === 'inline');
  const datepicker = customDatepicker ?? internalDatepicker;
  const [state, {
    set,
    sesame,
    reset
  }] = datepicker;
  const deviceSize = (0, _devicesize.useDeviceSize)();
  const {
    open,
    period,
    selecting
  } = state;
  const router = (0, _router.useRouter)();
  const computeDayProps = (0, _react.useMemo)(() => createDayModifier(state), [state]);
  const previousPeriod = (0, _react.useRef)(period);
  const dateLocale = (0, _localeHelpers.getDateFnsLocale)(router.locale);

  // when out of sync, perform an onChange
  (0, _react.useEffect)(() => {
    if (!onChange || selecting || value === period) {
      return;
    }
    if (periodsDiffer(period, previousPeriod)) {
      onChange(period);
      previousPeriod.current = period;
    }
  }, [onChange, period, selecting, value]);
  const prevValue = (0, _react.useRef)(value);
  (0, _react.useEffect)(() => {
    if (periodsDiffer(value, prevValue)) {
      set({
        period: value
      });
      prevValue.current = value;
    }
  }, [period, set, value]);
  const previousOpen = (0, _usePrevious.default)(open);

  // Scroll to the first date if there's a period selected and the modal is going to open;
  (0, _react.useEffect)(() => {
    if (deviceSize === 'mobile' && !(0, _isDate.default)(period) && (0, _validInterval.default)(period) && !previousOpen && open) {
      setTimeout(() => {
        if (period?.start) {
          const dayEl = document.getElementById((0, _parseToDateString.default)(period.start));
          dayEl?.scrollIntoView({
            block: 'center'
          });
        }
      });
    }
  }, [open, previousOpen, period, deviceSize]);
  const containerRef = (0, _react.useRef)(null);
  const onCancel = () => {
    if (selecting) {
      reset();
    } else if (open) {
      sesame('close');
    }
  };
  (0, _useOnclickoutside.default)(containerRef, ignoreClickOutside ? () => {
    // no op
  } : onCancel);
  (0, _useOnEscape.default)(onCancel);
  const context = (0, _react.useMemo)(() => ({
    datepicker,
    computeDayProps,
    locale: dateLocale,
    singleDate
  }), [computeDayProps, datepicker, dateLocale, singleDate]);
  return __jsx(_Context.default, {
    value: context
  }, __jsx(_grid.Box, {
    ref: deviceSize !== 'mobile' ? containerRef : undefined,
    style: {
      position: 'relative',
      height: '100%'
    },
    className: className
  }, children ?? __jsx(_Inputs.default, {
    hideInput: hideInput,
    hideCalendarIcon: hideCalendarIcon
  }), (variant === 'inline' || variant === 'field' && deviceSize !== 'mobile') && __jsx(StyledInline, {
    hideInput: hideInput,
    align: align,
    scrollIntoView: scrollIntoView,
    hasFlexibleDates: hasFlexibleDates,
    forceOpen: variant === 'inline',
    isField: variant === 'field'
  }), error && __jsx(_ErrorMessage.default, null, error)), variant === 'field' && deviceSize === 'mobile' && __jsx(_DatepickerModal.default, {
    hideHelper: hideHelper,
    hasFlexibleDates: hasFlexibleDates
  }));
};
const StyledInline = (0, _styledComponents.default)(_Inline.default).withConfig({
  displayName: "Datepicker__StyledInline",
  componentId: "sc-7hezc-0"
})(["", ""], ({
  isField
}) => isField ? (0, _styledComponents.css)(["@media screen and (min-width:", "){padding:3.2rem 2.4rem;margin-top:1.6rem;min-width:68rem;}"], ({
  theme
}) => theme.mediaQueries.s) : (0, _styledComponents.css)(["", "{@media screen and (min-width:", "){width:100%;height:unset;padding:0;}}"], _Month.StyledMonth, ({
  theme
}) => theme.mediaQueries.s));
var _default = exports.default = Datepicker;