"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactIntl = require("react-intl");
const accommodationMessages = (0, _reactIntl.defineMessages)({
  roomOrHome: {
    "id": "adMhD/",
    "defaultMessage": [{
      "type": 0,
      "value": "Kamer/vakantiehuis"
    }]
  },
  vacationHomes: {
    "id": "McHdzL",
    "defaultMessage": [{
      "type": 0,
      "value": "Alléén vakantiehuizen"
    }]
  },
  hotelOrBnB: {
    "id": "YzIgHk",
    "defaultMessage": [{
      "type": 0,
      "value": "Alléén bij hotel of B&B"
    }]
  },
  bnbOrRoom: {
    "id": "yiOtTS",
    "defaultMessage": [{
      "type": 0,
      "value": "B&B of kamerverhuur"
    }]
  },
  bnb: {
    "id": "vD2RWX",
    "defaultMessage": [{
      "type": 0,
      "value": "B&B"
    }]
  },
  hotel: {
    "id": "V+XwpG",
    "defaultMessage": [{
      "type": 0,
      "value": "Hotel"
    }]
  },
  camping: {
    "id": "VNIn54",
    "defaultMessage": [{
      "type": 0,
      "value": "Kamperen"
    }]
  },
  groupAccommodations: {
    "id": "B1LQE4",
    "defaultMessage": [{
      "type": 0,
      "value": "Groepsaccommodaties"
    }]
  },
  tinyHouse: {
    "id": "cwJCfX",
    "defaultMessage": [{
      "type": 0,
      "value": "Tiny house"
    }]
  },
  tent: {
    "id": "BIYMX0",
    "defaultMessage": [{
      "type": 0,
      "value": "Ingerichte tent"
    }]
  },
  caravan: {
    "id": "jsaSMg",
    "defaultMessage": [{
      "type": 0,
      "value": "Caravan"
    }]
  },
  camperPitch: {
    "id": "O65p99",
    "defaultMessage": [{
      "type": 0,
      "value": "Camperplaats"
    }]
  },
  boat: {
    "id": "1B5OHs",
    "defaultMessage": [{
      "type": 0,
      "value": "Boot"
    }]
  },
  chalet: {
    "id": "K4OupE",
    "defaultMessage": [{
      "type": 0,
      "value": "Chalet"
    }]
  },
  apartment: {
    "id": "GngJxE",
    "defaultMessage": [{
      "type": 0,
      "value": "Appartement"
    }]
  },
  beachHouse: {
    "id": "drbMt1",
    "defaultMessage": [{
      "type": 0,
      "value": "Strandhuis"
    }]
  },
  all: {
    "id": "TGHsFX",
    "defaultMessage": [{
      "type": 0,
      "value": "Volledig aanbod"
    }]
  }
});
var _default = exports.default = accommodationMessages;