"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.wrapperPadding = exports.default = void 0;
var _grid = require("@rebass/grid");
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const wrapperPadding = exports.wrapperPadding = (0, _styledComponents.css)(["--min-padding:22;--max-padding:96;--min-viewport:320;--max-viewport:1600;--wrapperPadding:min( 9.6rem,calc( var(--min-padding) * 1px + (var(--max-padding) - var(--min-padding)) * (100vw - (var(--min-viewport) * 1px)) / (var(--max-viewport) - var(--min-viewport)) ) );--wrapperSpaceCalc:calc(var(--wrapperPadding) + (100vw - var(--max-viewport) * 1px) / 2);--wrapperSpaceLeft:max(var(--wrapperPadding),var(--wrapperSpaceCalc));text-align:left;"]);
const Page = (0, _styledComponents.default)(_grid.Box).withConfig({
  displayName: "Page",
  componentId: "sc-1ubozr8-0"
})(["", ""], wrapperPadding);
var _default = exports.default = Page;