import { useCallback, useEffect } from 'react';
import { Router } from 'next/router';

const usePersonalization = () => {
    // this tells squeezly to re-init it's 'personalisations' which normally only get triggered on pageload.
    // because we're a SPA, this needs to be reinitialized after every navigation.
    const handleRouteChange = useCallback(() => {
        window.sqzl?.p?.push({ event: 'ReloadPersonalization' });
    }, []);

    useEffect(() => {
        Router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            Router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [handleRouteChange]);
};

export default usePersonalization;
