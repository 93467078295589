"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fragment = exports.default = void 0;
var _client = require("@apollo/client");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _Stack = _interopRequireDefault(require("./Stack"));
var _SummaryBarItem = _interopRequireWildcard(require("./SummaryBarItem"));
var _SummaryBarItemPriceLine = _interopRequireDefault(require("./SummaryBarItemPriceLine"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const fragment = exports.fragment = (0, _client.gql)`
    fragment OrderSummaryItemUpsellPurchase on UpsellPurchase {
        priceLines {
            ...PriceLine
        }
        totalPrice
        paymentPrice
        offer {
            id
        }
    }

    fragment PriceLine on PriceLine {
        label
        quantity
        totalPrice
        unitPrice
        category
    }
`;
const SummaryBarItemUpsell = ({
  item,
  updating
}) => __jsx(_SummaryBarItem.default, {
  updating: updating,
  item: item
}, __jsx(_SummaryBarItem.SummaryBarItemField, {
  title: __jsx(_reactIntl.FormattedMessage, {
    id: "fNJkWn",
    defaultMessage: [{
      "type": 0,
      "value": "Speciale aanbieding"
    }]
  })
}, __jsx(_Stack.default, {
  spacing: 3
}, item.priceLines.map((line, key) => __jsx(_SummaryBarItemPriceLine.default, {
  priceLine: line,
  key: key
})))));
var _default = exports.default = SummaryBarItemUpsell;