"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.truthy = exports.not = exports.applicable = void 0;
const not = fn => function negated(...args) {
  return !fn(...args);
};
exports.not = not;
const applicable = (...args) => val => args.every(arg => arg(val));
exports.applicable = applicable;
const truthy = val => !!val;
exports.truthy = truthy;