"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var __jsx = _react.default.createElement;
const surchargeCalculationTexts = {
  PER_PIECE: __jsx(_reactIntl.FormattedMessage, {
    id: "+NtB5P",
    defaultMessage: [{
      "type": 0,
      "value": "eenmalig"
    }]
  }),
  PER_PIECE_PER_NIGHT: __jsx(_reactIntl.FormattedMessage, {
    id: "e3B/4s",
    defaultMessage: [{
      "type": 0,
      "value": "per nacht"
    }]
  }),
  PER_PET: __jsx(_reactIntl.FormattedMessage, {
    id: "jBNnSS",
    defaultMessage: [{
      "type": 0,
      "value": "per huisdier"
    }]
  }),
  PER_PET_PER_NIGHT: __jsx(_reactIntl.FormattedMessage, {
    id: "iEzRwb",
    defaultMessage: [{
      "type": 0,
      "value": "per huisdier per nacht"
    }]
  }),
  PER_PERSON: __jsx(_reactIntl.FormattedMessage, {
    id: "CMh514",
    defaultMessage: [{
      "type": 0,
      "value": "per persoon"
    }]
  }),
  PER_PERSON_PER_NIGHT: __jsx(_reactIntl.FormattedMessage, {
    id: "YQBa0/",
    defaultMessage: [{
      "type": 0,
      "value": "per persoon per nacht"
    }]
  }),
  SUBSEQUENT: __jsx(_reactIntl.FormattedMessage, {
    id: "7WXL/T",
    defaultMessage: [{
      "type": 0,
      "value": "nacalculatie"
    }]
  })
};
var _default = exports.default = surchargeCalculationTexts;