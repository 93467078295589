"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactIntl = require("react-intl");
const travelGroupMessages = {
  adults: {
    name: 'amountAdults',
    label: (0, _reactIntl.defineMessage)({
      "id": "kqs3yQ",
      "defaultMessage": [{
        "type": 0,
        "value": "Volwassenen"
      }]
    }),
    description: (0, _reactIntl.defineMessage)({
      "id": "RLod5N",
      "defaultMessage": [{
        "type": 0,
        "value": "v.a. 18 jaar"
      }]
    })
  },
  babies: {
    name: 'amountBabies',
    label: (0, _reactIntl.defineMessage)({
      "id": "zwaGCR",
      "defaultMessage": [{
        "type": 0,
        "value": "Baby's"
      }]
    }),
    description: (0, _reactIntl.defineMessage)({
      "id": "C6OL3B",
      "defaultMessage": [{
        "type": 0,
        "value": "0 t/m 2 jaar"
      }]
    })
  },
  children: {
    name: 'amountChildren',
    label: (0, _reactIntl.defineMessage)({
      "id": "6ju3dZ",
      "defaultMessage": [{
        "type": 0,
        "value": "Kinderen"
      }]
    }),
    description: (0, _reactIntl.defineMessage)({
      "id": "LBohLR",
      "defaultMessage": [{
        "type": 0,
        "value": "3 t/m 17 jaar"
      }]
    })
  },
  pets: {
    name: 'amountPets',
    label: (0, _reactIntl.defineMessage)({
      "id": "DH//zo",
      "defaultMessage": [{
        "type": 0,
        "value": "Huisdieren"
      }]
    })
  },
  youth: {
    name: 'amountYouths',
    label: (0, _reactIntl.defineMessage)({
      "id": "/ofmKk",
      "defaultMessage": [{
        "type": 0,
        "value": "Jongeren"
      }]
    }),
    description: (0, _reactIntl.defineMessage)({
      "id": "dPDWMn",
      "defaultMessage": [{
        "type": 0,
        "value": "13 t/m 20 jaar"
      }]
    })
  }
};
var _default = exports.default = travelGroupMessages;