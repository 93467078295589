import { useEffect } from 'react';
import scrollLock from 'scroll-lock';

const useScrollLock = (enable: boolean) => {
    useEffect(() => {
        if (enable) {
            scrollLock.disablePageScroll();
        } else {
            scrollLock.enablePageScroll();
        }
    }, [enable]);
};
export default useScrollLock;
