"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _useQueryParams = require("use-query-params");
var _react = require("react");
var _removeUndefinedEntries = _interopRequireDefault(require("../utils/removeUndefinedEntries"));
// eslint-disable-next-line no-restricted-imports

// stupid use-queryparam lib still includes keys that aren't even in the URL. including these in the url again confuses the backend.
// ... so omit keys without values.
const useQueryParams = config => {
  const [params, setParams] = (0, _useQueryParams.useQueryParams)(config);
  const paramsWithoutUndefinedValues = (0, _react.useMemo)(() => (0, _removeUndefinedEntries.default)(params), [params]);
  return [paramsWithoutUndefinedValues, setParams];
};
var _default = exports.default = useQueryParams;