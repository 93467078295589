"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fragment = exports.default = void 0;
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _grid = require("@rebass/grid");
var _client = require("@apollo/client");
var _router = require("next/router");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _parseToDateString = _interopRequireDefault(require("../constants/parseToDateString"));
var _graphql = require("../generated/graphql");
var _createImgProps = _interopRequireDefault(require("../utils/createImgProps"));
var _parseDateTime = _interopRequireDefault(require("../utils/parseDateTime"));
var _Stack = _interopRequireDefault(require("./Stack"));
var _SummaryBarItem = _interopRequireWildcard(require("./SummaryBarItem"));
var _SummaryBarItemPriceLine = _interopRequireDefault(require("./SummaryBarItemPriceLine"));
var _useGetPageSlugByLocale = _interopRequireDefault(require("../hooks/useGetPageSlugByLocale"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const fragment = exports.fragment = (0, _client.gql)`
    fragment OrderSummaryItemTicket on Ticket {
        activity {
            id
            slug
            name
            place
            jpegThumbnail: listImage {
                transform(config: TILE, format: JPEG) {
                    ...Transform
                }
            }
            webpThumbnail: listImage {
                transform(config: TILE, format: WEBP) {
                    ...Transform
                }
            }
        }
        timeslot {
            id
            startDateTime
            endDateTime
            rateGroup {
                rates {
                    id
                    name
                    price
                }
            }
        }
    }
`;
const SummaryBarItemTicket = ({
  item,
  hidePrices,
  updating
}) => {
  const {
    activity,
    timeslot,
    ticketStatus
  } = item;
  const router = (0, _router.useRouter)();
  const intl = (0, _reactIntl.useIntl)();
  const {
    getPageSlugByLocale
  } = (0, _useGetPageSlugByLocale.default)();
  const activityPageSlug = getPageSlugByLocale('ACTIVITY');
  if (!activity || !timeslot) {
    return null;
  }
  const {
    name,
    place,
    jpegThumbnail,
    webpThumbnail,
    slug
  } = activity;
  const {
    endDateTime,
    startDateTime
  } = timeslot;
  return __jsx(_SummaryBarItem.default, {
    updating: updating,
    item: item,
    thumbnail: __jsx(_SummaryBarItem.SummaryBarItemThumbnail, {
      onClick: () => {
        router.push('/[activity]/[slug]', {
          pathname: `/${activityPageSlug}/${slug}`,
          query: {
            date: (0, _parseToDateString.default)((0, _parseDateTime.default)(startDateTime))
          }
        });
      },
      thumbnail: jpegThumbnail && webpThumbnail ? (0, _createImgProps.default)(jpegThumbnail, webpThumbnail) : undefined,
      title: name,
      subTitle: __jsx(_SummaryBarItem.SummaryBarItemBody, null, place ? intl.formatMessage({
        "id": "F0+IWa",
        "defaultMessage": [{
          "type": 1,
          "value": "place"
        }, {
          "type": 0,
          "value": ", activiteit"
        }]
      }, {
        place
      }) : undefined)
    })
  }, __jsx(_SummaryBarItem.SummaryBarItemField, {
    title: __jsx(_reactIntl.FormattedMessage, {
      id: "pqoMA0",
      defaultMessage: [{
        "type": 0,
        "value": "Datum"
      }]
    })
  }, __jsx(_Body.default, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedDate, {
    value: (0, _parseDateTime.default)(startDateTime),
    format: "FULLWEEKDAYMONTHYEAR"
  }))), __jsx(_SummaryBarItem.SummaryBarItemField, {
    title: __jsx(_reactIntl.FormattedMessage, {
      id: "vOTnd7",
      defaultMessage: [{
        "type": 0,
        "value": "Tijd"
      }]
    })
  }, __jsx(_Body.default, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedTime, {
    value: (0, _parseDateTime.default)(startDateTime)
  }), " \u2014", ' ', __jsx(_reactIntl.FormattedTime, {
    value: (0, _parseDateTime.default)(endDateTime)
  }))), !hidePrices && __jsx(_SummaryBarItem.SummaryBarItemField, {
    title: __jsx(_reactIntl.FormattedMessage, {
      id: "IRgtJJ",
      defaultMessage: [{
        "type": 0,
        "value": "Prijsopbouw"
      }]
    })
  }, __jsx(_Stack.default, {
    spacing: 3
  }, ticketStatus !== _graphql.TicketStatus.Waived && item.priceLines.map((line, key) => __jsx(_SummaryBarItemPriceLine.default, {
    priceLine: line,
    key: key
  })), ticketStatus === _graphql.TicketStatus.Waived && __jsx(_grid.Flex, null, __jsx(_Body.default, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "Q6Jhmv",
    defaultMessage: [{
      "type": 0,
      "value": "Vervallen"
    }]
  }))))));
};
var _default = exports.default = SummaryBarItemTicket;