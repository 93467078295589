"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styledSystem = require("styled-system");
const Heading = _styledComponents.default.div.withConfig({
  displayName: "Heading",
  componentId: "sc-1ljpr22-0"
})(["margin-bottom:", ";@media screen and (min-width:", "){margin-bottom:", ";}", ";"], ({
  theme
}) => theme.spacing['40_Standard'], ({
  theme
}) => theme.mediaQueries.m, ({
  theme,
  variant
}) => {
  switch (variant) {
    case 'tiny':
      return theme.spacing['30_Small'];
    case 'large':
      return theme.spacing['80_XXLarge'];
    case 'small':
      return '24px';
    default:
      return theme.spacing['50_Semi'];
  }
}, _styledSystem.space);
var _default = exports.default = Heading;