import defaultTheme, { Colors, Spacing } from '@oberoninternal/travelbase-ds/themes/default';
import { TitleVariant } from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { BodyVariant } from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { FontStyle, Theme } from '@oberoninternal/travelbase-ds/constants/theme';

const primary = {
    0: '#F5FBFF',
    5: '#DFF1FB',
    10: '#C9E7F7',
    20: '#A3D5EE',
    30: '#80C1E1',
    40: '#62AAD1', // Helderblauw
    50: '#4F8FC0',
    60: '#3E71A9',
    70: '#23397A',
    80: '#223260', // "Blauw"
    90: '#141940',
    100: '#070717',
};
const colors: Colors = {
    primary,
    // doesn't actually exist for this theme
    tertiary: primary,
    secondary: {
        0: '#FFFDF3', // Warm wit
        5: '#FFF6DD',
        10: '#FFE9BF',
        20: '#F3D76C', // Zacht geel
        30: '#f9be49',
        40: '#f2a71e',
        50: '#E79200',
        60: '#DA8300',
        70: '#CA7300',
        80: '#B66000',
        90: '#A04E00',
        100: '#8A3E00',
    },
    neutral: {
        0: '#ffffff',
        5: '#fbfcfc',
        10: '#f7f8f9',
        20: '#e6e9eb',
        30: '#cbd0d4',
        40: '#a4acb2',
        50: '#767f87',
        60: '#48515b',
        70: '#212a36',
        80: '#060e1c',
        90: '#00040d',
        100: '#000208',
    },
    positive: {
        0: '#ebffeb',
        5: '#E2FDE3',
        10: '#d9fbdb',
        20: '#b3f0b5',
        30: '#7ade7e',
        40: '#36c43c',
        50: '#00a308',
        60: '#008207',
        70: '#006606',
        80: '#005206',
        90: '#004705',
        100: '#004206',
    },
    warning: {
        0: '#ffe8be',
        5: '#fed790',
        10: '#fec662',
        20: '#f9ae2c',
        30: '#f1990b',
        40: '#e48800',
        50: '#d37800',
        60: '#be6500',
        70: '#a65000',
        80: '#8c3d00',
        90: '#722c00',
        100: '#591e00',
    },
    negative: {
        0: '#fff1f1',
        5: '#FEEBEB',
        10: '#fde6e6',
        20: '#f8cecd',
        30: '#f2a8a7',
        40: '#e87975',
        50: '#dc463e',
        60: '#cc1a0d',
        70: '#b71100',
        80: '#9f1400',
        90: '#831400',
        100: '#661300',
    },
};

const spacing: Spacing = {
    '00_None': '0px',
    '10_Micro': '4px',
    '20_Tiny': '8px',
    '30_Small': '12px',
    '40_Standard': '16px',
    '50_Semi': '24px',
    '60_Large': '32px',
    '70_XLarge': '40px',
    '80_XXLarge': '56px',
};

const baseTitle: Partial<FontStyle> = {
    color: colors.primary[80],
};

const titleStyles: Record<TitleVariant, FontStyle> = {
    large: {
        size: { min: 22, max: 32 },
        line: { min: 28, max: 32 },
        weight: 800,
        letterSpacing: 0,
        ...baseTitle,
    },
    regular: {
        size: { min: 23, max: 23 },
        line: { min: 28, max: 28 },
        weight: 800,
        letterSpacing: 0,
        ...baseTitle,
    },
    small: {
        size: { min: 19, max: 19 },
        line: { min: 24, max: 24 },
        ...baseTitle,
    },
    tiny: {
        size: { min: 15, max: 16 },
        line: { min: 20, max: 20 },
        ...baseTitle,
    },
    micro: {
        size: { min: 14, max: 15 },
        line: { min: 15, max: 20 },
        ...baseTitle,
    },
};

const bodyStyles: Record<BodyVariant, FontStyle> = {
    huge: {
        size: { min: 15, max: 21 },
        line: { min: 24, max: 28 },
        weight: 200,
    },
    large: {
        size: { min: 15, max: 16 },
        line: { min: 24, max: 28 },
        weight: 300,
    },
    regular: {
        size: { min: 15, max: 17 },
        line: { min: 24, max: 28 },
        letterSpacing: 0,
        weight: 400,
    },
    small: {
        size: { min: 15, max: 16 },
        line: { min: 24, max: 24 },
        letterSpacing: 0,
        weight: 400,
    },
    tiny: {
        size: { min: 12, max: 13 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
        weight: 600,
    },
    micro: {
        size: { min: 12, max: 12 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
    },
};

const mediaQueriesValues = {
    xs: 480,
    s: 688,
    m: 1024,
    l: 1200,
    xl: 1520,
    xxl: 1660,
    xxxl: 1920,
};

const theme: Theme = {
    ...defaultTheme,
    name: 'Texel',

    fontFamily: {
        title: 'Montserrat, sans-serif',
        body: 'Montserrat, sans-serif',
    },
    fontSizes: {
        title: titleStyles,
        body: bodyStyles,
    },
    breakpoints: Object.values(mediaQueriesValues).map(value => `${value}px`),
    mediaQueries: Object.assign(
        {},
        ...Object.entries(mediaQueriesValues).map(([key, value]) => ({
            [key]: `${value}px`,
        }))
    ),
    space: Object.values(spacing),
    spacing,
    mediaQueriesValues,
    colors,
    colorAssignments: {
        main: ['primary', 80],
        buttonLight: ['secondary', 40],
        buttonPrimary: ['primary', 80],
        textAction: ['primary', 60],
        input: ['secondary', 30],
        form: ['primary', 50],
        rating: ['primary', 60],
        booking: ['secondary', 50],
        special: ['primary', 40],
        calendar: defaultTheme.colorAssignments.calendar,
    },
    radius: {
        button: '2rem',
        textInput: '0.8rem',
        textArea: '0.8rem',
        dropdown: '0.5rem',
    },
};

export default theme;
