"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Container = void 0;
var _ArrowLeft = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/ArrowLeft"));
var _ArrowRight = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/ArrowRight"));
var _UnreachableCaseError = require("@oberoninternal/travelbase-ds/entities/UnreachableCaseError");
var _addDays = _interopRequireDefault(require("date-fns/addDays"));
var _addYears = _interopRequireDefault(require("date-fns/addYears"));
var _isSameMonth = _interopRequireDefault(require("date-fns/isSameMonth"));
var _react = _interopRequireWildcard(require("react"));
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _useScrollIntoView = _interopRequireDefault(require("../../../hooks/useScrollIntoView"));
var _isDate = _interopRequireDefault(require("../../../utils/isDate"));
var _Context = require("./Context");
var _Month = _interopRequireWildcard(require("./Month"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const GAP = 16;
const Inline = ({
  scrollIntoView = false,
  align = 'right',
  hideInput = false,
  hasFlexibleDates = false,
  forceOpen = false,
  className
}) => {
  const {
    datepicker: [{
      inView,
      selecting,
      period,
      open: datePickerStateOpen
    }, {
      onNextClick,
      onPreviousClick,
      onClick,
      onHover
    }],
    computeDayProps,
    locale
  } = (0, _Context.useDatepickerContext)();
  const open = forceOpen ? true : datePickerStateOpen;
  const monthsRef = (0, _react.useRef)(null);
  const getMonthDimensions = (0, _react.useCallback)((index = 0) => {
    const monthEl = monthsRef.current?.firstChild?.childNodes?.[index];
    return {
      width: monthEl ? monthEl.clientWidth + GAP : 0,
      height: monthEl ? Math.ceil(monthEl.clientHeight) : 0
    };
  }, []);
  const {
    0: maxHeight,
    1: setMaxHeight
  } = (0, _react.useState)(0);
  const {
    0: animate,
    1: setAnimate
  } = (0, _react.useState)(null);
  const onAnimationEndHandler = (0, _react.useCallback)(() => {
    if (animate === 'next') {
      onNextClick();
    }
    if (animate === 'previous') {
      onPreviousClick();
    }
    setAnimate(null);
  }, [animate, onNextClick, onPreviousClick]);
  const getSortedMonthDimensionsForRange = (0, _react.useCallback)((indexA, indexB) => [getMonthDimensions(indexA).height, getMonthDimensions(indexB).height].sort((a, b) => b - a)[0], [getMonthDimensions]);
  const onAnimationStartHandler = (0, _react.useCallback)(() => {
    if (animate === 'next') {
      const tallestHeight = getSortedMonthDimensionsForRange(2, 3);
      setMaxHeight(tallestHeight);
    }
    if (animate === 'previous') {
      const tallestHeight = getSortedMonthDimensionsForRange(0, 1);
      setMaxHeight(tallestHeight);
    }
  }, [animate, getSortedMonthDimensionsForRange]);
  (0, _react.useEffect)(() => {
    if (monthsRef.current) {
      const monthWidth = getMonthDimensions().width;
      monthsRef.current.scrollLeft = monthWidth;
    }
  }, [getMonthDimensions]);
  (0, _useScrollIntoView.default)(monthsRef, scrollIntoView && open, scrollIntoView && !open, {
    block: 'center'
  });
  (0, _react.useEffect)(() => {
    const tallestHeight = getSortedMonthDimensionsForRange(1, 2);
    setMaxHeight(tallestHeight);
  }, [getSortedMonthDimensionsForRange, open]);
  const [end] = inView;

  // We render 4 months, from which the two in the middle are in view
  const months = [...inView];
  return __jsx(Container, {
    maxheight: maxHeight,
    open: open,
    align: align,
    hideInput: hideInput,
    hasFlexibleDates: hasFlexibleDates,
    className: className
  }, !(0, _isSameMonth.default)(inView[0], new Date()) && __jsx(_react.default.Fragment, null, __jsx(LeftMobileButton, {
    disabled: (0, _isSameMonth.default)(inView[0], new Date()),
    onClick: () => setAnimate('previous'),
    type: "button"
  }, __jsx(_ArrowLeft.default, null))), __jsx(MonthContainer, {
    ref: monthsRef
  }, __jsx(Months, {
    translateValue: getMonthDimensions().width,
    animate: animate,
    onAnimationEnd: onAnimationEndHandler,
    onAnimationStart: onAnimationStartHandler
  }, months.map(monthDate => __jsx(_Month.default, {
    locale: locale,
    key: +monthDate,
    isSelecting: selecting,
    onDayMouseEnter: onHover,
    onDayClick: onClick,
    period: (0, _isDate.default)(period) ? {
      start: period,
      end: (0, _addDays.default)(period, 1)
    } : period,
    date: monthDate,
    computeDayProps: computeDayProps
  })))), __jsx(RightMobileButton, {
    disabled: (0, _isSameMonth.default)((0, _addYears.default)(new Date(), 2), end),
    onClick: () => setAnimate('next'),
    type: "button"
  }, __jsx(_ArrowRight.default, null)));
};
var _default = exports.default = Inline;
const LeftMobileButton = _styledComponents.default.button.withConfig({
  displayName: "Inline__LeftMobileButton",
  componentId: "sc-1lgnjhy-0"
})(["width:2.4rem;height:2.4rem;appearance:none;background:none;border:none;position:absolute;left:1.6rem;top:1.6rem;z-index:1111;", ""], ({
  disabled
}) => disabled && 'opacity: .6;');
const RightMobileButton = (0, _styledComponents.default)(LeftMobileButton).withConfig({
  displayName: "Inline__RightMobileButton",
  componentId: "sc-1lgnjhy-1"
})(["left:unset;right:1.6rem;"]);
const Months = _styledComponents.default.div.withConfig({
  displayName: "Inline__Months",
  componentId: "sc-1lgnjhy-2"
})(["display:flex;width:100%;@keyframes slideRight{from{transform:translateX(0);}to{transform:translateX(", "px);}}@keyframes slideLeft{from{transform:translateX(0);}to{transform:translateX(", "px);}}", ";@media screen and (max-width:", "px){}@media screen and (min-width:", "){> ", " + ", "{margin-left:", "px;}}"], ({
  translateValue
}) => translateValue * -1, ({
  translateValue
}) => translateValue, ({
  animate
}) => animate && `animation: 250ms ease-out ${animate === 'next' ? 'slideRight' : 'slideLeft'}`, ({
  theme
}) => theme.mediaQueriesValues.s - 1, ({
  theme
}) => theme.mediaQueries.s, _Month.StyledMonth, _Month.StyledMonth, GAP);
const getAlignment = align => {
  switch (align) {
    case 'left':
      return (0, _styledComponents.css)(["left:0;"]);
    case 'right':
      return (0, _styledComponents.css)(["right:0;"]);
    case 'center':
      return (0, _styledComponents.css)(["left:25%;"]);
    default:
      throw new _UnreachableCaseError.UnreachableCaseError(align);
  }
};
const Container = exports.Container = _styledComponents.default.div.withConfig({
  displayName: "Inline__Container",
  componentId: "sc-1lgnjhy-3"
})(["", ";transition:max-height 300ms;display:flex;visibility:", ";background:white;max-width:100%;flex-wrap:wrap;border-radius:0.8rem;padding:1.6rem;margin-top:0;z-index:", ";position:absolute;", ";", ""], ({
  maxheight
}) => maxheight && `max-height: calc(${maxheight}px + 6.4rem)`, ({
  open
}) => open ? 'visible' : 'hidden', ({
  theme
}) => theme.zIndices.dropdown, ({
  hideInput
}) => hideInput ? `position: relative;` : `box-shadow: 0 0 40px 0 rgba(16, 36, 48, 0.1);`, ({
  align
}) => getAlignment(align));
const MonthContainer = _styledComponents.default.div.withConfig({
  displayName: "Inline__MonthContainer",
  componentId: "sc-1lgnjhy-4"
})(["overflow-x:hidden;width:100%;"]);